import { Card, Container, Flex, Link as RadixLink, Separator, Text } from '@radix-ui/themes'
import { Navigate, createFileRoute } from '@tanstack/react-router'
import { EmptyState, Link } from '~/elementsv2'
import { listWorkspaceIntegrationsQueryOptions } from '~/queries'
import { HiOutlineCubeTransparent } from 'react-icons/hi2'
import { cn } from '../../../../utils'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/')({
  loader: ({ context, params }) =>
    context.queryClient.ensureQueryData(listWorkspaceIntegrationsQueryOptions({ workspaceId: params.workspaceId })),
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()
  const [firstIntegration] = useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data

  return firstIntegration ? (
    <Navigate
      to="/workspaces/$workspaceId/integrations/$integrationId"
      replace
      params={{ workspaceId, integrationId: firstIntegration.id }}
    />
  ) : (
    <EmptyIntegrations />
  )
}
const EmptyIntegrations = () => {
  return (
    <Container size={'4'} p={'5'}>
      <Card className="m-auto py-10 r-sm:w-[80%]">
        <EmptyState
          icon={({ className, ...props }) => (
            <HiOutlineCubeTransparent strokeWidth="0.9" className={cn('text-accent-10', className)} {...props} />
          )}
          iconSize={5}
          title="You own no integrations"
          description="Integrations connect Botpress to other services. What do you want to do from here?"
        />
        <Flex justify={'center'} align={'center'} className="mx-auto mt-8" gap={'8'}>
          <Flex direction={'column'} align={'center'}>
            <Text size={'2'} className="mb-1 text-gray-11">
              Looking for an integration
            </Text>
            <Link size={'1'} to={'/hub'} params={{}}>
              Seach the Botpress Hub
            </Link>
          </Flex>
          <Separator orientation="vertical" className="absolute left-[50%]" />
          <Flex direction={'column'} align={'center'}>
            <Text size={'2'} className="mb-1 text-gray-11">
              Build your own integration
            </Text>
            <RadixLink size={'1'} href="https://botpress.com/docs/developers/howTo/build-integration" target="blank">
              Developer documentation
            </RadixLink>
          </Flex>
        </Flex>
      </Card>
    </Container>
  )
}
