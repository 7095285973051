import { useSuspenseQuery } from '@tanstack/react-query'
import { BotAnalyticsQueryOptions, analyticsOverviewQueryOptions } from '../../queries'

type Params = Pick<BotAnalyticsQueryOptions, 'botId' | 'workspaceId'>

export const useAnalyticsOverview = (params: Params) => {
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 30)

  const defaultEndDate = new Date()

  return useSuspenseQuery(
    analyticsOverviewQueryOptions({ ...params, startDate: defaultStartDate, endDate: defaultEndDate })
  )
}
