import { Container } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { ManagePersonalAccessTokens } from '~/features/pats/components'

const settingsSearchSchema = z.object({
  action: z.string().optional(),
  note: z.string().optional(),
  redirectTo: z.string().optional(),
})

export const Route = createFileRoute('/workspaces/$workspaceId/profile/settings')({
  validateSearch: settingsSearchSchema,
  component: () => {
    const params = Route.useSearch()
    return (
      <Container size={'3'} p={'5'}>
        <ManagePersonalAccessTokens params={params} />
      </Container>
    )
  },
})
