import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import Stripe from 'stripe'
import { getQueryKey, queryFunctions } from '~/services'

export function useBillingSubscriptionSchedule(workspaceId: string) {
  const { data, ...rest } = useQuery({
    enabled: Boolean(workspaceId),
    queryKey: getQueryKey('workspaces_/$workspaceId_/subscriptionSchedule', { workspaceId }),
    queryFn: async () => queryFunctions['workspaces_/$workspaceId_/subscriptionSchedule']({ workspaceId }),
  })

  const subscriptionSchedule: Stripe.SubscriptionSchedule | undefined =
    data && !_.isEmpty(data) ? (data as Stripe.SubscriptionSchedule) : undefined

  return { subscriptionSchedule, ...rest }
}
