import { Color } from '../../../types'

export const statuses = ['processed', 'failed', 'pending', 'ignored', 'scheduled'] as const

export const statusDetails: Record<(typeof statuses)[number], { label: string; color: Color; description: string }> = {
  processed: { label: 'Processed', color: 'green', description: 'The event has been handled succesfully.' },
  failed: { label: 'Failed', color: 'red', description: 'The event has not been handled successfully.' },
  pending: { label: 'Pending', color: 'blue', description: 'The event is still waiting to be processed.' },
  ignored: { label: 'Ignored', color: 'gray', description: 'The event has been filtered out by subscriptions.' },
  scheduled: {
    label: 'Scheduled',
    color: 'purple',
    description: 'The event has been scheduled to be processed at a later time.',
  },
} as const
