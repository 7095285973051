import { useQuery as useTanstackQuery } from '@tanstack/react-query'
import { listBotsQueryOptions, publicWorkspaceQueryOptions } from '../queries'
import { useAppStore } from '~/stores'
import { Badge } from '@radix-ui/themes'
import { HiOutlineChevronUpDown } from 'react-icons/hi2'
import { WorkspaceSelectorMenu, BotSelectorMenu, type BreadcrumbPage, Breadcrumbs } from '~/componentsV2'
import { useParams, useSearch } from '@tanstack/react-router'
import { useCurrentRouteId } from '~/hooks'
import { useQuery } from '~/services'
import { match } from 'ts-pattern'
import { titleCase } from '~/utils'
import { IconButton } from '@botpress/ui-kit'

type Props = {
  workspaceId?: string
  botId?: string
  className?: string
}

const ignoredRoutes = ['workspaces', 'bots', 'v1', 'v2'] as const

export const NavbarBreadcrumbs = ({ botId, workspaceId, className }: Props) => {
  const { data: workspace } = useTanstackQuery({
    ...publicWorkspaceQueryOptions(workspaceId ?? ''),
    enabled: !!workspaceId,
  })
  const routeId = useCurrentRouteId() ?? ''
  const params = useParams({ strict: false })
  const search = useSearch({ strict: false })

  const { data: workspaces } = useQuery('workspaces_')
  const { data: bots } = useTanstackQuery(listBotsQueryOptions(workspaceId ?? ''))

  const plan = useAppStore((s) => s.activeWorkspace)?.plan

  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    botId: botId ?? '',
    workspaceId: workspaceId ?? '',
  })

  const routes = routeId.split('/').filter(Boolean)

  const breadcrumbsPages: BreadcrumbPage[] = []

  for (let i = 0; i < routes.length; i++) {
    const to = `/${routes.slice(0, i + 1).join('/')}`
    const props = { params, search, to: i !== routes.length - 1 ? (to as any) : undefined }
    if (to) {
      match(routes[i])
        .when(
          (route) => ignoredRoutes.includes(route ?? ''),
          () => {}
        )
        .with('$workspaceId', () => {
          breadcrumbsPages.push({
            ...props,
            name: workspace?.name ?? params.workspaceId ?? 'Workspace',
            trailing: (
              <>
                {plan && plan !== 'community' && (
                  <Badge className="-mb-0.5 capitalize" color={plan === 'team' ? 'grass' : 'iris'}>
                    {plan}
                  </Badge>
                )}
                {workspaces && (
                  <WorkspaceSelectorMenu workspaces={workspaces}>
                    <IconButton variant="minimal" mr={'-2'} size={'1'} color="gray" icon={HiOutlineChevronUpDown} />
                  </WorkspaceSelectorMenu>
                )}
              </>
            ),
          })
        })
        .with('$botId', () => {
          breadcrumbsPages.push({
            ...props,
            name: bot?.name ?? params.botId ?? 'Bot',
            trailing: bots ? (
              <BotSelectorMenu bots={bots}>
                <IconButton variant="minimal" mr={'-2'} size={'1'} color="gray" icon={HiOutlineChevronUpDown} />
              </BotSelectorMenu>
            ) : undefined,
          })
        })
        .otherwise(() => {
          breadcrumbsPages.push({
            ...props,
            name: titleCase(routes[i] ?? ''),
          })
        })
    }
  }

  return <Breadcrumbs gap={'2'} pages={breadcrumbsPages} className={className} />
}
