import { createContext, useContext } from 'react'

type TabsContextValue = {
  selectedTab: string | undefined
}
export const TabsContext = createContext<TabsContextValue | null>(null)

export function useTabsContext() {
  const context = useContext(TabsContext)
  if (!context) {
    throw new Error(
      'useTabsContext must be used within a TabsContextProvider. \n Tabs.* components must be used within a Tabs component.'
    )
  }
  return context
}
