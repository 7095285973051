import { TextBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage, WithBubble, withBubble } from './Utils'

export const TextSchema = BaseMessage.extend({
  type: z.literal('text'),
  payload: z.object({
    text: z.string(),
  }),
})
  .transform(({ type, payload: { text }, ...props }) => ({ ...props, block: withBubble({ type, text }) }))
  .transform<MessageObject<WithBubble<TextBlock>>>(ToMessage)
