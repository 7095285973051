import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/workspaces/$workspaceId/chatbots/$botId/$')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  return <Navigate to="/workspaces/$workspaceId/bots/$botId" params={{ workspaceId, botId }} replace />
}
