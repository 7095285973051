import { Box, Flex, Popover, ScrollArea, Separator, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { Card, DataListItem, DataListRoot, DropdownMenu, EmptyState, Icon, JSONTree } from '~/elementsv2'
import { Boundary, QueryParamBuilder, type FilterMenuInput, Page } from '~/componentsV2'
import { useRef, useState } from 'react'
import { z } from 'zod'
import type { ClientOutputs } from 'botpress-client'
import { DateTime } from 'luxon'
import EmptyStateIcon from '~/assets/programming-04.svg?react'
import { useSuspenseQuery } from '~/services'
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import {
  HiEllipsisHorizontal,
  HiMiniChevronDown,
  HiMiniChevronUp,
  HiMiniChevronUpDown,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2'
import { FileIcon, FileStatusBadge } from '~/features/files/components'
import { cn, formatters } from '~/utils'
import { Button, IconButton } from '@botpress/ui-kit'
import { getCdmStudioUrl } from '~/shared'
import { useWindowSize } from 'react-use'

const filesSearchSchema = z.object({
  tags: z
    .record(z.string())
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/files')({
  validateSearch: filesSearchSchema,
  component: Component,
})

function Component() {
  return (
    <Boundary height={'200px'} loaderSize="6">
      <ConversationsPage />
    </Boundary>
  )
}

const ConversationsPage = () => {
  const filters: FilterMenuInput[] = [
    {
      name: 'Source',
      type: 'object',
      paramName: 'tags',
      value: [
        { name: 'Integration', paramName: 'tags:source', value: 'integration' },
        { name: 'Knowledge Base', paramName: 'tags:source', value: 'knowledge-base' },
      ],
    },
    { name: 'Title', type: 'object', paramName: 'tags:title' },
    { name: 'Knowledge Base Id', type: 'object', paramName: 'tags:kbId' },
    { name: 'Data Source Id', type: 'object', paramName: 'tags:dsId' },
    {
      name: 'System',
      type: 'object',
      paramName: 'tags:system',
      value: 'true',
    },
  ]

  return (
    <Page title="Files">
      <Flex direction={'column'} gap={'4'}>
        <QueryParamBuilder filters={filters} />
        <Boundary loaderSize={'6'}>
          <FilesList />
        </Boundary>
      </Flex>
    </Page>
  )
}

const ROW_HEIGHT = 45
const OPENED_HEIGHT = 550
const SPACE_FOR_FOOTER = 120

const FilesList = () => {
  const { workspaceId, botId } = Route.useParams()
  const { tags } = Route.useSearch()
  const [filters, setFilters] = useState({ orderBy: 'name', direction: 'asc' })
  const scrollableRef = useRef<HTMLDivElement>(null)

  const { orderBy, direction } = filters
  const files = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/files/$tags/all', {
    botId,
    workspaceId,
    tags: tags ?? {},
  }).data.sort((_a, _b) => {
    const a = direction === 'asc' ? _a : _b
    const b = direction === 'asc' ? _b : _a
    if (orderBy === 'name') {
      return a.key.localeCompare(b.key)
    }
    if (orderBy === 'size') {
      return (a.size ?? 0) - (b.size ?? 0)
    }
    if (orderBy === 'updatedAt') {
      return DateTime.fromISO(a.updatedAt).toMillis() - DateTime.fromISO(b.updatedAt).toMillis()
    }
    if (orderBy === 'status') {
      return a.status.localeCompare(b.status)
    }
    return 0
  })

  const rowVirtualizer = useVirtualizer({
    count: files.length,
    getScrollElement: () => scrollableRef.current,
    estimateSize: () => ROW_HEIGHT,
    overscan: 10,
  })

  const { height: windowHeight } = useWindowSize()
  const maxListHeight = windowHeight - (scrollableRef.current?.getBoundingClientRect().top ?? 0) - SPACE_FOR_FOOTER
  const scrollPanelHeight = Math.min(maxListHeight, rowVirtualizer.getTotalSize() + ROW_HEIGHT + 5) //extra 5px to account for borders

  return (
    <>
      <ScrollArea asChild ref={scrollableRef}>
        <Card
          className="p-0"
          style={{
            height: files.length === 0 ? 'auto' : scrollPanelHeight,
            width: '100%',
          }}
        >
          <Text asChild size={'2'}>
            <div
              style={{
                height: files.length === 0 ? 'auto' : rowVirtualizer.getTotalSize() + ROW_HEIGHT,
                position: 'relative',
              }}
            >
              <Flex
                px="3"
                align={'center'}
                justify={'end'}
                className="w-[100%] bg-gray-2 font-medium "
                style={{ height: ROW_HEIGHT }}
              >
                <ColumnFilterButton filters={filters} setFilters={setFilters} field="name" className="ml-7 mr-auto">
                  Name
                </ColumnFilterButton>
                <div className="w-[15%]">
                  <ColumnFilterButton filters={filters} setFilters={setFilters} field="size">
                    Size
                  </ColumnFilterButton>
                </div>
                <div className="w-[15%]">
                  <ColumnFilterButton filters={filters} setFilters={setFilters} field="updatedAt">
                    Last Modified
                  </ColumnFilterButton>
                </div>
                <Text className="w-[15%] text-center">Tags</Text>
                <div className="w-32">
                  <ColumnFilterButton filters={filters} setFilters={setFilters} field="status">
                    Status
                  </ColumnFilterButton>
                </div>
              </Flex>
              {files.length === 0 ? (
                <>
                  <Separator size={'4'} className="col-[1/-1]" />
                  <EmptyState
                    iconSize={6}
                    icon={EmptyStateIcon}
                    className="col-[1/-1] py-16"
                    title="You don't have any files!"
                    description="Files uploaded to this bot will appear here."
                    primaryAction={
                      <Button onClick={() => window.open(getCdmStudioUrl(botId), '_blank')}>Edit in Studio</Button>
                    }
                  />
                </>
              ) : (
                rowVirtualizer.getVirtualItems().map((virtualItem) => {
                  const index = virtualItem.index
                  const file = files[index]!

                  return (
                    <FileRow
                      key={file.id}
                      file={file}
                      virtualItemProps={virtualItem}
                      onOpenChange={(open) => {
                        rowVirtualizer.resizeItem(index, ROW_HEIGHT + OPENED_HEIGHT * Number(open))
                      }}
                    />
                  )
                })
              )}
            </div>
          </Text>
        </Card>
      </ScrollArea>
    </>
  )
}

const ColumnFilterButton = ({
  children,
  field,
  filters: { orderBy, direction },
  setFilters,
  className,
}: {
  children: React.ReactNode
  field: 'name' | 'size' | 'updatedAt' | 'status'
  filters: { orderBy: string; direction: string }
  setFilters: (filters: { orderBy: string; direction: string }) => void
  className?: string
}) => {
  const selected = orderBy === field
  const newSortDirection = selected ? (direction === 'asc' ? 'desc' : 'asc') : 'asc'
  const icon = selected ? (direction === 'asc' ? HiMiniChevronUp : HiMiniChevronDown) : HiMiniChevronUpDown

  return (
    <Button
      variant="ghost"
      color="gray"
      highContrast
      className={cn('w-fit', selected && 'font-semibold', className)}
      onClick={() =>
        setFilters({
          orderBy: field,
          direction: newSortDirection,
        })
      }
      trailing={<Icon size="1" muted={!selected} icon={icon} />}
    >
      {children}
    </Button>
  )
}

type FileRowProps = {
  file: ClientOutputs['listFiles']['files'][number]
  virtualItemProps: VirtualItem
  onOpenChange: (open: boolean) => void
}

const FileRow = ({ file, virtualItemProps: { size, start }, onOpenChange }: FileRowProps) => {
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const navigate = Route.useNavigate()

  const tagCount = Object.keys(file.tags).length
  return (
    <Box
      key={file.id}
      className="absolute left-0 w-[100%] border-t-[1px] border-t-gray-5"
      style={{
        top: ROW_HEIGHT,
        height: size,
        transform: `translateY(${start}px)`,
      }}
    >
      <Flex
        px="3"
        align={'center'}
        justify={'end'}
        className="cursor-pointer hover:bg-gray-2 has-[.clickable:hover]:bg-transparent"
        style={{ height: ROW_HEIGHT }}
        onClick={() => {
          const newOpen = !opened
          onOpenChange(newOpen)
          setOpened(newOpen)
        }}
      >
        <Flex align={'center'} gap="4" className="flex-grow-1 mr-auto max-w-96">
          <FileIcon size="2" variant={'surface'} type={file.contentType} />
          <Text truncate>{file.key}</Text>
        </Flex>
        <Text className="w-[15%] flex-none" wrap={'nowrap'}>
          {formatters.byte(file.size ?? 0)}
        </Text>
        <Text className="w-[15%] flex-none" wrap={'nowrap'} color="gray">
          {DateTime.fromISO(file.updatedAt).toRelative()}
        </Text>
        <Popover.Root open={popoverOpen} onOpenChange={(v) => setPopoverOpen(v)}>
          <Flex className="w-[15%]" align={'center'} justify={'center'}>
            <Popover.Trigger disabled={!tagCount}>
              <Button variant="ghost" size={'1'} className="clickable" onClick={(e) => e.stopPropagation()}>
                {tagCount} {tagCount === 1 ? 'Tag' : 'Tags'}
              </Button>
            </Popover.Trigger>
          </Flex>
          <Popover.Content onClick={(e) => e.stopPropagation()} size={'1'} sideOffset={5} side="bottom" align="start">
            <DataListRoot size={'1'}>
              {Object.entries(file.tags).map(([tag, value]) => (
                <DataListItem highContrast key={tag} label={tag} className="font-semibold">
                  <Button
                    size={'1'}
                    variant="ghost"
                    highContrast
                    color="gray"
                    trailing={<Icon size="1" icon={HiOutlineMagnifyingGlass} />}
                    onClick={() => {
                      void navigate({
                        search: (prev) => ({ ...prev, tags: { ...prev?.tags, [tag]: value } }),
                      })
                      setPopoverOpen(false)
                    }}
                  >
                    {value}
                  </Button>
                </DataListItem>
              ))}
            </DataListRoot>
          </Popover.Content>
        </Popover.Root>
        <Flex className="w-32" justify={'between'} align={'center'}>
          <FileStatusBadge status={file.status} className="w-fit" />
          <DropdownMenu
            color="gray"
            onClick={(e) => e.stopPropagation()}
            content={[
              {
                type: 'item',
                content: (
                  <a href={file.url} target="_blank" rel="noreferrer" download>
                    Download
                  </a>
                ),
              },
            ]}
          >
            <IconButton
              variant="minimal"
              color="gray"
              className="clickable ml-auto"
              onClick={(e) => e.stopPropagation()}
              size={'1'}
              icon={HiEllipsisHorizontal}
            />
          </DropdownMenu>
        </Flex>
      </Flex>
      {opened ? (
        <>
          <Separator size={'4'} />
          <Box p={'4'} className="w-[100%]">
            <JSONTree hideRoot data={file} />
          </Box>
        </>
      ) : null}
    </Box>
  )
}
