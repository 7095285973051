import { useEditor } from '@tiptap/react'

import { ExtensionKit } from '../extensions/extension-kit'

export type BlockEditorProps = {
  content?: string
  editable?: boolean
  onChange?: (content: string) => void
}
export const useBlockEditor = ({ content = '', onChange, editable = true }: BlockEditorProps) => {
  const editor = useEditor(
    {
      onUpdate: ({ editor: e }) => {
        onChange?.(e.getHTML())
      },
      extensions: [...ExtensionKit()],
      editable,
      editorProps: {
        attributes: {
          class: 'min-h-full',
        },
      },
      content,
    },
    [content]
  )

  return { editor }
}
