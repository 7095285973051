import cx from 'classnames'
import React, { ComponentProps } from 'react'
import ReactMarkdown from 'react-markdown'

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const markdownComponents: ComponentProps<typeof ReactMarkdown>['components'] = {
  h1: (props) => <h1 {...props} className={cx('mb-4 mt-8 scroll-mt-24 text-xl text-gray-12', props.className)} />,
  h2: (props) => (
    <h2 {...props} className={cx('mb-4 mt-6 scroll-mt-24 text-lg font-medium text-gray-12', props.className)} />
  ),
  h3: (props) => <h3 {...props} className={cx('mb-4 mt-4 scroll-mt-24 text-gray-12', props.className)} />,
  h4: (props) => <h4 {...props} className={cx('text-gray-12', props.className)} />,
  h5: (props) => <h5 {...props} className={cx('text-gray-12', props.className)} />,
  h6: (props) => <h6 {...props} className={cx('text-gray-12', props.className)} />,
  p: (props) => <p {...props} className={cx('mb-2 text-sm text-gray-11', props.className)} />,
  hr: (props) => <hr className={cx('my-12', props.className)} />,
  a: (props) => (
    <a
      {...props}
      className={cx(
        'font-normal text-gray-11 underline decoration-accent-10 decoration-2 underline-offset-1 hover:text-gray-12 hover:decoration-4 hover:underline-offset-2',
        props.className
      )}
    />
  ),
  ol: ({ ordered: _, ...props }: any) => (
    <ol
      {...props}
      className={cx('mb-4 list-decimal space-y-2 pl-5 text-sm text-gray-11 marker:text-gray-12', props.className)}
    />
  ),
  ul: ({ ordered: _, ...props }: any) => (
    <ul {...props} className={cx('mb-4 list-disc space-y-2 pl-5 text-sm text-gray-11 ', props.className)} />
  ),
  li: ({ ordered: _, ...props }: any) => <li {...props} className={cx('text-gray-11', props.className)} />,
  br: (props) => <br {...props} />,
  img: (props) => <img {...props} className={cx('mb-6', props.className)} />,
  table: (props) => <table {...props} className={cx('w-full border-collapse', props.className)} />,
  tbody: (props) => <tbody {...props} className={cx('border', props.className)} />,
  td: (props) => (
    <td {...props} className={cx('block border border-gray-4 p-2 text-gray-11 lg:table-cell', props.className)} />
  ),
  th: (props) => <th {...props} className={cx('block border border-gray-4 p-2 lg:table-cell', props.className)} />,
  thead: (props) => <thead {...props} className={cx('border', props.className)} />,
  tr: (props) => <tr {...props} className={cx('even:bg-gray-1', props.className)} />,
  pre: (props) => (
    <pre {...props} className={cx('mb-4 rounded-lg border border-gray-4 bg-gray-1 p-4 text-sm', props.className)} />
  ),
  code: ({ ...props }) => <code {...props} className="font-mono" />,
}
