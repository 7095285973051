// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListenConversationRequestHeaders {
  "x-user-key": string;
}

export interface ListenConversationRequestQuery {}

export interface ListenConversationRequestParams {
  id: string;
}

export interface ListenConversationRequestBody {}

export type ListenConversationInput = ListenConversationRequestBody & ListenConversationRequestHeaders & ListenConversationRequestQuery & ListenConversationRequestParams

export type ListenConversationRequest = {
  headers: ListenConversationRequestHeaders;
  query: ListenConversationRequestQuery;
  params: ListenConversationRequestParams;
  body: ListenConversationRequestBody;
}

export const parseReq = (input: ListenConversationInput): ListenConversationRequest & { path: string } => {
  return {
    path: `/conversations/${encodeURIComponent(input['id'])}/listen`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface ListenConversationResponse {}

