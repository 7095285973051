import cx from 'classnames'
import { forwardRef, Ref } from 'react'
import { twMerge } from 'tailwind-merge'

export type PaneProps = { variant?: 'danger' | 'default' } & JSX.IntrinsicElements['div']

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Pane = ({ variant = 'default', ...props }: PaneProps): JSX.Element => (
  <div
    {...props}
    className={twMerge(
      cx(
        'flex flex-col gap-px overflow-hidden rounded-md border bg-gray-1',
        { 'border-gray-6': variant === 'default' },
        { 'border-red-9': variant === 'danger' },
        props.className
      )
    )}
  />
)

Pane.Header = forwardRef((props: JSX.IntrinsicElements['div'], ref: Ref<HTMLDivElement>) => (
  <div {...props} ref={ref} className={cx('border-b border-gray-6 bg-inherit px-6 py-4', props.className)} />
))

Pane.Body = forwardRef((props: JSX.IntrinsicElements['div'], ref: Ref<HTMLDivElement>) => (
  <div {...props} ref={ref} className={cx('flex flex-col gap-4 bg-inherit p-6', props.className)} />
))

Pane.Footer = forwardRef((props: JSX.IntrinsicElements['div'], ref: Ref<HTMLDivElement>) => (
  <div {...props} ref={ref} className={cx('border-t border-gray-6 bg-gray-2 px-6 py-3', props.className)} />
))
