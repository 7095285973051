import { Flex, Link } from '@radix-ui/themes'

const linkClasses = 'text-gray-11 decoration-accent-11 hover:text-accent-11'

const links = [
  { label: 'Terms', href: 'https://botpress.com/legal/terms-of-service' },
  { label: 'Privacy', href: 'https://botpress.com/legal/privacy-statement' },
  { label: 'DPA', href: 'https://botpress.com/legal/data-processing-agreement' },
  { label: 'Status', href: 'https://status.botpress.com' },
  { label: 'Docs', href: 'https://botpress.com/docs' },
  { label: 'Discord Server', href: 'https://discord.gg/botpress' },
]

const AppFooter = () => (
  <footer className="flex flex-col-reverse items-center justify-center gap-3 pb-10 pt-16 text-xs md:flex-row">
    <Flex align="center" gap="2">
      <img className="w-[24px] text-gray-2 opacity-45" src="/bp-logo-small.svg" />
      <Link className={linkClasses} href="https://botpress.com">
        © {new Date().getFullYear()} Botpress, Inc.
      </Link>
    </Flex>
    <Flex align="center" gap="3">
      {links.map(({ label, href }) => (
        <Link key={label} className={linkClasses} href={href}>
          {label}
        </Link>
      ))}
    </Flex>
  </footer>
)

export { AppFooter }
