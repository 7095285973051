import axios, { CreateAxiosDefaults } from 'axios'
import { Client as AutoGeneratedClient } from './gen/client'
import { SignalListener } from './signal-listener'

export { type Types as Signals, zod as signalSchemas } from './gen/schemas'
export { type File, type Message, type Conversation, type User } from './gen/client'
export * as errors from './gen/client/errors'
export { SignalListener } from './signal-listener'

type AsyncFunction = (...args: any[]) => Promise<any>
type I<T> = {
  [K in keyof T]: T[K]
}

export type IClient = I<AutoGeneratedClient>

export type ClientOperation = keyof {
  [O in keyof IClient as IClient[O] extends AsyncFunction ? O : never]: null
}

export type ClientInputs = {
  [O in ClientOperation]: Parameters<IClient[O]>[0]
}

export type ClientOutputs = {
  [O in ClientOperation]: Awaited<ReturnType<IClient[O]>>
}

export type ClientProps = CreateAxiosDefaults & {
  baseURL: string
}

export class Client implements Omit<IClient, 'listenConversation'> {
  private _inner: AutoGeneratedClient
  public constructor(public readonly props: ClientProps) {
    this._inner = new AutoGeneratedClient(axios.create(props))
  }

  public login = (args: ClientInputs['login']) => this._inner.login(args)
  public createConversation = (args: ClientInputs['createConversation']) => this._inner.createConversation(args)
  public createFile = (args: ClientInputs['createFile']) => this._inner.createFile(args)
  public createMessage = (args: ClientInputs['createMessage']) => this._inner.createMessage(args)
  public deleteConversation = (args: ClientInputs['deleteConversation']) => this._inner.deleteConversation(args)
  public deleteMessage = (args: ClientInputs['deleteMessage']) => this._inner.deleteMessage(args)
  public deleteUser = (args: ClientInputs['deleteUser']) => this._inner.deleteUser(args)
  public getConversation = (args: ClientInputs['getConversation']) => this._inner.getConversation(args)
  public getMessage = (args: ClientInputs['getMessage']) => this._inner.getMessage(args)
  public getUser = (args: ClientInputs['getUser']) => this._inner.getUser(args)
  public listConversationMessages = (args: ClientInputs['listConversationMessages']) =>
    this._inner.listConversationMessages(args)
  public listConversations = (args: ClientInputs['listConversations']) => this._inner.listConversations(args)
  public updateConversation = (args: ClientInputs['updateConversation']) => this._inner.updateConversation(args)

  public listenConversation = async ({
    'x-user-key': userKey,
    id,
  }: ClientInputs['listenConversation']): Promise<SignalListener> => {
    const signalListener = await SignalListener.listen({
      url: this.props.baseURL,
      conversationId: id,
      userKey,
    })
    return signalListener
  }
}
