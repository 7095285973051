import cx from 'classnames'
import { Inset, Separator } from '@radix-ui/themes'
import { Card } from '.'

export type ListProps<T> = {
  items: T[]
  children?: React.ReactNode | ((item: T) => React.ReactNode)
  divider?: React.ReactNode
} & Omit<React.ComponentProps<typeof Card>, 'children'>

export const List = <T,>({ items, children, divider = <Separator size="4" />, ...props }: ListProps<T>) => {
  if (!items.length) {
    return null
  }

  return (
    <Card {...props} className={cx('p-0', props.className)}>
      <Inset>
        {items.map((item, i) => {
          return (
            <div key={i}>
              {i !== 0 && divider}
              {typeof children === 'function' ? children(item) : children}
            </div>
          )
        })}
      </Inset>
    </Card>
  )
}
