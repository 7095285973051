import { Navigate, Outlet, createFileRoute } from '@tanstack/react-router'
import { useWebchatV2 } from '~/hooks'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2')({
  component: Component,
})

function Component() {
  const { isWebchatV2Enabled } = useWebchatV2()
  const params = Route.useParams()

  return isWebchatV2Enabled ? (
    <Outlet />
  ) : (
    <Navigate to="/workspaces/$workspaceId/bots/$botId/webchat/v1/general" params={params} />
  )
}
