import { getApiClient, getGenericClient } from '../../../client'
import { listAll } from '../../../utils'

export async function getPublicIntegrations() {
  return listAll(getGenericClient().listPublicIntegrations, { version: 'latest' }, ({ integrations }) => integrations)
}

export async function getWorkspaceIntegrations(workspaceId: string) {
  return listAll(getApiClient(workspaceId).listIntegrations, {}, ({ integrations }) => integrations)
}

export async function getPublicIntegration(id: string) {
  const { integration } = await getGenericClient().getPublicIntegrationById({
    id,
  })

  return integration
}

type listIntegrationApiKeysProps = {
  workspaceId: string
  integrationId: string
}

export async function listIntegrationApiKeys({ integrationId, workspaceId }: listIntegrationApiKeysProps) {
  const { iaks } = await getApiClient(workspaceId).listIntegrationApiKeys({ integrationId })
  return iaks
}

type createIntegrationApikeyProps = {
  workspaceId: string
  integrationId: string
  note: string
}

export async function createIntegrationApikey({ integrationId, workspaceId, note }: createIntegrationApikeyProps) {
  return getApiClient(workspaceId).createIntegrationApiKey({ integrationId, note })
}

type DeleteIntegrationApikeyProps = {
  id: string
  workspaceId: string
}

export const deleteIntegrationApikey = async ({ id, workspaceId }: DeleteIntegrationApikeyProps) => {
  return getApiClient(workspaceId).deleteIntegrationApiKey({ id })
}

export async function getPrivateIntegration({ id, workspaceId }: { id: string; workspaceId: string }) {
  const { integration } = await getApiClient(workspaceId).getIntegration({
    id,
  })

  return integration
}

type getByNameProps = {
  name: string
  version: string
  workspaceId?: string
}

type getByIdProps = {
  integrationId: string
  workspaceId?: string
}

export async function getIntegration(props: getByIdProps | getByNameProps) {
  const client = getGenericClient()
  if ('integrationId' in props) {
    const { integrationId, workspaceId } = props
    try {
      const { integration } = await client.getPublicIntegrationById({
        id: integrationId,
      })
      return integration
    } catch (e) {
      if (workspaceId) {
        return getPrivateIntegration({ id: integrationId, workspaceId })
      }

      throw e
    }
  } else {
    const { name, version, workspaceId } = props
    try {
      const { integration } = await client.getPublicIntegration({
        name,
        version,
      })
      return integration
    } catch (e) {
      if (workspaceId) {
        const { integration } = await getApiClient(workspaceId).getIntegrationByName({ name, version })
        return integration
      }
      throw e
    }
  }
}
