import { Flex, Table, Text } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { Link } from '~/elementsv2'
import { listWorkspaceIntegrationsQueryOptions } from '~/queries'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/versions')({
  component: () => {
    const { workspaceId, integrationId } = Route.useParams()
    const workspaceIntegrations = useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data
    const integration = workspaceIntegrations.find((i) => i.id === integrationId)
    if (!integration) {
      // Should not happend
      return <Text>Integration not found</Text>
    }
    const integrationVersions = workspaceIntegrations.filter((i) => i.name === integration.name)

    return (
      <Flex direction={'column'} gap={'2'}>
        <Text weight={'medium'}>Versions History</Text>
        <Table.Root>
          <Table.Header>
            <Table.Row className="text-gray-11">
              <Table.ColumnHeaderCell className="font-medium" width={'80%'}>
                Version
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="font-medium">Published</Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {integrationVersions.map((i) => (
              <Table.Row key={i.id}>
                <Table.Cell>
                  <Link
                    to="/workspaces/$workspaceId/integrations/$integrationId"
                    params={{ workspaceId, integrationId: i.id }}
                  >
                    {i.version}
                  </Link>
                </Table.Cell>
                <Table.Cell>{DateTime.fromISO(i.createdAt).toRelative()}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Flex>
    )
  },
})
