import * as RadixCollapsible from '@radix-ui/react-collapsible'
import Markdown from 'marked-react'
import { ComponentProps, PropsWithChildren, useState } from 'react'
import { theme } from './theme'
import { CollapsibleNestProps, CollapsibleProps, OpenAPISchemaProperty, PropertyProps, Theme } from './types'

export function Schemer({
  isCollapsible = false,
  ...props
}: PropsWithChildren<CollapsibleProps & { theme?: Theme } & ComponentProps<'div'>>) {
  const [open, setOpen] = useState<boolean>(Boolean(props.defaultOpen))
  return (
    <RadixCollapsible.Root
      open={open}
      onOpenChange={isCollapsible ? setOpen : undefined}
      className={theme.collapsible?.container?.className}
    >
      <RadixCollapsible.Trigger className={theme.collapsible?.trigger?.className}>
        {props.titleComponent ? (
          <props.titleComponent title={props.title} open={open} />
        ) : (
          <>
            <MarkDown markdownString={props.title} theme={theme} />
            <svg
              viewBox="0 0 24 24"
              data-state={open ? 'open' : 'closed'}
              data-collapsible={isCollapsible}
              className={theme.collapsible?.chevron?.className}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
            </svg>
          </>
        )}
      </RadixCollapsible.Trigger>
      <RadixCollapsible.Content className={theme.collapsible?.content?.className}>
        {typeof props.children === 'string' ? (
          <MarkDown theme={theme} markdownString={props.children} />
        ) : (
          props.children
        )}
      </RadixCollapsible.Content>
    </RadixCollapsible.Root>
  )
}

export const MarkDown = (props: { markdownString?: string; theme?: Theme }) => (
  <div className={theme.markdown?.container?.className}>
    <Markdown gfm>{props.markdownString}</Markdown>
  </div>
)

export function SchemerTree(props: CollapsibleNestProps & { title: string; theme?: Theme } & ComponentProps<'div'>) {
  const hasProperties = Boolean(props.schemaObject.properties)

  return (
    <Schemer defaultOpen={false} title={props.title} isCollapsible={hasProperties} theme={props.theme}>
      {hasProperties &&
        Object.entries(props.schemaObject.properties).map(([key, value]) => (
          <Property key={key} name={key} property={value} theme={theme} />
        ))}
    </Schemer>
  )
}

function Property(props: PropertyProps) {
  const defaultProperty = (
    <Schemer
      title={`\`${props.name}\` : ${props.property.type}`}
      isCollapsible={false}
      defaultOpen={Boolean(props.property.description)}
    >
      <p className={props.theme?.description?.className}>{props.property.description}</p>
    </Schemer>
  )

  switch (props.property.type) {
    case 'object':
      return <SchemerTree title={props.name} schemaObject={props.property} />

    case 'array': {
      if (props.property.items?.type === 'object') {
        return <SchemerTree title={props.name} schemaObject={props.property.items} />
      }
      return defaultProperty
    }
    default:
      return defaultProperty
  }
}

export function getPropertyType(property: OpenAPISchemaProperty): string {
  if (!property?.type) {
    return ''
  }

  if (property.type === 'object' && property.additionalProperties) {
    return 'map of objects'
  }

  if (property.type === 'array') {
    return `${property.type} of ${property.items?.type ?? 'string'}`
  }

  return property.type
}
