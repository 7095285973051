import { TextBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage, WithBubble, withBubble } from './Utils'

export const MarkdownSchema = BaseMessage.extend({
  type: z.literal('markdown'),
  payload: z.object({
    markdown: z.string(),
  }),
})
  .transform(({ payload, ...props }) => ({
    ...props,
    block: withBubble({ type: 'text', text: payload.markdown }),
  }))
  .transform<MessageObject<WithBubble<TextBlock>>>(ToMessage)
