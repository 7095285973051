import { useState, type ComponentProps, useEffect, useCallback } from 'react'
import { HiOutlineHashtag } from 'react-icons/hi2'
import { TextInput } from '~/elementsv2'

type Props = Omit<ComponentProps<typeof TextInput>, 'onChange'> & {
  onChange?: (color: string) => void
}

export const HexColorInput = ({ value, onChange, ...props }: Props) => {
  const [inputValue, setInputValue] = useState(value || '')
  const escapeChars = useCallback((val: string) => val.replace(/([^0-9A-F]+)/gi, '').substring(0, 6), [])

  useEffect(() => {
    setInputValue(String(value).replace('#', '') || '')
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = escapeChars(e.target.value.trim())

    // Basic validation for hex color format (# followed by 3 or 6 hexadecimal digits)
    const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/.test(`#${input}`)

    setInputValue(input.replace('#', ''))
    if (isValidHex) {
      if (onChange) {
        onChange(`#${input}`) // pass the event to parent handler if valid
      }
    }
  }

  return <TextInput {...props} value={inputValue} onChange={handleChange} leading={<HiOutlineHashtag />} />
}
