import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type DropdownContextValue = {
  state: [boolean, Dispatch<SetStateAction<boolean>>]
}
export const DropdownContext = createContext<DropdownContextValue | null>(null)

export function useDropdownContext() {
  const context = useContext(DropdownContext)
  if (!context) {
    throw new Error(
      'useDropdownContext must be used within a DropdownContextProvider. \n Dropdown.* components must be used within a Dropdown component.'
    )
  }
  return context
}
