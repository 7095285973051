import {
  LuColumns,
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuImage,
  LuList,
  LuListOrdered,
  LuListTodo,
  LuMinus,
  LuSquareCode,
} from 'react-icons/lu'
import { Command } from './types'

export const Commands: Command[] = [
  {
    name: 'heading1',
    label: 'Heading 1',
    icon: <LuHeading1 />,
    description: 'High priority section title',
    aliases: ['h1'],
    action: (editor) => {
      editor.chain().focus().setHeading({ level: 1 }).run()
    },
  },
  {
    name: 'heading2',
    label: 'Heading 2',
    icon: <LuHeading2 />,
    description: 'Medium priority section title',
    aliases: ['h2'],
    action: (editor) => {
      editor.chain().focus().setHeading({ level: 2 }).run()
    },
  },
  {
    name: 'heading3',
    label: 'Heading 3',
    icon: <LuHeading3 />,
    description: 'Low priority section title',
    aliases: ['h3'],
    action: (editor) => {
      editor.chain().focus().setHeading({ level: 3 }).run()
    },
  },
  {
    name: 'bulletList',
    label: 'Bullet List',
    icon: <LuList />,
    description: 'Unordered list of items',
    aliases: ['ul'],
    action: (editor) => {
      editor.chain().focus().toggleBulletList().run()
    },
  },
  {
    name: 'numberedList',
    label: 'Numbered List',
    icon: <LuListOrdered />,
    description: 'Ordered list of items',
    aliases: ['ol'],
    action: (editor) => {
      editor.chain().focus().toggleOrderedList().run()
    },
  },
  {
    name: 'taskList',
    label: 'Task List',
    icon: <LuListTodo />,
    description: 'Task list with todo items',
    aliases: ['todo'],
    action: (editor) => {
      editor.chain().focus().toggleTaskList().run()
    },
  },
  {
    name: 'codeBlock',
    label: 'Code Block',
    icon: <LuSquareCode />,
    description: 'Code block with syntax highlighting',
    shouldBeHidden: (editor) => editor.isActive('columns'),
    action: (editor) => {
      editor.chain().focus().setCodeBlock().run()
    },
  },
  {
    name: 'image',
    label: 'Image',
    icon: <LuImage />,
    description: 'Insert an image',
    aliases: ['img'],
    action: (editor) => {
      editor.chain().focus().setImageUpload().run()
    },
  },
  {
    name: 'columns',
    label: 'Columns',
    icon: <LuColumns />,
    description: 'Add two column content',
    aliases: ['cols'],
    shouldBeHidden: (editor) => editor.isActive('columns'),
    action: (editor) => {
      editor
        .chain()
        .focus()
        .setColumns()
        .focus(editor.state.selection.head - 1)
        .run()
    },
  },
  {
    name: 'horizontalRule',
    label: 'Horizontal Rule',
    icon: <LuMinus />,
    description: 'Insert a horizontal divider',
    aliases: ['hr'],
    action: (editor) => {
      editor.chain().focus().setHorizontalRule().run()
    },
  },
]
