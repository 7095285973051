import { useSuspenseQuery } from '@tanstack/react-query'
import { cacheDuration } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'
import { getApiClient } from '../../client'

export function useRecentAudits(workspaceId: string) {
  const { listRecentAudits } = useQueryKeys()
  const client = getApiClient(workspaceId)
  return useSuspenseQuery({
    staleTime: cacheDuration.short,
    queryKey: listRecentAudits(workspaceId),
    queryFn: async () => {
      return (await client.getAuditRecords({ id: workspaceId })).records
    },
    meta: {
      suppressError: true,
    },
  })
}
