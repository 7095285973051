import cx from 'classnames'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useState } from 'react'
import { Checkbox, Input } from '~/elements'
import { Category, useIntegrationCategoriesFilter, useFlaggedIntegrations, useSearchIntegrations } from '../hooks'
import _ from 'lodash'
import { Card, Flex, Text, Tooltip } from '@radix-ui/themes'
import { Link } from '@tanstack/react-router'
import { IntegrationIcon } from '~/features/integrations/components'
import { HiArrowLeft, HiOutlineShieldCheck } from 'react-icons/hi2'
import { Icon } from '~/elementsv2'
import { isBotpressWorkspace } from '~/utils'

const Hub = () => {
  const [categories, filterByCategories] = useIntegrationCategoriesFilter()
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
  const [query, setQuery] = useState('')
  const searchResults = useSearchIntegrations(query)

  const { scrollY } = useScroll()
  const y = useTransform(scrollY, [0, 600], ['0%', '25%'])

  const handleOnCheckedChange = (checked: boolean, newCategory: Category) => {
    if (checked) {
      setSelectedCategories((previous) => [...previous, newCategory])
    } else {
      setSelectedCategories((previous) => previous.filter((category) => category !== newCategory))
    }
  }

  const visibleIntegrations = _(searchResults)
    .thru(useFlaggedIntegrations)
    .thru(filterByCategories.bind(null, selectedCategories))
    .value()

  return (
    <div className="relative isolate mb-24 w-full overflow-clip">
      <motion.img
        style={{ y }}
        src="/Integrations-hub-graphic.png"
        alt="Error"
        className="absolute -right-[300px] -top-[175px] -z-10 w-[1153px] opacity-70"
      />
      <div className="mx-auto flex w-full max-w-7xl flex-col px-12">
        <div className="mb-12 mt-24 w-min">
          <div className="mb-8">
            <Link
              to="/"
              className=" mb-8 mt-6 flex w-fit items-center gap-2 text-sm text-accent-10 decoration-accent-10 hover:text-accent-11 hover:underline"
            >
              <HiArrowLeft className="h-4 w-4 " />
              <span>Back to Dashboard</span>
            </Link>
          </div>
          <h1 className="mb-6 w-max text-5xl font-bold text-gray-12">Find an Integration</h1>
          <p className="text-base text-gray-11">Supercharge your bot with ready-to-use integrations.</p>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="mb-4 flex-col md:mb-0 md:mr-8">
            <div className="relative w-full rounded-xl border border-gray-4 p-4 pb-6 md:sticky md:top-20 md:w-72">
              <Input
                className="rounded-lg shadow"
                type="search"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search integrations"
              />
              <p className="my-4 text-sm text-gray-11">Categories</p>
              <div className="flex flex-col gap-2">
                {categories!.map((label) => (
                  <Checkbox
                    key={label}
                    onCheckedChange={(checked) => handleOnCheckedChange(!!checked, label)}
                    label={<span className="text-gray-11 ">{label}</span>}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex-grow">
            <div className={cx('grid auto-rows-fr grid-cols-[repeat(auto-fit,minmax(15rem,1fr))] gap-4')}>
              {visibleIntegrations?.map((integration) => {
                const isVerified =
                  integration.verificationStatus === 'approved' || isBotpressWorkspace(integration.ownerWorkspace.id)
                return (
                  <Link
                    key={integration.id}
                    to="/hub/integrations/$integrationId"
                    params={{ integrationId: integration.id }}
                  >
                    <Card className=" h-full rounded-xl p-6 transition-colors hover:border-accent-9 hover:shadow">
                      <div className="mt-2 flex items-center gap-4">
                        <IntegrationIcon className="h-8" iconUrl={integration.iconUrl} />
                        <Flex direction="column">
                          <Flex gap="2" align={'center'}>
                            <Text className="text-base font-medium leading-tight text-gray-12">
                              {integration.title}
                            </Text>
                            {isVerified && (
                              <Tooltip content="Verified by Botpress">
                                <Icon color="green" size="2" icon={HiOutlineShieldCheck} />
                              </Tooltip>
                            )}
                          </Flex>
                          <p className="ml-1 text-xs text-gray-11">by {integration.ownerWorkspace.handle}</p>
                        </Flex>
                      </div>
                      <p className="mt-3 text-xs leading-4 text-gray-11">{integration.description}</p>
                    </Card>
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Hub }
