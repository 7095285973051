import { useQuery } from '@tanstack/react-query'
import { getPublicIntegrations } from '../../features/integrations/services'
import { cacheDuration } from '../../shared'
import { getQueryKey } from '~/services'

export function usePublicIntegrations() {
  return useQuery({
    staleTime: cacheDuration.medium,
    queryKey: getQueryKey('integrations_'),
    queryFn: async () => {
      return getPublicIntegrations()
    },
    meta: {
      errorMessage: 'Sorry, a problem occurred while trying to fetch the available integrations',
    },
  })
}
