import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react'
import { useCallback } from 'react'
import { sticky } from 'tippy.js'

import { ColumnLayout } from './Columns'
import { nanoid } from 'nanoid'
import { MenuProps } from '~/componentsV2/RichTextEditor/components/menus/types'
import { getRenderContainer } from '~/componentsV2/RichTextEditor/utils'
import { LuColumns, LuPanelLeft, LuPanelRight } from 'react-icons/lu'
import { Card } from '@radix-ui/themes'
import { ToolbarButton } from '../../components/ui/ToolbarButton'

export const ColumnsMenu = ({ editor, appendTo }: MenuProps) => {
  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'columns')
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)

    return rect
  }, [editor])

  const shouldShow = useCallback(() => {
    const isColumns = editor.isActive('columns')
    return isColumns
  }, [editor])

  const onColumnLeft = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarLeft).run()
  }, [editor])

  const onColumnRight = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.SidebarRight).run()
  }, [editor])

  const onColumnTwo = useCallback(() => {
    editor.chain().focus().setLayout(ColumnLayout.TwoColumn).run()
  }, [editor])

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`columnsMenu-${nanoid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        appendTo: () => appendTo?.current,
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Card className="bg-gray-1 [&_>div]:flex [&_>div]:p-2">
        <ToolbarButton
          tooltip="Sidebar left"
          active={editor.isActive('columns', { layout: ColumnLayout.SidebarLeft })}
          onClick={onColumnLeft}
        >
          <LuPanelLeft />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Two columns"
          active={editor.isActive('columns', { layout: ColumnLayout.TwoColumn })}
          onClick={onColumnTwo}
        >
          <LuColumns />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Sidebar right"
          active={editor.isActive('columns', { layout: ColumnLayout.SidebarRight })}
          onClick={onColumnRight}
        >
          <LuPanelRight />
        </ToolbarButton>
      </Card>
    </BaseBubbleMenu>
  )
}

export default ColumnsMenu
