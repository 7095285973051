import { useMutation } from '@tanstack/react-query'
import { TApiClient, getGenericClient } from '../../client'

type CheckHandleAvailability = Parameters<TApiClient['checkHandleAvailability']>[number]

export function useCheckWorkspaceHandleAvailability() {
  return useMutation({
    mutationFn: async ({ handle }: CheckHandleAvailability) => {
      return getGenericClient()
        .checkHandleAvailability({ handle })
        .catch(() => ({ available: false, suggestions: [] }))
    },
  })
}
