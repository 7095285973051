import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Page } from '~/componentsV2'
import { BotConfigurationVariables } from '~/features/bots/components'
import { useIsAuthorized } from '~/hooks'
import { botQueryOptions } from '~/queries'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/configuration-variables')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
  const { user } = Route.useRouteContext()
  const authorize = useIsAuthorized({ workspaceId, userId: user.id })

  return (
    <Page title="Configuration Variables">
      <BotConfigurationVariables bot={bot} workspaceId={workspaceId} authorize={authorize} />
    </Page>
  )
}
