// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetUserRequestHeaders {
  "x-user-key": string;
}

export interface GetUserRequestQuery {}

export interface GetUserRequestParams {}

export interface GetUserRequestBody {}

export type GetUserInput = GetUserRequestBody & GetUserRequestHeaders & GetUserRequestQuery & GetUserRequestParams

export type GetUserRequest = {
  headers: GetUserRequestHeaders;
  query: GetUserRequestQuery;
  params: GetUserRequestParams;
  body: GetUserRequestBody;
}

export const parseReq = (input: GetUserInput): GetUserRequest & { path: string } => {
  return {
    path: `/users/me`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: {  },
    body: {  },
  }
}

export interface GetUserResponse {
  /**
   * The user object represents someone interacting with the bot within a specific integration. The same person interacting with a bot in slack and messenger will be represented with two different users.
   */
  user: {
    /**
     * Id of the [User](#schema_user)
     */
    id: string;
    /**
     * workspace member id of the [User](#schema_user)
     */
    workspaceMemberId?: string;
    /**
     * Name of the [User](#schema_user)
     */
    name?: string;
    /**
     * Picture url of the [User](#schema_user)
     */
    pictureUrl?: string;
    /**
     * Creation date of the [User](#schema_user) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [User](#schema_user) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Additional dynamic information about the [User](#schema_user)
     */
    tags: {
      [k: string]: string;
    };
  };
}

