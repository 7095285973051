import { prefixToObjectMap, type Prefixes } from '@bpinternal/const'
import { match, P } from 'ts-pattern'
import { getCdmStudioUrl } from '~/shared'

const prefixArray = Object.keys(prefixToObjectMap) as Prefixes[]

export function parseIdentifier(value: string) {
  return match(value)
    .with(P.union(P.string.startsWith('https://'), P.string.startsWith('http://')), () => 'url' as const)
    .with(P.string.startsWith('ins-'), () => 'ins' as const)
    .with(P.string.startsWith('nd-'), () => 'nd' as const)
    .with(P.string.startsWith('wf-'), () => 'wf' as const)
    .with(P.string, (v) => {
      const splittedPrefix = v.split('_')
      const prefix = splittedPrefix[0] ?? ''
      return prefixArray.includes(prefix) && splittedPrefix.length > 1 ? (prefix as Prefixes) : ('unknown' as const)
    })
    .exhaustive()
}

export const isIdentifier = (value: string) => {
  return parseIdentifier(value) !== 'unknown'
}

export const createIdentifierStudioUrl = ({
  botId,
  workflowId,
  cardId,
  nodeId,
}: {
  botId: string
  workflowId?: string
  nodeId?: string
  cardId?: string
}) => {
  if (!workflowId) {
    return getCdmStudioUrl(botId)
  }
  const url = new URL(`${getCdmStudioUrl(botId)}/flows/${workflowId}`)
  const params = new URLSearchParams({ ...(nodeId ? { nodeId } : {}), ...(cardId ? { instructionId: cardId } : {}) })
  url.search = params.toString()
  return url.toString()
}
