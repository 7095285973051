// @ts-ignore types not working when importing from /component
import { SocialIcon as LibSocialIcon, networkFor } from 'react-social-icons/component'
// for tree shaking purposes
import 'react-social-icons/facebook'
import 'react-social-icons/twitter'
import 'react-social-icons/x'
import 'react-social-icons/linkedin'
import 'react-social-icons/behance'
import 'react-social-icons/dribbble'
import 'react-social-icons/codepen'
import 'react-social-icons/github'
import 'react-social-icons/gitlab'
import 'react-social-icons/discord'
import 'react-social-icons/medium'
import 'react-social-icons/youtube'
import 'react-social-icons/sharethis'
import { HiLink } from 'react-icons/hi2'
import { ErrorBoundary } from 'react-error-boundary'

const UnsupportedNetwork = ({ size }: { size: number }) => <HiLink className="mr-1 text-gray-10" size={size} />
const UNSUPPORTED_NETWORK_NAME = 'sharethis'

type Props = {
  account: string
  size?: number
}

export const isSupportedNetwork = (network: string) => network !== UNSUPPORTED_NETWORK_NAME

const SocialIcon = ({ account, size = 28 }: Props) => {
  const network = networkFor(account)

  return network === UNSUPPORTED_NETWORK_NAME ? (
    <UnsupportedNetwork size={size} /> // override the default icon it's too ugly otherwise
  ) : (
    <ErrorBoundary fallback={<UnsupportedNetwork size={size} />}>
      <LibSocialIcon className="mr-1" style={{ width: size, height: size }} network={network} />
    </ErrorBoundary>
  )
}

export { SocialIcon }
