import { Card, Link, Tooltip } from '@radix-ui/themes'
import { ToolbarButton } from '../../ui/ToolbarButton'
import { HiPencil, HiTrash } from 'react-icons/hi2'

export type LinkPreviewPanelProps = {
  url: string
  onEdit: () => void
  onClear: () => void
}

const LinkPreview = ({ onClear, onEdit, url }: LinkPreviewPanelProps) => {
  return (
    <Card className="bg-gray-1 [&__div]:items-center [&_div]:flex">
      <Link href={url} target="_blank" rel="noopener noreferrer" className="text-sm underline">
        {url}
      </Link>
      <Tooltip content="Edit link">
        <ToolbarButton onClick={onEdit}>
          <HiPencil />
        </ToolbarButton>
      </Tooltip>
      <Tooltip content="Remove link">
        <ToolbarButton onClick={onClear}>
          <HiTrash />
        </ToolbarButton>
      </Tooltip>
    </Card>
  )
}

export { LinkPreview }
