import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

export type CalendarProps = React.ComponentProps<typeof DayPicker>
/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cx('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 text-gray-11',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: 'h-6 w-6 p-1 rounded-md hover:bg-gray-4 hover:text-gray-12',
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-gray-11 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'text-center text-sm p-0 relative overflow-hidden focus-within:relative focus-within:z-20',
        day: cx('hover:bg-gray-4 rounded-md  hover:text-gray-12 h-9 w-9 p-0 font-normal aria-selected:opacity-100'),
        day_selected:
          'bg-accent-4 text-accent-12 hover:bg-accent-4 hover:text-accent-12 focus:bg-accent-4 focus:text-accent-12',
        day_today: 'border border-grass-9 text-grass-11',
        day_outside: 'text-gray-10',
        day_disabled: 'text-gray-11 opacity-50',
        day_range_middle: 'aria-selected:bg-gray-1 rounded-none aria-selected:text-gray-11',
        day_hidden: 'invisible',
        day_range_start: 'rounded-r-none aria-selected:text-gray-12',
        day_range_end: 'rounded-l-none aria-selected:text-gray-12',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeftIcon />,
        IconRight: () => <ChevronRightIcon />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'
