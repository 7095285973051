import { Avatar as RadixAvatar, Box } from '@radix-ui/themes'
import { ComponentProps, forwardRef } from 'react'
import { getUserAvatarURL, hashIdToColor } from '../utils'
import { Color } from '../types/colors'

type Props = {
  pictureUrl?: string
  name?: string
  className?: string
  color?: Color | 'auto'
} & Pick<ComponentProps<typeof RadixAvatar>, 'size' | 'variant' | 'highContrast' | 'radius' | 'src'>

export const Avatar = forwardRef<HTMLDivElement, Props>(
  (
    { pictureUrl, name, radius, variant = 'solid', size = '2', highContrast, src, className, color = 'auto', ...props },
    ref
  ) => {
    const firstLetter = name?.[0]?.toUpperCase() ?? '?'
    const avatarUrl = pictureUrl ?? (name ? getUserAvatarURL(name) : undefined)
    return (
      <Box {...props} ref={ref}>
        <RadixAvatar
          {...{ variant, radius, highContrast, src, className, size }}
          color={color === 'auto' ? hashIdToColor(name ?? '', 'regular') : color}
          src={avatarUrl}
          fallback={firstLetter}
        />
      </Box>
    )
  }
)
