import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon3 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <rect width="39.999" height="6.666" x="16.667" y="20.001" fill="var(--accent-5)" />
      <path
        fill="var(--accent-11)"
        d="M60,13.333H13.333c-1.832,0-3.333,1.502-3.333,3.334v13.334c0,1.832,1.501,3.332,3.333,3.332H60	c1.832,0,3.332-1.5,3.332-3.332V16.667C63.332,14.835,61.832,13.333,60,13.333z M56.666,26.667H16.667v-6.666h39.999V26.667z"
      />
      <rect width="40" height="6.665" x="43.333" y="46.667" fill="var(--accent-5)" />
      <path
        fill="var(--accent-11)"
        d="M86.666,40.001H40c-1.833,0-3.333,1.5-3.333,3.332v13.333c0,1.834,1.5,3.336,3.333,3.336h46.667	c1.832,0,3.334-1.502,3.334-3.336V43.333C90,41.501,88.498,40.001,86.666,40.001z M83.332,53.332h-40v-6.665h40V53.332z"
      />
      <rect width="39.999" height="6.67" x="16.667" y="73.332" fill="var(--accent-5)" />
      <path
        fill="var(--accent-11)"
        d="M60,66.666H13.333c-1.832,0-3.333,1.5-3.333,3.336v13.33c0,1.834,1.501,3.334,3.333,3.334H60	c1.832,0,3.332-1.5,3.332-3.334v-13.33C63.332,68.166,61.832,66.666,60,66.666z M56.666,80.002H16.667v-6.67h39.999V80.002z"
      />
      <polygon
        fill="var(--accent-5)"
        points="20.667,23.468 20.667,20.001 16.667,20.001 16.667,26.667 56.666,26.667 56.666,23.468"
      />
      <polygon
        fill="var(--accent-5)"
        points="47.333,50.132 47.333,46.667 43.333,46.667 43.333,53.332 83.332,53.332 83.332,50.132"
      />
      <polygon
        fill="var(--accent-5)"
        points="20.667,76.801 20.667,73.334 16.667,73.334 16.667,80.002 56.666,80.002 56.666,76.801"
      />
    </svg>
  )
}
