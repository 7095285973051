import { useId } from '@floating-ui/react-dom-interactions'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { ComponentProps } from 'react'
import { CodeSnippet } from '../components'

type Props = {
  label?: React.ReactNode
  value: string
  description: string
  obfuscated?: boolean
} & ComponentProps<'div'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Copy = (props: Props) => {
  const id = useId()
  const { label, value, description, className } = props
  return (
    <div {...props} className={cx('overflow-hidden', className)}>
      {label && (
        <div className={cx('mb-1 text-sm text-gray-11')}>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      <CodeSnippet
        icon={
          <ClipboardDocumentIcon className=" invisible absolute right-4 top-4 h-6 w-6 text-accent-10 opacity-0 transition-all group-hover:visible group-hover:opacity-100" />
        }
        code={value}
        description={description}
        className="border-2 border-dashed hover:border-accent-9"
      />
    </div>
  )
}
