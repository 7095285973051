import { Box } from '@radix-ui/themes'
import { cn } from '../../utils'
import './Spinner.css'
import { forwardRef } from 'react'

type Props = {
  className?: string
  size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9'
}

export const Spinner = forwardRef<HTMLDivElement, Props>(({ className, size = '9' }, ref) => (
  <Box className={className} width={`var(--space-${size})`} height={`var(--space-${size})`} ref={ref}>
    <svg className={cn('container h-full w-full')} viewBox="0 0 40 40" height="40" width="40">
      <title>loading...</title>
      <circle className="track" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="3px" fill="none" />
      <circle className="car" cx="20" cy="20" r="17.5" pathLength="100" strokeWidth="3px" fill="none" />
    </svg>
  </Box>
))
