import {
  init,
  replayIntegration,
  replayCanvasIntegration,
  breadcrumbsIntegration,
  browserProfilingIntegration,
  extraErrorDataIntegration,
  functionToStringIntegration,
  httpContextIntegration,
} from '@sentry/react'
import { config } from './configurations'

export function initSentry() {
  const sentryDsn = config.sentryDSN

  if (!sentryDsn) {
    return
  }

  init({
    dsn: sentryDsn,
    defaultIntegrations: false,
    integrations: [
      replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [config.apiBaseUrl],
        networkCaptureBodies: true,
      }),
      replayCanvasIntegration(),
      breadcrumbsIntegration({ console: true }),
      browserProfilingIntegration(),
      extraErrorDataIntegration(),
      functionToStringIntegration(),
      httpContextIntegration(),
    ],
    environment: config.sentryEnvironment,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
