import { Callout as RadixCallout, Flex } from '@radix-ui/themes'
import { ComponentProps, FC, forwardRef, ReactNode } from 'react'
import { cn } from '../utils'

type Props = {
  icon?: FC<{ className?: string }>
  children: ReactNode
  className?: string
  color?: 'grass' | 'blue' | 'red' | 'amber' | 'gray'
  calloutAction?: ReactNode
} & Pick<ComponentProps<typeof RadixCallout.Root>, 'size' | 'variant' | 'highContrast' | 'onClick'>

export const Callout = forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, children, calloutAction, className, ...props }, ref) => {
    return (
      <RadixCallout.Root {...props} ref={ref} className={cn(className, 'flex items-start')}>
        {Icon && (
          <RadixCallout.Icon>
            <Icon className="size-5 flex-none" />
          </RadixCallout.Icon>
        )}
        <Flex justify={'between'} gap={'3'} className="grow">
          <RadixCallout.Text className="w-full">{children}</RadixCallout.Text>
          {calloutAction}
        </Flex>
      </RadixCallout.Root>
    )
  }
)
