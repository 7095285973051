export const roundPrice = (price: number) => Math.ceil(price * 100) / 100

export const toOptionalFixed = (num: number, digits: number) => {
  return Number.parseFloat(num.toFixed(digits))
}

export const dollars = (val: number) => val.toFixed(2)

export const formatters = {
  // Takes in a number of bytes as input and returns a human-readable string (in SI system), e.g.
  // - 1000 bytes => 1 kB
  // - 1000000 bytes => 1 MB
  // - 1000000000 bytes => 1 GB
  byte(value: number) {
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB']
    const unitIndex = value ? Math.floor(Math.log(value) / Math.log(1000)) : 0
    const unit = units[unitIndex]
    const unitValue = value / 1000 ** unitIndex

    return `${Intl.NumberFormat('en').format(toOptionalFixed(unitValue, 1))} ${unit}`
  },
  number(value: number) {
    return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
  },
  numberDefault(value: number) {
    return Intl.NumberFormat('en').format(value)
  },
  nanodollars(value: number) {
    return `$${roundPrice(nanoToBase(value)).toFixed(2).toString()}`
  },
}

export function nanoToBase(nano: number): number {
  const nanoDollarsPerDollar = 1_000_000_000
  return nano / nanoDollarsPerDollar
}
