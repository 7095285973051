import * as wc from '@botpress/webchat'

export type Client = wc.WebchatClient

export type AsyncFunction = (...args: any[]) => Promise<any>
export type Operation = keyof {
  [O in keyof wc.WebchatClient as wc.WebchatClient[O] extends AsyncFunction ? O : never]: null
}

export type ClientInputs = {
  [O in Operation]: Parameters<wc.WebchatClient[O]>
}

export type ClientOutputs = {
  [O in Operation]: Awaited<ReturnType<wc.WebchatClient[O]>>
}

export type UserCredentials = NonNullable<ClientOutputs['connect']>
export type Message = NonNullable<ClientOutputs['listMessages']>[0]
export type User = NonNullable<ClientOutputs['getUser']>
export type FileType = wc.FileType

export type Events = wc.WebchatEvents & {
  '*': any
}

export const adapters = wc.__adapters
