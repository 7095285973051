import { Icon1, Icon10, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9 } from '../assets'
import { Box } from '@radix-ui/themes'
import { ComponentProps } from 'react'
import { hashIdToColor } from '../utils'
import { Icon } from '../elementsv2'

type Props = ComponentProps<typeof Box> & {
  id: string
  size?: ComponentProps<typeof Icon>['size']
  className?: string
}

const icons = [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8, Icon9, Icon10] as const

const hashIdToNumber = (id: string, max: number) =>
  id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % max

const getIcon = (id: string) => icons[hashIdToNumber(id, icons.length)] ?? icons[0]

export const BotIcon = ({ id, size = '5', ...props }: Props) => (
  <Icon {...props} size={size} variant="soft" color={hashIdToColor(id)} icon={getIcon(id)} />
)
