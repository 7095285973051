import { queryOptions } from '@tanstack/react-query'
import { GetPreferenceProps, Preferences, resolveKey } from '../services/preferences'
import { cacheDuration } from '../shared'
import { getQueryKey, queryFunctions } from '~/services'

export const userPreferencesQueryOptions = <T extends keyof Preferences>(props: GetPreferenceProps<T>) => {
  return queryOptions({
    queryKey: getQueryKey('preferences/$path', { path: resolveKey(props) }),
    queryFn: async () => queryFunctions['preferences/$path'](props),
    staleTime: cacheDuration.medium,
  })
}
