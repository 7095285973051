import { TextField } from '@radix-ui/themes'
import { ComponentProps, ReactNode } from 'react'

type Props = { leading?: ReactNode; trailing?: ReactNode } & ComponentProps<typeof TextField.Root> &
  Pick<ComponentProps<typeof TextField.Slot>, 'gap'>

export const Input = ({ leading, trailing, ...props }: Props) => {
  return (
    <TextField.Root {...props} className="w-full">
      {leading && <TextField.Slot side="left">{leading}</TextField.Slot>}
      {trailing && <TextField.Slot side="right">{trailing}</TextField.Slot>}
    </TextField.Root>
  )
}
