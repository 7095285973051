import { twMerge } from 'tailwind-merge'

type Props = {
  id: string
  backgroundColor?: string
  className?: string
}

// Count of chatbots images inside /public/chatobts
const numberOfIcons = 69

const getIconId = (id: string) => {
  let total = 1

  for (let i = 0; i < id.length; i++) {
    total += id.charCodeAt(i)
  }

  return total % numberOfIcons
}

export const BotIcon = ({ id, className }: Props) => (
  <img
    alt="Bot Icon"
    className={twMerge('flex aspect-square h-6 rounded-md p-0.5', className)}
    src={`/chatbots/abstract-${getIconId(id)}.svg`}
  />
)
