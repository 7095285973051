import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteIntegrationApikey } from '../../features/integrations/services'
import { IntegrationApiKey } from '../../features/integrations/types'

import { useQueryKeys } from '../useQueryKeys'

export function useDeleteIntegrationApikey({
  workspaceId,
  integrationId,
}: {
  workspaceId: string
  integrationId: string
}) {
  const queryClient = useQueryClient()
  const { listIntegrationApiKeys } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ id }: { id: string }) => deleteIntegrationApikey({ workspaceId, id }),
    onSuccess: async (_, { id }) => {
      queryClient.setQueryData<IntegrationApiKey[]>(listIntegrationApiKeys(integrationId), (iaks) =>
        iaks?.filter((iak) => iak.id !== id)
      )
    },
  })
}
