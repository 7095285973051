import cx from 'classnames'
import { ComponentProps } from 'react'

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Spinner = (props: ComponentProps<'div'>) => (
  <div
    className={cx(
      'inline-block h-12 w-12 animate-spin rounded-full border-2 border-current border-t-accent-9 text-gray-4',
      props.className
    )}
    role="status"
    aria-label="loading"
  />
)
