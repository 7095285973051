import {
  BoltIcon,
  ChatBubbleLeftIcon,
  CircleStackIcon,
  CodeBracketIcon,
  DocumentIcon,
  MapPinIcon,
  PhotoIcon,
  SignalIcon,
  SpeakerWaveIcon,
  TagIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ComponentProps, forwardRef } from 'react'
import { BiCarousel } from 'react-icons/bi'
import { RxCardStack, RxDropdownMenu, RxLetterCaseCapitalize, RxListBullet } from 'react-icons/rx'
import { Card } from '../../../elements'

type CardGridItemProps = {
  item: { name: string; type: string }
  isClickable?: boolean
} & ComponentProps<typeof Card>

export const CardGridItem = forwardRef<HTMLDivElement, CardGridItemProps>(
  ({ item: { name, type }, isClickable, ...props }, ref) => {
    return (
      <>
        <Card
          {...props}
          ref={ref}
          className={classNames(
            'flex items-center rounded-lg px-3 text-sm text-gray-12',
            { 'cursor-pointer hover:border-gray-8': isClickable },
            props.className
          )}
        >
          <ItemIcon type={type} />
          <div className="truncate">{name}</div>
        </Card>
      </>
    )
  }
)

type ItemIconProps = {
  type: string
}

type Icons = {
  readonly [key: string]: JSX.Element
}

export const ItemIcon = ({ type }: ItemIconProps) => {
  const icons: Icons = {
    action: <BoltIcon className="mr-1 h-4 w-4 text-accent-9" />,
    message: <ChatBubbleLeftIcon className="mr-1 h-4 w-4 text-grass-9" />,
    event: <SignalIcon className="mr-1 h-4 w-4 text-emerald-600" />,
    state: <CircleStackIcon className="mr-1 h-4 w-4 text-red-9" />,
    tag: <TagIcon className="mr-1 h-4 w-4 text-amber-9" />,
    location: <MapPinIcon className="mr-1 h-4 w-4 text-grass-9" />,
    image: <PhotoIcon className="mr-1 h-4 w-4 text-grass-9" />,
    file: <DocumentIcon className="mr-1 h-4 w-4 text-grass-9" />,
    video: <VideoCameraIcon className="mr-1 h-4 w-4 text-grass-9" />,
    audio: <SpeakerWaveIcon className="mr-1 h-4 w-4 text-grass-9" />,
    markdown: <CodeBracketIcon className="mr-1 h-4 w-4 text-grass-9" />,
    text: <RxLetterCaseCapitalize className="mr-1 h-4 w-4 text-grass-9" />,
    carousel: <BiCarousel className="mr-1 h-4 w-4 text-grass-9" />,
    card: <RxCardStack className="mr-1 h-4 w-4 text-grass-9" />,
    dropdown: <RxDropdownMenu className="mr-1 h-4 w-4 text-grass-9" />,
    choice: <RxListBullet className="mr-1 h-4 w-4 text-grass-9" />,
  }

  return icons[type] || <ChatBubbleLeftIcon className="mr-1 h-4 w-4 text-grass-9" />
}
