import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback } from 'react'

import { ImageUploader } from './ImageUploader'

const ImageUploadNodeView = ({ getPos, editor }: { getPos: () => number; editor: Editor }) => {
  const onUpload = useCallback(
    (url: string) => {
      if (url) {
        editor.chain().setImageBlock({ src: url }).deleteRange({ from: getPos(), to: getPos() }).focus().run()
      }
    },
    [getPos, editor]
  )

  return (
    <NodeViewWrapper>
      <ImageUploader onUpload={onUpload} />
    </NodeViewWrapper>
  )
}

export { ImageUploadNodeView }
