import React from 'react'
import { useAnalyticsOverview } from '../../../hooks'
import { SparkAreaChart } from '@tremor/react'

import { match } from 'ts-pattern'

type Props = {
  botId: string
  workspaceId: string
  color?: string
}

export const AnalyticsWidget = ({ botId, workspaceId, color = 'green' }: Props) => {
  const analytics = useAnalyticsOverview({ botId, workspaceId }).data.records

  const data = match(analytics.length)
    // Add 2 empty data points to make a straight line when no values
    .with(0, () => [
      { endDateTimeUtc: new Date().toISOString(), messages: 0 },
      { endDateTimeUtc: new Date().toISOString(), messages: 0 },
    ])
    // Duplicate the data to make it look like a straight line if only a single data point
    .with(1, () => [...analytics, ...analytics])
    .otherwise(() => analytics)

  return (
    <SparkAreaChart
      className="h-24 w-full"
      data={data}
      colors={[color]}
      index={'endDateTimeUtc'}
      categories={['messages']}
      curveType="monotone"
    />
  )
}
