import { Integration } from 'botpress-client'
import { SchemerTree } from '@bpinternal/schemer'
import { OpenApiSchemaObject } from '@bpinternal/schemer/types'
import { BoltIcon, CircleStackIcon, LanguageIcon, SignalIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { keys } from 'lodash'
import { ComponentProps, useState } from 'react'
import { Accordion, Popover } from '../../../elements'
import { splitCamelCase } from '../../../utils'
import { CardGrid } from './CardGrid'
import { CardGridItem } from './CardGridItem'
import { DetailSection } from './DetailSection'
type IntegrationDetailsProps = {
  integration: Integration
} & ComponentProps<'div'>

type Item<T> = { name: string; type: T }

export function IntegrationDetails({ integration, ...props }: IntegrationDetailsProps): JSX.Element {
  const states = keys(integration.states).map((key) => ({
    name: key,
    ...integration.states?.[key],
  }))

  const userItems: Item<'state' | 'tag'>[] = [
    ...states.filter((state) => state.type === 'user').map((state) => ({ name: state.name, type: 'state' }) as const),
    ...keys(integration.user.tags).map((tag) => ({ name: tag, type: 'tag' }) as const),
  ]
  const [selectedSchema, setSelectedSchema] = useState<any>(undefined)

  const actionItems = Object.entries(integration.actions).map(([key, value]) => ({
    name: key,
    action: value,
    type: 'action',
  }))
  const [selectedAction, setSelectedAction] = useState<(typeof actionItems)[number]['action'] | undefined>(undefined)

  const eventItems = Object.entries(integration.events).map(([key, value]) => ({
    name: key,
    type: 'event',
    schema: value.schema,
  }))

  const globalStateItems = states
    .filter((state) => state.type !== 'user' && state.type !== 'conversation')
    .map((state) => ({ name: state.name, type: 'state', schema: state.schema }) as const)

  const conversationStates = states
    .filter((state) => state.type === 'conversation')
    .map((state) => ({ name: state.name, type: 'state' }) as const)

  function onActionItemClick(action: (typeof actionItems)[number]['action']): void {
    setSelectedAction(action)
  }

  function onSchemaItemClick(item: any): void {
    setSelectedSchema({ title: item.name, schema: item.schema })
  }

  function cardGridItemWithSchemaPopover(item: (typeof globalStateItems)[number] | (typeof eventItems)[number]) {
    return (
      <Popover key={item.name}>
        <Popover.Trigger>
          <CardGridItem onClick={() => onSchemaItemClick(item)} title={item.name} item={item} isClickable />
        </Popover.Trigger>
        <Popover.Content className="w-[300px]">
          {selectedSchema && <SchemerTree title={selectedSchema.title} schemaObject={selectedSchema?.schema} />}
        </Popover.Content>
      </Popover>
    )
  }
  return (
    <div {...props} className={cx('mx-auto flex w-full flex-col gap-8 pb-16', props.className)}>
      {keys(integration.channels).length > 0 && (
        <Accordion defaultValue={[keys(integration.channels)?.[0] ?? '']}>
          {keys(integration.channels).map((name) => {
            const channelItems = keys(integration.channels[name]?.messages).map(
              (message) =>
                ({
                  name: message,
                  type: message,
                }) as const
            )

            const messageItems = keys(integration.channels[name]?.message.tags).map(
              (tag) => ({ name: tag, type: 'tag' }) as const
            )

            const conversationItems = [
              ...keys(integration.channels[name]?.conversation.tags).map(
                (tag) => ({ name: tag, type: 'tag' }) as const
              ),
              ...conversationStates,
            ]

            return (
              <Accordion.Item key={name} title={name} value={name}>
                <Accordion.Trigger className={cx('text-sm capitalize text-gray-12')}>
                  {splitCamelCase(name)}
                </Accordion.Trigger>

                <Accordion.Content title={name}>
                  <div className="flex flex-col gap-8">
                    <DetailSection
                      title="Message types"
                      icon={<LanguageIcon className="mr-1 h-4 w-4 text-lime-600" />}
                      description="This integration supports these message types"
                    >
                      <CardGrid items={channelItems}>
                        {(item) => <CardGridItem key={item.name} title={item.name} item={item} />}
                      </CardGrid>
                    </DetailSection>
                    <DetailSection
                      title="Message settings"
                      description="These tags are provided with messages from the integration"
                    >
                      <CardGrid items={messageItems}>
                        {(item) => <CardGridItem key={item.name} title={item.name} item={item} />}
                      </CardGrid>
                    </DetailSection>

                    <DetailSection
                      title="Conversation settings"
                      description={
                        integration.user.creation.enabled
                          ? 'This integration creates new conversations'
                          : 'This integration does not create new conversations'
                      }
                    >
                      {conversationItems.length > 0 && (
                        <CardGrid items={conversationItems}>
                          {(item) => <CardGridItem key={item.name} title={item.name} item={item} />}
                        </CardGrid>
                      )}
                    </DetailSection>
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            )
          })}
        </Accordion>
      )}

      {actionItems.length > 0 && (
        <DetailSection
          title="Actions"
          icon={<BoltIcon className="mr-1 h-4 w-4 text-accent-9" />}
          description="List of actions defined by the integration"
        >
          <CardGrid items={actionItems}>
            {(item) => (
              <Popover key={item.name}>
                <Popover.Trigger>
                  <CardGridItem
                    isClickable
                    onClick={() => onActionItemClick(item.action)}
                    title={item.name}
                    item={item}
                  />
                </Popover.Trigger>
                <Popover.Content className="w-[300px] space-y-2">
                  {selectedAction && (
                    <>
                      <SchemerTree title="Input" schemaObject={selectedAction.input.schema as OpenApiSchemaObject} />
                      <SchemerTree title="Output" schemaObject={selectedAction.output.schema as OpenApiSchemaObject} />
                    </>
                  )}
                </Popover.Content>
              </Popover>
            )}
          </CardGrid>
        </DetailSection>
      )}

      {eventItems.length > 0 && (
        <DetailSection
          title="Events"
          icon={<SignalIcon className="mr-1 h-4 w-4 text-grass-9" />}
          description="List of events defined by the integration"
        >
          <CardGrid items={eventItems}>{cardGridItemWithSchemaPopover}</CardGrid>
        </DetailSection>
      )}

      {globalStateItems.length > 0 && (
        <DetailSection
          title="Global states"
          icon={<CircleStackIcon className="mr-1 h-4 w-4 text-red-9" />}
          description="States globally available in your integration"
        >
          <CardGrid items={globalStateItems}>{cardGridItemWithSchemaPopover}</CardGrid>
        </DetailSection>
      )}

      <DetailSection
        title="User settings"
        description={
          integration.user.creation.enabled
            ? 'This integration creates new users'
            : 'This integration does not create new users'
        }
      >
        <CardGrid items={userItems}>
          {(item) => <CardGridItem key={item.name} title={item.name} item={item} />}
        </CardGrid>
      </DetailSection>
    </div>
  )
}
