import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon4 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M23.333,31.667c0,7.094,10.953,15,26.667,15c15.716,0,26.666-7.904,26.666-15c0-7.095-10.95-15-26.666-15	C34.285,16.667,23.333,24.572,23.333,31.667z"
      />
      <path
        fill="var(--accent-5)"
        d="M50,53.333c-15.715,0-26.667,7.903-26.667,15s10.953,15,26.667,15c15.716,0,26.666-7.903,26.666-15	S65.716,53.333,50,53.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M49.998,79.333c-14.248,0-24.58-6.499-26.384-13c-0.186,0.669-0.281,1.337-0.281,2	c0,7.097,10.951,15,26.665,15c15.716,0,26.668-7.903,26.668-15c0-0.663-0.096-1.331-0.281-2	C74.581,72.834,64.248,79.333,49.998,79.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M49.998,42.667c-14.248,0-24.58-6.498-26.384-13c-0.186,0.668-0.281,1.338-0.281,2	c0,7.097,10.951,15,26.665,15c15.716,0,26.668-7.903,26.668-15c0-0.662-0.096-1.332-0.281-2	C74.581,36.169,64.248,42.667,49.998,42.667z"
      />
      <path
        fill="var(--accent-11)"
        d="M50,46.666c-18.41,0-33.333,9.701-33.333,21.667S31.592,90,50,90c18.409,0,33.333-9.701,33.333-21.667	S68.408,46.666,50,46.666z M50,83.333c-15.714,0-26.667-7.903-26.667-15s10.952-15,26.667-15c15.716,0,26.666,7.903,26.666,15	S65.716,83.333,50,83.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M50,57.334c4.878,0,9.506-0.62,13.742-1.74c-3.92-1.401-8.57-2.261-13.742-2.261s-9.822,0.859-13.742,2.261	C40.494,56.714,45.121,57.334,50,57.334z"
      />
      <path
        fill="var(--accent-11)"
        d="M32.238,49.998c-1.665,0.684-3.231,1.458-4.681,2.316c2.641,1.345,5.564,2.448,8.7,3.279	c3.909-1.398,8.545-2.256,13.7-2.261C43.442,53.328,37.37,52.104,32.238,49.998z"
      />
      <path
        fill="var(--accent-11)"
        d="M50.042,53.333c5.155,0.005,9.791,0.862,13.7,2.261c3.136-0.831,6.06-1.935,8.7-3.279	c-1.448-0.858-3.016-1.633-4.681-2.316C62.63,52.104,56.558,53.328,50.042,53.333z"
      />
      <path
        fill="var(--accent-11)"
        d="M50,10c-18.41,0-33.333,9.701-33.333,21.667S31.592,53.334,50,53.334c18.409,0,33.333-9.701,33.333-21.667	S68.408,10,50,10z M50,46.667c-15.714,0-26.667-7.906-26.667-15c0-7.095,10.952-15,26.667-15c15.716,0,26.666,7.905,26.666,15	C76.666,38.763,65.716,46.667,50,46.667z"
      />
    </svg>
  )
}
