import { Integration } from 'botpress-client'
import { CheckIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import React from 'react'
import { BotIcon } from '../../bots/components'
import { BotSummary } from '../../bots/types'
import { useQuery } from '~/services'

type BotSelectorProps = {
  selectedBot: BotSummary | undefined
  setSelectedBot: (botSummary: BotSummary) => void
  currentWorkspaceId: string
  currentIntegration: Integration
}

export const BotSelector = ({
  selectedBot,
  setSelectedBot,
  currentWorkspaceId,
  currentIntegration,
}: BotSelectorProps) => {
  const { data: bots } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: currentWorkspaceId })
  return (
    <>
      <p className="mb-4 px-2 text-sm text-gray-10">To which bot do you want to install the integration?</p>
      <div className="mb-4 flex max-h-[230px] flex-col gap-1 overflow-y-auto">
        {bots?.map((botSummary) => {
          return (
            <BotSelectButton
              key={botSummary.id}
              botSummary={botSummary}
              selected={selectedBot?.id === botSummary.id}
              currentIntegration={currentIntegration}
              onBotSelected={setSelectedBot}
              currentWorkspaceId={currentWorkspaceId}
            />
          )
        })}
      </div>
    </>
  )
}

type BotSelectProps = {
  botSummary: BotSummary
  currentWorkspaceId: string
  selected?: boolean
  currentIntegration: Integration
  onBotSelected: (bot: BotSummary) => void
}

const BotSelectButton = ({
  currentWorkspaceId,
  selected,
  currentIntegration,
  onBotSelected,
  botSummary,
}: BotSelectProps) => {
  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: currentWorkspaceId,
    botId: botSummary.id,
  })
  const disabled = !!bot?.integrations?.[currentIntegration.id]

  return (
    <button
      key={botSummary.id}
      className={cx('group touch-none select-none rounded-md border border-transparent p-2 transition-all', {
        'text-gray-10': disabled,
        'cursor-pointer text-gray-12 ': !disabled,
        'hover:border-gray-4 hover:shadow': !selected && !disabled,
        'border-gray-6 bg-gray-2 text-gray-12 shadow': selected,
      })}
      onClick={() => {
        onBotSelected(botSummary)
      }}
      disabled={disabled}
    >
      <div className={cx('flex items-center text-sm ')}>
        <BotIcon id={botSummary.id} className={cx('mr-2 h-5', { grayscale: disabled })} />
        <span className=" truncate">{botSummary.name}</span>
        <CheckIcon
          className={cx('ml-auto mr-1 aspect-square h-4 transition-all', disabled ? 'opacity-100' : 'opacity-0')}
        />
      </div>
    </button>
  )
}
