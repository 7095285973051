import { Popover, Separator } from '@radix-ui/themes'
import { LuAlignCenter, LuAlignJustify, LuAlignLeft, LuAlignRight, LuSubscript, LuSuperscript } from 'react-icons/lu'
import { useTextmenuCommands } from '../hooks/useTextmenuCommands'
import { useTextmenuStates } from '../hooks/useTextmenuStates'
import { ToolbarButton } from '../../../ui/ToolbarButton'

type Props = {
  commands: ReturnType<typeof useTextmenuCommands>
  states: ReturnType<typeof useTextmenuStates>
}

const TextAlignmentMenu = ({ commands, states }: Props) => {
  const anyNonDefaultActive = [
    states.isSubscript,
    states.isSuperscript,
    states.isAlignCenter,
    states.isAlignRight,
    states.isAlignJustify,
  ].some(Boolean)

  return (
    <Popover.Root>
      <Popover.Trigger>
        <ToolbarButton tooltip="More options" active={anyNonDefaultActive}>
          <LuAlignLeft />
        </ToolbarButton>
      </Popover.Trigger>
      <Popover.Content side="bottom" sideOffset={7} className="border-grey-1 flex items-center border p-1 shadow-none">
        <ToolbarButton
          tooltip="Align left"
          tooltipShortcut={['Shift', 'Mod', 'L']}
          onClick={commands.onAlignLeft}
          active={states.isAlignLeft}
        >
          <LuAlignLeft />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Align center"
          tooltipShortcut={['Shift', 'Mod', 'E']}
          onClick={commands.onAlignCenter}
          active={states.isAlignCenter}
        >
          <LuAlignCenter />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Align right"
          tooltipShortcut={['Shift', 'Mod', 'R']}
          onClick={commands.onAlignRight}
          active={states.isAlignRight}
        >
          <LuAlignRight />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Justify"
          tooltipShortcut={['Shift', 'Mod', 'J']}
          onClick={commands.onAlignJustify}
          active={states.isAlignJustify}
        >
          <LuAlignJustify />
        </ToolbarButton>
        <Separator orientation="vertical" className="mr-1" />
        <ToolbarButton
          tooltip="Subscript"
          tooltipShortcut={['Mod', '.']}
          onClick={commands.onSubscript}
          active={states.isSubscript}
        >
          <LuSubscript />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Superscript"
          tooltipShortcut={['Mod', ',']}
          onClick={commands.onSuperscript}
          active={states.isSuperscript}
        >
          <LuSuperscript />
        </ToolbarButton>
      </Popover.Content>
    </Popover.Root>
  )
}

export { TextAlignmentMenu }
