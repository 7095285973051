import { useSuspenseQuery } from '@tanstack/react-query'
import { getApiClientForBot } from '../../client'
import { cacheDuration } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'
import { listAll } from '~/utils'

type Props = {
  conversationId: string
  botId: string
  workspaceId: string
}

export function useConversationParticipants({ conversationId, botId, workspaceId }: Props) {
  const queryKeys = useQueryKeys()

  return useSuspenseQuery({
    queryKey: queryKeys.listConversationParticipants(conversationId, botId, workspaceId),
    queryFn: () =>
      listAll(getApiClientForBot({ workspaceId, botId }).listUsers, { conversationId }, ({ users }) => users),
    staleTime: cacheDuration.short,
    meta: {
      errorMessage: "Sorry, we couldn't load users at this time.",
    },
  })
}
