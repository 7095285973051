import { Badge, Text } from '@radix-ui/themes'
import { IdentifierDropdownMenu } from '~/componentsV2'
import { cn } from '../utils'
import { Avatar } from '~/elementsv2'
import { forwardRef, type ComponentProps } from 'react'

type Props = {
  userId?: string
  name?: string
  avatarName?: string
  pictureUrl?: string
  className?: string
  color?: ComponentProps<typeof Badge>['color']
  variant?: ComponentProps<typeof Badge>['variant']
}

export const UserBadge = ({
  name,
  pictureUrl,
  userId,
  className,
  variant = 'surface',
  color = 'gray',
  avatarName,
  ...props
}: Props) => {
  const InnerBadge = forwardRef<HTMLDivElement, { className?: string }>(({ className: badgeClasses }, ref) => (
    <Badge {...props} ref={ref} className={badgeClasses} color={color} variant={variant}>
      <Avatar
        variant="soft"
        size={'1'}
        className="size-4 h-4"
        name={avatarName ?? name ?? userId}
        pictureUrl={pictureUrl}
      />
      <Text truncate>{name ?? userId}</Text>
    </Badge>
  ))

  return userId ? (
    <IdentifierDropdownMenu value={userId} prefix={'user'}>
      <InnerBadge
        className={cn(
          {
            'hover:bg-accent-2': variant === 'outline',
            'hover:bg-accent-3': variant === 'surface',
            'hover:bg-accent-4': variant === 'soft',
            'hover:bg-accent-10': variant === 'solid',
          },
          className
        )}
      />
    </IdentifierDropdownMenu>
  ) : (
    <InnerBadge className={className} />
  )
}
