import { useId } from '@floating-ui/react-dom-interactions'
import cx from 'classnames'
import { forwardRef } from 'react'

type Props = JSX.IntrinsicElements['textarea'] & {
  label?: React.ReactNode
  invalid?: boolean
}

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(({ invalid, ...props }: Props, ref) => {
  const id = useId()
  return (
    <div className="flex flex-1 flex-col">
      {props.label && <TextareaLabel forElementId={id}>{props.label}</TextareaLabel>}
      <textarea
        id={id}
        {...props}
        className={cx(
          'rounded-md border bg-gray-2 p-2 text-sm',
          {
            'border-red-9': invalid,
            'border-gray-6': !invalid,
          },
          props.className
        )}
        ref={ref}
      />
    </div>
  )
})

export function TextareaLabel({ children, forElementId }: React.PropsWithChildren<{ forElementId?: string }>) {
  return (
    <div className="mb-1">
      <label className={'text-sm text-gray-11'} htmlFor={forElementId}>
        {children}
      </label>
    </div>
  )
}
