import cx from 'classnames'
import { ComponentProps, forwardRef } from 'react'

export type SkeletonProps = {
  height?: number | string
  width?: number | string
} & ComponentProps<'span'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Skeleton = forwardRef<HTMLSpanElement, SkeletonProps>(({ className, height, width, ...props }, ref) => (
  <span
    ref={ref}
    style={{ height, width }}
    {...props}
    className={cx('items-center rounded-full ', height ? 'flex' : 'inline-flex', { 'w-full': !width }, className)}
  >
    <span className="h-full w-full animate-pulse rounded-full bg-gray-4 leading-[0.5]">&zwnj;</span>
  </span>
))
