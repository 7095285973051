import { useQuery } from '@tanstack/react-query'
import { getIntegration } from '../../features/integrations/services'
import { useQueryKeys } from '../useQueryKeys'

type useIntegrationProps = Parameters<typeof getIntegration>[0]

export function useIntegration(props: useIntegrationProps) {
  const { getIntegrationByName, getIntegration: getIntegrationQueryKey } = useQueryKeys()

  const queryKey =
    'integrationId' in props
      ? getIntegrationQueryKey(props.integrationId)
      : getIntegrationByName(props.name, props.version)

  return useQuery({
    //TODO: set a cache duration, currently not cached to be safe
    queryKey,
    queryFn: async () => getIntegration(props),
    meta: {
      errorMessage: 'Failed to fetch integration, please try again later',
    },
  })
}
