import { ButtonBlock, ColumnBlock, ImageBlock, TextBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage, WithBubble, withBubble } from './Utils'

const ActionSchema = z
  .object({
    action: z.enum(['postback', 'url', 'say']),
    label: z.string().min(1),
    value: z.string().min(1),
  })
  .transform<ButtonBlock>(({ label, value, action }) => ({
    type: 'button',
    variant: action === 'say' ? 'action' : 'link',
    text: label,
    buttonValue: value,
    reusable: false,
  }))

export type CardBlockType = { blocks: CardBlocks } & Omit<ColumnBlock, 'blocks'>
type CardBlocks = (ImageBlock | TextBlock | { type: 'row'; blocks: ButtonBlock[] })[]

export const CardPayload = z
  .object({
    title: z.string().min(1),
    subtitle: z.string().min(1).optional(),
    imageUrl: z.string().min(1).optional(),
    actions: z.array(ActionSchema),
  })
  .transform<CardBlockType>(({ title, imageUrl, actions }) => {
    const cardBlocks: CardBlocks = []

    if (imageUrl) {
      cardBlocks.push({
        type: 'image',
        url: imageUrl,
      })
    }

    if (title) {
      cardBlocks.push({
        type: 'text',
        text: title,
      })
    }

    if (actions.length > 0) {
      cardBlocks.push({
        type: 'row',
        blocks: actions,
      })
    }

    return {
      type: 'column' as const,
      horizontalAlignment: 'center',
      blocks: [...cardBlocks],
    }
  })

export const CardSchema = BaseMessage.extend({
  type: z.literal('card'),
  payload: CardPayload,
}).transform<MessageObject<WithBubble<CardBlockType>>>(({ payload, ...props }) => {
  return ToMessage({ block: withBubble(payload), ...props })
})
