import {
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  formatDistanceToNowStrict,
  isValid,
  parseISO,
} from 'date-fns'

const SHORT_DATE_FORMAT = 'MM/dd/yyyy'
export const MOMENTS_AGO = 'A few moments ago'
export const SHORT_MOMENTS_AGO = 'Moments ago'
const DAYS_IN_WEEK = 7
const MINUTES_IN_MOMENT = 5

export function friendlyFormatISODate(isoDate: string, options?: { short?: boolean }): string {
  const now = new Date()
  const date = parseISO(isoDate)
  const isShortFormat = options?.short

  if (!isValid(date)) {
    return ''
  }

  const diffMins = differenceInMinutes(now, date, { roundingMethod: 'floor' })
  if (diffMins < MINUTES_IN_MOMENT) {
    return isShortFormat ? SHORT_MOMENTS_AGO : MOMENTS_AGO
  }

  if (differenceInCalendarDays(now, date) < DAYS_IN_WEEK) {
    const distance = formatDistanceToNowStrict(date, { addSuffix: true })
    return isShortFormat
      ? distance
          .replace(/ days?/, 'd')
          .replace(/ hours?/, 'h')
          .replace(/ minutes?/, 'm')
          .replace(/ seconds?/, 's')
      : distance
  }

  return format(date, SHORT_DATE_FORMAT)
}
