import { ComponentProps } from 'react'
import { JSONTree as JSONTreeComponent, type KeyPath } from 'react-json-tree'
import { match, P } from 'ts-pattern'
import { copyToClipboard, startsWithAny } from '../utils'
import { prefixToObjectMap, type Prefixes } from '@bpinternal/const'
import { Identifier } from '../componentsV2/Identifier'
import type { MenuItem } from '~/elementsv2/types'
import { ContextMenu } from '~/elementsv2/ContextMenu'
import { Link } from '@radix-ui/themes'

const baseTheme: ComponentProps<typeof JSONTreeComponent>['theme'] = {
  base00: '#00000000', //Background color
  base03: 'var(--gray-7)', //Item string expanded
  base07: 'var(--gray-11)', //Text color
  base08: 'var(--tomato-11)', // Undefined, Function, Symbol, Null
  base09: 'var(--orange-11)', //Number, Boolean
  base0B: 'var(--indigo-11)', // String, Date,  Item string
  base0D: 'var(--gray-11)', // Label, Arrow
  tree: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    fontSize: '12px',
  },
  value: {
    display: 'flex',
    gap: '0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    flex: 'none',
  },
}

type Props = ComponentProps<typeof JSONTreeComponent>

export const JSONTree = ({ ...props }: Props) => (
  <JSONTreeComponent
    theme={baseTheme}
    {...props}
    labelRenderer={(path) => {
      const [label] = path

      return (
        <ContextMenu content={buildContextMenu({ path })} color="gray">
          <span>{label}</span>
        </ContextMenu>
      )
    }}
    valueRenderer={(valueAsString, value, ...path) => {
      return match({ value, path })
        .with(
          {
            value: P.intersection(
              P.string,
              P.when((val) =>
                startsWithAny(
                  String(val),
                  Object.keys(prefixToObjectMap).map((prefix) => `${prefix}_`)
                )
              ),
              P.when((val) => String(val).split('_')[1]?.length === 26)
            ),
          },
          ({ value: val }) => <JSONTreeIdentifier path={path.join('.')} id={val} />
        )
        .with({ value: P.union(P.string.startsWith('https://'), P.string.startsWith('http://')) }, ({ value: val }) => (
          <ContextMenu content={buildContextMenu({ path, value: val })} color="gray">
            <Link
              color="indigo"
              underline="always"
              className="cursor-pointer"
              href={val}
              target="_blank"
              rel="noreferrer noopener"
            >
              {val}
            </Link>
          </ContextMenu>
        ))
        .with({ value: P.string, path: [...P.array(P.string), P.string.startsWith('messageId')] }, ({ value: val }) => (
          <JSONTreeIdentifier path={path.join('.')} prefix="msg" id={val} />
        ))
        .with({ value: P.string, path: [...P.array(P.string), P.string.startsWith('userId')] }, ({ value: val }) => (
          <JSONTreeIdentifier path={path.join('.')} prefix="user" id={val} />
        ))
        .with({ value: P.string }, ({ value: val }) => (
          <ContextMenu content={buildContextMenu({ path, value: val })} color="gray">
            <span>{val}</span>
          </ContextMenu>
        ))
        .otherwise(() => <>{valueAsString}</>)
    }}
  />
)

const JSONTreeIdentifier = (props: { id: string; path?: string; prefix?: Prefixes }) => (
  <Identifier
    {...props}
    color="indigo"
    className="mb-0 inline-flex w-fit items-center truncate py-0 indent-0 align-bottom"
  />
)

function buildContextMenu({ path = [], value }: { path?: KeyPath; value?: string }): MenuItem[] {
  const pathAsString = Array.from(path).reverse().join('.')
  return [
    {
      type: 'item',
      content: 'Copy Path',
      hidden: !path.length,
      onSelect: (e) => void copyToClipboard(pathAsString, `path: ${pathAsString}`, { preventDefault: false, event: e }),
    },
    {
      type: 'item',
      content: 'Copy Value',
      hidden: !value,
      onSelect: (e) => void copyToClipboard(value ?? '', `value: ${value}`, { preventDefault: false, event: e }),
    },
  ]
}
