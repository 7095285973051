import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/$')({
  component: WorkspaceComponent,
})

function WorkspaceComponent() {
  const { botId, workspaceId } = Route.useParams()
  return <Navigate to="/workspaces/$workspaceId/bots/$botId/overview" params={{ workspaceId, botId }} replace />
}
