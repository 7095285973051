import { Separator as SeparatorPrimitive } from '@radix-ui/react-separator'
import cx from 'classnames'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Separator = ({ orientation = 'horizontal', ...props }: ComponentProps<typeof SeparatorPrimitive>) => {
  return (
    <SeparatorPrimitive
      {...props}
      className={twMerge(
        cx('bg-gray-4', { 'w-px': orientation === 'vertical' }, { 'h-px': orientation === 'horizontal' }),
        props.className
      )}
    />
  )
}
