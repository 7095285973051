// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetMessageRequestHeaders {
  "x-user-key": string;
}

export interface GetMessageRequestQuery {}

export interface GetMessageRequestParams {
  id: string;
}

export interface GetMessageRequestBody {}

export type GetMessageInput = GetMessageRequestBody & GetMessageRequestHeaders & GetMessageRequestQuery & GetMessageRequestParams

export type GetMessageRequest = {
  headers: GetMessageRequestHeaders;
  query: GetMessageRequestQuery;
  params: GetMessageRequestParams;
  body: GetMessageRequestBody;
}

export const parseReq = (input: GetMessageInput): GetMessageRequest & { path: string } => {
  return {
    path: `/messages/${encodeURIComponent(input['id'])}`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetMessageResponse {
  /**
   * The Message object represents a message in a [Conversation](#schema_conversation) for a specific [User](#schema_user).
   */
  message: {
    /**
     * Id of the [Message](#schema_message)
     */
    id: string;
    /**
     * Creation date of the [Message](#schema_message) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Payload is the content type of the message.
     */
    payload:
      | {
          text: string;
          type: "text";
          [k: string]: any;
        }
      | {
          markdown: string;
          type: "markdown";
          [k: string]: any;
        }
      | {
          imageUrl: string;
          type: "image";
          [k: string]: any;
        }
      | {
          audioUrl: string;
          type: "audio";
          [k: string]: any;
        }
      | {
          videoUrl: string;
          type: "video";
          [k: string]: any;
        }
      | {
          fileUrl: string;
          title?: string;
          type: "file";
          [k: string]: any;
        }
      | {
          latitude: number;
          longitude: number;
          address?: string;
          title?: string;
          type: "location";
          [k: string]: any;
        }
      | {
          items: {
            title: string;
            subtitle?: string;
            imageUrl?: string;
            actions: {
              action: "postback" | "url" | "say";
              label: string;
              value: string;
              [k: string]: any;
            }[];
            [k: string]: any;
          }[];
          type: "carousel";
          [k: string]: any;
        }
      | {
          title: string;
          subtitle?: string;
          imageUrl?: string;
          actions: {
            action: "postback" | "url" | "say";
            label: string;
            value: string;
            [k: string]: any;
          }[];
          type: "card";
          [k: string]: any;
        }
      | {
          text: string;
          options: {
            label: string;
            value: string;
            [k: string]: any;
          }[];
          type: "dropdown";
          [k: string]: any;
        }
      | {
          text: string;
          options: {
            label: string;
            value: string;
            [k: string]: any;
          }[];
          type: "choice";
          [k: string]: any;
        };
    /**
     * ID of the [User](#schema_user)
     */
    userId: string;
    /**
     * ID of the [Conversation](#schema_conversation)
     */
    conversationId: string;
    /**
     * Additional dynamic information about the [Message](#schema_message)
     */
    tags: {
      [k: string]: string;
    };
  };
}

