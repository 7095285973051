import { useUpdateBot } from '~/hooks'

export function useInstallIntegration(onSuccess?: () => void) {
  const {
    mutate: updateBot,
    isPending: isInstallingIntegration,
    isError: isErrorInstallingIntegration,
  } = useUpdateBot()

  function installIntegration(integrationId: string, selectedWorkspaceId: string, selectedBotId: string) {
    if (!selectedWorkspaceId || !selectedBotId || !integrationId) {
      return
    }
    updateBot({
      workspaceId: selectedWorkspaceId,
      id: selectedBotId,
      integrations: {
        [integrationId]: {
          enabled: false,
          configuration: {},
        },
      },
      onSuccess,
    })
  }
  return { installIntegration, isInstallingIntegration, isErrorInstallingIntegration }
}
