import { Dispatch, SetStateAction, createContext, useContext } from 'react'

export type IntegrationPageLayoutState = Record<string, { startDate: Date; endDate: Date }>

type IntegrationPageLayoutValue = {
  state: [IntegrationPageLayoutState, Dispatch<SetStateAction<IntegrationPageLayoutState>>]
}
export const IntegrationPageLayoutContext = createContext<IntegrationPageLayoutValue | null>(null)

export function useIntegrationPageLayoutContext() {
  const context = useContext(IntegrationPageLayoutContext)
  if (!context) {
    throw new Error(
      'useIntegrationPageLayoutContext must be used within a IntegrationPageLayoutContextProvider. \n IntegrationPageLayout.* components must be used within a IntegrationPageLayout component.'
    )
  }
  return context
}
