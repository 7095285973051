import { Flex, Link, Text } from '@radix-ui/themes'
import { SocialIcon, isSupportedNetwork } from '~/elementsv2/socials/SocialIcon'
import { urlSchema } from '~/utils'

type LinkProps = {
  text?: string
  icon?: React.ReactNode
}

const SocialLink = (props: LinkProps) => {
  if (!props.text) {
    return null
  }

  const parsedURL = urlSchema.safeParse(props.text)

  const displayText =
    parsedURL.success && isSupportedNetwork(parsedURL.data.url)
      ? parsedURL.data.pathName.replace('/', '') || parsedURL.data.hostname
      : props.text

  const textElement = (
    <Text size={'2'} className="text-gray-11">
      {displayText}
    </Text>
  )

  return (
    <Flex align={'center'}>
      <SocialIcon account={props.text} size={18} />
      {parsedURL.success ? (
        <Link href={parsedURL.data.url} target="_blank">
          {textElement}
        </Link>
      ) : (
        textElement
      )}
    </Flex>
  )
}

export { SocialLink }
