import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createIntegrationApikey } from '../../features/integrations/services'
import { IntegrationApiKey } from '../../features/integrations/types'

import { useQueryKeys } from '../useQueryKeys'

export function useCreateIntegrationApikey({
  workspaceId,
  integrationId,
}: {
  workspaceId: string
  integrationId: string
}) {
  const queryClient = useQueryClient()
  const { listIntegrationApiKeys } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ note }: { note: string }) => createIntegrationApikey({ workspaceId, integrationId, note }),
    onSuccess: async (iak) => {
      queryClient.setQueryData<IntegrationApiKey[]>(listIntegrationApiKeys(integrationId), (iaks) => {
        if (!iaks) {
          return [iak]
        }

        return [iak, ...iaks]
      })
    },
  })
}
