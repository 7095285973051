import { startsWithAny } from '../../utils/startsWithAny'
import { P, match } from 'ts-pattern'

// This is temporarly copied from QueryParamBuilder to avoid issues with vitest
export type FilterMenuInput = {
  name: string
  type?: 'string' | 'object' | 'array'
  value?: string | FilterMenuInput[]
  paramName?: string
  icon?: React.ReactNode
}

function extractKeywords(filter: FilterMenuInput): string[] {
  const { value, paramName } = filter
  if (Array.isArray(value)) {
    return value.flatMap((f) => extractKeywords(f))
  }
  return paramName ? [paramName] : []
}

function getKeywordType(filters: FilterMenuInput[], keyword?: string): 'string' | 'object' | 'array' | undefined {
  if (!keyword) {
    return 'string'
  }

  for (const filter of filters) {
    if (Array.isArray(filter.value)) {
      const type = getKeywordType(filter.value, keyword)
      if (type) {
        return type
      }
    }

    if (filter.paramName === keyword) {
      return filter.type ?? 'string'
    }
  }
  return undefined
}

export function parseSearchString(searchString: string, filters: FilterMenuInput[]) {
  const searchKeywords = filters.flatMap(extractKeywords)
  const { parsedParams } = searchString.split(' ').reduce(
    (acc, curr) => {
      if (
        startsWithAny(
          curr,
          searchKeywords.map((k) => `${k}:`)
        )
      ) {
        const [keyword, ...value] = curr.split(':')
        if (!keyword) {
          acc.search.push(curr)
          return acc
        }
        const type = getKeywordType(filters, keyword)
        if (type === 'array') {
          acc.parsedParams[keyword as string] = [
            ...((acc.parsedParams[keyword as string] ?? []) as string[]),
            value.join(':'),
          ]
        } else if (type === 'object') {
          const [key, ...rest] = value
          acc.parsedParams[keyword as string] = {
            ...((acc.parsedParams[keyword as string] ?? {}) as Record<string, string>),
            [key as string]: rest.join(':'),
          }
        } else {
          acc.parsedParams[keyword as string] = value.join(':')
        }
      } else {
        acc.search.push(curr)
      }
      return acc
    },
    { parsedParams: {}, search: [] } as {
      parsedParams: Record<string, string | string[] | Record<string, string>>
      search: string[]
    }
  )
  return { parsedParams }
}

export function parseParams(queryParams: Record<string, string | string[] | Record<string, string> | boolean>) {
  return Object.entries(queryParams)
    .map(([key, value]) => {
      return match(value)
        .with(P.array(), (stringArray) => stringArray.map((v) => `${key}:${v}`).join(' '))
        .with(P.string, (v) => `${key}:${v}`)
        .otherwise((paramRecord) => {
          return Object.entries(paramRecord as any)
            .map(([k, v]) => `${key}:${k}:${v}`)
            .join(' ')
        })
    })
    .join(' ')
}
