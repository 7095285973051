import { EditorContent } from '@tiptap/react'
import React, { useRef } from 'react'

import { LinkMenu } from '../menus'

import { BlockEditorProps, useBlockEditor } from '../../hooks/useBlockEditor'

import '../../styles/blockeditor.css'

import { ColumnsMenu } from '../../extensions/MultiColumn/ColumnsMenu'
import { TextMenu } from '../menus/TextMenu'
import { ImageBlockMenu } from '../../extensions/ImageBlock/components/ImageBlockMenu'

const BlockEditor = (props: BlockEditorProps) => {
  const menuContainerRef = useRef(null)
  const editorRef = useRef(null)

  const { editor } = useBlockEditor(props)

  if (!editor) {
    return null
  }

  return (
    <div className="flex h-full" ref={menuContainerRef}>
      <div className="overflow-hidde relative flex h-full flex-1 flex-col">
        <EditorContent
          editor={editor}
          ref={editorRef}
          className="flex-1 overflow-y-auto p-4 caret-black [&>*]:outline-0 [&>*]:selection:bg-gray-2"
        />
        <LinkMenu editor={editor} appendTo={menuContainerRef} />
        <TextMenu editor={editor} />
        <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
        <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
      </div>
    </div>
  )
}

export { BlockEditor }
