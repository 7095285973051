import type { Color } from '~/types'

const categoryLabels = ['user_code', 'configuration', 'limits', 'other'] as const
type CategoryLabel = (typeof categoryLabels)[number]

export const IssueCategory: Record<CategoryLabel, { title: string; color: Color }> = {
  user_code: { title: 'User code', color: 'grass' },
  configuration: { title: 'Configuration', color: 'blue' },
  limits: { title: 'Limits', color: 'red' },
  other: { title: 'Other', color: 'yellow' },
}
