import zod_messageCreated from './messageCreated.z'
import zod_webchatVisibility from './webchatVisibility.z'
import zod_webchatConfig from './webchatConfig.z'
import zod_custom from './custom.z'
import type { MessageCreated } from './messageCreated.t'
import type { WebchatVisibility } from './webchatVisibility.t'
import type { WebchatConfig } from './webchatConfig.t'
import type { Custom } from './custom.t'

export const json = {
}

export const zod = {
  messageCreated: zod_messageCreated,
  webchatVisibility: zod_webchatVisibility,
  webchatConfig: zod_webchatConfig,
  custom: zod_custom,
}

export type Types = {
  messageCreated: MessageCreated
  webchatVisibility: WebchatVisibility
  webchatConfig: WebchatConfig
  custom: Custom
}