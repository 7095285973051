import { createFileRoute } from '@tanstack/react-router'
import { useDeleteIntegrationApikey, useCreateIntegrationApikey, useSetIntegrationUrl, useFeature } from '~/hooks'
import { Copy } from '~/elements'
import { friendlyFormatISODate } from '~/utils'
import { integrationQueryOptions, listIntegrationApiKeysQueryOptions } from '~/queries'
import cx from 'classnames'
import { TextField, Button, Tooltip, Text } from '@radix-ui/themes'
import { useState } from 'react'
import { Popover, Icon, List } from '~/elementsv2'
import { HiOutlineQuestionMarkCircle } from 'react-icons/hi2'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/settings')({
  component: Component,
  loader: ({ params, context: { queryClient } }) =>
    queryClient.ensureQueryData(listIntegrationApiKeysQueryOptions(params)),
})

function Component() {
  const { workspaceId, integrationId } = Route.useParams()

  const { data: integration } = useSuspenseQuery(integrationQueryOptions({ integrationId, workspaceId }))
  const { mutate: deleteIntegrationApikey } = useDeleteIntegrationApikey({ workspaceId, integrationId })
  const { mutate: createIntegrationApikey } = useCreateIntegrationApikey({ workspaceId, integrationId })
  const { data: apikeys } = useSuspenseQuery(listIntegrationApiKeysQueryOptions({ integrationId, workspaceId }))
  const { mutate: setIntegrationUrl, isError, isPending } = useSetIntegrationUrl({ workspaceId, integrationId })

  const canSelfHostIntegrations = true || useFeature('integrations.selfhosted')

  const currentUrl = integration?.url ?? ''

  const [url, setUrl] = useState(currentUrl)
  const [note, setNote] = useState('')

  const allowSelfHosted = !integration?.public

  return (
    <div>
      {canSelfHostIntegrations && (
        <div>
          <div className="mb-4">
            <div className="mb-1 flex items-center text-sm text-gray-10">
              <p className="mr-1">Self hosted integration's url</p>
              {!allowSelfHosted && (
                <Tooltip
                  content={'Self hosted is only allowed for private integrations'}
                  open={!allowSelfHosted ? undefined : false}
                >
                  <Icon icon={HiOutlineQuestionMarkCircle} color="gray" />
                </Tooltip>
              )}
            </div>
            <div className="flex">
              <TextField.Root
                color={isError ? 'red' : undefined}
                variant={isError ? 'soft' : undefined}
                className="mr-2 w-full"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                disabled={integration?.public}
                placeholder="https://yourdomain.com/integration"
              />
              {allowSelfHosted && url !== currentUrl && (
                <Button
                  loading={isPending}
                  className="flex-none cursor-pointer"
                  onClick={() => setIntegrationUrl({ url: url === '' ? null : url })}
                >
                  <Text>Save</Text>
                </Button>
              )}
            </div>
          </div>
          <div className="mb-2 flex items-end justify-between">
            <p className="text-sm text-gray-10">Integration Api Keys</p>
            <Popover align="end" trigger={<Button className="cursor-pointer">Create api key</Button>}>
              <div>
                <p className="mb-1 text-sm text-gray-10">Note</p>
                <TextField.Root value={note} onChange={(e) => setNote(e.target.value)} className="mb-2" />
                <div className="flex justify-end">
                  <Button
                    className="cursor-pointer"
                    onClick={() => {
                      createIntegrationApikey({ note })
                      setNote('')
                    }}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Popover>
          </div>
          <List items={apikeys}>
            {(apikey) => <IakRow {...apikey} deleteIntegrationApikey={deleteIntegrationApikey} />}
          </List>
        </div>
      )}
    </div>
  )
}

type IakRowProps = {
  note: string
  createdAt: string
  value?: string
  id: string
  deleteIntegrationApikey: (props: { id: string }) => void
}

const IakRow = ({ note, createdAt, value, deleteIntegrationApikey, id }: IakRowProps): JSX.Element => (
  <div className={cx('flex flex-wrap items-center justify-between p-4', { 'bg-grass-3': !!value })}>
    <div className="mr-3">
      <div className="mb-2">
        <b>{note || 'Integration Api Key'}</b>
      </div>
      <div className="text-sm capitalize text-gray-8">Created {friendlyFormatISODate(createdAt)}</div>
    </div>
    {value && (
      <div className="mr-3">
        <Copy className="max-w-[500px]" value={value} description="integration api key" />
      </div>
    )}
    <Button className="cursor-pointer" variant="soft" color="red" onClick={() => deleteIntegrationApikey({ id })}>
      Delete
    </Button>
  </div>
)
