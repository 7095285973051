import { CarouselBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { CardBlockType, CardPayload } from './Card'
import { ToMessage } from './Utils'

type CarouselBlockType = { blocks: CardBlockType[] } & Omit<CarouselBlock, 'blocks'>
export const CarouselSchema = BaseMessage.extend({
  type: z.literal('carousel'),
  payload: z.object({
    items: z.array(CardPayload),
  }),
})
  .transform(({ payload: { items }, ...props }) => ({ ...props, block: { type: 'carousel' as const, blocks: items } }))
  .transform<MessageObject<CarouselBlockType>>(ToMessage)
