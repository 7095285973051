import { ThemeColor } from '@botpress/ui-kit'
import { Flex, Text, Link } from '@radix-ui/themes'
import type { PropsWithChildren } from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { cn } from '~/utils'

type Props = PropsWithChildren<{
  className?: string
  linkUrl?: string
  linkLabel?: string
  title: string
  description: string
}>

export const ConfigSection = ({ className, children, linkLabel, linkUrl, description, title, ...props }: Props) => {
  return (
    <Flex {...props} className={cn(className)} direction={'column'}>
      <Text weight={'medium'}>{title}</Text>
      <Text size={'1'} className="mb-4 text-gray-11">
        {description}{' '}
        {linkUrl && linkLabel && (
          <Link size={'1'} color={ThemeColor} className="inline-flex items-center gap-1" href={linkUrl} target="_blank">
            {linkLabel}
            <FiExternalLink />
          </Link>
        )}
      </Text>
      {children}
    </Flex>
  )
}
