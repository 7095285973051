import { getGenericClient } from '../../../client'
import { Bot, BotV2Partial } from '../../bots/types'
import { WorkspaceMember } from '../../users/types'

export const DEFAULT_WORKSPACE_NAME = 'Default Workspace'

const client = getGenericClient()

/**
 * @deprecated
 * @description This type is deprecated. Use `WorkspaceObject` instead.
 * @see WorkspaceObject
 */
export type Workspace = {
  id: string
  name: string
  ownerId: string
  createdOn: string
  updatedOn: string
  members: WorkspaceMember[]
  bots: Bot[]
  botsV2: BotV2Partial[]
  maxBotCount: number
  stripePublishableKey: string
  spendingLimit: number
}

export type WorkspaceObject = Awaited<ReturnType<typeof client.getWorkspace>>
export type WorkspaceSummary = Awaited<ReturnType<typeof client.listWorkspaces>>['workspaces'][number]
export type WorkspaceMemberObject = Awaited<ReturnType<typeof client.listWorkspaceMembers>>['members'][number]
