import { IconButton as ThemeIconButton } from '@radix-ui/themes'
import clsx from 'clsx'
import { ComponentProps, FC, forwardRef } from 'react'
import { button, dashed, minimal } from './iconButton.module.css'

type Variant = ComponentProps<typeof ThemeIconButton>['variant'] | 'minimal' | 'dashed'

type ButtonProps = { variant?: Variant; icon: FC<{ size?: number | string }> } & Omit<
  ComponentProps<typeof ThemeIconButton>,
  'variant' | 'children'
>

const getIconSize = (size: ButtonProps['size']) => {
  switch (size) {
    case '1':
      return 16
    case '2':
      return 20
    case '3':
      return 22
    case '4':
      return 24
    default:
      return 20
  }
}

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, icon: Icon, size = '2', ...props }, ref) => {
    return (
      <ThemeIconButton
        {...props}
        variant={variant === 'minimal' || variant === 'dashed' ? 'outline' : variant}
        size={size}
        className={clsx(
          'cursor-pointer',
          { [minimal]: variant === 'minimal', [dashed]: variant === 'dashed' },
          button,
          className
        )}
        ref={ref}
      >
        <Icon size={getIconSize(size)} />
      </ThemeIconButton>
    )
  }
)

IconButton.displayName = 'IconButton'
