import { useState } from 'react'
import { useAsync } from 'react-use'
import { getApiClientForBot } from '~/client'
import { config } from '~/shared'

export const useWebchatEmbedScript = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const [embedScript, setEmbedScript] = useState<string | null | undefined>(undefined)
  const [shareableLink, setShareableLink] = useState<string | null | undefined>(undefined)

  useAsync(async () => {
    const injectScriptFiles = await getApiClientForBot({ botId, workspaceId }).listFiles({
      tags: { webchatInjectConfigVersion: '1' },
    })

    const webchatConfigFileUrl = injectScriptFiles.files[0]?.url
    const script = webchatConfigFileUrl
      ? `
    <script src="${config.cdnBaseUrl}/webchat/v2.2/inject.js"></script>\n<script src="${webchatConfigFileUrl}"></script>
    `
      : null
    setEmbedScript(script)

    const shareableConfigFile = await getApiClientForBot({ botId, workspaceId }).listFiles({
      tags: { webchatConfigVersion: '1' },
    })

    const shareableConfigFileUrl = shareableConfigFile.files[0]?.url
    const shareableLink = shareableConfigFileUrl
      ? `${config.cdnBaseUrl}/webchat/v2.3/shareable.html?configUrl=${shareableConfigFileUrl}`
      : null
    setShareableLink(shareableLink)
  }, [botId, workspaceId])

  return { embedScript, shareableLink }
}
