import { BlockObject, BubbleBlock } from '@botpress/webchat'
import { MessageObject } from '../block.types'

export type WithBubble<T extends BlockObject> = { block: T } & Omit<BubbleBlock, 'block'>

//with bubble function with a generic type param
export function withBubble<T extends BlockObject>(block: T): WithBubble<T> {
  return {
    block,
    type: 'bubble',
  }
}

type ToMessageProps<T> = {
  block: T
  createdAt: string
  userId: string
  direction: string
}
export function ToMessage<T extends BlockObject>({
  block,
  createdAt,
  userId,
  direction,
}: ToMessageProps<T>): MessageObject<T> {
  return {
    direction: direction as 'incoming' | 'outgoing',
    sender: { id: userId, name: 'name' },
    timestamp: new Date(createdAt),
    block,
  }
}
