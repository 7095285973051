import { Flex, DropdownMenu as RadixDropdownMenu, Tooltip } from '@radix-ui/themes'
import { ComponentProps } from 'react'
import { MenuItem } from './types'
import { cn } from '../utils'

type DropdownMenuProps = Pick<
  ComponentProps<typeof RadixDropdownMenu.Root>,
  'onOpenChange' | 'dir' | 'modal' | 'defaultOpen' | 'open'
> &
  Pick<ComponentProps<typeof RadixDropdownMenu.Trigger>, 'disabled' | 'children'> &
  Omit<ComponentProps<typeof RadixDropdownMenu.Content>, 'content'> & {
    content: MenuItem[]
  }

export const DropdownMenu = ({
  children,
  disabled,
  onOpenChange,
  dir,
  modal,
  content,
  open,
  defaultOpen,
  variant = 'soft',
  size = '2',
  ...props
}: DropdownMenuProps) => {
  const disabledState = disabled || !content.filter((t) => !t.hidden).length

  return (
    <RadixDropdownMenu.Root {...{ onOpenChange, dir, modal, open, defaultOpen }}>
      <RadixDropdownMenu.Trigger disabled={disabledState} className={cn({ 'cursor-pointer': !disabled })}>
        {!content.filter((t) => !t.hidden).length ? null : children}
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Content {...props} size={size} variant={variant}>
        <DropdownMenuContent content={content} size={size} />
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Root>
  )
}

const DropdownMenuContent = ({ content, size }: { content: MenuItem[]; size: DropdownMenuProps['size'] }) => {
  const baseClassName = cn('cursor-pointer', { 'gap-2 px-3': size === '2', 'gap-1.5 px-2': size === '1' })
  return (
    <>
      {content.map((item, i) => {
        if (item.hidden) {
          return null
        }
        switch (item.type) {
          case 'item': {
            const { content: itemContent, leadingIcon, trailingIcon, tooltip, ...itemProps } = item
            return (
              <Tooltip hidden={!tooltip} content={tooltip} key={i}>
                <RadixDropdownMenu.Item className={baseClassName} {...itemProps}>
                  {leadingIcon}
                  <span className="grow">{itemContent}</span>
                  {trailingIcon}
                </RadixDropdownMenu.Item>
              </Tooltip>
            )
          }
          case 'separator':
            return (
              <RadixDropdownMenu.Separator className={cn({ ' mx-3': size === '2', ' mx-2': size === '1' })} key={i} />
            )
          case 'label':
            return (
              <RadixDropdownMenu.Label className={baseClassName} key={i}>
                {item.label}
              </RadixDropdownMenu.Label>
            )
          case 'radioGroup':
            return (
              <RadixDropdownMenu.RadioGroup key={i} value={item.value} onValueChange={item.onValueChange}>
                {item.items.map((radioItem, j) => {
                  const { content: itemContent, leadingIcon, trailingIcon, ...itemProps } = radioItem
                  return (
                    <RadixDropdownMenu.RadioItem className={cn(baseClassName, '*:relative')} key={j} {...itemProps}>
                      {leadingIcon}
                      <span className="grow">{itemContent}</span>
                      {trailingIcon}
                    </RadixDropdownMenu.RadioItem>
                  )
                })}
              </RadixDropdownMenu.RadioGroup>
            )
          case 'submenu':
            return (
              <RadixDropdownMenu.Sub key={i}>
                <RadixDropdownMenu.SubTrigger
                  className={baseClassName}
                  data-accent-color={item.color}
                  disabled={item.disabled}
                >
                  <Flex gap="2" align={'center'}>
                    {item.leadingIcon}
                    <span>{item.content}</span>
                  </Flex>
                </RadixDropdownMenu.SubTrigger>
                <RadixDropdownMenu.SubContent>
                  <DropdownMenuContent content={item.items} size={size} />
                </RadixDropdownMenu.SubContent>
              </RadixDropdownMenu.Sub>
            )
          case 'checkBoxItem': {
            const { content: itemContent, leadingIcon, trailingIcon, showCheckmark, ...itemProps } = item
            return (
              <RadixDropdownMenu.CheckboxItem
                key={i}
                className={cn(
                  baseClassName,
                  { '[&>.rt-DropdownMenuItemIndicator]:hidden': !showCheckmark && showCheckmark !== undefined },
                  '*:relative'
                )}
                {...itemProps}
              >
                {leadingIcon}
                <span className="grow">{itemContent}</span>
                {trailingIcon}
              </RadixDropdownMenu.CheckboxItem>
            )
          }
          default:
            return <></>
        }
      })}
    </>
  )
}
