import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon2 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M46.667,71.666h-5.834C37.618,71.67,35,74.287,35,77.5c0,3.215,2.614,5.832,5.83,5.832	c3.22,0,5.837-2.617,5.837-5.832V71.666z"
      />
      <path
        fill="var(--accent-5)"
        d="M53.333,28.333h5.834c3.216,0,5.833-2.617,5.833-5.833s-2.617-5.833-5.833-5.833	c-3.217,0-5.834,2.617-5.834,5.833V28.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M53.333,28.333V22.5c0-3.216,2.617-5.833,5.834-5.833c3.216,0,5.833,2.617,5.833,5.833	c0,0.703-0.131,1.375-0.36,2c-0.819-2.232-2.96-3.833-5.473-3.833c-1.011,0-1.834,0.822-1.834,1.833v5.833H53.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M46.667,71.666V77.5c0,3.215-2.617,5.832-5.833,5.832S35,80.715,35,77.5c0-0.703,0.132-1.375,0.361-2	c0.819,2.23,2.959,3.832,5.473,3.832c1.011,0,1.833-0.822,1.833-1.832v-5.834H46.667z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.667,40.833c0,3.217,2.617,5.834,5.833,5.834h24.167V35H22.5C19.285,35,16.667,37.617,16.667,40.833z"
      />
      <path
        fill="var(--accent-5)"
        d="M53.333,65H77.5c3.216,0,5.833-2.617,5.833-5.834s-2.617-5.834-5.833-5.834H53.333V65z"
      />
      <path
        fill="var(--accent-5)"
        d="M57.333,65v-7.668H77.5c2.513,0,4.653,1.602,5.473,3.834c0.229-0.625,0.36-1.297,0.36-2	c0-3.217-2.617-5.834-5.833-5.834H53.333V65H57.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M42.667,35v7.668H22.5c-2.512,0-4.653-1.601-5.472-3.834c-0.229,0.625-0.36,1.296-0.36,2	c0,3.217,2.617,5.834,5.833,5.834h24.167V35H42.667z"
      />
      <path
        fill="var(--accent-11)"
        d="M77.5,46.667H53.333V35h5.834c6.893,0,12.5-5.605,12.5-12.5S66.06,10,59.167,10	c-6.896,0-12.5,5.612-12.5,12.5v5.833H22.5c-6.895,0-12.5,5.605-12.5,12.5s5.605,12.5,12.5,12.5h24.167V65h-5.834	c-6.896,0.002-12.5,5.611-12.5,12.5c0,6.893,5.604,12.5,12.497,12.5c6.896,0,12.503-5.607,12.503-12.5v-5.834H77.5	c6.893,0,12.5-5.607,12.5-12.5C90,52.271,84.393,46.667,77.5,46.667z M53.333,22.5c0-3.216,2.617-5.833,5.834-5.833	c3.216,0,5.833,2.617,5.833,5.833s-2.617,5.833-5.833,5.833h-5.834V22.5z M46.667,77.5c0,3.215-2.617,5.832-5.837,5.832	c-3.216,0-5.83-2.617-5.83-5.832c0-3.213,2.618-5.83,5.833-5.834h5.834V77.5z M46.667,46.667H22.5c-3.215,0-5.833-2.617-5.833-5.834	c0-3.216,2.617-5.833,5.833-5.833h24.167V46.667z M77.5,65H53.333V53.332H77.5c3.216,0,5.833,2.617,5.833,5.834S80.716,65,77.5,65z"
      />
      <rect width="4" height="6.667" x="53.334" y="28.333" fill="var(--accent-11)" />
      <rect width="4" height="6.667" x="42.667" y="28.333" fill="var(--accent-11)" />
      <rect width="6.667" height="4" x="46.667" y="42.667" fill="var(--accent-11)" />
      <rect width="6.667" height="4" x="46.667" y="53.334" fill="var(--accent-11)" />
      <rect width="4" height="6.668" x="53.334" y="65" fill="var(--accent-11)" />
      <rect width="4" height="6.668" x="42.667" y="65" fill="var(--accent-11)" />
    </svg>
  )
}
