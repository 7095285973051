import { QuotaType } from '@bpinternal/const'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getApiClient, getGenericClient } from '../client'
import { queryOptions } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { Usage } from 'botpress-client'
import { clamp } from 'lodash'

type WorkspaceUsageParams = {
  workspaceId: string
  type: QuotaType
  /**
   * ISO date string
   */
  period: string
}

type WorkspaceUsagesParams = {
  workspaceId: string
  types: QuotaType[]
  /**
   * ISO date string
   */
  period: string
}

export const workspaceUsageQueryOptions = ({ workspaceId, type, period }: WorkspaceUsageParams) => {
  const currentPeriodStart = DateTime.now().startOf('month')
  const selectedDate = DateTime.fromISO(period)
  const isPreviousPeriod = selectedDate < currentPeriodStart

  return queryOptions({
    retry: 0,
    staleTime: isPreviousPeriod ? cacheDuration.long : cacheDuration.short,
    queryKey: useQueryKeys().getWorkspaceUsage(workspaceId, type, period),
    queryFn: async () => {
      const client = getApiClient(workspaceId)

      return client.getUsage({
        id: workspaceId,
        type,
        period,
      })
    },
    meta: {
      suppressError: true,
    },
  })
}

type UsageWithPercentage = Usage & { percentage: number }

export const workspaceUsagesQueryOptions = <T extends QuotaType>({
  workspaceId,
  types,
  period,
}: WorkspaceUsagesParams) => {
  const currentPeriodStart = DateTime.now().startOf('month')
  const selectedDate = DateTime.fromISO(period)
  const isPreviousPeriod = selectedDate < currentPeriodStart

  return queryOptions({
    retry: 0,
    staleTime: isPreviousPeriod ? cacheDuration.long : cacheDuration.short,
    queryKey: useQueryKeys().getWorkspaceUsages(workspaceId, period, types),
    queryFn: async () => {
      const client = getApiClient(workspaceId)

      const response = await client.getMultipleUsages({
        ids: [workspaceId],
        types,
        period,
      })

      return response.usages.reduce(
        (acc, usage) => {
          acc[usage.type as T] = {
            ...usage,
            percentage: calculateUsagePercentage(usage),
          }
          return acc
        },
        {} as Record<T, UsageWithPercentage>
      )
    },
    meta: {
      suppressError: true,
    },
  })
}

function calculateUsagePercentage(usage: Usage) {
  return usage.quota ? clamp((usage.value / usage.quota) * 100, 0, 100) : 0
}

export const workspacesUsagesQueryOptions = <T extends QuotaType>({
  workspaceIds,
  types,
  period,
}: {
  workspaceIds: string[]
  types: QuotaType[]
  /**
   * ISO date string
   */
  period: string
}) => {
  const currentPeriodStart = DateTime.now().startOf('month')
  const selectedDate = DateTime.fromISO(period)
  const isPreviousPeriod = selectedDate < currentPeriodStart

  return queryOptions({
    retry: 0,
    staleTime: isPreviousPeriod ? cacheDuration.long : cacheDuration.short,
    queryKey: useQueryKeys().getAllWorkspacesUsages(period, types),
    queryFn: async () => {
      const client = getGenericClient()

      const response = await client.getMultipleUsages({
        ids: workspaceIds,
        types,
        period,
      })

      return response.usages.reduce(
        (acc, usage) => {
          if (!acc[usage.id]) {
            acc[usage.id] = {} as Record<T, UsageWithPercentage>
          }

          acc[usage.id]![usage.type as T] = {
            ...usage,
            percentage: calculateUsagePercentage(usage),
          }

          return acc
        },
        {} as Record<string, Record<T, UsageWithPercentage>>
      )
    },
    meta: {
      suppressError: true,
    },
  })
}

export const usageBreakdownUsageQueryOptions = ({
  workspaceId,
  type,
  period = new Date().toISOString().split('T')[0] ?? '',
}: WorkspaceUsageParams) => {
  const currentPeriodStart = DateTime.now().startOf('month')
  const selectedDate = DateTime.fromISO(period)
  const isPreviousPeriod = selectedDate < currentPeriodStart

  return queryOptions({
    retry: 0,
    staleTime: isPreviousPeriod ? cacheDuration.long : cacheDuration.short,
    queryKey: useQueryKeys().getWorkspaceUsageByBot(workspaceId, type, period),
    queryFn: async () => {
      return getApiClient(workspaceId)
        .breakDownWorkspaceUsageByBot({
          id: workspaceId,
          type,
          period,
        })
        .then((response) => {
          return { type, usages: [...response.data].sort((usageA, usageB) => usageB.value - usageA.value) }
        })
    },
    meta: {
      suppressError: true,
    },
  })
}

export const highestWorkspacesUsageQuery = () => {
  const queryKeys = useQueryKeys()
  return queryOptions({
    queryKey: queryKeys.getWorkspaceQuotaCompletion(),
    queryFn: async () => {
      const client = getGenericClient()
      try {
        const response = await client.getAllWorkspaceQuotaCompletion({})
        return response
      } catch (err) {
        throw new Error('An error occured while processing the usages')
      }
    },
  })
}
