import { Flex, TextField, Text } from '@radix-ui/themes'
import { Avatar, DropdownMenu, MenuItem } from '../elementsv2'
import { NavbarBreadcrumbs, WorkspaceSelectorMenu, showBugReportDialog } from '~/componentsV2'
import { useState } from 'react'
import {
  HiOutlineFlag,
  HiOutlineGlobeAlt,
  HiOutlineKey,
  HiOutlineLink,
  HiOutlineLockClosed,
  HiOutlineUser,
} from 'react-icons/hi2'
import { WorkspaceProfilePicture } from '~/features/workspaces/components/WorkspaceProfilePicture'
import { showErrorToast, showSuccessToast } from '~/services'
import { User } from '~/features/users/types'
import { Link, useRouter, useRouterState } from '@tanstack/react-router'
import { HiOutlineLogout } from 'react-icons/hi'
import { config } from '../shared'
import { Workspace } from 'botpress-client'
import { useAppStore, useLocalStore } from '~/stores'
import _ from 'lodash'
import { ProfilePictureField } from '~/componentsV2/ProfilePictureField'
import { useUpdateUserProfile } from '~/hooks/users/useUpdateUserProfile'
import { copyToClipboard } from '~/utils'
import { TbMoon, TbSun, TbSunMoon } from 'react-icons/tb'
import { Button, DialogFooter, IconButton, showDialog } from '@botpress/ui-kit'

type TopLevelNavProps = {
  workspaces: Workspace[]
}

export const TopNav = ({ workspaces }: TopLevelNavProps) => {
  const user = useAppStore((s) => s.currentUser)
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const router = useRouter()
  const navigate = router.navigate
  const {
    location: { pathname },
  } = useRouterState()

  const params = [...router.state.matches].pop()?.params
  const userHasAccessToWorkspace = workspaces.some((w) => w.id === activeWorkspace?.id)

  const sortedWorkspaces = workspaces.sort((a, b) => a.name.localeCompare(b.name))

  const darkMode = useLocalStore((s) => s.theme)
  const setDarkMode = useLocalStore((s) => s.setTheme)

  const darkModeMenuItems: MenuItem[] = [
    {
      type: 'item',
      leadingIcon: <TbMoon />,
      content: 'Dark',
      onSelect: () => setDarkMode('dark'),
    },
    {
      type: 'item',
      leadingIcon: <TbSun />,
      content: 'Light',
      onSelect: () => setDarkMode('light'),
    },
    {
      type: 'item',
      leadingIcon: <TbSunMoon />,
      content: 'Auto',
      onSelect: () => setDarkMode('auto'),
    },
  ]

  const userMenuItems: MenuItem[] = !user
    ? []
    : [
        { type: 'label', label: user.displayName ?? user.email },
        {
          type: 'item',
          leadingIcon: <HiOutlineUser />,
          content: 'Update profile',
          onSelect: () =>
            showDialog((props) => <UserProfileModal {...props} user={user} />, {
              title: 'Update Profile',
            }),
        },
        {
          type: 'item',
          leadingIcon: <HiOutlineLink />,
          content: 'Link social accounts',
          onSelect: () => location.assign(config.ssoBaseUrl.concat('/link')),
        },
        {
          type: 'item',
          leadingIcon: <HiOutlineKey />,
          content: 'Change password',
          onSelect: () => location.assign(config.ssoBaseUrl.concat('/settings')),
        },
        {
          type: 'item',
          leadingIcon: <HiOutlineLockClosed />,
          content: 'Personal access tokens',
          disabled: !userHasAccessToWorkspace,
          onSelect: () => {
            if (!userHasAccessToWorkspace || !activeWorkspace?.id) {
              return
            }
            void navigate({
              to: '/workspaces/$workspaceId/profile/settings',
              params: { workspaceId: activeWorkspace.id },
            })
          },
        },
        {
          type: 'item',
          leadingIcon: <HiOutlineFlag />,
          content: 'Report a bug',
          onSelect: (e) =>
            showBugReportDialog({
              onSubmit: ({ id }) => void copyToClipboard(id, 'issue ID', { event: e, preventDefault: true }),
            }),
        },
        { type: 'separator' },
        {
          type: 'item',
          color: 'red',
          leadingIcon: <HiOutlineLogout />,
          content: 'Sign out',
          onSelect: () => location.replace(config.ssoBaseUrl.concat('/logout')),
        },
      ]

  return (
    <Flex align={'center'} gap="3">
      {activeWorkspace && (
        <WorkspaceSelectorMenu workspaces={sortedWorkspaces}>
          <Flex align={'center'} gap={'2'} className="relative cursor-pointer">
            <WorkspaceProfilePicture
              workspaceId={activeWorkspace.id}
              className="h-8 w-8 rounded-full"
              pictureUrl={activeWorkspace.profilePicture}
            />
            <Text weight={'medium'} className="lg:hidden">
              {activeWorkspace.name}
            </Text>
          </Flex>
        </WorkspaceSelectorMenu>
      )}
      <NavbarBreadcrumbs
        workspaceId={params && 'workspaceId' in params ? params.workspaceId : undefined}
        botId={params && 'botId' in params ? params.botId : undefined}
        className="max-lg:hidden"
      />
      <div className="ml-auto flex flex-row items-center gap-2">
        {!pathname.includes('/hub') && (
          <Link to="/hub">
            <Button size={'1'} color="iris" variant="outline" leading={<HiOutlineGlobeAlt />}>
              Explore the hub
            </Button>
          </Link>
        )}
        <DropdownMenu variant="soft" color="gray" content={darkModeMenuItems}>
          <IconButton
            variant="minimal"
            color="gray"
            size="2"
            icon={darkMode === 'dark' ? TbMoon : darkMode === 'light' ? TbSun : TbSunMoon}
          />
        </DropdownMenu>

        <DropdownMenu variant="soft" color="gray" content={userMenuItems}>
          <Avatar size={'2'} name={user?.email} pictureUrl={user?.profilePicture} />
        </DropdownMenu>
      </div>
    </Flex>
  )
}

const UserProfileModal = ({ user, close }: { user: User; close: () => void }) => {
  const [profile, setProfile] = useState(_.pick(user, 'displayName', 'profilePicture'))

  const updateProfile = useUpdateUserProfile({
    onSuccess: () => {
      showSuccessToast('Profile updated')
    },
    onError: () => {
      showErrorToast('Error updating profile')
    },
  })

  return (
    <>
      <Flex direction={'column'} gap={'3'} pb={'2'}>
        <ProfilePictureField
          pictureUrl={profile.profilePicture}
          onChange={(pictureUrl) => {
            setProfile({ ...profile, profilePicture: pictureUrl })
          }}
        >
          <Avatar size="8" name={user.email} pictureUrl={profile.profilePicture} />
        </ProfilePictureField>
        <Flex direction={'column'}>
          <Text size={'2'} className="text-gray-10">
            E-mail
          </Text>
          <Text size={'2'} className="hover:cursor-not-allowed">
            {user.email}
          </Text>
        </Flex>
        <div>
          <Text size={'2'} className="text-gray-10">
            Full Name
          </Text>
          <TextField.Root
            value={profile.displayName ?? ''}
            onChange={(e) => {
              setProfile({ ...profile, displayName: e.target.value })
            }}
            placeholder="John Doe"
          />
        </div>
      </Flex>
      <DialogFooter
        onConfirm={() => {
          if (!profile.displayName || (profile.displayName.length ?? 0) > 100) {
            return showErrorToast('Invalid Name')
          }
          void updateProfile.mutate(profile)
          close()
        }}
        onCancel={close}
      />
    </>
  )
}
