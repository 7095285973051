import { useId } from '@floating-ui/react-dom-interactions'
import cx from 'classnames'
import { forwardRef } from 'react'

type Props = JSX.IntrinsicElements['input'] & {
  label?: React.ReactNode
  leadingIcon?: React.ReactNode
  id?: string
}

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Input = forwardRef<HTMLInputElement, Props>(({ leadingIcon, ...props }: Props, ref) => {
  const labelId = props.id ?? useId()
  return (
    <div className="flex w-full flex-col">
      {props.label && <InputLabel forElementId={labelId}>{props.label}</InputLabel>}
      <div className="flex items-center">
        {leadingIcon}
        <input
          {...props}
          className={cx(
            'grow rounded-md border border-gray-6 bg-gray-2 p-2 text-sm placeholder-gray-10 disabled:bg-gray-3',
            props.className
          )}
          ref={ref}
          id={labelId}
        />
      </div>
    </div>
  )
})

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export function InputLabel({ children, forElementId }: React.PropsWithChildren<{ forElementId?: string }>) {
  return (
    <div className="mb-1">
      <label className="text-sm text-gray-11" htmlFor={forElementId}>
        {children}
      </label>
    </div>
  )
}
