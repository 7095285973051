import { Flex } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery, useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { z } from 'zod'
import { EmptyState } from '~/elementsv2'
import { ConversationDetails, ConversationList } from '~/features/hitl/components'
import { SidebarLayout } from '~/layoutsV2'
import { getIntegrationByNameQueryOptions, listHitlConversationsInfiniteQuery } from '~/queries'

import { getClient, HITLClient } from '~/features/hitl/client'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { HITLIntegrationNotEnabledError } from '~/features/hitl/client/errors'
import { useUpdateBot } from '~/hooks'
import { showSuccessToast, useSuspenseQuery } from '~/services'
import { HITLTabs } from '~/features/hitl/components/HITLTabs'
import { cn } from '~/utils'
import { Page } from '~/componentsV2'
import { Button } from '@botpress/ui-kit'

const paramsSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/hitl')({
  component: Component,
  validateSearch: paramsSchema,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { conversationId } = Route.useSearch()
  const { mutate: updateBot } = useUpdateBot()

  const { plan } = useSuspenseQuery('workspaces_/$workspaceId_', { workspaceId }).data
  const hitlIntegration = useTanstackSuspenseQuery(getIntegrationByNameQueryOptions({ name: 'hitl', workspaceId })).data

  const [integrationNotInstalled, setIntegrationNotInstalled] = useState(false)
  const [integrationInstalling, setIntegrationInstalling] = useState(false)
  const [hitlClient, setHitlClient] = useState<HITLClient>()

  const initHitlClient = async () => {
    if (hitlClient?.workspaceId === workspaceId && hitlClient?.botId === botId) {
      return
    }
    try {
      const client = await getClient({ workspaceId, botId })
      setHitlClient(client)
    } catch (error) {
      if (error instanceof HITLIntegrationNotEnabledError) {
        setIntegrationNotInstalled(true)
      }
    }
  }

  useAsync(initHitlClient, [workspaceId, botId])

  const { data: fetchedConversations, refetch } = useSuspenseInfiniteQuery(
    listHitlConversationsInfiniteQuery({ botId, workspaceId })
  )

  useEffect(() => {
    const i = setInterval(refetch, 5000) // yes, long polling..
    return () => clearInterval(i)
  }, [])

  const allConversations = fetchedConversations.pages?.flatMap((page) => page.conversations) ?? []
  const currentConversation = allConversations.find((c) => c.id === conversationId)

  const installHITLIntegration = async () => {
    setIntegrationInstalling(true)
    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [hitlIntegration.id]: { enabled: true, configuration: {} },
      },
      onSuccess: async () => {
        await initHitlClient()
        setIntegrationNotInstalled(false)
        showSuccessToast('HITL integration installed.')
        setIntegrationInstalling(false)
      },
    })
  }

  return (
    <Page title="Human in the loop" className="@container">
      {plan === 'community' ? (
        <Flex className="h-[55vh] w-full" align={'center'} justify={'center'}>
          <EmptyState
            className="m-auto"
            title="Unlock Live Chat User Support"
            description="Upgrade your plan to allow you and your team to instantly take over live conversations from your bot."
            primaryAction={
              <Link to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId: workspaceId }}>
                <Button>Upgrade Plan</Button>
              </Link>
            }
          />
        </Flex>
      ) : hitlClient ? (
        <SidebarLayout
          main={
            currentConversation ? (
              <HITLTabs botId={botId} workspaceId={workspaceId} downstreamConversation={currentConversation} />
            ) : (
              <EmptyState
                className="m-auto"
                title="No conversation selected"
                description="Select a conversation from the list to start helping your users."
              />
            )
          }
          leftSidebarSize="3"
          leftSidebar={
            integrationNotInstalled || allConversations.length === 0 ? (
              <div />
            ) : (
              <>
                {currentConversation && (
                  <div className="mb-6 @6xl:hidden">
                    <ConversationDetails
                      botId={botId}
                      workspaceId={workspaceId}
                      downstreamConversation={currentConversation}
                    />
                  </div>
                )}
                <ConversationList
                  downstreamConversations={allConversations}
                  botId={botId}
                  workspaceId={workspaceId}
                  currentConversationId={conversationId}
                  className={cn({
                    'h-[calc(75vh-18rem)] @6xl:h-[calc(100vh-18rem)]': !!currentConversation,
                    'h-[calc(100vh-18rem)]': !currentConversation,
                  })}
                />
              </>
            )
          }
          rightSidebar={
            currentConversation ? (
              <ConversationDetails
                botId={botId}
                workspaceId={workspaceId}
                downstreamConversation={currentConversation}
              />
            ) : (
              <div />
            )
          }
          rightSidebarClassName="hidden @6xl:flex"
        />
      ) : integrationNotInstalled ? (
        <EmptyState
          className="m-auto"
          title="Missing HITL Integration"
          description={
            <span>
              <strong>Human in the Loop</strong> requires an integration to be installed and enabled.
            </span>
          }
          primaryAction={
            <Button loading={integrationInstalling} onClick={installHITLIntegration}>
              <span>Install & enable HITL</span>
            </Button>
          }
        />
      ) : allConversations.length === 0 ? (
        <EmptyState
          className="m-auto"
          title="No Conversations"
          description="No conversations have been escalated yet, your bot is doing a great job!"
        />
      ) : null}
    </Page>
  )
}
