import { Router } from './Router'

import { Providers } from './providers'

export const App = () => {
  return (
    <Providers>
      <Router />
    </Providers>
  )
}
