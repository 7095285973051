import * as hitl from 'hitl-client'
import * as webchat from './webchat'

export const mapMessage = (m: hitl.Message): webchat.Message => {
  const { payload, disableInput } = webchat.adapters.webchatToTarget.messageAdapter(m.payload)
  return {
    id: m.id,
    authorId: m.userId,
    conversationId: m.conversationId,
    sentOn: new Date(m.createdAt),
    payload,
    disableInput,
  }
}
