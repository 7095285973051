import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon6 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M16.667,68.332c0,8.271,6.729,15,15,15s15-6.729,15-15v-15h-15C23.396,53.332,16.667,60.063,16.667,68.332z"
      />
      <path
        fill="var(--accent-5)"
        d="M53.333,46.667h15c8.271,0,15-6.729,15-15s-6.729-15-15-15s-15,6.729-15,15V46.667z"
      />
      <path
        fill="var(--accent-5)"
        d="M83.185,33.667c-0.983-7.326-7.261-13-14.852-13c-6.065,0-11,4.935-11,11v15h-4v-15c0-8.271,6.729-15,15-15	s15,6.729,15,15C83.333,32.346,83.271,33.01,83.185,33.667z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.816,66.332c0.983,7.326,7.26,13,14.851,13c6.065,0,11-4.936,11-11v-15h4v15c0,8.271-6.729,15-15,15	c-8.271,0-15-6.729-15-15C16.667,67.652,16.729,66.988,16.816,66.332z"
      />
      <path
        fill="var(--accent-11)"
        d="M68.333,10c-11.947,0-21.666,9.72-21.666,21.667v15h-15C19.72,46.667,10,56.387,10,68.332	C10,80.279,19.72,90,31.667,90c11.946,0,21.666-9.721,21.666-21.666v-15h15C80.279,53.334,90,43.614,90,31.667S80.279,10,68.333,10z M46.667,68.332c0,8.271-6.729,15-15,15s-15-6.729-15-15c0-8.27,6.729-15,15-15h15V68.332z M68.333,46.667h-15v-15	c0-8.271,6.729-15,15-15s15,6.729,15,15S76.604,46.667,68.333,46.667z"
      />
    </svg>
  )
}
