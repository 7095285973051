import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { ComponentProps } from 'react'
import { copyToClipboard } from '../utils'

type CodeSnippetProps = {
  code: string
  description?: string
  icon?: React.ReactNode
} & ComponentProps<'div'>

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export function CodeSnippet({ code, description, icon, ...props }: CodeSnippetProps) {
  return (
    <div
      {...props}
      onClick={() => void copyToClipboard(code, description ?? 'code snippet')}
      className={cx(
        'group relative rounded-md border border-gray-6 bg-gray-1 p-4 pr-12 transition-colors hover:cursor-pointer active:border-gray-7 active:bg-gray-1',
        props.className
      )}
    >
      {icon ? (
        icon
      ) : (
        <ClipboardDocumentIcon className="invisible absolute right-4 top-4 h-6 w-6 text-gray-11 opacity-0 transition-all group-hover:visible group-hover:opacity-100" />
      )}
      <pre className="truncate whitespace-pre text-xs">
        <code>{code.trim()}</code>
      </pre>
    </div>
  )
}
