export enum Action {
  UNKNOWN = 'UNKNOWN',
  ADD_WORKSPACE_MEMBER = 'ADD_WORKSPACE_MEMBER',
  REMOVE_WORKSPACE_MEMBER = 'REMOVE_WORKSPACE_MEMBER',
  UPDATE_WORKSPACE_MEMBER = 'UPDATE_WORKSPACE_MEMBER',
  DELETE_WORKSPACE = 'CLOSE_WORKSPACE',
  CREATE_BOT = 'CREATE_BOT',
  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
  DELETE_BOT = 'DELETE_BOT',
  DEPLOY_BOT = 'DEPLOY_BOT',
  TRANSFER_BOT = 'TRANSFER_BOT',
  DOWNLOAD_BOT_ARCHIVE = 'DOWNLOAD_BOT_ARCHIVE',
  UPDATE_BOT = 'UPDATE_BOT',
  UPDATE_BOT_CHANNEL = 'UPDATE_BOT_CHANNEL',
  UPDATE_BOT_CONFIG = 'UPDATE_BOT_CONFIG',
  UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD',
  UPDATE_WORKSPACE = 'UPDATE_WORKSPACE',
  SET_SPENDING_LIMIT = 'SET_SPENDING_LIMIT',
  SET_AI_SPENDING_LIMIT = 'SET_AI_SPENDING_LIMIT',
  UPDATE_WORKSPACE_BILLING_READONLY = 'UPDATE_WORKSPACE_BILLING_READONLY',
  UPDATE_WORKSPACE_ENTERPRISE = 'UPDATE_WORKSPACE_ENTERPRISE',
}
