import cx from 'classnames'
import { generateGradient } from '~/layouts'
export type WorkspaceProfilePictureProps = {
  pictureUrl?: string
  workspaceId: string
  className?: string
}

const WorkspaceProfilePicture = (props: WorkspaceProfilePictureProps) => {
  const commonClasses = [
    { [`${props.className}`]: !!props.className, 'rounded-xl': !props.className?.includes('rounded') },
  ]

  if (props.pictureUrl) {
    return (
      <img
        src={props.pictureUrl}
        alt="Workspace avatar"
        className={cx('border-1  border border-gray-3', ...commonClasses)}
      />
    )
  }

  return (
    <div
      className={cx(
        ...commonClasses,
        'bg-gradient-to-b',
        { grayscale: false },
        generateGradient(props.workspaceId ?? '')
      )}
    />
  )
}

export { WorkspaceProfilePicture }
