import { Flex, Text } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Conversation } from 'botpress-client'
import { DateTime } from 'luxon'
import { Icon, Link } from '~/elementsv2'
import { getIntegrationByNameQueryOptions } from '~/queries'

type Props = {
  conversation: Conversation
  botId: string
  workspaceId: string
  className?: string
}

const ConversationSummary = ({ conversation, botId, workspaceId, className }: Props) => {
  const integration = useSuspenseQuery(getIntegrationByNameQueryOptions({ name: conversation.integration })).data
  return (
    <Flex key={conversation.id} gap={'2'} align={'start'} className={className ?? ''}>
      <Icon
        size="2"
        className="mt-1.5"
        variant={'surface'}
        color={'gray'}
        icon={(props) => <img src={integration.iconUrl} {...props} />}
      />
      <Flex className="truncate" direction={'column'}>
        <Link
          to="/workspaces/$workspaceId/bots/$botId/conversations/$conversationId"
          params={{ botId, conversationId: conversation.id, workspaceId }}
          truncate
          size={'2'}
        >
          {conversation.id}
        </Link>
        <Text truncate color="gray" size={'1'}>
          {DateTime.fromISO(conversation.updatedAt).toRelative()}
        </Text>
      </Flex>
    </Flex>
  )
}

export { ConversationSummary }
