import { useAppStore } from '../../stores'

export function useCurrentUser() {
  const currentUser = useAppStore((state) => state.currentUser)

  if (!currentUser) {
    throw new Error('Unabled to get current user')
  }
  return currentUser
}
