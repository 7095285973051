import './utils/polyfills' // Must be first
import React from 'react'
import * as ReactDOM from 'react-dom/client'
import './styles/main.css' // IMPORTANT: This must be imported before App
import { App } from './App'
import { config, initSentry } from './shared'
import { installDevTools } from './utils/devtools'
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader'

async function startApp() {
  if (!(config.appEnv === 'production' || config.appEnv === 'local')) {
    // Start the Meticulous recorder before you initialise your app.
    // Note: all errors are caught and logged, so no need to surround with try/catch
    await tryLoadAndStartRecorder({
      projectId: 'B9LEYmJhuafguNkAVbIkgcDfjOofkFRYaKQm4uoU',
      isProduction: false,
    })
  }

  initSentry()
  installDevTools()

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

void startApp()
