import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type AppState = {
  isWebchatV2Enabled: boolean
  setIsWebchatV2Enabled: (isWebchatV2Enabled: boolean) => void
}

const useWebchatV2Store = create<AppState>()(
  persist(
    (set) => ({
      isWebchatV2Enabled: true,
      setIsWebchatV2Enabled: (isWebchatV2Enabled: boolean) => set({ isWebchatV2Enabled }),
    }),
    { name: 'webchat-preference' }
  )
)

export function useWebchatV2() {
  const store = useWebchatV2Store((state) => state)

  return store
}
