import { BlockObject, ColumnBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage, WithBubble, withBubble } from './Utils'

export const DropdownSchema = BaseMessage.extend({
  type: z.literal('dropdown'),
  payload: z.object({
    text: z.string().min(1),
    options: z.array(
      z.object({
        label: z.string().min(1),
        value: z.string().min(1),
      })
    ),
  }),
})
  .transform(({ payload, ...props }) => {
    const blocks: BlockObject[] = [
      {
        type: 'dropdown',
        label: payload.text ?? 'Select an option',
        options: payload.options,
      },
    ]

    if (payload.text) {
      blocks.unshift({
        type: 'text',
        text: payload.text,
      })
    }

    return {
      ...props,
      block: withBubble({
        type: 'column',
        blocks,
      }),
    }
  })
  .transform<MessageObject<WithBubble<ColumnBlock>>>(ToMessage)
