const fromClasses = [
  'from-red-300',
  'from-orange-300',
  'from-amber-300',
  'from-yellow-300',
  'from-lime-300',
  'from-green-300',
  'from-emerald-300',
  'from-teal-300',
  'from-cyan-300',
  'from-sky-300',
  'from-primary-300',
  'from-indigo-300',
  'from-violet-300',
  'from-purple-300',
  'from-fuchsia-300',
  'from-pink-300',
  'from-rose-300',
] as const

const toClasses = [
  'to-red-400',
  'to-orange-400',
  'to-amber-400',
  'to-yellow-400',
  'to-lime-400',
  'to-green-400',
  'to-emerald-400',
  'to-teal-400',
  'to-cyan-400',
  'to-sky-400',
  'to-primary-400',
  'to-indigo-400',
  'to-violet-400',
  'to-purple-400',
  'to-fuchsia-400',
  'to-pink-400',
  'to-rose-400',
] as const

const offsets = [-4, -3, -2, 2, 3, 4] as const

const gradientClasses = fromClasses.flatMap((fromClass, index) => {
  const classesLength = toClasses.length
  return offsets.map((offset) => {
    return `${fromClass} ${toClasses[(classesLength + index + offset) % classesLength]}`
  })
})

export function generateGradient(seed: string) {
  const hash = seed.split('').reduce((acc, char) => {
    return acc + char.charCodeAt(0)
  }, 0)
  const index = hash % gradientClasses.length
  return gradientClasses[index]
}
