import { z } from 'zod'

export const urlSchema = z.string().transform((candidateUrl, ctx) => {
  let url = candidateUrl.trim()
  if (!url.includes('://')) {
    url = `https://${url}`
  }
  try {
    const x = new URL(url)
    if (x.protocol !== 'http:' && x.protocol !== 'https:') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid protocol, only URLs starting with HTTP and HTTPS are supported',
      })
      return z.NEVER
    }

    if (!/.\.[a-zA-Z]{2,}$/.test(x.hostname)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid TLD',
      })
      return z.NEVER
    }
    const pathName = x.pathname.endsWith('/') ? x.pathname.slice(0, -1) : x.pathname
    return {
      url: `${x.origin}${pathName}`.toLowerCase(),
      origin: x.origin,
      pathName,
      hostname: x.hostname,
    }
  } catch (e) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Invalid URL',
    })
    return z.NEVER
  }
})
