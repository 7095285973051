import { z } from 'zod'
import { AudioSchema } from './Audio'
import { CardSchema } from './Card'
import { CarouselSchema } from './Carousel'
import { ChoiceSchema } from './Choice'
import { DropdownSchema } from './Dropdown'
import { FileSchema } from './File'
import { ImageSchema } from './Image'
import { LocationSchema } from './Location'
import { MarkdownSchema } from './Markdown'
import { TextSchema } from './Text'
import { VideoSchema } from './Video'
import { VoiceSchema } from './Voice'

export const MessageSchema = z.union([
  TextSchema,
  ImageSchema,
  AudioSchema,
  VideoSchema,
  CarouselSchema,
  CardSchema,
  LocationSchema,
  FileSchema,
  VoiceSchema,
  ChoiceSchema,
  DropdownSchema,
  MarkdownSchema,
])
