import { Portal } from '@radix-ui/themes'
import { usePortalStore } from '../stores'

type Props = {
  children?: React.ReactNode
}
export const PageActionPortal = (props: Props) => {
  const pageActionRef = usePortalStore((state) => state.pageActionsPortalRef)
  return <Portal {...props} className="flex gap-2" container={pageActionRef.current} />
}
