import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon1 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M73.332,49.998c0,2.759,2.244,5.001,5,5.001c2.76,0,5.002-2.242,5.002-5.001	c0-2.756-2.242-4.999-5.002-4.999C75.576,44.999,73.332,47.242,73.332,49.998z"
      />
      <path
        fill="var(--accent-11)"
        d="M78.332,38.332c-6.432,0-11.664,5.233-11.664,11.666c0,6.437,5.232,11.668,11.664,11.668	C84.768,61.666,90,56.435,90,49.998C90,43.565,84.768,38.332,78.332,38.332z M78.332,54.999c-2.756,0-5-2.242-5-5.001	c0-2.756,2.244-4.999,5-4.999c2.76,0,5.002,2.243,5.002,4.999C83.334,52.757,81.092,54.999,78.332,54.999z"
      />
      <path
        fill="var(--accent-5)"
        d="M64.59,78.334c0,2.756,2.242,5,5,5s5-2.244,5-5c0-2.758-2.242-5-5-5S64.59,75.576,64.59,78.334z"
      />
      <path
        fill="var(--accent-11)"
        d="M69.59,66.667c-6.432,0-11.666,5.231-11.666,11.667c0,6.43,5.234,11.667,11.666,11.667	c6.436,0,11.666-5.237,11.666-11.667C81.256,71.898,76.025,66.667,69.59,66.667z M69.59,83.334c-2.758,0-5-2.244-5-5	c0-2.758,2.242-5,5-5s5,2.242,5,5C74.59,81.09,72.348,83.334,69.59,83.334z"
      />
      <path
        fill="var(--accent-5)"
        d="M64.59,21.665c0,2.757,2.242,5,5,5s5-2.243,5-5s-2.242-5-5-5S64.59,18.908,64.59,21.665z"
      />
      <path
        fill="var(--accent-11)"
        d="M69.59,9.999c-6.432,0-11.666,5.234-11.666,11.666c0,6.434,5.234,11.667,11.666,11.667	c6.436,0,11.666-5.233,11.666-11.667C81.256,15.233,76.025,9.999,69.59,9.999z M69.59,26.665c-2.758,0-5-2.243-5-5s2.242-5,5-5	s5,2.243,5,5S72.348,26.665,69.59,26.665z"
      />
      <path
        fill="var(--accent-5)"
        d="M67.418,24.494c0-2.757,2.242-5,5-5c0.645,0,1.258,0.126,1.824,0.349c-0.73-1.857-2.539-3.178-4.652-3.178	c-2.758,0.001-5,2.243-5,5.001c0,2.113,1.32,3.921,3.178,4.65C67.545,25.751,67.418,25.138,67.418,24.494z"
      />
      <path
        fill="var(--accent-5)"
        d="M76.162,52.828c0-2.758,2.242-5,5-5.001c0.645,0,1.258,0.127,1.822,0.35	c-0.729-1.857-2.537-3.178-4.65-3.178c-2.76,0-5.002,2.243-5.002,5c0,2.114,1.32,3.921,3.178,4.651	C76.287,54.084,76.162,53.472,76.162,52.828z"
      />
      <path
        fill="var(--accent-5)"
        d="M67.418,81.163c0-2.757,2.242-5,5-5c0.645,0,1.258,0.126,1.824,0.349c-0.73-1.857-2.539-3.178-4.652-3.178	c-2.758,0.001-5,2.243-5,5.001c0,2.114,1.32,3.92,3.178,4.65C67.545,82.42,67.418,81.807,67.418,81.163z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.666,49.998c0,7.355,5.982,13.336,13.334,13.336s13.333-5.98,13.333-13.336	c0-7.35-5.981-13.332-13.333-13.332S16.666,42.648,16.666,49.998z"
      />
      <path
        fill="var(--accent-11)"
        d="M30,29.999c-11.027,0-20,8.975-20,19.999C10,61.03,18.972,70,30,70c11.025,0,19.998-8.97,19.998-20.002	C49.998,38.974,41.025,29.999,30,29.999z M30,63.334c-7.352,0-13.334-5.98-13.334-13.336c0-7.35,5.982-13.332,13.334-13.332	s13.333,5.982,13.333,13.332C43.333,57.354,37.351,63.334,30,63.334z"
      />
      <path
        fill="var(--accent-5)"
        d="M19.494,52.827c0-7.351,5.98-13.333,13.336-13.333c2.955,0,5.68,0.979,7.893,2.612	c-2.431-3.292-6.326-5.44-10.721-5.44c-7.355,0-13.336,5.981-13.336,13.332c0,4.397,2.148,8.291,5.44,10.722	C20.472,58.507,19.494,55.782,19.494,52.827z"
      />
    </svg>
  )
}
