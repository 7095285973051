import { useEffect, useMemo, useRef, useState } from 'react'

const useIntersecting = (root: React.RefObject<HTMLDivElement>): [React.RefObject<HTMLDivElement>, boolean] => {
  const [intersecting, setIntersecting] = useState(false)

  const ref = useRef<HTMLDivElement>(null)

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entry]) => {
        if (entry?.isIntersecting !== undefined) {
          setIntersecting(entry.isIntersecting)
        }
      },
      { threshold: [1.0], root: root.current }
    )
  }, [ref, root])

  useEffect(() => {
    if (ref.current) {
      observer?.observe(ref.current)
    }

    return () => observer?.disconnect()
  }, [])

  return [ref, intersecting]
}

type Props = {
  onVisibilityChange?: (visible: boolean) => void
  parentRef: React.RefObject<HTMLDivElement>
  children: React.ReactNode
  className?: string
}

export const Visibility = ({ children, parentRef, className, onVisibilityChange }: Props) => {
  const [ref, intersecting] = useIntersecting(parentRef)

  useEffect(() => {
    onVisibilityChange?.(intersecting)
  }, [intersecting])

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}
