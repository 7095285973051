import { QueryClientProvider } from '@tanstack/react-query'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import { useRef } from 'react'
import { createRoot } from 'react-dom/client'
import { useAsync } from 'react-use'
import { Modal } from '~/elements/Modal'
import { useCurrentUser } from '~/hooks'
import { useSetPreferences } from '~/hooks/users/useSetPreferences'
import { Providers, trackEvent } from '~/providers'
import { queryClient } from '~/providers/ReactQuery'
import { config } from '~/shared/configurations'

const ONBOARDING_SURVEY_ID = 'onboarding-survey'

export function OnboardingSurvey({ onDone }: { onDone: () => void }) {
  const user = useCurrentUser()
  const ref = useRef<HTMLDivElement>(null)
  const isMounted = useRef(false)
  const { mutate: setPreferences } = useSetPreferences()

  useAsync(async () => {
    if (isMounted.current || !ref.current) {
      return
    }

    // ensures the the DOM node is mounted before rendering the widget
    await new Promise((resolve) => requestAnimationFrame(resolve))
    isMounted.current = true
    const { unmount } = createWidget(config.typeformOnboardingFormId!, {
      autoFocus: true,
      autoResize: false,
      width: '100%',
      height: '100%',
      container: ref.current!,
      hidden: {
        userId: user.id,
        email: user.email,
      },
      onSubmit: () => {
        unmount()
        setPreferences({ path: 'requireOnboarding', value: false })
        onDone()
        trackEvent({ type: 'onboarding_survey_completed' })
      },
    })
  }, [ref.current])

  return (
    <Modal open onClose={() => {}} setOpen={() => {}} className="relative !mb-[0vh] h-[80vh] w-[80vw] max-w-[80vw]">
      <div className="h-full w-full" ref={ref} />
    </Modal>
  )
}

export async function launchOnboardingSurvey() {
  if (!config.typeformOnboardingFormId) {
    // No survey configured
    return
  }

  if (document.getElementById(ONBOARDING_SURVEY_ID)) {
    // Survey already launched
    return
  }

  const container = document.createElement('div')
  container.id = ONBOARDING_SURVEY_ID

  document.getElementById('root')!.appendChild(container)
  const removeContainer = () => document.getElementById('root')!.removeChild(container)

  const root = createRoot(container)

  return new Promise<void>((resolve) => {
    root.render(
      <Providers>
        <QueryClientProvider client={queryClient}>
          <OnboardingSurvey
            onDone={() => {
              try {
                removeContainer()
                root.unmount()
              } finally {
                resolve()
              }
            }}
          />
        </QueryClientProvider>
      </Providers>
    )
  })
}
