import { Button, Field, Input, Pane, Switch } from '~/elements'
import { SocialAccountInput } from '~/elementsv2'
import { ProfilePictureField } from '../../../componentsV2/ProfilePictureField'
import { WorkspaceObject } from '~/features/workspaces/types'
import { useEffect, useMemo, useState } from 'react'
import { useUpdateWorkspace } from '~/hooks'
import { showSuccessToast } from '~/services'
import _ from 'lodash'
import { useKeyPress } from 'react-use'
import { Permission } from '~/features/users/types'
import { RichEditor } from '~/componentsV2/RichTextEditor'
import { WorkspaceProfilePicture } from '~/features/workspaces/components/WorkspaceProfilePicture'
import { trackEvent } from '../../../providers'

type Props = {
  workspace: WorkspaceObject
  isAuthorized: (permission: Permission) => boolean | undefined
}

type ProfileData = Pick<WorkspaceObject, 'profilePicture' | 'contactEmail' | 'website' | 'isPublic' | 'about'> & {
  socialAccounts: string[]
}

const DEFAULT_SOCIAL_ACCOUNTS = new Array(5).fill('')

const getProfileFromWorkspace = (workspace: WorkspaceObject): ProfileData => {
  const socialAccounts = workspace.socialAccounts ?? []
  return {
    profilePicture: workspace.profilePicture,
    contactEmail: workspace.contactEmail,
    website: workspace.website,
    socialAccounts: [...socialAccounts, ...DEFAULT_SOCIAL_ACCOUNTS.slice(socialAccounts.length)],
    isPublic: workspace.isPublic,
    about: workspace.about,
  }
}

const ProfileSettings = ({ workspace, isAuthorized }: Props) => {
  const hasUpdatePermission = isAuthorized('workspace.update')
  const { mutate: updateWorkspace, isPending } = useUpdateWorkspace()
  const [profile, setProfile] = useState<ProfileData>(getProfileFromWorkspace(workspace))
  const isSubmitUsingKeyboard = useKeyPress((e) => e.key === 'Enter' && (e.ctrlKey || e.metaKey))
  const hasProfileChanges = useMemo(() => !_.isEqual(getProfileFromWorkspace(workspace), profile), [profile, workspace])

  useEffect(() => {
    if (isSubmitUsingKeyboard && hasProfileChanges) {
      submit()
    }
  }, [isSubmitUsingKeyboard])

  const submit = () => {
    if (!hasUpdatePermission || !hasProfileChanges) {
      return
    }

    const updatePayload = {
      id: workspace.id,
      ...(_.pickBy(profile, (v) => !_.isNil(v)) satisfies Partial<ProfileData>),
      socialAccounts: profile.socialAccounts.filter(Boolean),
    }

    trackEvent({
      type: 'update_workspace_profile',
      workspaceId: workspace.id,
      public: !!profile.isPublic,
      socialAccounts: profile.socialAccounts.length,
      website: !!profile.website,
      email: !!profile.contactEmail,
      about: !!profile.about,
      profilePicture: !!profile.profilePicture,
    })

    updateWorkspace(updatePayload, {
      onSuccess: () => {
        showSuccessToast('Workspace profile updated successfully')
      },
    })
  }

  return (
    <Pane className="w-full">
      <Pane.Body>
        <h3 className="pb-6 text-lg">Profile</h3>
        <p className="text-md mb-4 text-gray-11">
          When profile is publicly visible, settings below will visible to everyone. Workspace owner and administrators
          can change these settings at any time.
        </p>
        <div className="mb-8 w-full border-b border-b-gray-4">
          <Field label="About your Organization" inputMode="email" />
          <RichEditor content={workspace.about} onChange={(about) => setProfile((p) => ({ ...p, about }))} />
        </div>
        <div className="flex">
          <div className="flex w-3/5 flex-col gap-2">
            <Field label="Contact Email" inputMode="email">
              <Input
                readOnly={!hasUpdatePermission}
                className="w-auto"
                value={profile.contactEmail}
                onChange={(e) => setProfile((p) => ({ ...p, contactEmail: e.target.value }))}
              />
            </Field>
            <Field label="Website" inputMode="url">
              <Input
                readOnly={!hasUpdatePermission}
                className="w-auto"
                value={profile.website}
                onChange={(e) => setProfile((p) => ({ ...p, website: e.target.value }))}
              />
            </Field>
            <Field label="Social Accounts" inputMode="url">
              {profile.socialAccounts.map((account, index) => (
                <SocialAccountInput
                  readOnly={!hasUpdatePermission}
                  value={account}
                  key={index}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProfile((p) => ({
                      ...p,
                      socialAccounts: p.socialAccounts.map((x, i) => (i === index ? e.target.value : x)),
                    }))
                  }}
                />
              ))}
            </Field>

            <Switch
              disabled={!hasUpdatePermission}
              label="Publicly visible"
              className="grow-0"
              labelClassName="mr"
              checked={profile.isPublic}
              onCheckedChange={(isPublic) => setProfile((p) => ({ ...p, isPublic }))}
            />
          </div>
          <ProfilePictureField
            onChange={(profilePicture) => setProfile({ ...profile, profilePicture })}
            className="ml-auto mr-auto"
          >
            <WorkspaceProfilePicture
              pictureUrl={profile.profilePicture}
              workspaceId={workspace.id}
              className="mb-1 h-44 w-44"
            />
          </ProfilePictureField>
        </div>
      </Pane.Body>
      <Pane.Footer className="flex justify-end">
        <Button disabled={!hasUpdatePermission || !hasProfileChanges || isPending} variant="primary" onClick={submit}>
          Update Profile
        </Button>
      </Pane.Footer>
    </Pane>
  )
}

export { ProfileSettings }
