// this file was automatically generated, do not edit
/* eslint-disable */

export interface DeleteMessageRequestHeaders {
  "x-user-key": string;
}

export interface DeleteMessageRequestQuery {}

export interface DeleteMessageRequestParams {
  id: string;
}

export interface DeleteMessageRequestBody {}

export type DeleteMessageInput = DeleteMessageRequestBody & DeleteMessageRequestHeaders & DeleteMessageRequestQuery & DeleteMessageRequestParams

export type DeleteMessageRequest = {
  headers: DeleteMessageRequestHeaders;
  query: DeleteMessageRequestQuery;
  params: DeleteMessageRequestParams;
  body: DeleteMessageRequestBody;
}

export const parseReq = (input: DeleteMessageInput): DeleteMessageRequest & { path: string } => {
  return {
    path: `/messages/${encodeURIComponent(input['id'])}`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface DeleteMessageResponse {}

