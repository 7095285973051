import { useQuery } from '@tanstack/react-query'
import { listWorkspaces } from '../../features/workspaces/services'
import { cacheDuration } from '../../shared'
import { getQueryKey } from '~/services'

export function useWorkspaces() {
  return useQuery({
    staleTime: cacheDuration.short,
    queryKey: getQueryKey('workspaces_'),
    queryFn: async () => {
      return listWorkspaces()
    },
    meta: {
      errorMessage: 'Failed to retrieve your workspaces, please try again later',
    },
  })
}
