import { useQuery } from '@tanstack/react-query'
import { listWorkspaceMembers } from '../../features/workspaces/services'
import { cacheDuration } from '../../shared'
import { useCurrentUser } from './useCurrentUser'
import { getQueryKey } from '~/services'

export function useWorkspaceMembers({ workspaceId }: { workspaceId?: string }) {
  return useQuery({
    staleTime: cacheDuration.short,
    enabled: !!workspaceId,
    queryKey: getQueryKey('workspaces_/$workspaceId_/members', { workspaceId: workspaceId ?? '' }),
    queryFn: async () => {
      if (!workspaceId) {
        throw new Error('No workspaceId provided')
      }

      return listWorkspaceMembers(workspaceId)
    },
    meta: {
      errorMessage: 'Failed to fetch workspace collaborators, please try again later',
    },
  })
}

export function useCurrentUserWorkspaceMember({ workspaceId }: { workspaceId: string }) {
  const currentUser = useCurrentUser()
  const { data: members } = useWorkspaceMembers({ workspaceId })
  return members?.find((member) => member.userId === currentUser.id)
}
