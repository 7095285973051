import { Card, Container, Flex, Separator, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { RichEditor } from '~/componentsV2/RichTextEditor'
import { WorkspaceSocialHeader } from '~/features/workspaces/components/WorkspaceSocialHeader'
import { SidebarLayout } from '~/layouts'
import { publicWorkspaceQueryOptions, workspacesQueryOptions } from '~/queries'
import { useAppStore } from '~/stores'
import { Boundary } from '~/componentsV2'
import { EmptyState } from '~/elementsv2'
import { ViewAsSection } from '~/features/workspaces/components/ViewAsSidebarSection'
import { ContributionsSidebarSection } from '~/features/workspaces/components/ContributionsSidebarSection'
import EmptyStateIcon from '~/assets/programming-10.svg?react'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/profile/$workspaceId')({
  loader: async ({ context, params, navigate }) => {
    const { setActiveWorkspace } = useAppStore.getState()

    const workspace = await context.queryClient.ensureQueryData(publicWorkspaceQueryOptions(params.workspaceId))
    if (!workspace) {
      void navigate({ to: '/404', replace: true })
      return
    }
    setActiveWorkspace(workspace)
  },
  component: PublicWorkspace,
})

function PublicWorkspace() {
  const { workspaceId } = Route.useParams()
  const workspace = useSuspenseQuery(publicWorkspaceQueryOptions(workspaceId)).data
  const workspaces = useSuspenseQuery(workspacesQueryOptions()).data

  if (!workspace) {
    return null
  }

  return (
    <Container size={'4'} p={'5'}>
      <WorkspaceSocialHeader workspace={workspace} />
      <Separator size={'4'} my={'6'} />
      <SidebarLayout
        main={
          <Flex direction={'column'} gap={'4'}>
            <Text size={'2'} weight={'medium'}>
              About {workspace.name}
            </Text>
            <Boundary className={'h-80'}>
              {!workspace.about && (
                <Card>
                  <EmptyState
                    icon={EmptyStateIcon}
                    iconSize={5}
                    className="h-80"
                    title={workspace.name}
                    description={`No description has been set for ${workspace.name}.`}
                  />
                </Card>
              )}
              {workspace.about && <RichEditor content={workspace.about} editable={false} />}
            </Boundary>
          </Flex>
        }
        rightSidebar={
          <>
            <Boundary className="h-48" loaderSize={'7'}>
              <ViewAsSection currentRoute={Route.id} userWorkspaces={workspaces} />
            </Boundary>
            <ContributionsSidebarSection workspace={workspace} userWorkspaces={workspaces} />
          </>
        }
      />
    </Container>
  )
}
