import {
  CharacterCount,
  Color,
  Document,
  Dropcursor,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  Columns,
  Column,
  TaskItem,
  TaskList,
} from '.'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { ImageUpload } from './ImageUpload'
import { lowlight } from 'lowlight'
import { NoNewLine } from './NoNewLine'

export const ExtensionKit = () => [
  Document,
  Columns,
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Column,
  Selection,
  Heading.configure({
    levels: [1, 2, 3, 4, 5, 6],
  }),
  HorizontalRule,
  StarterKit.configure({
    document: false,
    dropcursor: false,
    heading: false,
    horizontalRule: false,
    history: {
      depth: 20,
    },
    codeBlock: false,
  }),
  CodeBlockLowlight.configure({
    lowlight,
    defaultLanguage: 'typescript',
  }),
  TextStyle,
  FontSize,
  FontFamily,
  Color,
  TrailingNode,
  Link.configure({
    openOnClick: false,
    linkOnPaste: true,
  }),
  Highlight.configure({ multicolor: true }),
  Underline,
  CharacterCount.configure({ limit: 50000 }),
  ImageBlock.configure({ allowBase64: true }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  Subscript,
  Superscript,
  SlashCommand,
  Focus,
  Typography,
  Dropcursor.configure({
    color: 'gray',
    width: 2,
  }),
  ImageUpload,
  Placeholder.configure({
    placeholder: ({ node, editor }) => {
      if (node.type.name === 'codeBlock') {
        return ''
      } else if (node.type.name === 'heading') {
        return 'Type something'
      } else {
        return editor.isFocused
          ? 'Type / to insert'
          : editor.isEmpty
            ? 'Type something to get started'
            : 'Type something'
      }
    },
  }),
  NoNewLine,
]

export default ExtensionKit
