// this file was automatically generated, do not edit
/* eslint-disable */

export interface LoginRequestHeaders {
  "x-access-token"?: string;
}

export interface LoginRequestQuery {}

export interface LoginRequestParams {}

export interface LoginRequestBody {}

export type LoginInput = LoginRequestBody & LoginRequestHeaders & LoginRequestQuery & LoginRequestParams

export type LoginRequest = {
  headers: LoginRequestHeaders;
  query: LoginRequestQuery;
  params: LoginRequestParams;
  body: LoginRequestBody;
}

export const parseReq = (input: LoginInput): LoginRequest & { path: string } => {
  return {
    path: `/login`,
    headers: { 'x-access-token': input['x-access-token'] },
    query: {  },
    params: {  },
    body: {  },
  }
}

export interface LoginResponse {
  key: string;
}

