import { contentType } from 'mime-types'
import type { ComponentProps } from 'react'
import { Icon } from '~/elementsv2'
import { P, match } from 'ts-pattern'
import { HiOutlineCamera, HiOutlineDocumentText, HiOutlinePhoto, HiOutlineSpeakerWave } from 'react-icons/hi2'

type Props = { className?: string; type: string } & Omit<ComponentProps<typeof Icon>, 'icon' | 'color'>

export const FileIcon = ({ type, ...props }: Props) => {
  const mimeType = contentType(type)

  const iconProps = match(mimeType)
    .with(P.string.startsWith('image/'), () => ({ color: 'blue', icon: HiOutlinePhoto }) as const)
    .with(P.string.startsWith('video/'), () => ({ color: 'plum', icon: HiOutlineCamera }) as const)
    .with(P.string.startsWith('audio/'), () => ({ color: 'orange', icon: HiOutlineSpeakerWave }) as const)
    .otherwise(() => ({ color: 'amber', icon: HiOutlineDocumentText }) as const)

  return <Icon {...iconProps} {...props} />
}
