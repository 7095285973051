import { useMutation } from '@tanstack/react-query'
import { BillingApiClient } from '~/features/billing/services'
import { getQueryKey, showErrorToast, showSuccessToast } from '~/services'
import { queryClient } from '../../providers/ReactQuery'

export function useSetPromoCode({ workspaceId }: { workspaceId: string }) {
  return useMutation({
    gcTime: 0,
    mutationFn: async ({ code }: { code: string }) => {
      return BillingApiClient.setPromoCode({ workspaceId, code })
    },
    onSuccess: () => {
      showSuccessToast('Promo code applied successfully.')
      void queryClient.invalidateQueries({
        queryKey: getQueryKey('workspaces_/$workspaceId_/promoCode', { workspaceId }),
      })
    },
    onError: () => {
      showErrorToast('There was an error while applying promo code. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
