import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient, getGenericClient } from '../../client'
import { BotSummary } from '../../features/bots/types'
import { getQueryKey, showErrorToast } from '../../services'
import { handleHttpErrors } from '../../shared'
import { getPreference } from '../../services/preferences'
import { useSetPreferences } from '../users'

type deleteBotParams = { options: { onError?: () => Promise<void>; onSuccess?: () => Promise<void> } } & Parameters<
  ReturnType<typeof getGenericClient>['deleteBot']
>['0']

export function useDeleteBot(workspaceId: string) {
  const queryClient = useQueryClient()

  const listBotsQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_', { workspaceId })
  const { mutate: setPreference } = useSetPreferences()

  return useMutation({
    mutationFn: async ({ id }: deleteBotParams) => {
      return await getApiClient(workspaceId).deleteBot({ id }).catch(handleHttpErrors)
    },
    onSuccess: async (_, { id, options: { onSuccess } }) => {
      queryClient.setQueryData<BotSummary[]>(listBotsQueryKey, (prevBots) => {
        return prevBots?.filter((bot) => bot.id !== id)
      })
      const getBotQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_/$botId_', { botId: id, workspaceId })
      const prefsProps = { path: '$workspaceId/botHistory', params: { workspaceId } } as const
      const botHistory = await getPreference(prefsProps)
      setPreference({ ...prefsProps, value: botHistory.filter((botId) => botId !== id) })

      await onSuccess?.()

      // Queries should be removed AFTER the redirect and success callback, otherwise a race condition happens and data for the deleted bot will try to be refetched.
      queryClient.removeQueries({ queryKey: getBotQueryKey })
      queryClient.removeQueries({ queryKey: listBotsQueryKey })
    },
    onError: async (error: Error, { options: { onError } }) => {
      showErrorToast(error.message)
      await onError?.()
    },
  })
}
