import { Flex } from '@radix-ui/themes'
import { Button } from '../Button'

type Variant = 'primary' | 'danger' | 'neutral'

type Props = {
  cancelLabel?: string
  confirmLabel?: string
  variant?: Variant
  onCancel?: () => void
  onConfirm?: () => void
  showCancel?: boolean
  disabled?: boolean
  loading?: boolean
}

const getVariant = (variant: Variant): { color?: 'blue' | 'red' | 'gray'; highContrast?: boolean } => {
  switch (variant) {
    case 'primary':
      return { color: 'blue' }
    case 'danger':
      return { color: 'red' }
    default:
      return { color: 'gray', highContrast: true }
  }
}

export const DialogFooter = ({
  cancelLabel = 'Cancel',
  onCancel,
  onConfirm,
  confirmLabel = 'Confirm',
  variant = 'primary',
  showCancel,
  disabled,
  loading,
}: Props) => {
  return (
    <Flex gap="3" mt="4" justify="end">
      {showCancel && !loading && (
        <Button onClick={onCancel} variant="soft" color="gray">
          {cancelLabel}
        </Button>
      )}
      <Button loading={loading} onClick={onConfirm} variant="solid" disabled={disabled} {...getVariant(variant)}>
        <span>{confirmLabel}</span>
      </Button>
    </Flex>
  )
}
