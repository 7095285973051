import { HoverCard as RadixHoverCard } from '@radix-ui/themes'
import { ComponentProps, ReactNode } from 'react'

type Props = {
  content: ReactNode
  children: ReactNode
} & Pick<
  ComponentProps<typeof RadixHoverCard.Root>,
  'openDelay' | 'closeDelay' | 'open' | 'onOpenChange' | 'defaultOpen'
> &
  Pick<
    ComponentProps<typeof RadixHoverCard.Content>,
    | 'maxWidth'
    | 'minWidth'
    | 'minHeight'
    | 'maxHeight'
    | 'sideOffset'
    | 'side'
    | 'width'
    | 'height'
    | 'size'
    | 'asChild'
    | 'className'
    | 'style'
    | 'onClick'
  >

export const HoverCard = ({
  children,
  open,
  onOpenChange,
  openDelay,
  defaultOpen,
  closeDelay,
  maxWidth = '300px',
  content,
  ...props
}: Props) => {
  return (
    <RadixHoverCard.Root {...{ open, onOpenChange, openDelay, defaultOpen, closeDelay }}>
      <RadixHoverCard.Trigger>{children}</RadixHoverCard.Trigger>
      <RadixHoverCard.Content {...props} maxWidth={maxWidth}>
        {content}
      </RadixHoverCard.Content>
    </RadixHoverCard.Root>
  )
}
