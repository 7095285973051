import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/workspaces/$workspaceId/')({
  component: WorkspaceComponent,
})

function WorkspaceComponent() {
  const { workspaceId } = Route.useParams()
  return <Navigate to="/workspaces/$workspaceId/home" params={{ workspaceId }} replace />
}
