import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateBot } from '../../features/bots/services'
import { BotSummary } from '../../features/bots/types'
import { getQueryKey, showErrorToast } from '../../services'
import { handleHttpErrors } from '../../shared'

type Props = { onSuccess?: () => void; onError?: () => void; onSettled?: () => void } & Parameters<typeof updateBot>[0]

export function useUpdateBot() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ workspaceId, id: botId, onSuccess: _onSuccess, ...props }: Props) => {
      return await updateBot({ workspaceId, id: botId, ...props }).catch(handleHttpErrors)
    },
    onSuccess: async (data, { workspaceId, id: botId, onSuccess }) => {
      const botsQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_', { workspaceId })
      const getBotQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId })

      queryClient.setQueryData<BotSummary[]>(botsQueryKey, (bots) => {
        const prevBots = bots || []
        const { id, name, createdAt, updatedAt, deployedAt } = data

        return prevBots.map((bot) => {
          if (bot.id === id) {
            return { id, name, createdAt, updatedAt, deployedAt }
          }
          return bot
        })
      })

      await queryClient.invalidateQueries({ queryKey: getBotQueryKey })
      // TODO: We need to fix the return of updateBot to include all the webchat configs in the mean time we can't use this and simply invalidate the query
      // queryClient.setQueryData<BotObject>(queryKeys.getBot(botId), () => {
      //   return data
      // })
      onSuccess?.()
    },
    onError: (error: Error, { onError }) => {
      showErrorToast(error.message)
      onError?.()
    },
    onSettled: (_data, _error, { onSettled }) => {
      onSettled?.()
    },
  })
}
