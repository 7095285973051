import { words, upperFirst } from 'lodash'

export const titleCase = (str: string) => words(str).map(upperFirst).join(' ')

export function splitCamelCase(input: string) {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function isBase64(str: string): boolean {
  // Remove the data URL prefix if it exists
  const base64String = str.replace(/^data:\w+\/[a-zA-Z+\-.]+;base64,/, '').trim()

  // Base64 validation regex (updated for better readability and handling)
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/

  // Check if the remaining string is a valid Base64 and its length is a multiple of 4
  return base64Regex.test(base64String) && base64String.length % 4 === 0
}
