export const DEFAULT_LOCALE = 'en-US'

export const DEFAULT_CURRENCY = 'USD'

export const PAT_KEY = 'pat'

export const cacheDuration = {
  //** 1 second */
  extraExtraShort: 1000,

  /**  5 seconds */
  extraShort: 1000 * 5,

  /**  30 seconds */
  short: 1000 * 30,

  /**  5 minutes */
  medium: 1000 * 60 * 5,

  /**  1 hour */
  long: 1000 * 60 * 60 * 24,

  /**  3 days */
  extraLong: 1000 * 60 * 60 * 24 * 3,

  /**  7 days */
  extraExtraLong: 1000 * 60 * 60 * 24 * 7,
} as const
