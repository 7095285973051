import { useState } from 'react'
import { copyToClipboard } from '~/utils'
import cx from 'classnames'
import {
  HiMiniCheckCircle,
  HiOutlineClipboardDocument,
  HiOutlineClipboardDocumentCheck,
  HiMiniXCircle,
} from 'react-icons/hi2'

type HandleMessageProps = {
  workspaceHandle?: string
  errorMessage: string | null
  successMessage: string | null
}

const makeHandleUrl = (handle: string) => {
  return {
    forHumans: `${window.location.host}/${handle}`,
    forComputers: `${window.location.origin}/${handle}`,
  }
}

function WorkspaceHandleMessage({ workspaceHandle, errorMessage, successMessage }: HandleMessageProps) {
  const [handleCopied, setHandleCopied] = useState(false)

  const { forHumans, forComputers } = makeHandleUrl(workspaceHandle ?? '')

  const copyHandleToClipboard = () => {
    void copyToClipboard(forComputers, 'Shareable Workspace url').then(() => {
      setHandleCopied(true)
      setTimeout(() => setHandleCopied(false), 4000)
    })
  }

  const showError = !!errorMessage
  const showSuccess = !errorMessage && !!successMessage
  const showNeutral = !showError && !showSuccess
  const showNotset = showNeutral && !workspaceHandle
  const showCopy = showNeutral && workspaceHandle

  // this is to ignore the lint complain about complexity of the function
  const renderMessage = () => {
    if (showError) {
      return (
        <>
          <HiMiniXCircle size={16} />
          {errorMessage}
        </>
      )
    } else if (showSuccess) {
      return (
        <>
          <HiMiniCheckCircle size={16} />
          {successMessage}
        </>
      )
    } else if (showNotset) {
      return (
        <>
          A Workspace Handle will allow to navigate directly to this Workspace using a pretty URL.This is particularly
          useful when you want to showcase your work on external platforms or share it with others like so:&nbsp;
          <strong className="font-normal underline">{makeHandleUrl('super-team').forHumans}</strong>
        </>
      )
    } else if (showCopy) {
      return (
        <span onClick={copyHandleToClipboard} className="cursor-pointer">
          This Workspace can be accessed by navigating to&nbsp;
          <strong className="mr-2 font-normal underline">{forHumans}</strong>
          {handleCopied && <HiOutlineClipboardDocumentCheck size={16} className="inline-block" />}
          {!handleCopied && <HiOutlineClipboardDocument size={16} className="inline-block" />}
        </span>
      )
    } else {
      return null
    }
  }

  return (
    <p
      className={cx('mt-1 text-xs font-light', {
        'text-gray-11': showNeutral,
        'text-red-10': showError,
        'text-grass-10': showSuccess,
        'flex items-center gap-1': showError || showSuccess,
      })}
    >
      {renderMessage()}
    </p>
  )
}

export { WorkspaceHandleMessage, makeHandleUrl }
