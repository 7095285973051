import { ReactNode } from 'react'
import { create } from 'zustand'

export type DialogOptions = {
  title?: string
  size?: '1' | '2' | '3' | '4' | '5'
}

export type DialogStore = DialogOptions & {
  content?: ReactNode
  show: boolean
  setShow: (show: boolean) => void
  update: (options: Partial<DialogStore>) => void
}

export const useDialogStore = create<DialogStore>((set) => ({
  title: '',
  content: null,
  show: false,
  size: '2',
  setShow: (show: boolean) => set({ show }),
  update: (options: Partial<DialogStore>) => set(options),
}))
