import { Flex, Separator, Text } from '@radix-ui/themes'
import { ReactNode } from 'react'

export const PageSection = ({
  title,
  children,
  ...props
}: {
  title: ReactNode
  children?: ReactNode
  className?: string
}) => {
  return (
    <Flex {...props} direction="column" gap="3">
      <Text size="5" weight="medium">
        {title}
      </Text>
      <Separator size="4" />
      {children}
    </Flex>
  )
}
