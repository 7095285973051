import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryKeys } from './useQueryKeys'

export function useReadFile({ url }: { url: string }) {
  const { readFile } = useQueryKeys()
  return useQuery({
    enabled: !!url,
    staleTime: 60_000,
    queryKey: readFile(url ?? ''),
    queryFn: async () => {
      const file = await axios.get(url, {
        withCredentials: false,
      })

      return file?.data ?? ''
    },
    meta: {
      errorMessage: `Failed to read file from: ${url}, please try again later`,
    },
  })
}
