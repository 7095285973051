import { Badge, Flex, Text } from '@radix-ui/themes'
import { forwardRef, ReactNode } from 'react'
import { cn } from '../utils'
import { Color } from '../types'

type Props = {
  label: ReactNode
  value: ReactNode
  className?: string
  color?: Color
  clickable?: boolean
}

export const Tag = forwardRef<HTMLDivElement, Props>(
  ({ label, value, className, color = 'gray', clickable, ...props }, ref) => {
    return (
      <Badge
        {...props}
        variant="surface"
        color={color}
        className={cn('p-px', { 'cursor-pointer hover:brightness-[.98]': clickable }, className)}
        ref={ref}
      >
        <Flex className="truncate">
          <Text truncate size={'1'} weight={'medium'} className="flex-none rounded-l-sm bg-white p-1 dark:bg-accent-1">
            {label}
          </Text>
          <Text truncate size={'1'} weight={'regular'} className="rounded-r-sm bg-accent-2 p-1">
            {value}
          </Text>
        </Flex>
      </Badge>
    )
  }
)
