import type { ConversationStatus } from './types'
import type { Color } from '~/types'

export const conversationStatusesMap: Record<ConversationStatus, { color: Color; title: string }> = {
  pending: {
    title: 'Pending',
    color: 'blue',
  },
  assigned: {
    title: 'Assigned',
    color: 'grass',
  },
  timed_out: {
    title: 'Timed out',
    color: 'red',
  },
  unknown: {
    title: 'Unknown',
    color: 'gray',
  },
  cancelled: {
    title: 'Cancelled',
    color: 'red',
  },
  solved: {
    title: 'Solved',
    color: 'gray',
  },
} as const
