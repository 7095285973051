import { useId } from '@floating-ui/react-dom-interactions'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import cx from 'classnames'
import { twMerge } from 'tailwind-merge'

export type SwitchProps = {
  label?: React.ReactNode
  leadingIcon?: React.ReactNode
  color?: 'accent' | 'emerald'
  labelClassName?: string
} & React.ComponentProps<typeof SwitchPrimitive.Root>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Switch = ({ label, id, leadingIcon, color = 'accent', labelClassName, ...props }: SwitchProps) => {
  const switchId = id || useId()

  return (
    <div className={cx('flex items-center', props.className)}>
      {leadingIcon}
      <label
        className={twMerge(
          cx('mr-auto grow pr-2 text-sm text-gray-11', {
            'cursor-pointer': !props.disabled,
            'cursor-not-allowed': props.disabled,
          }),
          labelClassName
        )}
        htmlFor={switchId}
      >
        {label}
      </label>
      <SwitchPrimitive.Root
        {...props}
        id={switchId}
        className={cx(
          'flex h-6 w-11 flex-none items-center rounded-full bg-gray-4 transition-colors disabled:cursor-not-allowed disabled:opacity-50',
          { 'data-[state=checked]:bg-accent-9': color === 'accent' },
          { 'data-[state=checked]:bg-emerald-9': color === 'emerald' }
        )}
      >
        <SwitchPrimitive.Thumb className="flex h-5 w-5 translate-x-0.5 items-center justify-center rounded-full bg-gray-1 shadow transition-transform data-[state=checked]:translate-x-[22px] dark:bg-gray-9 dark:data-[state=checked]:bg-gray-12" />
      </SwitchPrimitive.Root>
    </div>
  )
}
