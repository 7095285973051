import { useEffect } from 'react'
import { useCurrentUser } from '../hooks'

type Props = {
  children?: React.ReactNode
}

export const PartnerStackProvider = ({ children }: Props) => {
  const user = useCurrentUser()

  useEffect(() => {
    const growsumo = window.growsumo

    if (growsumo) {
      try {
        const growsumoSignedupKey = `growsumo_signup_${user.id}`
        const growsumoSignedup = localStorage.getItem(growsumoSignedupKey)

        if (!growsumoSignedup) {
          growsumo.data.name = 'anonymous'
          growsumo.data.email = user.email
          growsumo.data.customer_key = user.id
          growsumo.createSignup()
          localStorage.setItem(growsumoSignedupKey, 'true')
        }
      } catch (e) {
        // TODO add sentry here
      }
    }
  }, [])

  return <>{children}</>
}
