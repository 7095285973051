import { queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { BillingApiClient } from '../features/billing/services'
import { getApiClient } from '../client'
import { ClientReturn } from 'botpress-client'

export const customerPortalQueryOptions = (workspaceId: string) => {
  return queryOptions({
    gcTime: 0,
    queryKey: useQueryKeys().getWorkspaceCustomerPortal(workspaceId),
    queryFn: async () => BillingApiClient.generateCustomerPortalSession(workspaceId),
    meta: {
      suppressError: true,
    },
  })
}

export const upcomingInvoiceQueryOptions = (workspaceId: string) => {
  const client = getApiClient(workspaceId)
  return queryOptions({
    gcTime: 0,
    queryKey: useQueryKeys().getUpcomingInvoice(workspaceId),
    queryFn: async () => client.getUpcomingInvoice({ id: workspaceId }),
    meta: {
      suppressError: true,
    },
  })
}

export const listInvoicesQueryOptions = (workspaceId: string) => {
  const client = getApiClient(workspaceId)
  return queryOptions({
    gcTime: 0,
    queryKey: useQueryKeys().listInvoices(workspaceId),
    queryFn: async (): Promise<ClientReturn<'listWorkspaceInvoices'>> =>
      client.listWorkspaceInvoices({ id: workspaceId }),
  })
}
