import { useEffect } from 'react'
import { useAsync } from 'react-use'
import { getGenericClient, isHttpUnauthorizedError } from '../client'
import { logout } from '../services'
import { useAppStore } from '../stores'
import { setUser } from '@sentry/react'

type Props = {
  children?: React.ReactNode
}

const client = getGenericClient()

export const UserProvider = ({ children }: Props) => {
  const currentUser = useAppStore((state) => state.currentUser)
  const setCurrentUser = useAppStore((state) => state.setCurrentUser)

  const state = useAsync(async () => {
    try {
      const {
        account: { profilePicture, ...account },
      } = await client.getAccount({})
      setUser(account)
      setCurrentUser({ ...account, profilePicture })
    } catch (err) {
      if (isHttpUnauthorizedError(err)) {
        logout()
      } else {
        throw err
      }
    }
  }, [])

  useEffect(() => {
    if (state.error) {
      throw state.error
    }
  }, [state.error])

  if (!currentUser) {
    return null
  }

  return <>{children}</>
}
