import { queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getIntegration, getWorkspaceIntegrations } from '../features/integrations/services'
import { getGenericClient } from '~/client'
import semver from 'semver'

type IntegrationQueryProps = {
  integrationId: string
  workspaceId: string
}
export const integrationQueryOptions = ({ integrationId, workspaceId }: IntegrationQueryProps) => {
  const { getIntegration: getIntegrationQueryKey } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getIntegrationQueryKey(integrationId),
    queryFn: () =>
      getIntegration({
        integrationId,
        workspaceId,
      }),
    meta: {
      suppressError: true,
    },
  })
}

export const getIntegrationByNameQueryOptions = ({
  name,
  version = 'latest',
  workspaceId,
}: {
  name: string
  version?: string
  workspaceId?: string
}) => {
  const { getIntegrationByName } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getIntegrationByName(name, version),
    queryFn: () =>
      getIntegration({
        name,
        version,
        workspaceId,
      }),
    meta: {
      suppressError: true,
    },
  })
}

export const listWorkspaceIntegrationsQueryOptions = ({ workspaceId }: { workspaceId: string }) => {
  const { listWorkspaceIntegrations } = useQueryKeys()
  return queryOptions({
    enabled: !!workspaceId,
    staleTime: cacheDuration.extraShort,
    queryKey: listWorkspaceIntegrations(workspaceId),
    queryFn: () => getWorkspaceIntegrations(workspaceId),
    meta: {
      errorMessage: 'A problem occurred while trying to fetch the available integrations',
    },
  })
}

export const getPublicIntegrationQueryOptions = ({ integrationId }: { integrationId: string }) => {
  const { getIntegration: queryKeys } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: queryKeys(integrationId),
    queryFn: () =>
      getGenericClient()
        .getPublicIntegrationById({ id: integrationId })
        .then((res) => res.integration),
    meta: {
      suppressError: true,
    },
  })
}

export const listPublicIntegrationVersionsQueryOptions = ({ integrationName }: { integrationName: string }) => {
  const { listPublicIntegrationVersions } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: listPublicIntegrationVersions(integrationName),
    queryFn: () =>
      getGenericClient()
        .listPublicIntegrations({ name: integrationName })
        .then((res) => [...res.integrations].sort((a, b) => (semver.gt(a.version, b.version) ? -1 : 1))),
    meta: {
      suppressError: true,
    },
  })
}
