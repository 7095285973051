import { QuotaType } from '@bpinternal/const'
import { formatters } from '~/utils'

type StrokeColor<T extends string = string, U extends string = string> = `stroke-${T}-${U}`

export function formatValueWithUnit(value: number, type?: QuotaType): string {
  if (!value) {
    return '0'
  }
  if (type === 'storage_count' || type === 'knowledgebase_vector_storage') {
    return formatters.byte(value)
  }
  if (type === 'ai_spend') {
    return formatters.nanodollars(value)
  }
  if (!type) {
    return formatters.numberDefault(value)
  }
  return formatters.number(value)
}

export function getColorClassForPercent(percent: number): StrokeColor {
  if (percent > 100) {
    return 'stroke-rose-600'
  }
  if (percent >= 90) {
    return 'stroke-red-500'
  }
  if (percent >= 75) {
    return 'stroke-yellow-500'
  }
  return 'stroke-blue-500'
}

export function getColorClassVariablesForPercent(percent: number): string {
  if (percent >= 100) {
    return '[--tremor-default:221_68_37] [--tremor-muted:255_220_211]'
  }
  if (percent >= 75) {
    return '[--tremor-default:255_220_0] [--tremor-muted:255_243_148]'
  }
  return '[--tremor-default:5_136_240] [--tremor-muted:213_239_255]'
}
