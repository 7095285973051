import { useDialogStore, type DialogOptions } from './dialogStore'
import { useState, type FC, type ReactNode, type ComponentProps } from 'react'
import { DialogFooter } from './DialogFooter'

type DialogContent = FC<{
  close: () => void
}>

export function showDialog(Content: DialogContent, options?: DialogOptions) {
  const { title, size } = options ?? {}

  useDialogStore.setState({
    show: true,
    title,
    size,
    content: <Content close={() => useDialogStore.getState().setShow(false)} />,
  })
}

type PromptDialogContent<T> = FC<{
  value?: T
  setValue: (value: T) => void
  setValid: (valid: boolean) => void
  close: () => void
}>

type PromptOptions = DialogOptions &
  Pick<ComponentProps<typeof DialogFooter>, 'cancelLabel' | 'confirmLabel' | 'variant' | 'showCancel'>

const defaultFooterOptions: PromptOptions = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  variant: 'primary',
  title: 'Title',
}

export function showPromptDialog<T>(Content: PromptDialogContent<T>, options?: PromptOptions) {
  const { title, size, ...footerOptions } = { ...defaultFooterOptions, ...(options ?? {}) }
  return new Promise<T | undefined>((resolve, reject) => {
    showDialog(
      ({ close }: { close: () => void }) => {
        const [value, setValue] = useState<T>()
        const [valid, setValid] = useState<boolean>(false)
        return (
          <>
            <Content value={value} setValue={setValue} setValid={setValid} close={close} />
            <DialogFooter
              {...footerOptions}
              disabled={!valid}
              onCancel={() => {
                reject()
                close()
              }}
              onConfirm={() => {
                resolve(value)
                close()
              }}
            />
          </>
        )
      },
      { title, size }
    )
  })
}

export function showConfirmationPrompt(content: ReactNode, options?: PromptOptions) {
  const { title, size, ...footerOptions } = { ...defaultFooterOptions, ...(options ?? {}) }

  return new Promise<boolean>((resolve, reject) => {
    showDialog(
      ({ close }: { close: () => void }) => {
        return (
          <>
            {content}
            <DialogFooter
              {...footerOptions}
              onCancel={() => {
                close()
                reject()
              }}
              onConfirm={() => {
                resolve(true)
                close()
              }}
            />
          </>
        )
      },
      { title, size }
    )
  })
}
