import { useMutation, useQueryClient } from '@tanstack/react-query'
import { setPreference, SetPreferencePropsUnion } from '../../services/preferences'
import { getQueryOptions } from '~/services'

export const useSetPreferences = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (props: SetPreferencePropsUnion) => {
      return setPreference(props)
    },
    onSuccess: async (_, props) => {
      const { queryKey } = getQueryOptions('preferences/$path', {
        path: '$workspaceId/$botId/analyticsGridBoard',
        params: { botId: 'botId', workspaceId: 'workspaceId' },
      })

      queryClient.setQueryData(queryKey, props.value)
    },
    onError: (error) => {
      console.error('Error updating user preferences', error)
    },
  })
}
