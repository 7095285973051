import type React from 'react'
import { showSuccessToast } from '../services'

export function copyToClipboard(
  valueToCopy: string,
  descriptionOfValue: string,
  options?: { preventDefault?: boolean; event: Event | React.MouseEvent }
) {
  if (options?.preventDefault !== false) {
    options?.event.preventDefault()
  }
  return navigator.clipboard.writeText(valueToCopy).then(() => {
    return showSuccessToast(`The ${descriptionOfValue} has been copied to your clipboard.`)
  })
}
