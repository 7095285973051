import cx from 'classnames'
import { ComponentProps } from 'react'

export type LinkProps = ComponentProps<'a'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Link = (props: LinkProps) => (
  <a
    {...props}
    className={cx(
      'text-accent-11 no-underline hover:underline hover:decoration-accent-10 hover:decoration-2',
      props.className
    )}
  />
)
