import { useEffect, useRef } from 'react'
import { useIsInViewport } from '~/hooks/useIsInViewPort'

export function useInfiniteScroll(fetchMore: () => any, hasNextPage: boolean) {
  const bottomRef = useRef<null | HTMLDivElement>(null)
  const isAtBottom = useIsInViewport(bottomRef)

  useEffect(() => {
    if (isAtBottom && hasNextPage) {
      void fetchMore()
    }
  }, [isAtBottom])

  return bottomRef
}
