import { forwardRef, PropsWithChildren } from 'react'

import { Block, MessageObject, useWebchatStore } from '@botpress/webchat'
import { Avatar } from '../../../elementsv2'
import { Link } from '@tanstack/react-router'
import { cn } from '../../../utils'

import './styles.css'

type MessageProps = PropsWithChildren<Partial<MessageObject>> & {
  id: string
  selected?: boolean
  disableLink?: boolean
}
export const Message = forwardRef<HTMLDivElement, MessageProps>(
  ({ direction, block, disableInput, children, sender, id, selected, disableLink, ...props }, ref) => {
    const { botAvatar, botName } = useWebchatStore((s) => s.configuration)

    return (
      <div
        {...props}
        className={'bpMessageContainer'}
        data-disable-input={!!disableInput}
        data-direction={direction}
        ref={ref}
      >
        {direction === 'incoming' && (
          <Avatar size={'1'} variant="soft" name={sender?.name ?? botName} pictureUrl={sender?.avatar ?? botAvatar} />
        )}
        <Link
          resetScroll={false}
          preload={false}
          className={cn('flex justify-end rounded-sm', { 'ring-2 ring-offset-2': selected })}
          to="."
          search={{ messageId: id }}
          disabled={disableLink}
        >
          {block ? <Block block={block} /> : null}
        </Link>
        {children}
      </div>
    )
  }
)
