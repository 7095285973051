import { DialogFooter, showDialog } from '@botpress/ui-kit'
import { Flex, TextField, Text } from '@radix-ui/themes'
import { useRouter } from '@tanstack/react-router'
import type { Workspace } from 'botpress-client'
import { useState, type ReactNode } from 'react'
import { HiOutlineExclamationCircle, HiOutlineExclamationTriangle, HiOutlinePlus } from 'react-icons/hi2'
import { DropdownMenu, type MenuItem } from '~/elementsv2'
import { DYNAMIC_QUOTAS, QuotaUsedPercentageWarningThreshold } from '~/features/usage/constants'
import { WorkspaceProfilePicture } from '~/features/workspaces/components'
import { useCreateWorkspace, useWorkspacesUsages } from '~/hooks'
import { useAppStore } from '~/stores'

type Props = { className?: string; workspaces: Workspace[]; children: ReactNode }

export const WorkspaceSelectorMenu = ({ workspaces, ...props }: Props) => {
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const navigate = useRouter().navigate
  const workspacesUsages = useWorkspacesUsages({
    workspaceIds: workspaces.map((w) => w.id),
    quotas: DYNAMIC_QUOTAS,
  })

  const userHasAccessToWorkspace = workspaces.some((w) => w.id === activeWorkspace?.id)

  const workspaceMenuItems: MenuItem[] = [
    {
      type: 'radioGroup',
      value: userHasAccessToWorkspace ? activeWorkspace?.id : undefined,
      items:
        workspaces?.map((workspace) => {
          const workspaceUsage = workspacesUsages[workspace.id]
          const anyUsageHasReachedQuota = Object.entries(workspaceUsage ?? {}).some(
            ([_type, usage]) => usage.percentage >= 100
          )
          const anyUsageExceedsWarningThreshold = !anyUsageHasReachedQuota
            ? Object.entries(workspaceUsage ?? {}).some(
                ([_type, usage]) => usage.percentage > QuotaUsedPercentageWarningThreshold
              )
            : true

          return {
            content: workspace.name,
            type: 'radioItem',
            value: workspace.id,
            onSelect: () => navigate({ to: '/workspaces/$workspaceId', params: { workspaceId: workspace.id } }),
            leadingIcon: (
              <WorkspaceProfilePicture
                workspaceId={workspace.id}
                className="h-5 w-5 rounded-full"
                pictureUrl={workspace.profilePicture}
              />
            ),
            trailingIcon: anyUsageHasReachedQuota ? (
              <HiOutlineExclamationCircle className="text-red-10" title="One or more quotas have reached 100% usage" />
            ) : anyUsageExceedsWarningThreshold ? (
              <HiOutlineExclamationTriangle
                className="text-amber-10"
                title={`One or more quotas are above ${QuotaUsedPercentageWarningThreshold}% usage`}
              />
            ) : null,
          }
        }) ?? [],
    },
    { type: 'separator' },
    {
      type: 'item',
      content: 'Create a workspace',
      leadingIcon: <HiOutlinePlus />,
      color: 'blue',
      onSelect: () =>
        showDialog(
          ({ close }) => (
            <NewWorkspaceModal
              onWorkspaceCreated={(workspace) => {
                void navigate({ to: '/workspaces/$workspaceId', params: { workspaceId: workspace.id } })
                close()
              }}
            />
          ),
          {
            title: 'Create a workspace',
          }
        ),
    },
  ]

  return <DropdownMenu {...props} variant="soft" color="gray" content={workspaceMenuItems} />
}

type NewWorkspaceModalProps = {
  onWorkspaceCreated: (workspace: Workspace) => void
}

const NewWorkspaceModal = ({ onWorkspaceCreated }: NewWorkspaceModalProps) => {
  const [workspaceName, setWorkspaceName] = useState('')
  const { mutate: createWorkspace, isPending } = useCreateWorkspace()

  return (
    <>
      <Flex direction={'column'} gap={'4'}>
        <Text size={'1'}>This is the name you’ll see in your workspace list.</Text>
        <TextField.Root
          value={workspaceName}
          onChange={(e) => {
            setWorkspaceName(e.target.value)
          }}
          placeholder="Workspace name"
        />
      </Flex>
      <DialogFooter
        loading={isPending}
        onConfirm={() => {
          createWorkspace({ name: workspaceName, onSuccess: onWorkspaceCreated })
        }}
      />
    </>
  )
}
