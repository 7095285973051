import { queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getApiClient } from '../client'
import { listAll } from '../utils'
import { ClientReturn } from 'botpress-client'

export const getIssueQueryOptions = ({
  botId,
  workspaceId,
  issueId,
}: {
  botId: string
  workspaceId: string
  issueId: string
}) => {
  const { getIssue } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    enabled: !!botId && !!issueId,
    queryKey: getIssue({ botId, workspaceId, issueId }),
    queryFn: async () => {
      const apiClient = getApiClient(workspaceId)
      const issue = await apiClient.getBotIssue({ id: botId, issueId })

      return issue
    },
  })
}

export const listAllIssuesQueryOptions = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const { listAllIssues } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    enabled: !!botId || !!workspaceId,
    queryKey: listAllIssues({ botId, workspaceId }),
    queryFn: async () => {
      const apiClient = getApiClient(workspaceId)
      const issues: Promise<ClientReturn<'listBotIssues'>['issues']> = listAll(
        apiClient.listBotIssues,
        { id: botId },
        (response) => response.issues
      )

      return issues
    },
    meta: {
      suppressError: true,
    },
  })
}

export const listIssueEventsQueryOptions = ({
  botId,
  workspaceId,
  issueId,
}: {
  botId: string
  workspaceId: string
  issueId: string
}) => {
  const { listIssueEvents } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    enabled: !!botId && !!issueId,
    queryKey: listIssueEvents({ botId, workspaceId, issueId }),
    queryFn: async () => (await getApiClient(workspaceId).listBotIssueEvents({ id: botId, issueId })).issueEvents,
  })
}
