// this file was automatically generated, do not edit
/* eslint-disable */

import axios, { AxiosInstance } from 'axios'
import { errorFrom } from './errors'
import { toAxiosRequest } from './to-axios'
import * as createConversation from './operations/createConversation'
import * as deleteConversation from './operations/deleteConversation'
import * as getConversation from './operations/getConversation'
import * as listConversationMessages from './operations/listConversationMessages'
import * as listConversations from './operations/listConversations'
import * as listenConversation from './operations/listenConversation'
import * as updateConversation from './operations/updateConversation'
import * as createMessage from './operations/createMessage'
import * as deleteMessage from './operations/deleteMessage'
import * as getMessage from './operations/getMessage'
import * as deleteUser from './operations/deleteUser'
import * as getUser from './operations/getUser'
import * as createFile from './operations/createFile'
import * as login from './operations/login'

export * from './models'

export * as createConversation from './operations/createConversation'
export * as deleteConversation from './operations/deleteConversation'
export * as getConversation from './operations/getConversation'
export * as listConversationMessages from './operations/listConversationMessages'
export * as listConversations from './operations/listConversations'
export * as listenConversation from './operations/listenConversation'
export * as updateConversation from './operations/updateConversation'
export * as createMessage from './operations/createMessage'
export * as deleteMessage from './operations/deleteMessage'
export * as getMessage from './operations/getMessage'
export * as deleteUser from './operations/deleteUser'
export * as getUser from './operations/getUser'
export * as createFile from './operations/createFile'
export * as login from './operations/login'

export type ClientProps = {
  toAxiosRequest: typeof toAxiosRequest
}

export class Client {

  public constructor(private axiosInstance: AxiosInstance, private props: Partial<ClientProps> = {}) {}

  public readonly createConversation = async (input: createConversation.CreateConversationInput): Promise<createConversation.CreateConversationResponse> => {
    const { path, headers, query, body } = createConversation.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "post",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<createConversation.CreateConversationResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly deleteConversation = async (input: deleteConversation.DeleteConversationInput): Promise<deleteConversation.DeleteConversationResponse> => {
    const { path, headers, query, body } = deleteConversation.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "delete",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<deleteConversation.DeleteConversationResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly getConversation = async (input: getConversation.GetConversationInput): Promise<getConversation.GetConversationResponse> => {
    const { path, headers, query, body } = getConversation.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<getConversation.GetConversationResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly listConversationMessages = async (input: listConversationMessages.ListConversationMessagesInput): Promise<listConversationMessages.ListConversationMessagesResponse> => {
    const { path, headers, query, body } = listConversationMessages.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<listConversationMessages.ListConversationMessagesResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly listConversations = async (input: listConversations.ListConversationsInput): Promise<listConversations.ListConversationsResponse> => {
    const { path, headers, query, body } = listConversations.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<listConversations.ListConversationsResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly listenConversation = async (input: listenConversation.ListenConversationInput): Promise<listenConversation.ListenConversationResponse> => {
    const { path, headers, query, body } = listenConversation.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<listenConversation.ListenConversationResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly updateConversation = async (input: updateConversation.UpdateConversationInput): Promise<updateConversation.UpdateConversationResponse> => {
    const { path, headers, query, body } = updateConversation.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "post",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<updateConversation.UpdateConversationResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly createMessage = async (input: createMessage.CreateMessageInput): Promise<createMessage.CreateMessageResponse> => {
    const { path, headers, query, body } = createMessage.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "post",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<createMessage.CreateMessageResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly deleteMessage = async (input: deleteMessage.DeleteMessageInput): Promise<deleteMessage.DeleteMessageResponse> => {
    const { path, headers, query, body } = deleteMessage.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "delete",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<deleteMessage.DeleteMessageResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly getMessage = async (input: getMessage.GetMessageInput): Promise<getMessage.GetMessageResponse> => {
    const { path, headers, query, body } = getMessage.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<getMessage.GetMessageResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly deleteUser = async (input: deleteUser.DeleteUserInput): Promise<deleteUser.DeleteUserResponse> => {
    const { path, headers, query, body } = deleteUser.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "delete",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<deleteUser.DeleteUserResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly getUser = async (input: getUser.GetUserInput): Promise<getUser.GetUserResponse> => {
    const { path, headers, query, body } = getUser.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "get",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<getUser.GetUserResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly createFile = async (input: createFile.CreateFileInput): Promise<createFile.CreateFileResponse> => {
    const { path, headers, query, body } = createFile.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "post",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<createFile.CreateFileResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

  public readonly login = async (input: login.LoginInput): Promise<login.LoginResponse> => {
    const { path, headers, query, body } = login.parseReq(input)
    const mapper = this.props.toAxiosRequest ?? toAxiosRequest
    const axiosReq = mapper({
        method: "post",
        path,
        headers: { ...headers },
        query: { ...query },
        body,
    })
    return this.axiosInstance.request<login.LoginResponse>(axiosReq)
      .then((res) => res.data)
      .catch((e) => { throw getError(e) })
  }

}

// maps axios error to api error type
function getError(err: Error) {
  if (axios.isAxiosError(err) && err.response?.data) {
    return errorFrom(err.response.data)
  }
  return errorFrom(err)
}

