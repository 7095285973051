// this file was automatically generated, do not edit
/* eslint-disable */

export interface CreateConversationRequestHeaders {
  "x-user-key": string;
}

export interface CreateConversationRequestQuery {}

export interface CreateConversationRequestParams {}

/**
 * Conversation properties
 */
export interface CreateConversationRequestBody {
  /**
   * Status of the [Conversation](#schema_conversation)
   */
  status?: "pending" | "assigned" | "timed_out" | "cancelled" | "solved" | "unknown";
  assignee?: {
    /**
     * Id of the [User](#schema_user)
     */
    id?: string;
    /**
     * workspace member id of the [User](#schema_user)
     */
    workspaceMemberId?: string;
  };
}

export type CreateConversationInput = CreateConversationRequestBody & CreateConversationRequestHeaders & CreateConversationRequestQuery & CreateConversationRequestParams

export type CreateConversationRequest = {
  headers: CreateConversationRequestHeaders;
  query: CreateConversationRequestQuery;
  params: CreateConversationRequestParams;
  body: CreateConversationRequestBody;
}

export const parseReq = (input: CreateConversationInput): CreateConversationRequest & { path: string } => {
  return {
    path: `/conversations`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: {  },
    body: { 'status': input['status'], 'assignee': input['assignee'] },
  }
}

export interface CreateConversationResponse {
  conversation: {
    /**
     * Id of the [Conversation](#schema_conversation)
     */
    id: string;
    /**
     * Creation date of the [Conversation](#schema_conversation) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Conversation](#schema_conversation) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Title of the [Conversation](#schema_conversation)
     */
    title?: string;
    /**
     * Description of the [Conversation](#schema_conversation)
     */
    description?: string;
    /**
     * Status of the [Conversation](#schema_conversation)
     */
    status: "pending" | "assigned" | "timed_out" | "cancelled" | "solved" | "unknown";
    assignee?: {
      /**
       * Id of the [User](#schema_user)
       */
      id: string;
      /**
       * workspace member id of the [User](#schema_user)
       */
      workspaceMemberId?: string;
    };
    /**
     * User who initialy started the hitl [Conversation](#schema_conversation)
     */
    requester?: {
      id: string;
    };
    /**
     * Additional dynamic information about the [Conversation](#schema_conversation)
     */
    tags: {
      [k: string]: string;
    };
  };
}

