import { Flex, Text } from '@radix-ui/themes'
import { ProgressBar } from '@tremor/react'
import React, { ReactNode } from 'react'
import { formatValueWithUnit } from '../features/usage/helpers'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '../features/usage/constants'
import { Usage } from 'botpress-client'
import { clamp } from 'lodash'
import { blue, ruby, yellow } from '@radix-ui/colors'

type Props = { description?: ReactNode; title?: ReactNode; color?: string } & Pick<Usage, 'type' | 'value' | 'quota'>
export const UsageProgressBar = ({ quota, type, value, description, title, color }: Props) => {
  const usagePercentage = quota ? clamp((value / quota) * 100, 0, 100) : 0
  color = color || (usagePercentage > 90 ? ruby.ruby8 : usagePercentage > 70 ? yellow.yellow8 : blue.blue8)

  return (
    <Flex key={type} direction="column" gap="1">
      {title ? title : <Text size={'2'}>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}</Text>}
      {description}
      <ProgressBar value={usagePercentage} color={color as any} />
      <Text size={'1'} className="text-nowrap" align={'right'} color="gray">
        <Text weight={'medium'}>{formatValueWithUnit(value, type)}</Text>
        <Text>{` of ${formatValueWithUnit(quota, type)}`}</Text>
      </Text>
    </Flex>
  )
}
