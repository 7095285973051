import { Flex, Text } from '@radix-ui/themes'
import { ComponentProps, FC, ReactNode } from 'react'

type Props = {
  icon?: FC<{ className?: string }>
  title?: ReactNode
  description?: ReactNode
  primaryAction?: ReactNode
  secondaryAction?: ReactNode
  iconSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
  descriptionSize?: '1' | '2'
  className?: string
} & Pick<ComponentProps<typeof Flex>, 'gap' | 'p' | 'm' | 'mx' | 'maxWidth'>

export const EmptyState = ({
  icon: Icon,
  title,
  description,
  primaryAction,
  secondaryAction,
  iconSize = 4,
  descriptionSize = '2',
  ...props
}: Props) => {
  return (
    <Flex direction={'column'} justify={'center'} align={'center'} gap={'5'} {...props}>
      {Icon && (
        <Flex style={{ height: 16 * iconSize, width: 16 * iconSize }}>
          <Icon className="h-full w-full" />
        </Flex>
      )}
      {title && (
        <Text align={'center'} size={'4'} weight={'bold'}>
          {title}
        </Text>
      )}
      <Text size={descriptionSize} align={'center'}>
        {description}
      </Text>
      {primaryAction}
      {secondaryAction}
    </Flex>
  )
}
