import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getApiClientForBot } from '../client'
import { ClientParams } from 'botpress-client'

type GetConversationProps = {
  botId: string
  workspaceId: string
  conversationId: string
}
export const conversationQueryOptions = ({ botId, workspaceId, conversationId }: GetConversationProps) => {
  const { getConversation } = useQueryKeys()
  const client = getApiClientForBot({ workspaceId, botId })
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getConversation({ botId, workspaceId, conversationId }),
    queryFn: async () => client.getConversation({ id: conversationId }),
    meta: {
      suppressError: true,
    },
  })
}

export type ListConversationsProps = { botId: string; workspaceId: string } & Pick<
  ClientParams<'listConversations'>,
  'participantIds' | 'tags' | 'integrationName'
>
export const listConversationsInfiniteQuery = ({ botId, workspaceId, ...props }: ListConversationsProps) => {
  const { listConversations } = useQueryKeys()
  const client = getApiClientForBot({ workspaceId, botId })
  return infiniteQueryOptions({
    staleTime: cacheDuration.short,
    queryKey: listConversations({ botId, workspaceId, ...props }),
    queryFn: async ({ pageParam }) =>
      client
        .listConversations({
          nextToken: pageParam,
          sortField: 'updatedAt', // TODO: updatedAt is too unstable to use for sorting conversations, change default back to createdAt, see: https://botpresshq.slack.com/archives/C03TB7C3WLA/p1721663321069319
          sortDirection: 'desc',
          ...props,
        })
        .catch((e) => {
          console.error('Failed to fetch conversations', e)
          return { conversations: [], meta: { nextToken: null } }
        }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
    meta: {
      suppressError: true,
    },
  })
}

export const listConversationUsersQueryOptions = ({ botId, workspaceId, conversationId }: GetConversationProps) => {
  const queryKeys = useQueryKeys()
  return queryOptions({
    queryKey: queryKeys.listConversationUsers(conversationId, botId, workspaceId),
    queryFn: () => getApiClientForBot({ workspaceId, botId }).listUsers({ conversationId }),
    staleTime: cacheDuration.short,
    meta: {
      errorMessage: "Sorry, we couldn't load users at this time.",
      suppressError: true,
    },
  })
}
