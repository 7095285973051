import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query'
import { QuotaType, quotaTypes } from '@bpinternal/const'
import {
  workspacesUsagesQueryOptions,
  usageBreakdownUsageQueryOptions,
  workspaceUsageQueryOptions,
  workspaceUsagesQueryOptions,
} from '../../queries/usage'
import { Usage } from 'botpress-client'

type Params = {
  workspaceId: string
  period?: string
}

/**
 * @deprecated Please use `useUsage` instead.
 */
export function useWorkspaceUsages({ workspaceId, period = new Date().toISOString().split('T')[0] ?? '' }: Params) {
  return useSuspenseQueries({
    queries: quotaTypes.map((type) => workspaceUsageQueryOptions({ type, workspaceId, period })),
  }).map((query) => query.data.usage)
}

type UsageParams<T extends QuotaType> = {
  workspaceId: string
  period?: string
  quotas: T[]
}

export function useUsage<T extends QuotaType>({
  workspaceId,
  quotas,
  period = new Date().toISOString().split('T')[0] ?? '',
}: UsageParams<T>) {
  return useSuspenseQuery(workspaceUsagesQueryOptions<T>({ types: quotas, workspaceId, period })).data
}

type WorkspacesUsagesParams<T extends QuotaType> = {
  workspaceIds: string[]
  period?: string
  quotas: T[]
}

export function useWorkspacesUsages<T extends QuotaType>({
  workspaceIds,
  quotas,
  period = new Date().toISOString().split('T')[0] ?? '',
}: WorkspacesUsagesParams<T>) {
  return useSuspenseQuery(workspacesUsagesQueryOptions<T>({ workspaceIds, types: quotas, period })).data
}

function useUsageBreakdown<T extends QuotaType>({
  workspaceId,
  quotas,
  period = new Date().toISOString().split('T')[0] ?? '',
}: UsageParams<T>) {
  return useSuspenseQueries({
    queries: quotas.map((type) => usageBreakdownUsageQueryOptions({ type, workspaceId, period })),
    combine: (results) => {
      return results.reduce(
        (acc, result) => {
          if (result.data) {
            acc[result.data.type as T] = result.data.usages
          }
          return acc
        },
        {} as Record<T, { botId: string; value: number }[]>
      )
    },
  })
}

export function useDetailedUsage<T extends QuotaType>({
  workspaceId,
  quotas,
  period = new Date().toISOString().split('T')[0] ?? '',
}: UsageParams<T>) {
  const baseUsage = useUsage({ workspaceId, quotas, period })
  const breakdown = useUsageBreakdown({ workspaceId, quotas, period })
  return Object.keys(baseUsage).reduce(
    (acc, key) => {
      acc[key as T] = { ...baseUsage[key as T], breakdown: breakdown[key as T] }
      return acc
    },
    {} as Record<T, Usage & { breakdown: { botId: string; value: number }[] }>
  )
}
