import { useQuery } from '@tanstack/react-query'
import { useQueryKeys } from '../useQueryKeys'
import { BillingApiClient } from '../../features/billing/services'

export function useBillingProducts(workspaceId: string) {
  const { data: products, ...rest } = useQuery({
    queryKey: useQueryKeys().getWorkspaceBillingProducts(workspaceId),
    queryFn: () => BillingApiClient.getBillingProducts(workspaceId),
  })
  return { products, query: rest }
}
