import { type VerticalLayout } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import { LayoutRenderer } from '../services'

export const VerticalLayoutRenderer = withJsonFormsLayoutProps(({ uischema, schema, path, visible, renderers }) => {
  const verticalLayout = uischema as VerticalLayout
  const layoutProps = {
    elements: verticalLayout.elements,
    schema,
    path,
    visible,
    renderers,
    uischema,
  }

  return (
    <div className="flex flex-col gap-8">
      <LayoutRenderer {...layoutProps} />
    </div>
  )
})
