import type { ProductQuantityMap } from '../../usage/useBilling'
import { PromoCode } from '../services'

export function computeTotalCost(productQuantityMap: ProductQuantityMap, promoCode?: PromoCode | null) {
  return Object.values(productQuantityMap).reduce((acc, { quantity, productDetails }) => {
    let unitAmount = productDetails.price.unit_amount ?? 0
    if (promoCode?.coupon.appliesTo.includes(productDetails.id)) {
      unitAmount = unitAmount * (1 - promoCode.coupon.percentOff / 100)
    }

    return acc + (quantity * unitAmount) / 100
  }, 0)
}
