import { AudioBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

export const AudioSchema = BaseMessage.extend({
  type: z.literal('audio'),
  payload: z.object({
    audioUrl: z.string().min(1),
  }),
})
  .transform(({ type, payload: { audioUrl }, ...props }) => ({
    ...props,
    block: { type, url: audioUrl },
  }))
  .transform<MessageObject<AudioBlock>>(ToMessage)
