import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useHover,
  FloatingFocusManager,
  Placement,
  FloatingPortal,
  arrow,
  safePolygon,
} from '@floating-ui/react-dom-interactions'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { useRef, useState } from 'react'

type Variant = 'light' | 'dark'

export type TooltipProps = {
  disabled?: boolean
  label: React.ReactNode
  children: React.ReactNode
  placement?: Placement
  delay?: number
  variant?: Variant
  className?: string
}

const placementTransitions = {
  top: { translateY: 5 },
  'top-start': { translateY: 5 },
  'top-end': { translateY: 5 },
  right: { translateX: -5 },
  'right-start': { translateX: -5 },
  'right-end': { translateX: -5 },
  bottom: { translateY: -5 },
  'bottom-start': { translateY: -5 },
  'bottom-end': { translateY: -5 },
  left: { translateX: 5 },
  'left-start': { translateX: 5 },
  'left-end': { translateX: 5 },
} as const

const variants: { [key in Variant]: string } = {
  light: 'bg-gray-1 text-gray-11 shadow-md',
  dark: 'bg-gray-1 text-gray-12',
}

const arrowVariants: { [key in Variant]: string } = {
  light: 'bg-white',
  dark: 'bg-gray-11',
}

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Tooltip = ({
  children,
  label,
  disabled,
  placement = 'top',
  delay,
  variant = 'dark',
  ...props
}: TooltipProps) => {
  const [open, setOpen] = useState(false)
  const arrowRef = useRef(null)

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    placement: placementStrategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} } = {},
  } = useFloating({
    open: open && !disabled,
    onOpenChange: setOpen,
    middleware: [flip(), offset(10), shift(), arrow({ element: arrowRef })],
    whileElementsMounted: autoUpdate,
    placement,
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      handleClose: safePolygon(),
      delay: {
        open: delay,
        close: 150,
      },
    }),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  const arrowSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[placementStrategy.split('-')[0]!]!

  return (
    <>
      <div {...getReferenceProps({ ref: reference })} {...props}>
        {children}
      </div>
      <FloatingPortal>
        <AnimatePresence>
          {open && !disabled && (
            <FloatingFocusManager context={context} modal={false} order={['reference', 'content']}>
              <motion.div
                initial={{ opacity: 0, ...placementTransitions[placement] }}
                animate={{ opacity: 1, translateX: 0, translateY: 0 }}
                exit={{ opacity: 0, ...placementTransitions[placement] }}
                transition={{ type: 'spring', damping: 20, stiffness: 500 }}
                className={cx(
                  'z-[100] max-w-3xl break-words rounded-md px-3 py-2 outline-none [&_a:hover]:decoration-solid [&_a]:text-gray-12 [&_a]:underline [&_a]:decoration-dotted',
                  variants[variant]
                )}
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                {...getFloatingProps()}
              >
                <div
                  ref={arrowRef}
                  className={cx('absolute h-2 w-2 rotate-45 transform', arrowVariants[variant])}
                  style={{
                    left: arrowX ? `${arrowX}px` : '',
                    top: arrowY ? `${arrowY}px` : '',
                    [arrowSide]: '-4px',
                  }}
                />
                {label}
              </motion.div>
            </FloatingFocusManager>
          )}
        </AnimatePresence>
      </FloatingPortal>
    </>
  )
}
