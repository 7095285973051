import { Theme } from './types'

export const theme: Theme = {
  description: {
    className: 'text-sm text-gray-11',
  },
  markdown: {
    container: {
      className:
        '[&_code]:bg-gray-1 [&_code]:py-0.5 [&_code]:px-1 [&_code]:rounded-md [&_code]:text-gray-11 [&_code]:font-mono [&_code]:text-xs [&_code]:border [&_code]:border-gray-4 [&_p]:text-sm',
    },
  },
  collapsible: {
    container: {
      className: 'rounded-lg border border-gray-4 text-gray-11 group/container',
    },
    trigger: {
      className:
        'flex items-center justify-between w-full p-2 text-sm data-[state=open]/container:border-b border-gray-6 group/trigger',
    },
    chevron: {
      className:
        'group-hover/trigger:bg-gray-1 w-6 h-6 p-1 rounded-full data-[state=open]:rotate-90 data-[state=open]:bg-gray-1 data-[collapsible=false]:hidden fill-none stroke-black border border-gray-4',
    },
    content: {
      className: 'p-2 space-y-2',
    },
  },
}
