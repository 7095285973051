import { Popover } from '@radix-ui/themes'
import { TwitterPicker } from 'react-color'
import { ToolbarButton } from '../../../ui/ToolbarButton'

export type ColorPickerProps = {
  currentColor?: string
  onChange?: (color: string) => void
  icon: React.ReactNode
}

export const ColorPickerPopover = ({ currentColor: color, onChange, icon }: ColorPickerProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <ToolbarButton tooltip="Set Link">{icon}</ToolbarButton>
      </Popover.Trigger>
      <Popover.Content className="border-grey-1 border p-0 shadow-none">
        <TwitterPicker
          className="w-full !bg-gray-1 !shadow-none"
          triangle="hide"
          color={color || ''}
          colors={[
            '#FF6900',
            '#FCB900',
            '#7BDCB5',
            '#00D084',
            '#8ED1FC',
            '#0693E3',
            '#ABB8C3',
            '#EB144C',
            '#F78DA7',
            '#9900EF',
            '#000',
            '#fff',
          ]}
          onChange={(c) => onChange?.(c.hex)}
        />
      </Popover.Content>
    </Popover.Root>
  )
}
