export const issueCodes = [
  'AGENT_REGISTRATION_ISSUE',
  'EXECUTE_CODE_ISSUE',
  'INVALID_ACTION_PAYLOAD_ISSUE',
  'UNCAUGHT_EXCEPTION_ISSUE',
  'UNHANDLED_REJECTION_ISSUE',
  'AGENT_HOOK_EXECUTION_ISSUE',
  'AGENT_ACTION_EXECUTION_ISSUE',
  'AGENT_DISABLED_ISSUE',
  'INFINITE_LOOP_DETECTED_ISSUE',
  'INTEGRATION_ACTION_EXECUTION_ISSUE',
  'INTEGRATION_DISABLED_ISSUE',
] as const

export type IssueCode = (typeof issueCodes)[number]
