import { LinkEditor } from '../../LinkMenu/LinkEditor'
import { HiLink } from 'react-icons/hi2'
import { Popover } from '@radix-ui/themes'
import { ToolbarButton } from '../../../ui/ToolbarButton'

export type EditLinkPopoverProps = {
  onSetLink: (link: string, openInNewTab?: boolean) => void
}

export const EditLinkPopover = ({ onSetLink }: EditLinkPopoverProps) => {
  return (
    <Popover.Root>
      <Popover.Trigger>
        <ToolbarButton tooltip="Set Link">
          <HiLink />
        </ToolbarButton>
      </Popover.Trigger>
      <Popover.Content className="border-grey-1 border shadow-none">
        <LinkEditor onSetLink={onSetLink} />
      </Popover.Content>
    </Popover.Root>
  )
}
