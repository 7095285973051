import { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon9 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <polygon fill="var(--accent-5)" points="66.667,66.585 83.333,64.501 83.333,53.646 66.667,53.646" />
      <polygon fill="var(--accent-5)" points="83.333,46.979 83.333,35.498 66.667,33.415 66.667,46.979" />
      <polygon fill="var(--accent-5)" points="60,46.979 60,32.582 40,30.082 40,46.979" />
      <polygon fill="var(--accent-5)" points="16.667,72.835 33.333,70.751 33.333,53.646 16.667,53.646" />
      <polygon fill="var(--accent-5)" points="33.333,46.979 33.333,29.248 16.667,27.165 16.667,46.979" />
      <polygon fill="var(--accent-5)" points="40,69.916 60,67.416 60,53.646 40,53.646" />
      <polygon
        fill="var(--accent-5)"
        points="16.667,53.646 16.667,72.835 33.333,70.751 33.333,66.72 20.667,68.304 20.667,53.646"
      />
      <polygon
        fill="var(--accent-5)"
        points="83.333,60.465 83.333,64.501 66.667,66.585 66.667,53.646 70.667,53.646 70.667,62.049"
      />
      <polygon fill="var(--accent-5)" points="40,69.916 40,53.646 44,53.646 44,65.385 60,63.384 60,67.416" />
      <path
        fill="var(--accent-11)"
        d="M86.692,29.199l-73.385-9.173C11.487,19.798,10,21.113,10,22.946v54.108c0,1.833,1.487,3.147,3.308,2.921	l73.386-9.174C88.514,70.571,90,68.887,90,67.054V32.946C90,31.113,88.514,29.427,86.692,29.199z M33.333,70.751l-16.666,2.084	V53.646h16.666V70.751z M33.333,46.979H16.667V27.165l16.666,2.083V46.979z M60,67.416l-20,2.5v-16.27h20V67.416z M60,46.979H40	V30.082l20,2.5V46.979z M83.333,64.501l-16.666,2.084V53.646h16.666V64.501z M83.333,46.979H66.667V33.415l16.666,2.083V46.979z"
      />
      <polygon
        fill="var(--accent-5)"
        points="20.667,42.979 20.667,27.665 16.667,27.165 16.667,46.979 33.333,46.979 33.333,42.979"
      />
      <polygon fill="var(--accent-5)" points="44,42.979 44,30.582 40,30.082 40,46.979 60,46.979 60,42.979" />
      <polygon
        fill="var(--accent-5)"
        points="70.667,42.979 70.667,33.915 66.667,33.415 66.667,46.979 83.333,46.979 83.333,42.979"
      />
    </svg>
  )
}
