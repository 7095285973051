import cx from 'classnames'
import { Card } from './Card'
import { Separator } from './Separator'

export type ListProps<T> = {
  items: T[]
  children?: React.ReactNode | ((item: T) => React.ReactNode)
  divider?: React.ReactNode
} & Omit<React.ComponentProps<typeof Card>, 'children'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const List = <T,>({ items, children, divider = <Separator className="w-full" />, ...props }: ListProps<T>) => {
  return (
    <Card {...props} className={cx('p-0', props.className)}>
      <ul>
        {items.map((item, i) => {
          return (
            <li key={i}>
              {i !== 0 && divider}
              {typeof children === 'function' ? children(item) : children}
            </li>
          )
        })}
      </ul>
    </Card>
  )
}
