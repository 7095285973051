import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { useAsync } from 'react-use'
import { DEFAULT_WORKSPACE_NAME } from '../features/workspaces/types'
import { useCreateWorkspace, useCurrentUser, useFeature, useWorkspaces } from '../hooks'
import { getQueryKey, showErrorToast } from '../services'
import { launchOnboardingSurvey } from '~/features/marketing/components'
import { userPreferencesQueryOptions } from '../queries/user'
import { trackEvent } from './SegmentProvider'

type Props = {
  children?: React.ReactNode
}

export const WorkspaceProvider = ({ children }: Props) => {
  const queryClient = useQueryClient()
  const user = useCurrentUser()
  const { data: workspaces } = useWorkspaces()
  const { mutateAsync: createWorkspace } = useCreateWorkspace()
  const canShowSurvey = useFeature('dashboard-onboarding-experiment')
  const { data: requireOnboarding } = useSuspenseQuery(userPreferencesQueryOptions({ path: 'requireOnboarding' }))

  async function ensureDefaultWorkspace() {
    if (workspaces && workspaces.length === 0) {
      await createWorkspace({ name: DEFAULT_WORKSPACE_NAME })
      await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_') })
    }
  }

  useAsync(async () => {
    if (workspaces) {
      await ensureDefaultWorkspace().catch((e) => {
        showErrorToast(
          "Sorry, we couldn't automatically create a workspace for you. Please try creating your first workspace manually."
        )
        // eslint-disable-next-line no-console
        console.error(e)
      })
    }
  }, [user, workspaces])

  useAsync(async () => {
    if (requireOnboarding && canShowSurvey) {
      trackEvent({ type: 'onboarding_survey_presented' })
      await launchOnboardingSurvey()
    }
  }, [requireOnboarding, canShowSurvey])

  if (!workspaces?.length) {
    return null
  }

  return <>{children}</>
}
