import cx from 'classnames'
import { IoIosHelpCircle } from 'react-icons/io'

import { Tooltip } from './Tooltip'

type Props = JSX.IntrinsicElements['div'] & {
  label?: React.ReactNode
  tooltip?: React.ReactNode
  labelClassName?: string
}

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Field = (props: Props) => (
  <div className={cx('flex flex-col', props.labelClassName)}>
    <div className="flex items-center">
      {props.label && (
        <div className="mr-2">
          <div className="text-sm text-gray-11">{props.label}</div>
        </div>
      )}
      {props.tooltip && (
        <Tooltip placement="top" label={props.tooltip}>
          <div className="flex text-gray-11">
            <IoIosHelpCircle size="20px" />
          </div>
        </Tooltip>
      )}
    </div>
    <div {...props} className={cx('text-sm text-gray-12', props.className)} />
  </div>
)
