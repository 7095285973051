import { Flex } from '@radix-ui/themes'
import { FC, forwardRef } from 'react'
import { ColorAccentProvider } from '../componentsV2'
import { Color } from '../types'
import { tv } from 'tailwind-variants'
import { cn } from '../utils'

type Props = {
  size?: '1' | '2' | '3' | '4' | '5' | '6'
  icon: FC<{ className?: string }>
  variant?: 'soft' | 'outline' | 'surface'
  color?: Color
  className?: string
  muted?: boolean
  padding?: boolean
}

const variants = tv({
  slots: {
    container: '',
    icon: 'size-full',
  },
  variants: {
    variant: {
      soft: {
        container: 'bg-accent-3 rounded',
        icon: 'text-accent-11',
      },
      surface: {
        container: 'bg-accent-3 rounded border border-accent-5',
        icon: 'text-accent-11',
      },
      outline: {
        container: '',
        icon: 'text-accent-11',
      },
    },
    size: {
      '1': undefined,
      '2': undefined,
      '3': undefined,
      '4': undefined,
      '5': undefined,
      '6': undefined,
    },
    muted: {
      true: undefined,
      false: undefined,
    },
    padding: {
      true: undefined,
      false: undefined,
    },
  },
  compoundVariants: [
    { variant: 'soft', size: '1', class: { container: 'size-4 p-px' } },
    { variant: 'soft', size: '2', class: { container: 'size-5 p-0.5' } },
    { variant: 'soft', size: '3', class: { container: 'size-7 p-1' } },
    { variant: 'soft', size: '4', class: { container: 'size-9 p-2' } },
    { variant: 'soft', size: '5', class: { container: 'size-10 p-2' } },
    { variant: 'soft', size: '6', class: { container: 'size-12 p-2' } },

    { variant: 'surface', size: '1', class: { container: 'size-4 p-px' } },
    { variant: 'surface', size: '2', class: { container: 'size-5 p-0.5' } },
    { variant: 'surface', size: '3', class: { container: 'size-7 p-1' } },
    { variant: 'surface', size: '4', class: { container: 'size-9 p-2' } },
    { variant: 'surface', size: '5', class: { container: 'size-10 p-2' } },
    { variant: 'surface', size: '6', class: { container: 'size-12 p-2' } },

    { variant: 'outline', size: '1', class: { container: 'size-3.5' } },
    { variant: 'outline', size: '2', class: { container: 'size-4' } },
    { variant: 'outline', size: '3', class: { container: 'size-5' } },
    { variant: 'outline', size: '4', class: { container: 'size-5' } },
    { variant: 'outline', size: '5', class: { container: 'size-6' } },
    { variant: 'outline', size: '6', class: { container: 'size-8' } },

    { padding: true, size: '1', class: { container: 'size-4 p-px' } },
    { padding: true, size: '2', class: { container: 'size-5 p-0.5' } },
    { padding: true, size: '3', class: { container: 'size-7 p-1' } },
    { padding: true, size: '4', class: { container: 'size-9 p-2' } },
    { padding: true, size: '5', class: { container: 'size-10 p-2' } },
    { padding: true, size: '6', class: { container: 'size-12 p-2' } },

    { muted: true, variant: 'soft', class: { container: 'bg-accent-2', icon: 'text-accent-8' } },
    { muted: true, variant: 'surface', class: { container: 'bg-accent-2 border-accent-6', icon: 'text-accent-8' } },
    { muted: true, variant: 'outline', class: { icon: 'text-accent-8' } },
  ],
  defaultVariants: {
    variant: 'outline',
    size: '2',
    muted: false,
  },
})

export const Icon = forwardRef<HTMLDivElement, Props>(
  ({ icon: InnerIcon, color, className, muted, size, variant, padding, ...props }, ref) => {
    const { container, icon } = variants({ muted, size, variant, padding })

    return (
      <ColorAccentProvider color={color}>
        <Flex {...props} ref={ref} className={cn(container(), className, 'flex-none')}>
          <InnerIcon className={icon()} />
        </Flex>
      </ColorAccentProvider>
    )
  }
)
