import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getGenericClient } from '../../client'
import { WorkspaceObject } from '../../features/workspaces/types'
import { getQueryKey, showErrorToast } from '../../services'
import { handleHttpErrors } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'

type UpdateWorkspaceParams = { onSuccess?: () => void } & Parameters<
  ReturnType<typeof getGenericClient>['updateWorkspace']
>['0']

export function useUpdateWorkspace() {
  const queryClient = useQueryClient()
  const { checkWorkspaceHandle } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ id, ...params }: UpdateWorkspaceParams) => {
      return await getGenericClient()
        .updateWorkspace({ id, ...params })
        .catch(handleHttpErrors)
    },
    onSuccess: async (updatedWorkspace, { onSuccess, handle }) => {
      const workspaceQueryKey = getQueryKey('workspaces_/$workspaceId_', { workspaceId: updatedWorkspace.id })
      queryClient.setQueryData<WorkspaceObject>(workspaceQueryKey, () => {
        return updatedWorkspace
      })

      await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_') })
      if (handle !== undefined) {
        await queryClient.invalidateQueries({ queryKey: checkWorkspaceHandle(handle) })
      }

      onSuccess?.()
    },
    onError: (error: Error) => {
      showErrorToast(error.message)
    },
  })
}
