import cx from 'classnames'
import { Callout as RadixCallout, Flex } from '@radix-ui/themes'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentProps, forwardRef, type FC, type ReactNode } from 'react'
import { HiX } from 'react-icons/hi'
import { cn } from '~/utils'
import { IconButton } from '@botpress/ui-kit'

type ToastProps = {
  content?: React.ReactNode
  isVisible?: boolean
  onDismiss?: () => void
} & ComponentProps<typeof Callout>

type Props = {
  icon?: FC<{ className?: string }>
  children: ReactNode
  className?: string
  color?: 'grass' | 'blue' | 'red' | 'amber' | 'gray'
  calloutAction?: ReactNode
} & Pick<ComponentProps<typeof RadixCallout.Root>, 'size' | 'variant' | 'highContrast' | 'onClick'>

const Callout = forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, children, calloutAction, className, ...props }, ref) => {
    return (
      <RadixCallout.Root {...props} ref={ref} className={cn(className, 'flex items-start')}>
        {Icon && (
          <RadixCallout.Icon>
            <Icon className="size-5 flex-none" />
          </RadixCallout.Icon>
        )}
        <Flex justify={'between'} gap={'3'} className="grow">
          <RadixCallout.Text className="w-full">{children}</RadixCallout.Text>
          {calloutAction}
        </Flex>
      </RadixCallout.Root>
    )
  }
)

const MotionCallout = motion(Callout)

export const Toast = ({ color = 'gray', children, isVisible, onDismiss }: ToastProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <MotionCallout
          key="notification"
          variants={{
            open: {
              opacity: 1,
              translateY: 0,
            },
            closed: {
              opacity: 0,
              translateY: '-50%',
            },
          }}
          initial="closed"
          animate="open"
          exit="closed"
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
          color={color}
          className={cx('z-[999] flex w-full max-w-[32em] items-center bg-accent-3 shadow-lg [word-break:break-word]')}
          calloutAction={<IconButton variant="ghost" icon={HiX} size={'1'} onClick={onDismiss} />}
        >
          {children}
        </MotionCallout>
      )}
    </AnimatePresence>
  )
}
