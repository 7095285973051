import cx from 'classnames'
import { Card } from '../../../elements'

export type CardGrid<T> = {
  items: T[]
  children?: React.ReactNode | ((item: T) => React.ReactNode)
} & Omit<React.ComponentProps<typeof Card>, 'children'>

export const CardGrid = <T,>({ items, children, ...props }: CardGrid<T>) => {
  return (
    <div {...props} className={cx('mt-4 grid grid-cols-3 gap-4', props.className)}>
      {items.map((item) => (typeof children === 'function' ? children(item) : children))}
    </div>
  )
}
