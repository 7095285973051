import * as RadixPopover from '@radix-ui/react-popover'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { PopoverContext, usePopoverContext } from '../contexts'
import { Card } from './Card'
import { Pane } from './Pane'

type PopoverProps = {
  state?: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
} & React.ComponentProps<typeof RadixPopover.Root>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Popover = ({ state, ...props }: PopoverProps) => {
  const [open, setOpen] = state || React.useState(false)

  return (
    <PopoverContext.Provider value={{ state: [open, setOpen] }}>
      <RadixPopover.Root open={open} onOpenChange={setOpen} {...props} />
    </PopoverContext.Provider>
  )
}

Popover.Trigger = (props: React.ComponentProps<typeof RadixPopover.Trigger>) => <RadixPopover.Trigger {...props} />

Popover.Anchor = (props: React.ComponentProps<typeof RadixPopover.Anchor>) => <RadixPopover.Anchor asChild {...props} />

type RenderProps = {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
type ContentProps = {
  children: React.ReactNode | (({ ...props }: RenderProps) => React.ReactNode)
} & Omit<React.ComponentProps<typeof RadixPopover.Content>, 'children'> &
  Omit<React.ComponentProps<typeof Pane.Body>, 'children'>

Popover.Content = ({
  asChild,
  forceMount,
  side = 'bottom',
  sideOffset = 16,
  align,
  alignOffset,
  collisionPadding = 16,
  children,
  className,
  ...props
}: ContentProps) => {
  const contentProps = {
    asChild,
    forceMount,
    side,
    sideOffset,
    align,
    alignOffset,
    collisionPadding,
  }

  const {
    state: [open, setOpen],
  } = usePopoverContext()

  const popoverSide = contentProps.side

  const variantPropsBySide = {
    top: { scaleY: 0.8, scaleX: 0.9, translateY: '10%' },
    bottom: { scaleY: 0.8, scaleX: 0.9, translateY: '-10%' },
    left: { scaleX: 0.8, scaleY: 0.9, translateX: '10%' },
    right: { scaleX: 0.8, scaleY: 0.9, translateX: '-10%' },
  }

  return (
    <AnimatePresence>
      {open && (
        <RadixPopover.Portal forceMount>
          <RadixPopover.Content {...contentProps}>
            <motion.div
              variants={{
                open: { opacity: 1, scaleY: 1, scaleX: 1, translateY: '0', translateX: '0' },
                closed: {
                  opacity: 0,
                  ...variantPropsBySide[popoverSide],
                },
              }}
              initial="closed"
              animate="open"
              exit="closed"
              transition={{ type: 'spring', stiffness: 500, damping: 30 }}
            >
              <Card {...props} className={cx('shadow-lg', className)}>
                {typeof children === 'function' ? children({ open, setOpen }) : children}
              </Card>
            </motion.div>
          </RadixPopover.Content>
        </RadixPopover.Portal>
      )}
    </AnimatePresence>
  )
}

Popover.Close = (props: React.ComponentProps<typeof RadixPopover.Close>) => <RadixPopover.Close asChild {...props} />
