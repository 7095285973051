import { FiExternalLink } from 'react-icons/fi'
import { Link } from '../elements'

type Props = {
  page: string
  children?: React.ReactNode
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const DocumentationLink = ({ page, children }: Props) => (
  <Link
    target="_blank"
    href={`https://botpress.com/docs${page}`}
    onClick={(e) => {
      e.stopPropagation()
    }}
    rel="noreferrer"
    className="flex items-center"
  >
    <p className="mr-2 whitespace-nowrap">{children}</p>
    <FiExternalLink />
  </Link>
)
