import cx from 'classnames'
import { ComponentProps, useEffect, useState } from 'react'

type Props = {
  iconUrl?: string
} & ComponentProps<'img'>

export const IntegrationIcon = ({ iconUrl, className = 'h-6 w-6', ...props }: Props) => {
  const [imgSrc, setImgSrc] = useState<string | undefined>(iconUrl)

  useEffect(() => {
    setImgSrc(iconUrl)
  }, [iconUrl])

  return imgSrc ? (
    <img {...props} className={cx('aspect-square', className)} src={imgSrc} onError={() => setImgSrc('/bp-logo.svg')} />
  ) : (
    <div className={cx('animate-pulse rounded-full bg-gray-4', className)} />
  )
}
