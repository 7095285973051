import classNames from 'classnames'
import { ComponentProps } from 'react'

const icons = {
  search: () => <img className="h-40" src="/emptystate/NoSearchResult.svg" />,
  searchDark: () => <img className="h-40 contrast-75 grayscale invert" src="/emptystate/NoSearchResult.svg" />,
  alert: () => <img className="h-40" src="/emptystate/Alert.svg" />,
  chatbot: () => <img className="h-40" src="/emptystate/NoMessages.svg" />,
  unavailable: () => <img className="h-40" src="/emptystate/Stop.svg" />,
  NoMessages: () => <img className="h-40" src="/emptystate/NoMessages.svg" />,
  error: () => <img className="h-40" src="/emptystate/Error.svg" />,
  box: () => <img className="h-40" src="/emptystate/Box.svg" />,
} as const

type Props = {
  icon: keyof typeof icons
  title: React.ReactNode
  message: React.ReactNode
  action?: React.ReactNode
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const EmptyState = ({
  icon,
  title,
  message,
  action,
  ...otherProps
}: Props & Omit<ComponentProps<'div'>, 'title'>) => (
  <div className={classNames('flex flex-1 flex-col justify-center', otherProps.className)}>
    <div className="m-4 flex flex-col items-center">
      <div className="m-4">{icons[icon]()}</div>
      <h2 className="break-all text-center text-3xl">{title}</h2>
      <div className="mt-2 max-w-lg text-center text-base text-gray-11">{message}</div>
      <div className="mt-6">{action}</div>
    </div>
  </div>
)
