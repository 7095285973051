import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react'
import React, { useCallback, useRef } from 'react'
import { Instance, sticky } from 'tippy.js'
import {
  LuAlignHorizontalDistributeCenter,
  LuAlignHorizontalDistributeEnd,
  LuAlignHorizontalDistributeStart,
} from 'react-icons/lu'

import { ImageBlockWidth } from './ImageBlockWidth'
import { MenuProps } from '../../../components/menus/types'
import { getRenderContainer } from '../../../utils'
import { nanoid } from 'nanoid'
import { Card } from '@radix-ui/themes'
import { ToolbarButton } from '~/componentsV2/RichTextEditor/components/ui/ToolbarButton'

const ImageBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const tippyInstance = useRef<Instance | null>(null)

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, 'node-imageBlock')
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0)

    return rect
  }, [editor])

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive('imageBlock')

    return isActive
  }, [editor])

  const onAlignImageLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('left').run()
  }, [editor])

  const onAlignImageCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('center').run()
  }, [editor])

  const onAlignImageRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign('right').run()
  }, [editor])

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockWidth(value).run()
    },
    [editor]
  )

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${nanoid()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: 'flip', enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance
        },
        appendTo: () => {
          return appendTo?.current
        },
        plugins: [sticky],
        sticky: 'popper',
      }}
    >
      <Card className="bg-gray-1 [&_>div]:flex [&_>div]:p-2">
        <ToolbarButton
          tooltip="Align image left"
          active={editor.isActive('imageBlock', { align: 'left' })}
          onClick={onAlignImageLeft}
        >
          <LuAlignHorizontalDistributeStart />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Align image center"
          active={editor.isActive('imageBlock', { align: 'center' })}
          onClick={onAlignImageCenter}
        >
          <LuAlignHorizontalDistributeCenter />
        </ToolbarButton>
        <ToolbarButton
          tooltip="Align image right"
          active={editor.isActive('imageBlock', { align: 'right' })}
          onClick={onAlignImageRight}
        >
          <LuAlignHorizontalDistributeEnd />
        </ToolbarButton>
        <ImageBlockWidth onChange={onWidthChange} value={parseInt(editor.getAttributes('imageBlock').width)} />
      </Card>
    </BaseBubbleMenu>
  )
}

export { ImageBlockMenu }
