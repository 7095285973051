import { ComponentProps, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { Spinner } from './Spinner'
import classNames from 'classnames'

export type Variant = 'primary' | 'secondary' | 'tertiary'
type Color = 'action' | 'error' | 'accent'
type Size = 'small' | 'medium' | 'large'

type ButtonVariant = `${Variant}-${Color}`

const tertiaryClasses = 'bg-transparent border-0 hover:underline hover:decoration-2 px-0'

const primaryActionClasses = 'border-accent-9 bg-accent-9 text-white hover:bg-accent-10'
const secondaryActionClasses = 'border-accent-7 bg-accent-3 text-accent-11 hover:border-accent-8'
const tertiaryActionClasses = `text-accent-10 hover:text-accent-11 hover:decoration-accent-10 ${tertiaryClasses}`

const primaryErrorClasses = 'border-red-9 bg-red-9 text-white hover:bg-red-10 hover:border-red-10'
const secondaryErrorClasses = 'border-red-7 bg-red-3 text-red-11 hover:border-red-8'
const tertiaryErrorClasses = `text-red-10 hover:text-red-11 hover:decoration-red-10 ${tertiaryClasses}`

const primaryAccentClasses = 'border-accent-9 bg-accent-9 text-white hover:bg-accent-10 hover:border-accent-11'
const secondaryAccentClasses = 'border-accent-7 bg-accent-3 text-accent-11 hover:border-accent-8'
const tertiaryAccentClasses = `text-accent-10 hover:text-accent-11 hover:decoration-accent-10 ${tertiaryClasses}`

const variableClasses: { [key in ButtonVariant]: string } = {
  'primary-action': primaryActionClasses,
  'secondary-action': secondaryActionClasses,
  'tertiary-action': tertiaryActionClasses,
  'primary-error': primaryErrorClasses,
  'secondary-error': secondaryErrorClasses,
  'tertiary-error': tertiaryErrorClasses,
  'primary-accent': primaryAccentClasses,
  'secondary-accent': secondaryAccentClasses,
  'tertiary-accent': tertiaryAccentClasses,
}

const disabledClasses = 'border-gray-2 bg-gray-3 text-gray-10 cursor-not-allowed'

const smallClasses = 'px-2 py-1.5 text-xs'
const mediumClasses = 'px-3 py-2 text-sm'
const largeClasses = 'px-4 py-3 text-base'

const sizeClasses: { [key in Size]: string } = {
  small: smallClasses,
  medium: mediumClasses,
  large: largeClasses,
}

type ButtonProps = {
  variant?: Variant
  color?: Color
  size?: 'small' | 'medium' | 'large'
  leading?: React.ReactNode
  isLoading?: boolean
  trailing?: React.ReactNode
} & ComponentProps<'button'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'primary',
      color = 'action',
      size = 'medium',
      children,
      leading,
      trailing,
      isLoading,
      className,
      ...props
    }: ButtonProps,
    ref
  ) => {
    return (
      <button
        type="button"
        {...props}
        ref={ref}
        className={twMerge(
          classNames(
            'flex cursor-pointer items-center rounded-md border px-3 py-2 text-center',
            sizeClasses[size],
            props.disabled ? disabledClasses : variableClasses[`${variant}-${color}`]
          ),
          className
        )}
        disabled={props.disabled || isLoading}
      >
        {leading && <span className="mr-2">{leading}</span>}
        {isLoading ? <Spinner className="h-4 w-4" /> : children}
        {trailing && <span className={classNames(size === 'small' ? 'ml-1' : 'ml-2')}>{trailing}</span>}
      </button>
    )
  }
)
