import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { Outlet, useParams, ScrollRestoration, createRootRouteWithContext } from '@tanstack/react-router'
import { useAppStore, usePortalStore } from '../stores'
import { workspacesQueryOptions } from '../queries'
import { TopNav } from '~/layoutsV2/TopNav'
import { Box, Container } from '@radix-ui/themes'
import { useCurrentRouteId } from '../hooks/useCurrentRouteId'
import { AppFooter } from '~/layoutsV2/AppFooter'
import { useEffect } from 'react'
import { trackPage } from '../providers'
import { DrawerLayout, SideNav } from '~/layoutsV2'
import { DialogProvider } from '@botpress/ui-kit'

type RouterContext = {
  queryClient: QueryClient
}

export const Route = createRootRouteWithContext<RouterContext>()({
  beforeLoad: async () => {
    const { currentUser: user } = useAppStore.getState()
    if (!user) {
      throw new Error('Unabled to get current user')
    }
    return { user }
  },
  loader: ({ context }) => context.queryClient.ensureQueryData(workspacesQueryOptions()),
  component: RootComponent,
})

function RootComponent() {
  const { data: workspaces } = useSuspenseQuery(workspacesQueryOptions())
  const routeId = useCurrentRouteId()

  const bannerPortalRef = usePortalStore((state) => state.bannerPortalRef)

  const { workspaceId, botId, conversationId } = useParams({ strict: false })

  useEffect(() => {
    if (routeId) {
      trackPage({ name: routeId, workspaceId, botId, conversationId })
    }
  }, [routeId])

  return (
    <>
      <ScrollRestoration />
      <DialogProvider />
      <DrawerLayout navbar={<TopNav workspaces={workspaces} />} sidebar={<SideNav />}>
        <>
          <Container size={'4'}>
            <Box className="has-[:not(.hidden)]:mb-4" ref={bannerPortalRef} />
          </Container>
          <Outlet />
        </>
        {/* TODO: Temporary hack for initial release until we remove dashboard v1 and fix the position on the component */}
        {!(routeId?.includes('issues') || routeId?.includes('logs')) && <AppFooter />}
      </DrawerLayout>
    </>
  )
}
