import { BotSummary } from '~/features/bots/types'
import { PublicIntegration } from '../types'
import { Flex, Text } from '@radix-ui/themes'

type Props = {
  integration: PublicIntegration
  bot: BotSummary
}

export const InstallPublicIntegrationDisclamer = ({ integration, bot }: Props) => (
  <Flex direction={'column'} gap={'2'}>
    <Text size={'2'}>
      Authorize <strong>{integration.title}</strong> by{' '}
      <span className="italic">{integration.ownerWorkspace.handle}</span> ?
    </Text>
    <Text size={'1'} color="gray" className="leading-10">
      By installing <strong>{integration.title}</strong> to <strong>{bot.name}</strong>, you grant the rights to:
      <ul className="ml-2">
        <li>Read information sent to Actions</li>
        <li>Read your integration's configuration</li>
        <li>Read messages sent through the integration’s channels</li>
        <li>Send events to your bot</li>
      </ul>
    </Text>
  </Flex>
)
