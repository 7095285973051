import { defaultWebchatTheme } from '~/features/webchat/configs'
import type { WebchatIntegrationConfig } from '~/features/webchat/schemas'
import type { WebchatStore } from '~/stores'

/**
 * Represents the legacy theme.
 *
 * @deprecated This type is deprecated and will be removed in future versions. Please use the new theme store implementation.
 */
type LegacyTheme = Pick<WebchatIntegrationConfig, 'theme' | 'themeColor' | 'additionalStyles'>

const legacyThemeValues: Record<LegacyTheme['theme'], Omit<WebchatStore['theme'], 'primaryColor'>> = {
  prism: {
    borderRadiusScale: 2,
    fontFamily: 'rubik',
    themeMode: 'light',
    variant: 'solid',
  },
  galaxy: {
    borderRadiusScale: 2,
    fontFamily: 'rubik',
    themeMode: 'dark',
    variant: 'solid',
  },
  eggplant: {
    borderRadiusScale: 1,
    fontFamily: 'ibm',
    themeMode: 'dark',
    variant: 'soft',
  },
  dusk: {
    borderRadiusScale: 1,
    fontFamily: 'ibm',
    themeMode: 'dark',
    variant: 'soft',
  },
  dawn: {
    borderRadiusScale: 4,
    fontFamily: 'inter',
    themeMode: 'light',
    variant: 'solid',
  },
  midnight: {
    borderRadiusScale: 4,
    fontFamily: 'inter',
    themeMode: 'dark',
    variant: 'solid',
  },
} as const

export function migrateConfigs(config: WebchatIntegrationConfig): WebchatIntegrationConfig {
  return {
    ...defaultWebchatTheme,
    ...config,
    primaryColor: config.primaryColor ?? config.themeColor,
    themeMode: config.themeMode ?? legacyThemeValues[config.theme].themeMode,
    variant: config.variant ?? legacyThemeValues[config.theme].variant,
    fontFamily: config.fontFamily ?? legacyThemeValues[config.theme].fontFamily,
    borderRadiusScale: config.borderRadiusScale ?? legacyThemeValues[config.theme].borderRadiusScale,
    botDisplayName: config.botDisplayName ?? config.botName,
    botAvatarUrl: config.botAvatarUrl ?? config.avatarUrl,
    botDescription: config.botDescription ?? config.botConversationDescription,
    descriptionPhoneNumber: config.descriptionPhoneNumber ?? { title: config.phoneNumber, link: config.phoneNumber },
    descriptionEmailAddress: config.descriptionEmailAddress ?? {
      title: config.emailAddress,
      link: config.emailAddress,
    },
    descriptionWebsiteUrl: config.descriptionWebsiteUrl ?? { title: config.website, link: config.website },
    termsConditionsUrl: config.termsConditionsUrl ?? { title: config.termsConditions, link: config.termsConditions },
    privacyPolicyUrl: config.privacyPolicyUrl ?? { title: config.privacyPolicy, link: config.privacyPolicy },
    botComposerPlaceholder: config.botComposerPlaceholder ?? config.composerPlaceholder,
    additionalStylesheet: config.additionalStylesheet ?? config.additionalStyles,
    showPoweredByBotpress: config.showPoweredByBotpress ?? config.showPoweredBy,
  }
}
