import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon10 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-11)"
        d="M33.503,61.783c1.295-1.295,3.418-1.295,4.713,0l0,0c1.296,1.295,1.296,3.418,0,4.713L26.433,78.283	c-1.296,1.297-3.418,1.297-4.714,0l0,0c-1.296-1.295-1.296-3.418,0-4.713L33.503,61.783z"
      />
      <path
        fill="var(--accent-11)"
        d="M73.57,21.714c1.295-1.295,3.418-1.295,4.714,0l0,0c1.295,1.296,1.295,3.418,0,4.715L66.502,38.215	c-1.297,1.295-3.419,1.295-4.715,0l0,0c-1.297-1.296-1.297-3.418,0-4.714L73.57,21.714z"
      />
      <path
        fill="var(--accent-11)"
        d="M61.784,66.496c-1.296-1.295-1.296-3.418,0-4.713l0,0c1.296-1.295,3.417-1.295,4.713,0L78.284,73.57	c1.295,1.295,1.295,3.418,0,4.713l0,0c-1.296,1.297-3.419,1.297-4.714,0L61.784,66.496z"
      />
      <path
        fill="var(--accent-11)"
        d="M21.716,26.429c-1.296-1.297-1.296-3.419,0-4.715l0,0c1.295-1.295,3.418-1.295,4.713,0L38.216,33.5	c1.296,1.295,1.296,3.418,0,4.713l0,0c-1.295,1.295-3.418,1.295-4.713,0L21.716,26.429z"
      />
      <path
        fill="var(--accent-11)"
        d="M35.764,35.952L23.978,24.167c-0.884-0.885-1.15-2.15-0.827-3.278c-0.525,0.15-1.024,0.416-1.435,0.827	c-1.296,1.296-1.296,3.417,0,4.714l11.786,11.785c1.295,1.295,3.417,1.296,4.713,0c0.412-0.411,0.678-0.91,0.828-1.435	C37.914,37.102,36.648,36.836,35.764,35.952z"
      />
      <path
        fill="var(--accent-11)"
        d="M64.236,64.049l11.785,11.785c0.885,0.883,1.15,2.148,0.827,3.277c0.526-0.15,1.024-0.416,1.436-0.826	c1.297-1.297,1.297-3.42,0-4.715L66.499,61.785c-1.295-1.295-3.417-1.295-4.713,0c-0.413,0.412-0.679,0.91-0.828,1.436	C62.087,62.898,63.353,63.164,64.236,64.049z"
      />
      <path
        fill="var(--accent-11)"
        d="M35.952,64.236L24.166,76.021c-0.884,0.885-2.149,1.15-3.278,0.828c0.15,0.523,0.416,1.023,0.828,1.436	c1.296,1.295,3.417,1.295,4.713,0l11.785-11.787c1.295-1.295,1.296-3.416,0-4.713c-0.412-0.412-0.91-0.678-1.435-0.826	C37.102,62.086,36.836,63.352,35.952,64.236z"
      />
      <path
        fill="var(--accent-11)"
        d="M64.049,35.763l11.784-11.785c0.885-0.885,2.15-1.15,3.279-0.827c-0.151-0.525-0.416-1.024-0.828-1.436	c-1.296-1.296-3.417-1.296-4.714,0L61.786,33.5c-1.296,1.295-1.297,3.417,0,4.713c0.411,0.412,0.909,0.678,1.435,0.828	C62.898,37.913,63.164,36.647,64.049,35.763z"
      />
      <path
        fill="var(--accent-11)"
        d="M53.333,30c0,1.833-1.5,3.333-3.333,3.333l0,0c-1.833,0-3.333-1.501-3.333-3.333V13.333	C46.667,11.5,48.167,10,50,10l0,0c1.833,0,3.333,1.5,3.333,3.333V30z"
      />
      <path
        fill="var(--accent-11)"
        d="M49.867,30V13.333c0-1.251,0.706-2.333,1.732-2.903C51.122,10.164,50.582,10,50,10	c-1.833,0-3.333,1.5-3.333,3.333V30c0,1.832,1.5,3.333,3.333,3.333c0.582,0,1.123-0.165,1.6-0.43	C50.573,32.333,49.867,31.25,49.867,30z"
      />
      <path
        fill="var(--accent-11)"
        d="M53.333,86.666c0,1.832-1.5,3.334-3.333,3.334l0,0c-1.833,0-3.333-1.502-3.333-3.334V70	c0-1.834,1.5-3.334,3.333-3.334l0,0c1.833,0,3.333,1.5,3.333,3.334V86.666z"
      />
      <path
        fill="var(--accent-11)"
        d="M30,46.667c1.833,0,3.333,1.5,3.333,3.333l0,0c0,1.833-1.5,3.333-3.333,3.333H13.333	C11.501,53.332,10,51.832,10,50l0,0c0-1.833,1.501-3.333,3.333-3.333H30z"
      />
      <path
        fill="var(--accent-11)"
        d="M86.667,46.667c1.831,0,3.333,1.5,3.333,3.333l0,0c0,1.833-1.502,3.333-3.333,3.333H70	c-1.833,0-3.333-1.5-3.333-3.333l0,0c0-1.833,1.5-3.333,3.333-3.333H86.667z"
      />
      <path
        fill="var(--accent-11)"
        d="M50.133,70v16.668c0,1.25-0.706,2.332-1.733,2.902C48.878,89.836,49.418,90,50,90	c1.833,0,3.333-1.5,3.333-3.332V70c0-1.832-1.5-3.332-3.333-3.332c-0.582,0-1.123,0.164-1.6,0.43	C49.427,67.666,50.133,68.75,50.133,70z"
      />
      <path
        fill="var(--accent-11)"
        d="M30,50.133H13.333c-1.25,0-2.333-0.707-2.903-1.733C10.165,48.877,10,49.418,10,50	c0,1.833,1.5,3.333,3.333,3.333H30c1.832,0,3.333-1.5,3.333-3.333c0-0.583-0.164-1.123-0.43-1.601	C32.333,49.426,31.25,50.133,30,50.133z"
      />
      <path
        fill="var(--accent-11)"
        d="M70,49.866h16.667c1.252,0,2.334,0.708,2.903,1.734C89.836,51.123,90,50.582,90,50	c0-1.834-1.5-3.334-3.333-3.334H70c-1.831,0-3.333,1.5-3.333,3.334c0,0.582,0.164,1.123,0.43,1.599	C67.667,50.574,68.75,49.866,70,49.866z"
      />
    </svg>
  )
}
