import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon5 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M48.333,65.833c0,9.648,7.852,17.5,17.5,17.5s17.5-7.852,17.5-17.5c0-4.382-1.674-8.339-4.343-11.413	c-3.789,2.468-8.3,3.913-13.157,3.913c-4.856,0-9.358-1.464-13.143-3.933C50.013,57.474,48.333,61.445,48.333,65.833z"
      />
      <path
        fill="var(--accent-5)"
        d="M83.333,34.167c0-9.648-7.852-17.5-17.5-17.5c-4.378,0-8.339,1.673-11.409,4.343	c2.464,3.789,3.909,8.301,3.909,13.157c0,4.857-1.461,9.355-3.929,13.138c3.072,2.682,7.041,4.361,11.429,4.361	C75.481,51.666,83.333,43.815,83.333,34.167z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.667,65.833c0,9.648,7.85,17.5,17.5,17.5c4.38,0,8.34-1.674,11.411-4.349	c-2.466-3.783-3.911-8.295-3.911-13.151s1.461-9.354,3.931-13.144c-3.075-2.676-7.043-4.356-11.431-4.356	C24.517,48.333,16.667,56.185,16.667,65.833z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.667,34.167c0,4.375,1.674,8.34,4.342,11.413c3.789-2.468,8.301-3.913,13.158-3.913	s9.358,1.458,13.143,3.933c2.678-3.08,4.357-7.044,4.357-11.433c0-9.648-7.85-17.5-17.5-17.5S16.667,24.518,16.667,34.167z"
      />
      <path
        fill="var(--accent-11)"
        d="M84.06,50C87.75,45.762,90,40.228,90,34.167C90,20.82,79.18,10,65.833,10C59.769,10,54.24,12.246,50,15.938	C45.757,12.246,40.231,10,34.167,10C20.818,10,10,20.82,10,34.167c0,6.062,2.249,11.589,5.938,15.833	C12.249,54.237,10,59.766,10,65.833C10,79.18,20.818,90,34.167,90c6.064,0,11.59-2.253,15.833-5.938	C54.24,87.747,59.769,90,65.833,90C79.18,90,90,79.18,90,65.833c0-6.067-2.25-11.596-5.938-15.833H84.06z M45.578,78.984	c-3.071,2.675-7.031,4.349-11.411,4.349c-9.65,0-17.5-7.852-17.5-17.5s7.85-17.5,17.5-17.5c4.388,0,8.355,1.68,11.431,4.356	c-2.47,3.789-3.931,8.287-3.931,13.144S43.112,75.201,45.578,78.984z M34.167,41.667c-4.857,0-9.369,1.445-13.158,3.913	c-2.668-3.073-4.342-7.038-4.342-11.413c0-9.648,7.85-17.5,17.5-17.5s17.5,7.852,17.5,17.5c0,4.388-1.68,8.353-4.357,11.433	C43.525,43.125,39.024,41.667,34.167,41.667z M65.833,83.333c-9.648,0-17.5-7.852-17.5-17.5c0-4.388,1.68-8.359,4.357-11.433	c3.784,2.469,8.286,3.933,13.143,3.933c4.857,0,9.368-1.445,13.157-3.913c2.669,3.074,4.343,7.031,4.343,11.413	C83.333,75.481,75.481,83.333,65.833,83.333z M65.833,51.666c-4.388,0-8.356-1.68-11.429-4.361	c2.468-3.783,3.929-8.281,3.929-13.138c0-4.856-1.445-9.368-3.909-13.157c3.07-2.669,7.031-4.343,11.409-4.343	c9.648,0,17.5,7.852,17.5,17.5S75.481,51.666,65.833,51.666z"
      />
      <path
        fill="var(--accent-11)"
        d="M15.919,50c1.462-1.685,3.152-3.165,5.024-4.391c-0.87-1.004-1.614-2.117-2.242-3.3	c-1.901,1.255-3.644,2.733-5.187,4.398C14.225,47.872,15.028,48.974,15.919,50z"
      />
      <path
        fill="var(--accent-11)"
        d="M84.081,50c-1.462,1.685-3.153,3.164-5.024,4.391c0.87,1.005,1.615,2.115,2.242,3.3	c1.901-1.255,3.644-2.732,5.188-4.398C85.775,52.127,84.972,51.025,84.081,50z"
      />
      <path
        fill="var(--accent-11)"
        d="M50,15.918c1.685,1.462,3.164,3.153,4.391,5.025c1.005-0.87,2.117-1.615,3.3-2.242	c-1.255-1.902-2.732-3.644-4.396-5.187C52.128,14.224,51.025,15.027,50,15.918z"
      />
      <path
        fill="var(--accent-11)"
        d="M50,84.081c-1.684-1.462-3.163-3.153-4.39-5.024c-1.005,0.87-2.117,1.615-3.301,2.242	c1.254,1.901,2.732,3.644,4.398,5.188C47.873,85.775,48.975,84.972,50,84.081z"
      />
      <path
        fill="var(--accent-5)"
        d="M34.167,37.667c-4.41,0-8.58,1.029-12.3,2.844c-0.769-1.968-1.201-4.104-1.201-6.344	c0-8.988,6.776-16.389,15.498-17.382c-0.655-0.075-1.321-0.118-1.997-0.118c-9.648,0-17.5,7.852-17.5,17.5	c0,4.372,1.615,8.372,4.276,11.442c3.8-2.49,8.342-3.942,13.224-3.942s9.423,1.453,13.223,3.942	c0.872-1.006,1.618-2.121,2.246-3.307C45.063,39.294,39.69,37.667,34.167,37.667z"
      />
      <path
        fill="var(--accent-5)"
        d="M62.333,34.167c0-4.41-1.028-8.581-2.844-12.3c1.967-0.768,4.103-1.201,6.344-1.201	c8.989,0,16.389,6.777,17.383,15.498c0.075-0.655,0.117-1.321,0.117-1.997c0-9.648-7.852-17.5-17.5-17.5	c-4.372,0-8.37,1.616-11.442,4.276c2.49,3.8,3.942,8.341,3.942,13.224c0,4.882-1.452,9.422-3.942,13.223	c1.006,0.872,2.12,1.619,3.308,2.246C60.706,45.063,62.333,39.69,62.333,34.167z"
      />
      <path
        fill="var(--accent-5)"
        d="M65.833,62.333c4.411,0,8.581-1.03,12.3-2.846c0.769,1.969,1.201,4.104,1.201,6.346	c0,8.989-6.776,16.389-15.498,17.383c0.654,0.075,1.32,0.117,1.997,0.117c9.648,0,17.5-7.852,17.5-17.5	c0-4.372-1.616-8.372-4.276-11.442c-3.801,2.489-8.341,3.942-13.224,3.942s-9.424-1.453-13.224-3.942	c-0.872,1.006-1.619,2.12-2.245,3.307C54.936,60.705,60.31,62.333,65.833,62.333z"
      />
      <path
        fill="var(--accent-5)"
        d="M37.667,65.834c0,4.41,1.029,8.58,2.846,12.299c-1.969,0.769-4.105,1.201-6.346,1.201	c-8.989,0-16.389-6.776-17.383-15.496c-0.075,0.654-0.117,1.32-0.117,1.996c0,9.647,7.852,17.499,17.5,17.499	c4.372,0,8.372-1.616,11.442-4.276c-2.489-3.801-3.942-8.341-3.942-13.223c0-4.883,1.453-9.423,3.942-13.223	c-1.007-0.872-2.12-1.619-3.307-2.246C39.295,54.938,37.667,60.311,37.667,65.834z"
      />
    </svg>
  )
}
