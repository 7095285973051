// this file was automatically generated, do not edit
/* eslint-disable */

export interface CreateFileRequestHeaders {
  "x-user-key": string;
}

export interface CreateFileRequestQuery {}

export interface CreateFileRequestParams {}

export interface CreateFileRequestBody {
  /**
   * Unique key for the file. Must be unique across the bot (and the integration, when applicable).
   */
  key: string;
  /**
   * File size in bytes. This will count against your File Storage quota. If the index parameter is set to true, this will also count against your Vector DB Storage quota.
   */
  size: number;
  /**
   * File access policies. Add "public_content" to allow public access to the file content. Add "integrations" to allow read, search and list operations for any integration installed in the bot.
   */
  accessPolicies?: ("integrations" | "public_content")[];
  /**
   * Whether the file should be indexed. If true, the file content will be searchable in the Vector DB. This will count against your Vector DB Storage quota.
   */
  index?: boolean;
  /**
   * File content type. If omitted, the content type will be inferred from the file extension (if any) specified in key. If a content type cannot be inferred, the default is "application/octet-stream".
   */
  contentType?: string;
  /**
   * File tags. These are used for search and filtering operations.
   */
  tags?: {
    [k: string]: string;
  };
}

export type CreateFileInput = CreateFileRequestBody & CreateFileRequestHeaders & CreateFileRequestQuery & CreateFileRequestParams

export type CreateFileRequest = {
  headers: CreateFileRequestHeaders;
  query: CreateFileRequestQuery;
  params: CreateFileRequestParams;
  body: CreateFileRequestBody;
}

export const parseReq = (input: CreateFileInput): CreateFileRequest & { path: string } => {
  return {
    path: `/files`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: {  },
    body: { 'key': input['key'], 'size': input['size'], 'accessPolicies': input['accessPolicies'], 'index': input['index'], 'contentType': input['contentType'], 'tags': input['tags'] },
  }
}

export interface CreateFileResponse {
  file: {
    id: string;
    botId: string;
    key: string;
    size: number | null;
    contentType: string;
    tags: {
      [k: string]: string;
    };
    createdAt: string;
    updatedAt: string;
    accessPolicies: ("integrations" | "public_content")[];
    index: boolean;
    url: string;
    status:
      | "upload_pending"
      | "upload_failed"
      | "upload_completed"
      | "indexing_pending"
      | "indexing_failed"
      | "indexing_completed";
    failedStatusReason?: string;
    uploadUrl: string;
  };
}

