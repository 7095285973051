import { forwardRef } from 'react'
import { container } from './card.module.css'
import { clsx } from 'clsx'

export type CardProps = JSX.IntrinsicElements['div']

export const Card = forwardRef<HTMLDivElement, CardProps>(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={clsx(className, container)} />
))

Card.displayName = 'Card'
