import { FlagProvider } from '@unleash/proxy-client-react'
import { useCurrentUser } from '../hooks'
import { config } from '../shared'

type Props = {
  children?: React.ReactNode
}

export const UnleashProvider = ({ children }: Props) => {
  const { unleashProxyAppName: appName, unleashProxyClientKey: clientKey, unleashProxyUrl: url } = config
  const user = useCurrentUser()

  return (
    <FlagProvider
      config={{
        appName,
        clientKey,
        url,
        disableMetrics: true,
        context: { userId: user.email },
      }}
    >
      {children}
    </FlagProvider>
  )
}
