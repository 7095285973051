import { useMutation } from '@tanstack/react-query'
import { getGenericClient } from '~/client'
import { useAppStore } from '~/stores'

type UpdateUserProfileParams = {
  onSuccess?: () => void
  onError?: () => void
}

const useUpdateUserProfile = ({ onSuccess, onError }: UpdateUserProfileParams) => {
  const setCurrentUser = useAppStore((s) => s.setCurrentUser)

  return useMutation({
    mutationFn: getGenericClient().updateAccount,
    onSuccess: async ({ account: updatedUser }) => {
      setCurrentUser(updatedUser)
      onSuccess?.()
    },
    onError: (error) => {
      onError?.()
      console.error('Error updating profile', error)
    },
  })
}

export { useUpdateUserProfile }
