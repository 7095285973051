import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { useCallback } from 'react'
import { HiChevronDown } from 'react-icons/hi2'
import { DropdownButton } from '../../../ui/Dropdown'
import { Card } from '@radix-ui/themes'
import { ToolbarButton } from '../../../ui/ToolbarButton'

const FONT_FAMILIES = [
  { label: 'Inter', value: '' },
  { label: 'Arial', value: 'Arial' },
  { label: 'Helvetica', value: 'Helvetica' },
  { label: 'Times New Roman', value: 'Times' },
  { label: 'Garamond', value: 'Garamond' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Courier', value: 'Courier' },
  { label: 'Courier New', value: 'Courier New' },
]

export type FontFamilyPickerProps = {
  onChange: (value: string) => void
  value: string
}

// TODO change to use our Dropdown
export const FontFamilyPicker = ({ onChange, value }: FontFamilyPickerProps) => {
  const currentValue = FONT_FAMILIES.find((size) => size.value === value)
  const currentFontLabel = currentValue?.label.split(' ')[0] || 'Inter'

  const selectFont = useCallback((font: string) => () => onChange(font), [onChange])

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <ToolbarButton active={!!currentValue?.value}>
          {currentFontLabel}
          <HiChevronDown className="h-2 w-2" />
        </ToolbarButton>
      </Dropdown.Trigger>
      <Dropdown.Content asChild>
        <Card className="flex flex-col gap-1 bg-gray-1">
          {FONT_FAMILIES.map((font) => (
            <DropdownButton
              isActive={value === font.value}
              onClick={selectFont(font.value)}
              key={`${font.label}_${font.value}`}
            >
              <span style={{ fontFamily: font.value }}>{font.label}</span>
            </DropdownButton>
          ))}
        </Card>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
