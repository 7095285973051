/**
 * Computes the bot history based on the given bot IDs.
 * @param botIds - An array of bot IDs.
 * @param count - The number of top bots to return. Default is 4.
 * @param lambda - The lambda value used in the score calculation, the higher the lambda the less weight is given to older values. Default is 0.1.
 * @returns An array of the top bot IDs based on their scores.
 */
export function computeBotHistory(botIds: string[], count = 3, lambda = 0.1) {
  const scoreMap = botIds.reduce((map, botId, index) => {
    const weight = Math.exp(-lambda * index)
    const currentScore = map.get(botId) || 0
    map.set(botId, currentScore + weight)
    return map
  }, new Map<string, number>())

  const sortedScores = Array.from(scoreMap).sort((a, b) => b[1] - a[1])
  const topBots = sortedScores.slice(0, count).map(([botId]) => botId)

  return topBots
}
