import { isApiError, ClientReturn } from 'botpress-client'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useMount } from 'react-use'
import { getGenericClient } from '../../../client'
import { Button, Copy, Input, List } from '../../../elements'
import { showErrorToast } from '../../../services'
import { friendlyFormatISODate } from '../../../utils'
import { NewPersonalAccessToken, PersonalAccessToken } from '../../users/types'
import { Callout } from '../../../elementsv2'
import { showConfirmationPrompt } from '@botpress/ui-kit'

type ListPersonalAccessTokensResponsePatsInner = ClientReturn<'listPersonalAccessTokens'>['pats'][number]

const client = getGenericClient()

type Props = {
  params: Record<string, string>
}

export function ManagePersonalAccessTokens({ params }: Props) {
  const [pats, setPats] = useState<PersonalAccessToken[]>([])
  const [creating, setCreating] = useState(false)
  const [defaultNote, setDefaultNote] = useState('')
  const [submitting, setSubmitting] = useState(false)

  useMount(() => {
    const action = params.action
    const note = params.note

    if (action === 'create-pat' && note) {
      setCreating(true)
      setDefaultNote(note)
    } else if (action === 'authorize') {
      authorize().catch(handleError)
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function sortPats(pats: ListPersonalAccessTokensResponsePatsInner[]) {
    return pats.sort((a, b) => -a.createdAt.localeCompare(b.createdAt))
  }

  const fetchPats = async () => {
    const patsResponse = await client.listPersonalAccessTokens({}).catch(handleError)
    if (patsResponse) {
      setPats(sortPats(patsResponse.pats))
    }
  }

  const handleError = (error: any) => {
    if (isApiError(error)) {
      showErrorToast(error.message)
    } else {
      showErrorToast('Sorry, an error occurred, please try again in a few moments.')
    }
  }

  async function authorize() {
    const note = params.note
    const redirectTo = params.redirectTo

    void showConfirmationPrompt(`"${note}" would like to access your account, allow?`, {
      title: 'Authorize Personal Access Token',
    }).then(
      () =>
        void createPat(`${note}_${Date.now()}`).then((newPat) => {
          if (newPat && redirectTo) {
            window.location.assign(`${redirectTo}?pat=${newPat.value}`)
          }
        })
    )
  }

  const createPat = async (note: string) => {
    if (submitting) {
      return
    }

    setSubmitting(true)

    const newPatResponse = await client
      .createPersonalAccessToken({ note })
      .catch(handleError)
      .finally(() => {
        setSubmitting(false)
      })

    if (!newPatResponse) {
      return undefined
    }

    const newPat = newPatResponse.pat
    setPats(sortPats([...pats.map(removeValueFromPat), newPat]))

    setCreating(false)

    return newPat
  }

  const deletePat = async (pat: PersonalAccessToken) => {
    await showConfirmationPrompt('Are you sure you want to permanently delete this Personal Access Token?', {
      title: 'Delete Personal Access Token',
    })
    void client
      .deletePersonalAccessToken(pat)
      .catch(handleError)
      .then(() => {
        setPats(sortPats([...pats.filter((p) => p.id !== pat.id)]))
      })
  }

  useEffect(() => {
    void fetchPats()
  }, [])

  const firstPat = pats.length > 0 ? pats[0] : undefined
  const firstPatIsNew = firstPat !== undefined && isNewPersonalAccessToken(firstPat)
  const clickedGenerateNewToken = () => {
    setCreating(true)
  }

  return creating ? (
    <GenerateTokenForm
      note={defaultNote}
      submitting={submitting}
      onSubmit={(note) => {
        void createPat(note)
      }}
      onCancel={() => {
        setCreating(false)
        // ensures no new pats remain when clicking cancel
        setPats(sortPats(pats.map(removeValueFromPat)))
      }}
    />
  ) : (
    <>
      <div className="pb-4">
        <h2 className="text-2xl" id="pats">
          Personal Access Tokens
        </h2>

        <div className="mb-4 mt-4">
          <p>Personal Access Tokens can be used in the Studio to publish chatbots to Botpress Cloud.</p>
        </div>
        <Button variant="primary" color="action" onClick={clickedGenerateNewToken}>
          Generate new token
        </Button>
      </div>
      {firstPatIsNew && (
        <Callout color="amber" className="my-6 ">
          Make sure to copy your personal access token now. You won't be able to see it again!
        </Callout>
      )}
      <List items={pats} className="w-full">
        {(pat) => <PatRow key={pat.id} pat={pat} onDelete={() => void deletePat(pat)} />}
      </List>
    </>
  )
}

function isNewPersonalAccessToken(pat: PersonalAccessToken & { value?: string }): pat is NewPersonalAccessToken {
  return pat.value !== undefined
}

function PatRow(props: { pat: PersonalAccessToken | NewPersonalAccessToken; onDelete: () => void }): JSX.Element {
  const { pat } = props

  const newPat = isNewPersonalAccessToken(pat)
  return (
    <div className={cx('flex flex-wrap items-center justify-between p-4', { 'bg-grass-2': newPat })}>
      <div className="mr-3">
        <div className="mb-2">
          <b>{pat.note}</b>
        </div>
        <div className="text-sm capitalize text-gray-11">Created {friendlyFormatISODate(pat.createdAt)}</div>
      </div>
      {newPat && (
        <div className="mr-3">
          <Copy className="max-w-[500px]" value={pat.value} description="personal access token" />
        </div>
      )}
      <Button variant="secondary" color="error" onClick={() => props.onDelete()}>
        Delete
      </Button>
    </div>
  )
}

function GenerateTokenForm(props: {
  note?: string
  submitting: boolean
  onSubmit: (note: string) => void
  onCancel: () => void
}): JSX.Element {
  const { submitting, onSubmit, onCancel } = props
  const [note, setNote] = useState(props.note ?? '')
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef])

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        onSubmit(note)
        setNote('') // Clear the input field
      }}
      className="w-full"
    >
      <h2 className="text-lg">New Personal Access Token</h2>
      <div className="mb-6">
        <Input label="Note" value={note} ref={inputRef} onChange={(e) => void setNote(e.target.value)} />
        <div className="text-sm">What's this token for?</div>
      </div>
      <div className="flex">
        <Button variant="tertiary" color="action" className="mr-2" disabled={submitting} onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" color="action" disabled={submitting || note.length === 0}>
          Generate Token
        </Button>
      </div>
    </form>
  )
}

function removeValueFromPat(
  pat: PersonalAccessToken & { value?: NewPersonalAccessToken['value'] }
): PersonalAccessToken {
  const copy = { ...pat }
  delete copy['value']
  return copy
}
