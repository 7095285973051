import type { ComponentProps, ReactNode } from 'react'
import { Flex, TextArea as RadixTextArea, Text } from '@radix-ui/themes'

type TextAreaProps = {
  label?: ReactNode
} & Pick<
  ComponentProps<typeof RadixTextArea>,
  'size' | 'variant' | 'color' | 'radius' | 'value' | 'onChange' | 'className' | 'children' | 'placeholder' | 'rows'
>

export const TextArea = ({ label, className, ...props }: TextAreaProps) => {
  return (
    <Flex direction={'column'} gap={'1'} className={className}>
      {label && (
        <Text size={'2'} color="gray">
          {label}
        </Text>
      )}
      <RadixTextArea {...props} />
    </Flex>
  )
}
