import { Flex, ContextMenu as RadixContextMenu } from '@radix-ui/themes'
import { ComponentProps } from 'react'
import { MenuItem } from './types'
import { cn } from '../utils'

type ContextMenuProps = Pick<ComponentProps<typeof RadixContextMenu.Root>, 'onOpenChange' | 'dir' | 'modal'> &
  Pick<ComponentProps<typeof RadixContextMenu.Trigger>, 'disabled' | 'children'> &
  Omit<ComponentProps<typeof RadixContextMenu.Content>, 'content'> & {
    content: MenuItem[]
  }

export const ContextMenu = ({
  children,
  disabled,
  onOpenChange,
  dir,
  modal,
  content,
  variant = 'soft',
  size,
  ...props
}: ContextMenuProps) => {
  return (
    <RadixContextMenu.Root {...{ onOpenChange, dir, modal }}>
      <RadixContextMenu.Trigger {...{ children }} disabled={disabled || !content.filter((t) => !t.hidden).length} />
      <RadixContextMenu.Content {...props} size={size} variant={variant}>
        <ContextMenuContent content={content} size={size} />
      </RadixContextMenu.Content>
    </RadixContextMenu.Root>
  )
}

const ContextMenuContent = ({ content, size }: { content: MenuItem[]; size: ContextMenuProps['size'] }) => {
  const baseClassName = cn({ 'gap-2 px-3': size === '2', 'gap-1.5 px-2': size === '1' })

  return (
    <>
      {content.map((item, i) => {
        if (item.hidden) {
          return null
        }
        switch (item.type) {
          case 'item': {
            const { content: itemContent, leadingIcon, trailingIcon, ...itemProps } = item
            return (
              <RadixContextMenu.Item className={baseClassName} key={i} {...itemProps}>
                {leadingIcon}
                <span className="grow">{itemContent}</span>
                {trailingIcon}
              </RadixContextMenu.Item>
            )
          }
          case 'separator':
            return (
              <RadixContextMenu.Separator className={cn({ ' mx-3': size === '2', ' mx-2': size === '1' })} key={i} />
            )
          case 'label':
            return (
              <RadixContextMenu.Label className={baseClassName} key={i}>
                {item.label}
              </RadixContextMenu.Label>
            )
          case 'radioGroup':
            return (
              <RadixContextMenu.RadioGroup key={i} value={item.value}>
                {item.items.map((radioItem, j) => {
                  const { content: itemContent, leadingIcon, trailingIcon, ...itemProps } = radioItem
                  return (
                    <RadixContextMenu.RadioItem className={cn(baseClassName, '*:relative')} key={j} {...itemProps}>
                      {leadingIcon}
                      <span className="grow">{itemContent}</span>
                      {trailingIcon}
                    </RadixContextMenu.RadioItem>
                  )
                })}
              </RadixContextMenu.RadioGroup>
            )
          case 'submenu':
            return (
              <RadixContextMenu.Sub key={i}>
                {/* TODO disabing does no work on the color of the trailing chevron */}
                <RadixContextMenu.SubTrigger data-accent-color={item.color} disabled={item.disabled}>
                  <Flex gap="2" align={'center'}>
                    {item.leadingIcon}
                    <span>{item.content}</span>
                  </Flex>
                </RadixContextMenu.SubTrigger>
                <RadixContextMenu.SubContent>
                  <ContextMenuContent content={item.items} size={size} />
                </RadixContextMenu.SubContent>
              </RadixContextMenu.Sub>
            )
          case 'checkBoxItem': {
            const { content: itemContent, leadingIcon, trailingIcon, showCheckmark, ...itemProps } = item
            return (
              <RadixContextMenu.CheckboxItem
                className={cn(
                  baseClassName,
                  { '[&>.rt-DropdownMenuItemIndicator]:hidden': !showCheckmark && showCheckmark !== undefined },
                  '*:relative'
                )}
                key={i}
                {...itemProps}
              >
                {leadingIcon}
                <span className="grow">{itemContent}</span>
                {trailingIcon}
              </RadixContextMenu.CheckboxItem>
            )
          }
          default:
            return <></>
        }
      })}
    </>
  )
}
