import EventSource from 'event-source-polyfill'
import { EventEmitter } from './event-emitter'

type OnOpen = NonNullable<EventSource.EventSourcePolyfill['onopen']>
type OnMessage = NonNullable<EventSource.EventSourcePolyfill['onmessage']>
type OnError = NonNullable<EventSource.EventSourcePolyfill['onerror']>

export type OpenEvent = Parameters<OnOpen>[0]
export type MessageEvent = Parameters<OnMessage>[0]
export type ErrorEvent = Parameters<OnError>[0]

export type Events = {
  open: OpenEvent
  message: MessageEvent
  error: ErrorEvent
}

export type Props = {
  headers?: Record<string, string>
}

const makeEventSource = (url: string, props: Props = {}) => {
  const source = new EventSource.EventSourcePolyfill(url, { headers: props.headers })
  const emitter = new EventEmitter<Events>()
  source.onopen = (ev) => emitter.emit('open', ev)
  source.onmessage = (ev) => emitter.emit('message', ev)
  source.onerror = (ev) => emitter.emit('error', ev)
  return {
    emitter,
    source,
  }
}

export type EvSource = {
  on: EventEmitter<Events>['on']
  close: () => void
}

export const listenEventSource = async (url: string, props: Props = {}): Promise<EvSource> => {
  const { emitter, source } = makeEventSource(url, props)

  await new Promise<void>((resolve, reject) => {
    emitter.on('open', () => {
      resolve()
    })
    emitter.on('error', (thrown) => {
      reject(thrown)
    })
  }).finally(() => emitter.cleanup())

  return {
    on: emitter.on.bind(emitter),
    close: () => {
      emitter.cleanup()
      source.close()
    },
  }
}
