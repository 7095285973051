import { Extension } from '@tiptap/core'
import { Plugin, PluginKey } from '@tiptap/pm/state'

export const NoNewLine = Extension.create({
  name: 'no_new_line',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('eventHandler'),
        props: {
          handleKeyDown: (_, event) => {
            if (event.key === 'Enter' && event.metaKey) {
              return true
            }
          },
        },
      }),
    ]
  },
})
