const animals = [
  'aardvark',
  'albatross',
  'alligator',
  'alpaca',
  'ant',
  'anteater',
  'antelope',
  'ape',
  'armadillo',
  'donkey',
  'baboon',
  'badger',
  'barracuda',
  'bat',
  'bear',
  'beaver',
  'bee',
  'bison',
  'boar',
  'buffalo',
  'butterfly',
  'camel',
  'capybara',
  'caribou',
  'cassowary',
  'cat',
  'caterpillar',
  'cattle',
  'chamois',
  'cheetah',
  'chicken',
  'chimpanzee',
  'chinchilla',
  'chough',
  'clam',
  'cod',
  'cormorant',
  'coyote',
  'crab',
  'crane',
  'crocodile',
  'crow',
  'curlew',
  'deer',
  'dinosaur',
  'dog',
  'dogfish',
  'dolphin',
  'dotterel',
  'dove',
  'dragonfly',
  'duck',
  'dugong',
  'dunlin',
  'eagle',
  'echidna',
  'eel',
  'eland',
  'elephant',
  'elk',
  'emu',
  'falcon',
  'ferret',
  'finch',
  'fish',
  'flamingo',
  'fly',
  'fox',
  'frog',
  'gaur',
  'gazelle',
  'gerbil',
  'giraffe',
  'gnat',
  'gnu',
  'goat',
  'goldfinch',
  'goldfish',
  'goose',
  'gorilla',
  'goshawk',
  'grasshopper',
  'grouse',
  'guanaco',
  'gull',
  'hamster',
  'hare',
  'hawk',
  'hedgehog',
  'heron',
  'herring',
  'hippopotamus',
  'hornet',
  'horse',
  'hummingbird',
  'hyena',
  'ibex',
  'ibis',
  'jackal',
  'jaguar',
  'jay',
  'jellyfish',
  'kangaroo',
  'kingfisher',
  'koala',
  'kookabura',
  'kouprey',
  'kudu',
  'lapwing',
  'lark',
  'lemur',
  'leopard',
  'lion',
  'llama',
  'lobster',
  'locust',
  'loris',
  'louse',
  'lyrebird',
  'magpie',
  'mallard',
  'manatee',
  'mandrill',
  'mantis',
  'marten',
  'meerkat',
  'mink',
  'mole',
  'mongoose',
  'monkey',
  'moose',
  'mosquito',
  'mouse',
  'mule',
  'narwhal',
  'newt',
  'nightingale',
  'octopus',
  'okapi',
  'opossum',
  'oryx',
  'ostrich',
  'otter',
  'owl',
  'oyster',
  'panther',
  'parrot',
  'partridge',
  'peafowl',
  'pelican',
  'penguin',
  'pheasant',
  'pig',
  'pigeon',
  'pony',
  'porcupine',
  'porpoise',
  'quail',
  'quelea',
  'quetzal',
  'rabbit',
  'raccoon',
  'ram',
  'raven',
  'reindeer',
  'rhinoceros',
  'salmon',
  'sandpiper',
  'sardine',
  'seahorse',
  'seal',
  'shark',
  'sheep',
  'snail',
  'sparrow',
  'spider',
  'spoonbill',
  'squid',
  'squirrel',
  'starling',
  'stingray',
  'stork',
  'swan',
  'tapir',
  'tarsier',
  'tiger',
  'toad',
  'trout',
  'turkey',
  'turtle',
  'wallaby',
  'walrus',
  'wasp',
  'weasel',
  'whale',
  'wildcat',
  'wolf',
  'wolverine',
  'woodpecker',
  'wren',
  'yak',
  'zebra',
]

const adjectives = [
  'adorable',
  'agreeable',
  'amazing',
  'ambitious',
  'amused',
  'ancient',
  'attractive',
  'aware',
  'awkward',
  'beautiful',
  'blushing',
  'brave',
  'bright',
  'brilliant',
  'calm',
  'careful',
  'cautious',
  'charming',
  'cheerful',
  'chubby',
  'clumsy',
  'comfortable',
  'competent',
  'concerned',
  'confident',
  'confused',
  'convinced',
  'cool',
  'courageous',
  'cuddly',
  'curious',
  'cute',
  'decisive',
  'delicate',
  'delighted',
  'delightful',
  'determined',
  'devoted',
  'effective',
  'elderly',
  'electrical',
  'elegant',
  'embarrassed',
  'emotional',
  'enchanting',
  'energetic',
  'enthusiastic',
  'excellent',
  'exceptional',
  'excited',
  'exotic',
  'extraordinary',
  'famous',
  'fancy',
  'fantastic',
  'fascinating',
  'fierce',
  'fluffy',
  'foolish',
  'formidable',
  'fortunate',
  'friendly',
  'fun',
  'funny',
  'generous',
  'gentle',
  'glorious',
  'gorgeous',
  'graceful',
  'handsome',
  'happy',
  'healthy',
  'helpful',
  'hilarious',
  'honest',
  'imaginative',
  'impressed',
  'impressive',
  'incredible',
  'innovative',
  'intense',
  'interested',
  'interesting',
  'jolly',
  'lively',
  'lovely',
  'lucky',
  'magnetic',
  'magnificent',
  'marvellous',
  'mighty',
  'miniature',
  'modern',
  'modest',
  'mysterious',
  'neat',
  'nervous',
  'nice',
  'noble',
  'optimistic',
  'outstanding',
  'overwhelming',
  'peculiar',
  'perfect',
  'polite',
  'popular',
  'powerful',
  'precious',
  'professional',
  'profound',
  'proud',
  'puzzled',
  'quick',
  'quiet',
  'rational',
  'reasonable',
  'reliable',
  'remarkable',
  'respectable',
  'responsible',
  'romantic',
  'satisfied',
  'sensible',
  'sensitive',
  'serious',
  'shiny',
  'shy',
  'skilled',
  'smart',
  'smiling',
  'sophisticated',
  'sparkling',
  'spontaneous',
  'strange',
  'successful',
  'super',
  'superb',
  'surprised',
  'suspicious',
  'sweet',
  'swift',
  'tiny',
  'ultimate',
  'victorious',
  'wise',
  'witty',
  'wonderful',
  'young',
  'zesty',
]

function getRandomAnimal(): string {
  return animals[Math.floor(Math.random() * animals.length)]!
}

function getRandomAdjective(): string {
  return adjectives[Math.floor(Math.random() * adjectives.length)]!
}

export function generateRandomName() {
  const animal = getRandomAnimal()
  const adjective = getRandomAdjective()
  return `${adjective}-${animal}`
}
