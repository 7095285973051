import { ClientReturn } from 'botpress-client'

// TODO: Get these types from the @botpress/cdm-backend package or move them to @botpress/common
export type Role = 'viewer' | 'billing' | 'developer' | 'manager' | 'administrator' | 'owner'

export const RoleNames: { [key in Role]: string } = {
  viewer: 'Viewer',
  billing: 'Billing Manager',
  developer: 'Developer',
  manager: 'Manager',
  administrator: 'Administrator',
  owner: 'Owner',
}

export type Permission =
  | 'bots.view'
  | 'bots.create'
  | 'bots.update'
  | 'bots.delete'
  | 'bots.transfer'
  | 'bots.logs'
  | 'workspace.view'
  | 'workspace.update'
  | 'workspace.delete'
  | 'workspace.manageBilling'
  | 'workspace.manageMembers'
  | 'workspace.audit'
  | 'misunderstood'
  | 'integrations.view'
  | 'integrations.create'
  | 'integrations.update'
  | 'integrations.delete'
  | 'studio.view'
  | 'studio.edit'

export function isManagementRole(role: Role): boolean {
  return role === 'manager' || role === 'administrator' || role === 'owner'
}

export type User = ClientReturn<'getAccount'>['account']

export type WorkspaceMember = {
  id: string
  workspaceId: string
  userId?: string
  email: string
  createdOn: string
  /**
   * Use `permissions` instead of `role` for access control where possible.
   */
  role: Role
  permissions: Permission[]
}

export type PersonalAccessToken = {
  id: string
  note: string
  createdAt: string
}

export type NewPersonalAccessToken = {
  value: string
} & PersonalAccessToken
