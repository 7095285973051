import { motion } from 'framer-motion'
import { ComponentProps, useEffect, useRef, useState } from 'react'

const svgPathProps: ComponentProps<typeof motion.path> = {
  clipRule: 'evenodd',
  d: 'M99.7798 50.3759ZM73.1189 63.3525C73.1727 62.3294 72.5803 61.3603 71.7185 60.8756L64.2858 56.5681C63.424 56.0834 62.2928 56.0297 61.485 56.6219L57.7687 58.7756L54.4831 60.6603L43.2803 54.1987V46.6066L54.4831 40.1453L61.431 36.1069L57.4992 41.8681L57.7146 41.9759L61.431 44.1297C62.3468 44.6681 63.424 44.6681 64.2858 44.1297L71.7185 39.8222C72.5803 39.2838 73.1189 38.3684 73.1189 37.3453V28.7839C73.1727 27.7608 72.5803 26.7916 71.7185 26.307L64.2858 21.9994C63.3699 21.4609 62.2928 21.4609 61.431 21.9994L53.9983 26.307C53.1368 26.8455 52.5982 27.7608 52.5982 28.7839V36.8069L41.395 43.2681L38.1098 41.3838L34.3934 39.23C33.4775 38.6912 32.4003 38.6912 31.5387 39.23L24.106 43.5375C23.2442 44.0759 22.7056 44.9913 22.7056 46.0144V54.6297C22.7056 55.6525 23.2442 56.5681 24.106 57.0525L31.5387 61.3603C32.4544 61.8987 33.5316 61.8987 34.3934 61.3603L38.6483 58.8834L35.2549 53.8219L52.5982 63.8372V71.9138C52.5441 72.9369 53.1368 73.9063 53.9983 74.3906L61.431 78.6984C62.3468 79.2369 63.424 79.2369 64.2858 78.6984L71.7185 74.3906C72.5803 73.8522 73.1189 72.9369 73.1189 71.9138V63.3525Z',
  stroke: 'currentColor',
  strokeLinejoin: 'round',
  strokeWidth: '1',
}

export function BpSpinner(props: ComponentProps<typeof motion.svg>) {
  const logoRef = useRef<SVGPathElement>(null)
  const [logoPathLength, setLogoPathLength] = useState<number>(0)

  const dash = logoPathLength
  const gap = logoPathLength / 2

  useEffect(() => {
    const logoPath = logoRef.current
    if (logoPath) {
      setLogoPathLength(logoPath.getTotalLength())
    }
  }, [])

  return (
    <motion.svg
      {...props}
      className={`isolate ${props.className}}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      width="width"
      height="height"
      viewBox="0 0 100 100"
      fill="none"
    >
      <motion.path
        className={'text-gray-6'}
        animate={{
          strokeDashoffset: [0, dash + gap],
          transition: {
            delay: 0.5,
            duration: 4,
            repeat: Infinity,
            repeatDelay: 0,
            ease: 'easeInOut',
          },
        }}
        strokeDasharray={`${dash} ${gap}`}
        {...svgPathProps}
      />
      <motion.path
        ref={logoRef}
        animate={{
          strokeDashoffset: [0, dash + gap],
          transition: {
            duration: 4,
            repeat: Infinity,
            repeatDelay: 0,
            ease: 'easeInOut',
          },
        }}
        strokeDasharray={`${dash} ${gap}`}
        {...svgPathProps}
      />
    </motion.svg>
  )
}
