import { z } from "zod";

export default z
  .object({
    type: z.literal("webchat_config"),
    data: z
      .object({ config: z.record(z.union([z.any(), z.null()])) })
      ,
  })
  ;
