import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon7 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-5)"
        d="M66.666,69.998c0,4.605,3.725,8.332,8.329,8.332c4.606,0,8.335-3.727,8.335-8.332	c0-4.604-3.731-8.332-8.335-8.332C70.393,61.666,66.666,65.394,66.666,69.998z"
      />
      <path
        fill="var(--accent-11)"
        d="M74.992,54.997c-8.286,0-14.997,6.712-14.997,15.003c0,8.287,6.707,15,14.997,15C83.286,85,90,78.287,90,70	C90,61.709,83.281,54.997,74.992,54.997z M74.995,78.33c-4.604,0-8.329-3.727-8.329-8.332c0-4.604,3.727-8.332,8.329-8.332	c4.604,0,8.335,3.728,8.335,8.332C83.33,74.604,79.602,78.33,74.995,78.33z"
      />
      <path
        fill="var(--accent-5)"
        d="M58.331,26.67c0-4.606-3.729-8.333-8.333-8.333c-4.603,0-8.333,3.726-8.333,8.333	c0,4.603,3.73,8.333,8.333,8.333C54.602,35.003,58.331,31.272,58.331,26.67z"
      />
      <path
        fill="var(--accent-11)"
        d="M49.998,11.669c-8.286,0-15.001,6.707-15.001,15.001c0,8.286,6.715,15.002,15.001,15.002	c8.288,0,15-6.716,15-15.002C64.998,18.379,58.286,11.669,49.998,11.669z M49.998,35.003c-4.603,0-8.333-3.73-8.333-8.333	c0-4.607,3.73-8.333,8.333-8.333c4.604,0,8.333,3.727,8.333,8.333C58.331,31.272,54.602,35.003,49.998,35.003z"
      />
      <path
        fill="var(--accent-5)"
        d="M16.667,69.998c0,4.605,3.729,8.33,8.332,8.332c4.603,0,8.333-3.727,8.333-8.332s-3.73-8.332-8.333-8.336	C20.396,61.666,16.67,65.394,16.667,69.998z"
      />
      <path
        fill="var(--accent-11)"
        d="M24.999,54.995C16.714,55.002,10.007,61.713,10,70.002c0,8.285,6.714,14.993,14.999,14.996	c8.284,0,15-6.711,15-14.996C39.999,61.709,33.283,55.002,24.999,54.995z M24.999,78.33c-4.603-0.002-8.332-3.727-8.332-8.332	c0.003-4.604,3.729-8.332,8.332-8.336c4.603,0.004,8.333,3.73,8.333,8.336S29.601,78.33,24.999,78.33z"
      />
      <path
        fill="var(--accent-5)"
        d="M44.494,29.499c0-4.604,3.73-8.334,8.333-8.334c1.577,0,3.046,0.445,4.302,1.204	c-1.459-2.413-4.1-4.032-7.13-4.032c-4.603,0-8.333,3.73-8.333,8.334c0,3.027,1.619,5.668,4.033,7.128	C44.938,32.542,44.494,31.074,44.494,29.499z"
      />
      <path
        fill="var(--accent-5)"
        d="M69.494,72.827c0-4.603,3.73-8.333,8.332-8.333c1.577,0,3.046,0.445,4.302,1.203	c-1.459-2.413-4.1-4.031-7.13-4.031c-4.603,0-8.332,3.73-8.332,8.332c0,3.028,1.618,5.669,4.032,7.129	C69.939,75.87,69.494,74.403,69.494,72.827z"
      />
      <path
        fill="var(--accent-5)"
        d="M19.495,72.823c0-4.603,3.73-8.333,8.333-8.333c1.577,0,3.044,0.445,4.301,1.203	C30.671,63.28,28.03,61.662,25,61.662c-4.603,0-8.333,3.73-8.333,8.333c0,3.027,1.619,5.669,4.033,7.128	C19.94,75.867,19.495,74.399,19.495,72.823z"
      />
    </svg>
  )
}
