export async function listAll<
  TParams,
  TResponse extends { meta: { nextToken?: string } },
  TItem extends { id: string },
>(
  clientMethod: (_: TParams & { nextToken?: string }) => Promise<TResponse>,
  params: TParams,
  extractor: (response: TResponse) => TItem[],
  options?: { maxPages?: number }
): Promise<TItem[]> {
  const allItemsById: Map<string, TItem> = new Map()
  const getAllItems = () => Array.from(allItemsById.values())
  let nextToken: string | undefined
  let pageCount = 0

  do {
    const response = await clientMethod({ ...params, nextToken })
    const items = extractor(response)

    for (const item of items) {
      if (allItemsById.has(item.id)) {
        /* eslint-disable no-console */
        console.error(`Duplicate item found during pagination ${item.id}, page loading was stopped.`)
        // This is a protection against an HTTP amplification risk in the case of the backend pagination functionality returning the same records continuously (e.g. if a nextToken is always returned)
        return getAllItems()
      }

      allItemsById.set(item.id, item)
    }

    nextToken = response.meta.nextToken
    pageCount += 1
  } while (nextToken !== undefined && (!options || pageCount < (options.maxPages ?? 100)))

  return getAllItems()
}
