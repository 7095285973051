import { Text } from '@radix-ui/themes'
import { HiOutlineCheck } from 'react-icons/hi2'

export const OAuthSuccess = () => (
  <>
    <div className="mt-auto w-min rounded-full border-2 border-grass-8 bg-grass-2 p-6">
      <HiOutlineCheck className="h-16 w-16 text-grass-10" />
    </div>
    <Text color="gray" size={'4'} mt={'6'}>
      Authentication successful!
    </Text>
  </>
)
