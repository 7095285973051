import { createContext, useContext } from 'react'

type FormFieldContextValue = {
  name: string
}
export const FormFieldContext = createContext<FormFieldContextValue | null>(null)

export function useFormFieldContext() {
  const context = useContext(FormFieldContext)
  if (!context) {
    throw new Error(
      'useFormFieldContext must be used within a FormFieldContextProvider. \n Component must be used within a FormField component.'
    )
  }
  return context
}
