import { DataList } from '@radix-ui/themes'
import { ComponentProps, ReactNode } from 'react'

type DataListRootProps = Pick<
  ComponentProps<typeof DataList.Root>,
  'children' | 'orientation' | 'size' | 'trim' | 'className'
>

export const DataListRoot = (props: DataListRootProps) => <DataList.Root {...props} />

type ItemProps = Pick<ComponentProps<typeof DataList.Item>, 'children' | 'align' | 'className'>
type LabelProps = Pick<
  ComponentProps<typeof DataList.Label>,
  'width' | 'maxWidth' | 'minWidth' | 'color' | 'highContrast'
>

type DaraListItemProps = {
  label: ReactNode
  labelWidth?: LabelProps['width']
  labelMaxwidth?: LabelProps['maxWidth']
  labelMinWidth?: LabelProps['minWidth']
  color?: LabelProps['color']
  highContrast?: LabelProps['highContrast']
} & ItemProps
export const DataListItem = ({
  label,
  children,
  align,
  labelMaxwidth,
  labelMinWidth,
  labelWidth,
  ...props
}: DaraListItemProps) => (
  <DataList.Item align={align}>
    <DataList.Label {...props} maxWidth={labelMaxwidth} minWidth={labelMinWidth} width={labelWidth}>
      {label}
    </DataList.Label>
    <DataList.Value>{children}</DataList.Value>
  </DataList.Item>
)
