import { ThemeColor } from '@botpress/ui-kit'
import { Badge } from '@radix-ui/themes'
import type { File } from 'botpress-client'
import type { ComponentProps } from 'react'
import { match } from 'ts-pattern'

type Props = { status: File['status'] } & Omit<ComponentProps<typeof Badge>, 'color' | 'children'>

export const FileStatusBadge = ({ status, ...props }: Props) => {
  const { color, text } = match(status)
    .with('upload_completed', () => ({ color: 'green', text: 'Uploaded' }) as const)
    .with('upload_failed', () => ({ color: 'red', text: 'Failed' }) as const)
    .with('upload_pending', () => ({ color: ThemeColor, text: 'Uploading' }) as const)
    .with('indexing_pending', () => ({ color: ThemeColor, text: 'Indexing' }) as const)
    .with('indexing_completed', () => ({ color: 'iris', text: 'Indexed' }) as const)
    .with('indexing_failed', () => ({ color: 'red', text: 'Indexing failed' }) as const)
    .otherwise(() => ({ color: 'gray', text: 'Unknown' }) as const)

  return (
    <Badge {...props} color={color}>
      {text}
    </Badge>
  )
}
