import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { createAjv } from '@jsonforms/core'
import React, { ComponentProps } from 'react'
import { VerticalLayoutRenderer } from '../layouts/VerticalLayout'
import { booleanInputTester, textInputTester, verticalLayoutTester } from '../testers'
import { CheckboxControl } from './CheckboxControl'
import { TextControl } from './TextControl'

type JsonFormProps = {} & Omit<ComponentProps<typeof JsonForms>, 'renderers' | 'cells'>

export const JsonForm = ({ ...props }: JsonFormProps) => {
  return (
    <JsonForms
      {...props}
      renderers={[
        ...materialRenderers,
        { tester: textInputTester, renderer: TextControl },
        { tester: verticalLayoutTester, renderer: VerticalLayoutRenderer },
        { tester: booleanInputTester, renderer: CheckboxControl },
      ]}
      validationMode="ValidateAndShow"
      cells={materialCells}
      ajv={createAjv({ useDefaults: true })}
    />
  )
}
