import cx from 'classnames'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

export type CardProps = JSX.IntrinsicElements['div']

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => (
  <div
    {...props}
    ref={ref}
    className={twMerge(cx('overflow-hidden rounded-md border border-gray-4 bg-gray-2 p-2', props.className))}
  />
))
