import { useMemo } from 'react'
import { useUsage } from '~/hooks'
import {
  DYNAMIC_QUOTAS,
  QUOTA_TYPE_USER_FACING_PROPERTIES_MAP,
  QuotaUsedPercentageWarningThreshold,
} from '../../usage/constants'
import { match } from 'ts-pattern'
import { Callout, Link } from '../../../elementsv2'
import { formatValueWithUnit } from '../../usage/helpers'
import { HiOutlineExclamationCircle, HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Text } from '@radix-ui/themes'

type Props = {
  workspaceId: string
}

export const WorkspaceUsageCallout = ({ workspaceId }: Props) => {
  const usages = useUsage({
    workspaceId,
    quotas: DYNAMIC_QUOTAS,
  })

  const [highestUsage] = useMemo(() => {
    return Object.values(usages)
      .map(({ value, quota, ...rest }) => ({
        usage: value,
        quota,
        ...rest,
      }))
      .filter(({ percentage }) => percentage > QuotaUsedPercentageWarningThreshold)
      .sort((a, b) => b.percentage - a.percentage)
  }, [usages])
  return match(highestUsage)
    .with(undefined, () => null)
    .when(
      ({ percentage }) => percentage >= 100,
      ({ quota, type }) => (
        <Callout color="red" icon={HiOutlineExclamationCircle} size={'1'}>
          You've used <Text weight={'bold'}>100% </Text>
          of your {formatValueWithUnit(quota, type)}{' '}
          <Text weight={'bold'}>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}</Text>. To restore functionality and
          prevent disruption, please{' '}
          <Link
            color="red"
            weight={'medium'}
            to="/workspaces/$workspaceId/settings/billing/plans"
            params={{ workspaceId }}
          >
            {' '}
            upgrade your plan
          </Link>{' '}
          or{' '}
          <Link color="red" weight={'medium'} to="/workspaces/$workspaceId/settings/billing" params={{ workspaceId }}>
            purchase an add-on
          </Link>{' '}
          .
        </Callout>
      )
    )
    .when(
      ({ percentage }) => percentage >= 75,
      ({ quota, type, percentage }) => (
        <Callout color="amber" icon={HiOutlineExclamationTriangle} size={'1'}>
          You have used <Text weight={'bold'}>{percentage.toFixed(0)}% </Text>
          of your {formatValueWithUnit(quota, type)} limit for{' '}
          <Text weight={'bold'}>{QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[type].name}</Text>. To avoid service disruption,
          consider{' '}
          <Link
            color="amber"
            weight={'medium'}
            to="/workspaces/$workspaceId/settings/billing/plans"
            params={{ workspaceId }}
          >
            {' '}
            upgrading your plan
          </Link>{' '}
          or{' '}
          <Link color="amber" weight={'medium'} to="/workspaces/$workspaceId/settings/billing" params={{ workspaceId }}>
            adding an add-on
          </Link>{' '}
          to increase your limit.
        </Callout>
      )
    )
    .otherwise(() => null)
}
