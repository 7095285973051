import { QuotaType } from '@bpinternal/const'
import { useAppStore } from '../stores'
import { type listEventsInfiniteQueryProps } from '../queries/events'
import type { GetStateProps } from '../queries/states'
import type { ListHITLConversationsProps, ListConversationsProps } from '../queries'

const WORKSPACE = 'workspaces'
const WORKSPACE_PROFILE = 'workspaces'
const BOT = 'bots'
const COLLABORATOR = 'collaborators'
const CONVERSATIONS = 'conversations'
const INTEGRATION = 'integrations'
const FILE = 'files'
const AUDITS = 'audits'
const LOGS = 'logs'
const ISSUES = 'issues'
const ISSUE_EVENTS = 'issue_events'
const BLOCKS = 'blocks'
const USAGE = 'usage'
const QUOTAS = 'quotas'
const BILLING = 'billing'
const WORKSPACE_HANDLE = 'workspace_handle'

/**
 * @deprecated Use utils in services/queries.ts instead
 */
export function useQueryKeys() {
  const user = useAppStore.getState().currentUser
  if (!user) {
    throw new Error('Unabled to get current user')
  }

  return {
    getPublicWorkspace: (workspaceId?: string) => [user.id, WORKSPACE, workspaceId, WORKSPACE_PROFILE],
    getWorkspaceQuotas: (workspaceId?: string, period?: Date) => [
      user.id,
      WORKSPACE,
      workspaceId,
      QUOTAS,
      period?.toISOString(),
    ],
    getWorkspaceQuota: (workspaceId?: string, type?: QuotaType, period?: Date) => [
      user.id,
      WORKSPACE,
      workspaceId,
      QUOTAS,
      type,
      period?.toISOString(),
    ],
    getWorkspaceQuotaCompletion: () => [user.id, WORKSPACE, QUOTAS, 'completion'],
    getAllWorkspaceUsages: (workspaceId: string) => [user.id, WORKSPACE, workspaceId, USAGE],
    getWorkspaceUsage: (workspaceId?: string, type?: QuotaType, period?: string) => [
      user.id,
      WORKSPACE,
      workspaceId,
      USAGE,
      ...(type ? [type] : []),
      ...(period ? [period] : []),
    ],
    getWorkspaceUsages: (workspaceId?: string, period?: string, types?: QuotaType[]) => [
      user.id,
      WORKSPACE,
      workspaceId,
      USAGE,
      ...(period ? [period] : []),
      ...(types ? types : []),
    ],
    getWorkspaceUsageByBot: (workspaceId?: string, type?: QuotaType, period?: string) => [
      user.id,
      WORKSPACE,
      workspaceId,
      USAGE,
      BOT,
      ...(type ? [type] : []),
      ...(period ? [period] : []),
    ],
    getWorkspaceBillingProducts: (workspaceId?: string) => [user.id, WORKSPACE, workspaceId, BILLING, 'products'],
    getWorkspaceCustomerPortal: (workspaceId?: string) => [user.id, WORKSPACE, workspaceId, BILLING, 'portal'],
    listInvoices: (workspaceId?: string) => [user.id, WORKSPACE, workspaceId, BILLING, 'invoices'],
    getUpcomingInvoice: (workspaceId?: string) => [user.id, WORKSPACE, workspaceId, BILLING, 'upcoming-invoice'],
    getAllWorkspacesUsages: (period: string, types: string[]) => [user.id, WORKSPACE, USAGE, period, ...types],
    getBot: (botId?: string) => [user.id, BOT, botId],
    getBotWebchat: (botId?: string) => [user.id, BOT, botId, 'webchat'],
    getBotAnalyticsOverview: ({
      botId,
      workspaceId,
      startDate,
      endDate,
    }: {
      botId?: string
      workspaceId?: string
      startDate?: string
      endDate?: string
    }) => [user.id, botId, workspaceId, startDate, endDate],
    listAudits: (workspaceId?: string) => [user.id, workspaceId, AUDITS],
    listRecentAudits: (workspaceId?: string) => [user.id, workspaceId, AUDITS, 'recent'],
    listCollaborators: (workspaceId?: string) => [user.id, workspaceId, COLLABORATOR],
    getConversation: ({
      workspaceId,
      botId,
      conversationId,
    }: {
      workspaceId: string
      botId: string
      conversationId: string
    }) => [user.id, workspaceId, botId, 'conversations', conversationId],
    listConversations: ({ botId, workspaceId, participantIds, tags, integrationName }: ListConversationsProps) => [
      user.id,
      workspaceId,
      botId,
      CONVERSATIONS,
      integrationName,
      participantIds ?? [],
      tags ?? {},
    ],
    listHitlConversations: ({ botId, workspaceId }: ListHITLConversationsProps) => [
      user.id,
      workspaceId,
      botId,
      CONVERSATIONS,
      'HITL',
    ],
    listConversationUsers: (workspaceId?: string, botId?: string, conversationId?: string) => [
      user.id,
      workspaceId,
      botId,
      CONVERSATIONS,
      conversationId,
      'users',
    ],
    listConversationParticipants: (workspaceId?: string, botId?: string, conversationId?: string) => [
      user.id,
      workspaceId,
      botId,
      CONVERSATIONS,
      conversationId,
      'participants',
    ],
    getMessage: (messageId: string) => [user.id, messageId],
    getState: ({ workspaceId, botId, name, id }: GetStateProps) => [user.id, workspaceId, botId, 'states', name, id],
    getEvent: ({ workspaceId, botId, eventId }: { workspaceId: string; botId: string; eventId: string }) => [
      user.id,
      workspaceId,
      botId,
      'events',
      eventId,
    ],
    listEvents: ({ workspaceId, botId, ...rest }: listEventsInfiniteQueryProps) => [
      user.id,
      workspaceId,
      botId,
      'events',
      ...(Object.entries.length ? [rest] : []),
    ],
    listLogs: ({ id, type, startDate, endDate }: { id: string; type: string; startDate: Date; endDate: Date }) => [
      LOGS,
      id,
      type,
      startDate,
      endDate,
    ],
    getIssue: ({ botId, workspaceId, issueId }: { botId: string; workspaceId: string; issueId: string }) => [
      workspaceId,
      botId,
      ISSUES,
      issueId,
    ],
    listIssuesLegacy: ({ botId }: { botId: string }) => [ISSUES, botId], // TODO: fix those query keys for proper cache busting
    listAllIssues: ({ botId, workspaceId }: { botId: string; workspaceId: string }) => [
      workspaceId,
      botId,
      ISSUES,
      'all',
    ],
    listIssueEventsLegacy: ({ botId, issueId }: { botId: string; issueId: string }) => [ISSUE_EVENTS, botId, issueId], // TODO: fix those query keys for proper cache busting
    listIssueEvents: ({ botId, workspaceId, issueId }: { botId: string; workspaceId: string; issueId: string }) => [
      workspaceId,
      botId,
      ISSUES,
      issueId,
      ISSUE_EVENTS,
    ],
    listMessages: (workspaceId?: string, botId?: string, conversationId?: string, users?: string[]) => [
      BLOCKS,
      workspaceId,
      botId,
      conversationId,
      ...(users ?? []),
    ],

    listIntegrationApiKeys: (integrationId?: string) => [user.id, INTEGRATION, integrationId, 'api-keys'],
    listBotConversations: (workspaceId?: string, botId?: string) => [BOT, CONVERSATIONS, workspaceId, botId],
    getIntegration: (integrationId?: string) => [user.id, INTEGRATION, integrationId],
    getIntegrationByName: (name: string, version: string) => [user.id, INTEGRATION, name, version],
    listWorkspaceIntegrations: (workspaceId?: string) => [user.id, workspaceId, INTEGRATION],
    listPublicIntegrationVersions: (integrationName: string) => [INTEGRATION, integrationName],
    readFile: (fileUrl: string) => [user.id, FILE, fileUrl],
    checkWorkspaceHandle: (handle: string) => [WORKSPACE_HANDLE, handle],
  } as const
}
