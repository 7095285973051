import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient, getGenericClient } from '../../client'
import { useQueryKeys } from '../useQueryKeys'
import { getQueryKey } from '~/services'

type UpdateWorkspaceParams = {
  workspaceId: string
  options?: {
    onSuccess?: () => Promise<void>
    onError?: (error: Error) => Promise<void>
    onSettled?: () => Promise<void>
  }
} & Parameters<ReturnType<typeof getGenericClient>['createWorkspaceMember']>['0']

export function useCreateWorkspaceMember() {
  const queryClient = useQueryClient()
  const { getWorkspaceUsages, getAllWorkspaceUsages } = useQueryKeys()

  return useMutation({
    meta: {
      suppressError: true,
    },
    mutationFn: async ({ workspaceId, ...props }: UpdateWorkspaceParams) => {
      return await getApiClient(workspaceId).createWorkspaceMember({ ...props })
    },
    onSuccess: async (_, { workspaceId, options }) => {
      const listMembersKey = getQueryKey('workspaces_/$workspaceId_/members', { workspaceId })

      await queryClient.invalidateQueries({ queryKey: listMembersKey })
      await queryClient.invalidateQueries({ queryKey: getWorkspaceUsages(workspaceId) })
      await queryClient.invalidateQueries({ queryKey: getAllWorkspaceUsages(workspaceId) })

      await options?.onSuccess?.()
    },
    onError: async (error, { options }) => {
      await options?.onError?.(error)
    },
    onSettled: (_data, _error, { options }) => {
      if (options?.onSettled) {
        void options.onSettled()
      }
    },
  })
}
