export class HITLClientError extends Error {
  constructor(message: string) {
    super(message)
    console.error(`[HITL ERROR] ${message}`)
  }
}

export class UnsuportedFeatureError extends HITLClientError {
  constructor(feature: string) {
    super(`${feature} is not supported by the HITL API`)
  }
}

export class HITLIntegrationNotEnabledError extends HITLClientError {
  constructor() {
    super('HITL integration is either not installed or not enabled')
  }
}

export class InvalidStateError extends HITLClientError {
  constructor(action: string) {
    super(`Cannot ${action} as the client is not yet connected to a conversation`)
  }
}
