import { Callout as RadixCallout, Flex } from '@radix-ui/themes'
import clsx from 'clsx'
import { ComponentProps, FC, forwardRef, ReactNode } from 'react'
import { root, icon, grow, content } from './callout.module.css'

type Props = {
  icon?: FC<{ className?: string }>
  children?: ReactNode
  className?: string
  color?: 'grass' | 'blue' | 'red' | 'amber' | 'gray'
  calloutAction?: ReactNode
} & Pick<ComponentProps<typeof RadixCallout.Root>, 'size' | 'variant' | 'highContrast' | 'onClick'>

export const Callout = forwardRef<HTMLDivElement, Props>(
  ({ icon: Icon, children, calloutAction, className, ...props }, ref) => {
    return (
      <RadixCallout.Root {...props} ref={ref} className={clsx(className, root)}>
        {Icon && (
          <RadixCallout.Icon>
            <Icon className={icon} />
          </RadixCallout.Icon>
        )}
        <Flex justify={'between'} gap={'3'} className={grow}>
          <RadixCallout.Text className={content}>{children}</RadixCallout.Text>
          {calloutAction}
        </Flex>
      </RadixCallout.Root>
    )
  }
)

Callout.displayName = 'Callout'
