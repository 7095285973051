import { z } from "zod";

export default z
  .object({
    type: z.literal("message_created"),
    data: z
      .object({
        id: z.string().describe("Id of the [Message](#schema_message)"),
        createdAt: z
          .string()
          .datetime()
          .describe(
            "Creation date of the [Message](#schema_message) in ISO 8601 format"
          ),
        payload: z
          .union([
            z.object({ text: z.string().min(1), type: z.literal("text") }),
            z.object({
              markdown: z.string().min(1),
              type: z.literal("markdown"),
            }),
            z.object({ imageUrl: z.string().min(1), type: z.literal("image") }),
            z.object({ audioUrl: z.string().min(1), type: z.literal("audio") }),
            z.object({ videoUrl: z.string().min(1), type: z.literal("video") }),
            z.object({
              fileUrl: z.string().min(1),
              title: z.string().min(1).optional(),
              type: z.literal("file"),
            }),
            z.object({
              latitude: z.number(),
              longitude: z.number(),
              address: z.string().optional(),
              title: z.string().optional(),
              type: z.literal("location"),
            }),
            z.object({
              items: z.array(
                z.object({
                  title: z.string().min(1),
                  subtitle: z.string().min(1).optional(),
                  imageUrl: z.string().min(1).optional(),
                  actions: z.array(
                    z.object({
                      action: z.enum(["postback", "url", "say"]),
                      label: z.string().min(1),
                      value: z.string().min(1),
                    })
                  ),
                })
              ),
              type: z.literal("carousel"),
            }),
            z.object({
              title: z.string().min(1),
              subtitle: z.string().min(1).optional(),
              imageUrl: z.string().min(1).optional(),
              actions: z.array(
                z.object({
                  action: z.enum(["postback", "url", "say"]),
                  label: z.string().min(1),
                  value: z.string().min(1),
                })
              ),
              type: z.literal("card"),
            }),
            z.object({
              text: z.string().min(1),
              options: z.array(
                z.object({ label: z.string().min(1), value: z.string().min(1) })
              ),
              type: z.literal("dropdown"),
            }),
            z.object({
              text: z.string().min(1),
              options: z.array(
                z.object({ label: z.string().min(1), value: z.string().min(1) })
              ),
              type: z.literal("choice"),
            }),
          ])
          .describe("Payload is the content type of the message."),
        userId: z.string().describe("ID of the [User](#schema_user)"),
        conversationId: z
          .string()
          .describe("ID of the [Conversation](#schema_conversation)"),
        tags: z
          .record(z.string())
          .describe(
            "Additional dynamic information about the [Message](#schema_message)"
          ),
      })
      
      .describe(
        "The Message object represents a message in a [Conversation](#schema_conversation) for a specific [User](#schema_user)."
      ),
  })
  ;
