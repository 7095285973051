import { z } from "zod";

export default z
  .object({
    type: z.literal("webchat_visibility"),
    data: z
      .object({
        visibility: z.union([
          z.literal("show"),
          z.literal("hide"),
          z.literal("toggle"),
        ]),
      })
      ,
  })
  ;
