// Added by @sly on 2024-Aug-14
// This is a hack to fix a bug in React
// The problem is that Chrome translates HTML nodes and expects some HTML elements (such as <button>, <select>) to only have text nodes as children
// When we have conditional rendering, such as <button>{condition && <span>text</span>}</button>, React will crash when the conditional node has been replaced by Chrome
// The impact is that our app crashes for many users using translation tools, Chrome Translate and some other Chrome Extensions
// https://github.com/facebook/react/issues/17256

// @ts-nocheck
try {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this)
        }
        return child
      }
      return originalRemoveChild.apply(this, arguments)
    }

    const originalInsertBefore = Node.prototype.insertBefore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn('Cannot insert before a reference node from a different parent', referenceNode, this)
        }
        return newNode
      }
      return originalInsertBefore.apply(this, arguments)
    }
  }
} catch (e) {
  console.error(`Error while applying React Node polyfill: ${e.message}`)
}
