import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Icon8 = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50px" height="50px" {...props}>
      <path
        fill="var(--accent-11)"
        d="M73.438,63.552H55.273l11.071-11.195c2.5-2.495,3.874-5.82,3.874-9.348c0-3.536-1.374-6.856-3.87-9.353	c-1.801-1.8-4.03-3.018-6.461-3.558c1.253-2.048,1.923-4.407,1.923-6.875C61.811,15.931,55.88,10,48.587,10h-22.03	c-7.292,0-13.224,5.93-13.224,13.222c0,7.292,5.932,13.226,13.223,13.226l18.168,0.004L33.652,47.643	c-5.152,5.156-5.148,13.548,0,18.701c1.798,1.802,4.027,3.023,6.458,3.561c-1.25,2.049-1.919,4.403-1.919,6.869	C38.19,84.063,44.122,90,51.414,90h22.024c7.294,0,13.229-5.937,13.229-13.226C86.667,69.481,80.732,63.552,73.438,63.552z M26.557,29.783c-3.615,0-6.556-2.94-6.556-6.56c0-3.614,2.943-6.556,6.556-6.556v0.001h22.031c3.615,0,6.558,2.943,6.558,6.557	c0,1.75-0.682,3.398-1.935,4.648l-1.894,1.914L26.557,29.783z M38.372,61.633c-2.558-2.558-2.558-6.719,0.007-9.289L54.1,36.452H57	c1.753,0,3.398,0.68,4.631,1.914c1.241,1.241,1.922,2.891,1.922,4.642c0,1.751-0.681,3.398-1.935,4.648l-15.72,15.896h-2.902	C41.243,63.552,39.602,62.87,38.372,61.633z M73.438,83.333H51.414c-3.619,0-6.558-2.942-6.558-6.557	c0-1.752,0.684-3.398,1.934-4.648l1.893-1.911v-0.001h24.756c3.621,0,6.562,2.94,6.562,6.558S77.056,83.333,73.438,83.333z"
      />
      <path
        fill="var(--accent-11)"
        d="M59.171,36.825c-0.688-0.241-1.419-0.373-2.171-0.373h-2.9l5.788-6.354c1.401,0.312,2.73,0.854,3.954,1.598	L59.171,36.825z"
      />
      <path
        fill="var(--accent-11)"
        d="M40.828,63.178c0.689,0.241,1.419,0.372,2.171,0.372h2.899l-5.787,6.354	c-1.402-0.311-2.732-0.854-3.956-1.599L40.828,63.178z"
      />
      <path
        fill="var(--accent-5)"
        d="M38.379,52.344c-2.565,2.57-2.565,6.731-0.007,9.289c1.23,1.237,2.871,1.919,4.624,1.919h2.902l15.72-15.896	c1.254-1.25,1.935-2.897,1.935-4.648c0-1.751-0.681-3.401-1.922-4.642c-1.232-1.234-2.878-1.914-4.631-1.914h-2.9L38.379,52.344z"
      />
      <path
        fill="var(--accent-5)"
        d="M59.308,36.874c-0.728-0.273-1.506-0.422-2.31-0.422H54.1L38.378,52.344	c-1.929,1.933-2.406,4.766-1.437,7.139L59.308,36.874z"
      />
      <path
        fill="var(--accent-5)"
        d="M40.7,63.13c0.729,0.271,1.506,0.422,2.309,0.422h2.899L61.63,47.659c1.929-1.934,2.406-4.766,1.437-7.14	L40.7,63.13z"
      />
      <path
        fill="var(--accent-5)"
        d="M44.856,76.776c0,3.614,2.939,6.557,6.558,6.557h22.024c3.617,0,6.562-2.942,6.562-6.56	s-2.94-6.558-6.562-6.558H48.683v0.001l-1.893,1.911C45.54,73.378,44.856,75.024,44.856,76.776z"
      />
      <path
        fill="var(--accent-5)"
        d="M20,23.223c0,3.62,2.941,6.56,6.556,6.56l24.761,0.003l1.894-1.914c1.253-1.25,1.935-2.897,1.935-4.648	c0-3.613-2.942-6.557-6.558-6.557H26.557v-0.001C22.943,16.667,20,19.609,20,23.223z"
      />
      <path
        fill="var(--accent-5)"
        d="M45.651,29.785l4.717-4.727c0.514-0.513,0.777-1.141,0.777-1.834c0-1.41-1.148-2.557-2.558-2.557H26.557	v-0.001c-2.917,0-5.394,1.918-6.242,4.558C20.112,24.593,20,23.921,20,23.223c0-3.614,2.943-6.556,6.556-6.556v0.001h22.031	c3.615,0,6.558,2.943,6.558,6.557c0,1.75-0.682,3.398-1.935,4.648l-1.894,1.914L45.651,29.785z"
      />
      <path
        fill="var(--accent-5)"
        d="M54.35,70.217l-4.717,4.727c-0.515,0.515-0.776,1.143-0.776,1.835c0,1.409,1.146,2.556,2.556,2.556h22.031	v0.002c2.917,0,5.394-1.919,6.242-4.558C79.889,75.409,80,76.081,80,76.779c0,3.614-2.942,6.557-6.557,6.557v-0.002H51.412	c-3.614,0-6.556-2.942-6.556-6.556c0-1.75,0.68-3.398,1.934-4.648l1.895-1.914L54.35,70.217z"
      />
      <polygon fill="var(--accent-11)" points="44.723,36.452 39.098,36.45 45.692,29.785 51.317,29.786" />
      <polygon fill="var(--accent-11)" points="54.308,70.216 48.683,70.214 55.276,63.55 60.901,63.55" />
    </svg>
  )
}
