import { OwnPropsOfRenderer, UISchemaElement } from '@jsonforms/core'
import { JsonFormsDispatch } from '@jsonforms/react'
import { isEmpty } from 'lodash'
import { Fragment, memo } from 'react'

type MaterialLayoutRendererProps = {
  elements: UISchemaElement[]
} & OwnPropsOfRenderer

const LayoutRendererComponent = ({
  elements,
  schema,
  path,
  enabled,
  renderers,
  cells,
}: MaterialLayoutRendererProps) => {
  if (isEmpty(elements)) {
    return null
  } else {
    return (
      <>
        {elements.map((child, index) => (
          <Fragment key={`${path}-${index}`}>
            <JsonFormsDispatch
              uischema={child}
              schema={schema}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          </Fragment>
        ))}
      </>
    )
  }
}
export const LayoutRenderer = memo(LayoutRendererComponent)
