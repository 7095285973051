import { useSuspenseQuery } from '@tanstack/react-query'
import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useLocalStore } from '~/stores'
import { workspacesQueryOptions } from '../../queries'

export const Route = createFileRoute('/workspaces/')({
  component: HomeComponent,
})

function HomeComponent() {
  const { data: workspaces } = useSuspenseQuery(workspacesQueryOptions())
  const { lastActiveWorkspaceId } = useLocalStore()

  const lastActiveWorkspace = workspaces.find((workspace) => workspace.id === lastActiveWorkspaceId) ?? workspaces[0]

  return lastActiveWorkspace ? (
    <Navigate to="/workspaces/$workspaceId" params={{ workspaceId: lastActiveWorkspace.id }} replace />
  ) : (
    <></>
  )
}
