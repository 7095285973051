import { getApiClient, getGenericClient } from '../../../client'
import { listAll } from '../../../utils'
import { BotSummary } from '../types'

export async function listBots(workspaceId: string): Promise<BotSummary[]> {
  return listAll(getApiClient(workspaceId).listBots, {}, ({ bots }) => bots)
}

export async function getBot({ workspaceId, botId }: { workspaceId: string; botId: string }) {
  const { bot } = await getApiClient(workspaceId).getBot({ id: botId })
  return bot
}

type UpdateBotParams = {
  workspaceId: string
} & Parameters<ReturnType<typeof getGenericClient>['updateBot']>['0']
export async function updateBot({ workspaceId, ...props }: UpdateBotParams) {
  const { bot } = await getApiClient(workspaceId).updateBot({ ...props })
  return bot
}
