import { Button, Flex, Separator, Text } from '@radix-ui/themes'
import { Link, ToOptions, useNavigate } from '@tanstack/react-router'
import { Workspace } from 'botpress-client'
import { capitalize } from 'lodash'
import { HiChevronDown, HiOutlineEye } from 'react-icons/hi2'
import { DropdownMenu, MenuItem } from '~/elementsv2'
import { useAppStore } from '~/stores'

type ViewAsProps = {
  currentRoute: ToOptions['to']
  userWorkspaces: Workspace[]
}

export const ViewAsSection = (props: ViewAsProps) => {
  const navigate = useNavigate()
  const activeWorkspace = useAppStore((s) => s.activeWorkspace)
  const userIsMember = props.userWorkspaces.some((workspace) => workspace.id === activeWorkspace?.id)

  if (!userIsMember || !props.currentRoute || !activeWorkspace) {
    return null
  }

  const viewingAs = props.currentRoute.includes('workspaces/$workspaceId/home') ? 'member' : 'public'

  const items: MenuItem[] = [
    {
      type: 'radioGroup',
      value: viewingAs,
      items: [
        {
          type: 'radioItem',
          value: 'member',
          content: 'Member',
          onSelect: () => {
            if (viewingAs === 'public') {
              void navigate({ to: '/workspaces/$workspaceId/home', params: { workspaceId: activeWorkspace.id } })
            }
          },
        },
        {
          type: 'radioItem',
          content: 'Public',
          value: 'public',
          onSelect: () => {
            if (viewingAs === 'member') {
              void navigate({ to: '/profile/$workspaceId', params: { workspaceId: activeWorkspace.id } })
            }
          },
        },
      ],
    },
  ]

  return (
    <>
      <Flex direction={'column'} gap={'4'}>
        <DropdownMenu content={items} variant="soft" color="gray">
          <Button variant="ghost" color="gray" className="w-fit font-medium" size="2">
            <HiOutlineEye size={16} />
            View as:
            <span className="flex items-baseline gap-1 text-gray-12">
              {capitalize(viewingAs)} <HiChevronDown size={10} />
            </span>
          </Button>
        </DropdownMenu>
        <Text size={'1'} className="text-gray-11">
          You are currently viewing {activeWorkspace.name} as {viewingAs === 'member' ? 'a member' : 'public'}.
          <br />
          <br />
          Admins&nbsp;
          <Link
            to={'/workspaces/$workspaceId/settings/details'}
            params={{ workspaceId: activeWorkspace.id }}
            className="text-accent-11"
          >
            can edit this profile
          </Link>
          &nbsp;and change public visibility settings.
        </Text>
      </Flex>
      <Separator size={'4'} mt={'6'} mb={'4'} />
    </>
  )
}
