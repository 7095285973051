import { useQuery } from '@tanstack/react-query'
import { cacheDuration } from '../../shared'
import { queryFunctions, getQueryKey } from '~/services'

export function usePromoCode(workspaceId: string) {
  const { data } = useQuery({
    enabled: Boolean(workspaceId),
    queryKey: getQueryKey('workspaces_/$workspaceId_/promoCode', { workspaceId }),
    queryFn: async () => queryFunctions['workspaces_/$workspaceId_/promoCode']({ workspaceId }),
    staleTime: cacheDuration.medium,
  })

  return { promoCode: data ?? null }
}
