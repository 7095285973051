import { Flex, Grid, Text } from '@radix-ui/themes'
import { ReactNode } from 'react'
import { DateTime } from 'luxon'
import cn from 'classnames'

type HistoryItem = {
  timestamp: string
  content: ReactNode
  timelinePointElement?: ReactNode
}

type props = {
  items: HistoryItem[]
} & React.ComponentProps<typeof Flex>

export const History = ({ items }: props) => {
  return (
    <Flex direction={'column'}>
      {items.map((item, index) => (
        <Grid gapX={'4'} gapY={'2'} key={index} className="grid-cols-[auto,1fr]" align={'center'}>
          <Text size={'1'} color="gray" className="col-span-1 col-start-2">
            {DateTime.fromISO(item.timestamp).toRelative()}
          </Text>
          <div className={cn('col-span-1 col-start-2', { 'pb-3': index !== items.length - 1 })}>{item.content}</div>
          {/* Width has to be an odd number of else the line is off by 1 px */}
          <div
            className={cn('col-start-1 row-span-2 row-start-1 h-full w-px justify-self-center', {
              'pt-2': index === 0,
              'pb-0.5': index === items.length - 1,
            })}
          >
            <div className="h-full bg-gray-6" />
          </div>
          <Flex
            justify={'center'}
            align={'center'}
            className={cn('col-start-1 row-start-1 h-[10px] w-[10px]', {
              ['rounded-sm bg-gray-6']: !item.timelinePointElement,
            })}
          >
            {item.timelinePointElement}
          </Flex>
        </Grid>
      ))}
    </Flex>
  )
}
