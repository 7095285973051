import { launchOnboardingSurvey } from '~/features/marketing/components'

import axios from 'axios'
import { config } from '~/shared'

type AddAIUsageProps = { dollars: number; botId: string }
async function addAiUsage(props: AddAIUsageProps) {
  if (!props.dollars) {
    console.error('No dollar amount provided')
    return
  }
  if (!props.botId) {
    console.error('No botId provided')
    return
  }
  if (config.appEnv !== 'local') {
    return
  }

  try {
    await axios.post(`http://localhost:3600/v1/cognitive/devtools/track`, {
      botId: props.botId,
      path: '',
      timestamp: new Date().toISOString(),
      tokens: props.dollars * 1_000_000_000,
      model: 'gpt-4',
      origin: '',
      spendType: 'openai_spend',
      spendAmount: props.dollars * 1_000_000_000,
    })
    // eslint-disable-next-line no-console
    console.log('Successfully updated AI usage, reloading window')
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Does the workspace have an active subscription?')
    console.error(e)
  }
}

declare const globalThis: {
  __devTools?: {
    launchOnboardingSurvey: () => Promise<void>
    addAiUsage?: (props: AddAIUsageProps) => Promise<void>
  }
}

export const installDevTools = () => {
  globalThis['__devTools'] = {
    launchOnboardingSurvey,
    ...(config.appEnv === 'local' || config.appEnv === 'development'
      ? {
          addAiUsage,
        }
      : {}),
  }
}

export const uninstallDevTools = () => {
  globalThis['__devTools'] = undefined
}
