import { ChevronRightIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { WorkspaceSummary } from '../../workspaces/types'
import { WorkspaceProfilePicture } from '~/features/workspaces/components/WorkspaceProfilePicture'

type WorkspaceSelectProps = {
  workspace: WorkspaceSummary
  onWorkspaceSelected: (workspace: WorkspaceSummary) => void
}
export const WorkspaceSelectButton = ({ workspace, onWorkspaceSelected }: WorkspaceSelectProps) => {
  const disabled = workspace.botCount === 0

  return (
    <button
      key={workspace.id}
      className={cx(
        'group touch-none select-none rounded-md border border-transparent p-2',
        disabled
          ? 'text-gray-10'
          : 'cursor-pointer text-gray-11 hover:border-gray-6 hover:bg-gray-3 active:border-gray-8 active:bg-gray-3'
      )}
      onClick={() => {
        onWorkspaceSelected(workspace)
      }}
      disabled={disabled}
    >
      <div className={cx('flex items-center text-sm ')}>
        <WorkspaceProfilePicture
          className="mr-1 h-6 w-6 rounded-full"
          workspaceId={workspace.id}
          pictureUrl={workspace.profilePicture}
        />
        <span className=" truncate">{workspace.name}</span>
        {!disabled && (
          <ChevronRightIcon className={cx('ml-auto aspect-square h-4  opacity-0 group-hover:opacity-100')} />
        )}
      </div>
    </button>
  )
}
