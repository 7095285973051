import { getIntegrationsFromBot } from '~/features/bots/services'
import { useWebchatConfig } from '~/hooks'
import { useSuspenseQuery } from '~/services'
import { useUpdateWebchat } from '~/hooks/webchat/useUpdateWebchat'

export const useWebchat = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')
  const [config, setConfig] = useWebchatConfig({ botId, workspaceId })
  const {
    mutate: updateWebchatIntegration,
    mutateAsync: updateWebchatIntegrationAsync,
    isPending,
  } = useUpdateWebchat({ botId, workspaceId })

  return { config, setConfig, updateWebchatIntegration, updateWebchatIntegrationAsync, isPending, webchatIntegration }
}
