import { Node } from '@tiptap/pm/model'
import { Editor, NodeViewWrapper } from '@tiptap/react'
import { useCallback, useRef } from 'react'
import cn from 'classnames'

type ImageBlockViewProps = {
  editor: Editor
  getPos: () => number
  node: Node & {
    attrs: {
      src: string
    }
  }
  updateAttributes: (attrs: Record<string, string>) => void
}

export const ImageBlockView = (props: ImageBlockViewProps) => {
  const { editor, getPos, node } = props
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const { src } = node.attrs

  const wrapperClassName = cn({
    'ml-0 mr-auto': node.attrs.align === 'left',
    'mr-0 ml-auto': node.attrs.align === 'right',
    'mx-auto': node.attrs.align === 'center',
  })

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos())
  }, [getPos, editor.commands])

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width }} data-drag-handle>
        <div contentEditable={false} ref={imageWrapperRef}>
          <img
            className="forcus:border-gray-6 block overflow-hidden rounded-xl border-2 border-transparent transition-all duration-200 ease-in-out hover:border-gray-4 active:border-gray-6"
            src={src}
            alt=""
            onClick={onClick}
          />
        </div>
      </div>
    </NodeViewWrapper>
  )
}

export default ImageBlockView
