import React, { PropsWithChildren } from 'react'
import { Color } from '../types'
import { Box } from '@radix-ui/themes'

type Props = PropsWithChildren<{
  color?: Color
}>
export const ColorAccentProvider = ({ color, ...props }: Props) => {
  const accentStyle = color
    ? Array.from({ length: 12 }, (_, i) => `--accent-${i + 1}`).reduce((acc, cur, index) => {
        // @ts-ignore
        acc[cur] = `var(--${color}-${index + 1})`
        return acc
      }, {} as React.CSSProperties)
    : {}

  return <Box asChild {...props} style={{ ...accentStyle }} />
}
