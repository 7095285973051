import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Button, Input } from '../elements'

type Props = {
  value: string
  onSave: (newValue: string) => Promise<boolean> | boolean
  readonly?: boolean
  emptyButtonText?: string
  onCancel?: () => void
  validate?: (value: string) => boolean
  isValid?: boolean // if defined, it will override the validation result
  onChange?: (value: string) => void
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export function EditableName(props: Props) {
  const [inputValue, setInputValue] = useState(props.value)
  const [editing, setEditing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [isValid, setIsValid] = useState(props.isValid ?? true)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (props.isValid !== undefined && props.isValid !== isValid) {
      setIsValid(props.isValid)
    }
  }, [props.isValid])

  useEffect(() => {
    const candidate = inputRef.current?.value ?? ''
    if (!editing || candidate === props.value || !props.validate || props.isValid !== undefined) {
      return
    }
    setIsValid(props.validate?.(candidate))
  }, [editing, inputRef.current?.value])

  function handleEditClick() {
    setEditing(true)
    setInputValue(props.value)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  function handleSave() {
    if (!isValid || submitting) {
      return
    }

    if (inputValue === props.value) {
      setEditing(false)
      return
    }

    setSubmitting(true)

    const newName = inputValue.trim()

    void Promise.resolve(props.onSave(newName)).then((ok) => {
      if (ok) {
        setEditing(false)
        setInputValue(newName)
      }

      setSubmitting(false)
    })
  }

  if (props.readonly) {
    return (
      <div>
        <span>{props.value ?? 'Not set'}</span>
      </div>
    )
  }

  const cancel = () => {
    setEditing(false)
    setIsValid(true)
    props.onCancel?.()
    setInputValue(props.value)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      void handleSave()
    } else if (event.key === 'Escape') {
      cancel()
    }
  }

  return (
    <div className={'inline-block'}>
      <div className={cx('flex items-center', !editing ? 'inline-block' : 'hidden')}>
        {props.value && (
          <>
            {props.value}
            <button onClick={handleEditClick}>
              <PencilSquareIcon className="ml-1 h-7 w-7 rounded-md p-1 hover:bg-gray-4" />
            </button>
          </>
        )}
        {!props.value && (
          <Button size="small" variant="tertiary" onClick={handleEditClick}>
            {props.emptyButtonText ?? 'Edit'}
          </Button>
        )}
      </div>
      {editing && (
        <div className={'mr-2 flex gap-2'} data-testid="form">
          <Input
            ref={inputRef}
            placeholder="Enter some text"
            value={inputValue}
            disabled={submitting}
            onChange={(ev) => {
              setInputValue(ev.target.value)
              props.onChange?.(ev.target.value)
            }}
            onKeyDown={handleKeyPress}
          />
          <Button className="inline" size="small" disabled={!isValid || submitting} onClick={handleSave}>
            Save
          </Button>
          <Button className="inline" size="small" variant="tertiary" disabled={submitting} onClick={cancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}
