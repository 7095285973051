import { ComponentProps, FC } from 'react'
import { IssueCode, issueCodes } from '../types'
import {
  HiOutlineArrowPathRoundedSquare,
  HiOutlineCodeBracket,
  HiOutlineCommandLine,
  HiOutlineCubeTransparent,
  HiOutlineExclamationTriangle,
  HiOutlineNoSymbol,
  HiOutlineWrenchScrewdriver,
} from 'react-icons/hi2'
import { BsBraces } from 'react-icons/bs'

type Props = {
  code: string
} & ReactIconProps

type ReactIconProps = ComponentProps<typeof HiOutlineArrowPathRoundedSquare>

const icon: Record<IssueCode, FC<ReactIconProps>> = {
  EXECUTE_CODE_ISSUE: HiOutlineCodeBracket,
  INFINITE_LOOP_DETECTED_ISSUE: HiOutlineArrowPathRoundedSquare,
  INVALID_ACTION_PAYLOAD_ISSUE: BsBraces,
  INTEGRATION_DISABLED_ISSUE: HiOutlineNoSymbol,
  INTEGRATION_ACTION_EXECUTION_ISSUE: HiOutlineCubeTransparent,
  AGENT_DISABLED_ISSUE: HiOutlineNoSymbol,
  AGENT_ACTION_EXECUTION_ISSUE: HiOutlineCommandLine,
  AGENT_HOOK_EXECUTION_ISSUE: HiOutlineCommandLine,
  AGENT_REGISTRATION_ISSUE: HiOutlineWrenchScrewdriver,
  UNCAUGHT_EXCEPTION_ISSUE: HiOutlineExclamationTriangle,
  UNHANDLED_REJECTION_ISSUE: HiOutlineExclamationTriangle,
}

export const IssueIcon = ({ code, ...props }: Props) => {
  const Icon = issueCodes.includes(code as IssueCode) ? icon[code as IssueCode] : null

  return Icon ? <Icon {...props} /> : <HiOutlineExclamationTriangle {...props} />
}
