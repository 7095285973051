import { useDialogStore } from './dialogStore'
import { Dialog } from './Dialog'

export const DialogProvider = () => {
  const title = useDialogStore((state) => state.title)
  const content = useDialogStore((state) => state.content)
  const isOpen = useDialogStore((state) => state.show)
  const size = useDialogStore((state) => state.size)
  const setIsOpen = useDialogStore((state) => state.setShow)

  return (
    <Dialog title={title} isOpen={isOpen} size={size} setIsOpen={setIsOpen}>
      {content}
    </Dialog>
  )
}
