import { useEffect, type ReactNode } from 'react'
import { useLocalStore } from '~/stores'

type Props = { children: ReactNode }

export const DarkModeProvider = ({ children }: Props) => {
  const theme = useLocalStore((s) => s.theme)

  useEffect(() => {
    if (theme === 'auto') {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches
      document.body.classList.toggle('dark', prefersDark)
    } else {
      document.body.classList.toggle('dark', theme === 'dark')
    }
  }, [theme])

  return <>{children}</>
}
