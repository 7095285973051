import { ImageBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

export const ImageSchema = BaseMessage.extend({
  type: z.literal('image'),
  payload: z.object({
    imageUrl: z.string(),
  }),
})
  .transform(({ type, payload: { imageUrl }, ...props }) => ({ ...props, block: { type, url: imageUrl } }))
  .transform<MessageObject<ImageBlock>>(ToMessage)
