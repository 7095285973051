// this file was automatically generated, do not edit
/* eslint-disable */

export interface DeleteUserRequestHeaders {
  "x-user-key": string;
}

export interface DeleteUserRequestQuery {}

export interface DeleteUserRequestParams {}

export interface DeleteUserRequestBody {}

export type DeleteUserInput = DeleteUserRequestBody & DeleteUserRequestHeaders & DeleteUserRequestQuery & DeleteUserRequestParams

export type DeleteUserRequest = {
  headers: DeleteUserRequestHeaders;
  query: DeleteUserRequestQuery;
  params: DeleteUserRequestParams;
  body: DeleteUserRequestBody;
}

export const parseReq = (input: DeleteUserInput): DeleteUserRequest & { path: string } => {
  return {
    path: `/users/me`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: {  },
    body: {  },
  }
}

export interface DeleteUserResponse {}

