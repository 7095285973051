import { useId } from '@floating-ui/react-dom-interactions'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { CheckIcon } from '@radix-ui/react-icons'
import cx from 'classnames'
import { ComponentProps } from 'react'

export type CheckboxProps = ComponentProps<typeof CheckboxPrimitive.Root> & {
  label?: React.ReactNode
  id?: string
}

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Checkbox = ({
  children = <CheckIcon className="h-5 w-5 text-accent-11" />,
  label,
  id,
  ...props
}: CheckboxProps) => {
  const checkboxId = id ?? useId()
  return (
    <div className="flex items-center">
      <CheckboxPrimitive.Root
        {...props}
        id={checkboxId}
        className={cx(
          'mr-2 flex h-6 w-6 items-center justify-center rounded-md border border-gray-4 bg-gray-1 transition-colors ',
          'data-[state=checked]:border-accent-9 data-[state=checked]:bg-accent-2',
          props.className
        )}
      >
        <CheckboxPrimitive.Indicator>{children}</CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <label className="text-sm text-gray-11" htmlFor={checkboxId}>
          {label}
        </label>
      )}
    </div>
  )
}
