import { create } from 'zustand'

// This is a store to fit with the old logs component, once refactors are done, this store will be removed

export type LogsStore = {
  startDate: Record<string, Date>
  endDate: Record<string, Date>
  setStartDate: (id: string, date: Date) => void
  setEndDate: (id: string, date: Date) => void
}

export const useLogsStore = create<LogsStore>((set) => ({
  startDate: {},
  endDate: {},
  setStartDate: (id, date) => set((state) => ({ startDate: { ...state.startDate, [id]: date } })),
  setEndDate: (id, date) => set((state) => ({ endDate: { ...state.endDate, [id]: date } })),
}))
