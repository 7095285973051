import { forwardRef, type ComponentProps } from 'react'
import { Avatar } from '~/elementsv2'
import { useQuery } from '~/services'

type Props = { workspaceId: string; userId?: string } & Omit<ComponentProps<typeof Avatar>, 'pictureUrl' | 'name'>

export const UserAvatar = forwardRef(({ userId, workspaceId, ...props }: Props, ref: React.Ref<HTMLDivElement>) => {
  const members = useQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const user = members?.find((member) => member.userId === userId)
  return <Avatar ref={ref} name={user?.email} pictureUrl={user?.profilePicture} {...props} />
})
