import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import cookie from 'js-cookie'
import { config } from './configurations'
import { PAT_KEY } from './constants'

type ErrorResponseBody = {
  message: string
}

/**
 * @deprecated
 */
export function isHttpError(error: unknown): error is HttpError {
  return error instanceof HttpError
}

/**
 * @deprecated
 */
export function isNotFoundError(error: unknown): boolean {
  return isHttpError(error) && error.code === HttpError.Codes.NotFound
}

/**
 * @deprecated
 */
export function handleHttpErrors(error: Error): never {
  if (axios.isAxiosError(error)) {
    throw new HttpError(error)
  } else {
    throw error
  }
}

export class HttpError extends Error {
  readonly code: number | undefined
  readonly type: string | undefined
  readonly data: ErrorResponseBody
  readonly requestId: string | undefined

  static Codes = {
    BadRequest: 400,
    Unauthorized: 401,
    PaymentRequired: 402,
    Forbidden: 403,
    NotFound: 404,
    Conflict: 409,
  }

  constructor(axiosError: AxiosError) {
    const data = axiosError.response?.data as ErrorResponseBody
    super(data?.message ?? axiosError.message)
    this.code = axiosError.response?.status
    this.type = this.code ? axiosError.response?.statusText : 'Unknown'
    this.data = data
    this.requestId = axiosError.response?.headers['x-request-id'] // NOTE: Header name needs to be in lowercase.
  }

  get isClientError() {
    if (this.code === undefined) {
      return false
    }
    return this.code >= 400 && this.code <= 499
  }
}

export class HttpClient {
  /**
   * @param {AxiosRequestConfig} axiosConfig
   * @returns {HttpClient} an http client pool
   */
  static of(axiosConfig?: AxiosRequestConfig): HttpClient {
    const pat = config.usePat ? (config.pat ?? cookie.get(PAT_KEY)) : undefined

    const optionalXUserIdHeader = config.userId
    const client = axios.create({
      timeout: 100000,
      maxBodyLength: 100 * 1024 * 1024, // 100MB
      maxContentLength: 1024 * 1024, // 1MB
      headers: {
        ...axiosConfig?.headers,
        ...(optionalXUserIdHeader ? { 'x-user-id': optionalXUserIdHeader } : {}),
        ...(pat ? { Authorization: `Bearer ${pat}` } : {}),
        'Content-Type': 'application/json',
      },
      withCredentials: !pat,
      ...axiosConfig,
    })

    return new HttpClient(client)
  }

  private constructor(private client: AxiosInstance) {}

  /**
   * @param {AxiosRequestConfig} axiosConfig supply the url, method, headers, body and other request configurations of your choice
   * @returns {Promise<T>} a Promise wrapping type T
   * @description `await client.callApi<Resource>({ url: "https://resource.com/12345", method: "GET" })` would return Resource, or throw
   * @warning handle errors/validation outside
   */
  public async callApi<T>(axiosConfig: AxiosRequestConfig): Promise<T> {
    return await this.client
      .request<T>(axiosConfig)
      .then((response) => response.data)
      .catch((error: AxiosError) => {
        throw new HttpError(error)
      })
  }
}

export function isHttpUnauthorizedError(error: unknown) {
  return isHttpError(error) && error.code === HttpError.Codes.Unauthorized
}

/**
 * Can use the singleton instance throughout the project like `import { httpClient } from "./http-client.ts"`
 */
export const httpClient = HttpClient.of()
