import { Box, Flex, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseQuery as useTanstackSuspenseQuery, useQuery as useTanstackQuery } from '@tanstack/react-query'
import { useRouter } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import {
  HiOutlineArrowTopRightOnSquare,
  HiOutlineBolt,
  HiOutlineBoltSlash,
  HiOutlineCubeTransparent,
  HiOutlineEllipsisVertical,
  HiOutlineExclamationTriangle,
  HiOutlinePencilSquare,
  HiOutlineXMark,
} from 'react-icons/hi2'
import { match } from 'ts-pattern'
import { Boundary } from '~/componentsV2'
import { BotIcon } from '~/componentsV2/BotIcon'
import { Card, ContextMenu, DropdownMenu, Link, MenuItem } from '~/elementsv2'
import { AnalyticsWidget } from '~/features/analytics'
import { useDeleteBot, useIsAuthorized, useUpdateAlwaysAlive, useUsage } from '~/hooks'
import { listAllIssuesQueryOptions } from '~/queries'
import { getCdmStudioUrl } from '~/shared'
import { Icon } from '../../../elementsv2/Icon'
import { trackEvent } from '../../../providers'
import { getQueryOptions, showUpsellDialog, useQuery } from '../../../services'
import { cn, hashIdToNumber } from '../../../utils'
import { ruby, blue, yellow } from '@radix-ui/colors'
import { Button, IconButton, showConfirmationPrompt } from '@botpress/ui-kit'

type BotPreviewProps = {
  id: string
  name?: string
  workspaceId: string
  userId: string
  showChart?: boolean
  location?: 'recent_bots' | 'all_bots'
  className?: string
}
export const BotPreviewCard = ({
  id: botId,
  workspaceId,
  name,
  userId,
  location,
  className,
  showChart = true,
}: BotPreviewProps) => {
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const authorize = useIsAuthorized({ userId, workspaceId: workspace.id })
  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId: workspace.id })
  const { mutate: deleteBot } = useDeleteBot(workspace.id)
  const { mutate: updateAlwaysAlive, isPending: alwaysAlivePending } = useUpdateAlwaysAlive()

  const router = useRouter()

  const isAuthorizedToUpdateBot = authorize('bots.update')
  const isAuthorizedToOpenStudio = authorize('studio.view') || authorize('studio.edit')
  const { always_alive: alwaysAliveUsage } = useUsage({ workspaceId: workspace.id, quotas: ['always_alive'] })
  const alwaysAliveDisabled = alwaysAliveUsage.value >= alwaysAliveUsage.quota && !bot?.alwaysAlive

  const integrations = Object.entries(bot?.integrations ?? {}).map(([botIntegrationId, integration]) => ({
    botIntegrationId,
    ...integration,
  }))
  const issues = useTanstackQuery(listAllIssuesQueryOptions({ botId, workspaceId: workspace.id })).data

  const issuesCount = issues?.length ?? 0

  const colors = [ruby.ruby8, blue.blue8, yellow.yellow8]
  const colorIndex = hashIdToNumber(botId, colors.length)

  const openInStudio = () => {
    window.open(getCdmStudioUrl(botId), '_blank')
  }

  const botActionsMenuItems: MenuItem[] = useMemo(
    () => [
      {
        type: 'item',
        hidden: !isAuthorizedToUpdateBot,
        content: bot?.alwaysAlive ? 'Disable Always Alive' : 'Enable Always Alive',
        onSelect: () => {
          alwaysAliveDisabled
            ? showUpsellDialog({
                quota: 'always_alive',
                workspaceId: workspace.id,
              }).then(
                () =>
                  void router.navigate({
                    to: '/workspaces/$workspaceId/settings/billing',
                    params: { workspaceId: workspace.id },
                  })
              )
            : showConfirmationPrompt(
                <Text>
                  Are you sure you want to {bot?.alwaysAlive ? 'disable' : 'enable'} the always alive mode for the bot{' '}
                  <Text weight={'bold'}>{name ?? bot?.name}</Text>?
                </Text>,
                {
                  title: bot?.alwaysAlive ? 'Disable Always Alive' : 'Enable Always Alive',
                }
              ).then(() =>
                updateAlwaysAlive({
                  botId,
                  workspaceId: workspace.id,
                  alwaysAlive: !bot?.alwaysAlive,
                })
              )
        },
        trailingIcon: bot?.alwaysAlive ? <HiOutlineBoltSlash /> : <HiOutlineBolt />,
      },
      {
        type: 'item',
        content: 'Edit in studio',
        trailingIcon: <HiOutlineArrowTopRightOnSquare />,
        hidden: !isAuthorizedToOpenStudio,
        onSelect: openInStudio,
      },
      { type: 'separator', hidden: !isAuthorizedToUpdateBot },
      {
        type: 'item',
        hidden: !isAuthorizedToUpdateBot,
        content: 'Delete',
        trailingIcon: <HiOutlineXMark />,
        color: 'red',
        onSelect: () =>
          showConfirmationPrompt(`Are you sure you want to delete the bot "${name ?? bot?.name}"?`, {
            title: 'Delete bot',
            variant: 'danger',
          }).then(() =>
            deleteBot({
              id: botId,
              options: {},
            })
          ),
      },
    ],
    [botId, name ?? bot?.name, deleteBot, bot?.alwaysAlive]
  )

  return (
    <ContextMenu content={botActionsMenuItems} variant="soft" color="gray">
      <Card className={cn('group flex flex-col justify-between gap-4 p-3 @container', className)}>
        <Flex gap={'2'} align={'center'}>
          <BotIcon size="3" id={botId} className="flex-none" />
          <Link
            className="mr-auto group-hover:truncate"
            weight={'medium'}
            to="/workspaces/$workspaceId/bots/$botId"
            params={{ workspaceId: workspace.id, botId }}
            onClick={() => trackEvent({ type: 'navigate_to_bot', from: location })}
          >
            {name ?? bot?.name}
          </Link>
          <Button
            onClick={openInStudio}
            trailing={<Icon icon={HiOutlinePencilSquare} />}
            className="hidden group-hover:flex"
            variant="ghost"
          >
            Edit in Studio
          </Button>
          <DropdownMenu content={botActionsMenuItems} variant="soft" color="gray">
            <IconButton size={'1'} variant="minimal" color="gray" icon={HiOutlineEllipsisVertical} />
          </DropdownMenu>
        </Flex>
        {showChart && (
          <Boundary className="ml-auto" suspenseFallback={null} onError={() => null}>
            <Box className="w-full self-center">
              <AnalyticsWidget color={colors[colorIndex]} botId={botId} workspaceId={workspace.id} />
            </Box>
          </Boundary>
        )}
        <Flex align={'center'} gap={'2'}>
          <Tooltip content={bot?.alwaysAlive ? 'Always alive' : 'Not always alive'}>
            {match({ alwaysAlive: !!bot?.alwaysAlive, alwaysAlivePending })
              .with({ alwaysAlivePending: true, alwaysAlive: false }, () => (
                <Icon variant="soft" muted className="animate-pulse" icon={HiOutlineBolt} />
              ))
              .with({ alwaysAlivePending: true, alwaysAlive: true }, () => (
                <Icon variant="soft" color="gray" muted className="animate-pulse" icon={HiOutlineBoltSlash} />
              ))
              .with({ alwaysAlive: true }, () => <Icon variant="soft" icon={HiOutlineBolt} />)
              .with({ alwaysAlive: false }, () => <Icon icon={HiOutlineBoltSlash} padding muted color="gray" />)
              .exhaustive()}
          </Tooltip>
          <Flex align={'center'} gap={'1'}>
            <Icon variant="outline" color="gray" icon={HiOutlineCubeTransparent} />
            <Text size={'1'} color="gray">
              {integrations.length}
            </Text>
          </Flex>
          <Link
            className="text-gray-11 no-underline hover:text-accent-11"
            to="/workspaces/$workspaceId/bots/$botId/issues"
            params={{ botId, workspaceId: workspace.id }}
          >
            <Flex
              align={'center'}
              gap={'1'}
              className={cn({
                'rounded bg-red-3 p-0.5 px-1 text-red-11 hover:bg-red-4': issuesCount > 0,
                'text-gray-11': issuesCount === 0,
              })}
            >
              <HiOutlineExclamationTriangle />
              <Text size={'1'}>{issuesCount}</Text>
            </Flex>
          </Link>
          <Text size={'1'} ml={'auto'} color="gray">
            {bot?.deployedAt ? <>Deployed {DateTime.fromISO(bot?.deployedAt).toRelative()}</> : <>Not deployed</>}
          </Text>
        </Flex>
      </Card>
    </ContextMenu>
  )
}
