import { z } from 'zod'

export const BaseMessage = z.object({
  id: z.string(),
  createdAt: z.string(),
  direction: z.string(),
  userId: z.string(),
  conversationId: z.string(),
  tags: z.record(z.string()),
})
