import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/$')({
  component: WorkspaceComponent,
})

function WorkspaceComponent() {
  const { workspaceId, integrationId } = Route.useParams()
  return (
    <Navigate
      to="/workspaces/$workspaceId/integrations/$integrationId"
      params={{ workspaceId, integrationId }}
      replace
    />
  )
}
