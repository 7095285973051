// this file was automatically generated, do not edit
/* eslint-disable */

export interface DeleteConversationRequestHeaders {
  "x-user-key": string;
}

export interface DeleteConversationRequestQuery {}

export interface DeleteConversationRequestParams {
  id: string;
}

export interface DeleteConversationRequestBody {}

export type DeleteConversationInput = DeleteConversationRequestBody & DeleteConversationRequestHeaders & DeleteConversationRequestQuery & DeleteConversationRequestParams

export type DeleteConversationRequest = {
  headers: DeleteConversationRequestHeaders;
  query: DeleteConversationRequestQuery;
  params: DeleteConversationRequestParams;
  body: DeleteConversationRequestBody;
}

export const parseReq = (input: DeleteConversationInput): DeleteConversationRequest & { path: string } => {
  return {
    path: `/conversations/${encodeURIComponent(input['id'])}`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface DeleteConversationResponse {}

