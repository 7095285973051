import { Role, RoleNames } from '../../../features/users/types'

export type RoleOption = { role: Role; label: string; description: string }

export const RoleOptions: RoleOption[] = [
  {
    role: 'viewer',
    label: RoleNames.viewer,
    description: 'Can view chatbots in this workspace but not make any changes.',
  },
  {
    role: 'billing',
    label: RoleNames.billing,
    description: 'Can view and change billing information of this workspace.',
  },
  {
    role: 'developer',
    label: RoleNames.developer,
    description: 'Can create, view and update chatbots in this workspace. Can only delete the chatbots they created.',
  },
  {
    role: 'manager',
    label: RoleNames.manager,
    description:
      'Can create, view, update, and delete all chatbots in this workspace, view audit records, and view/change billing information.',
  },
  {
    role: 'administrator',
    label: RoleNames.administrator,
    description:
      'Can add/remove workspace collaborators, create and manage all chatbots in this workspace, view audit records, and view/change billing information.',
  },
]
