import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useQueryKeys } from '..'
import { getQueryKey, showErrorToast, showSuccessToast } from '../../services'
import { getApiClient } from '~/client'

type MakeIntegrationPublicProps = {
  integrationId: string
  workspaceId: string
  onSuccess?: () => void
}

export const useMakeIntegrationPublic = ({ workspaceId, integrationId, onSuccess }: MakeIntegrationPublicProps) => {
  const queryClient = useQueryClient()
  const { listWorkspaceIntegrations, getIntegrationByName, getIntegration } = useQueryKeys()

  return useMutation({
    mutationFn: async () => {
      return getApiClient(workspaceId).updateIntegration({
        id: integrationId,
        public: true,
      })
    },
    onSuccess: async ({ integration }) => {
      const queryKeys = [
        getQueryKey('integrations_'),
        listWorkspaceIntegrations(workspaceId),
        getIntegration(integration.id),
        getIntegrationByName(integration.name, integration.version),
      ]
      // we have to do this because the way we structured the integration query keys won't invalidate properly if I use [INTEGRATION]
      await Promise.all(queryKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey })))
      showSuccessToast('Integration updated successfully')
      onSuccess?.()
    },
  })
}

type RequestIntegrationVerificationProps = {
  integrationId: string
  workspaceId: string
}

export const useRequestIntegrationVerification = ({
  workspaceId,
  integrationId,
}: RequestIntegrationVerificationProps) => {
  const queryClient = useQueryClient()
  const { listWorkspaceIntegrations, getIntegration } = useQueryKeys()

  return useMutation({
    mutationFn: async () => {
      return getApiClient(workspaceId).requestIntegrationVerification({ integrationId })
    },
    onSuccess: async () => {
      const queryKeys = [
        getQueryKey('integrations_'),
        listWorkspaceIntegrations(workspaceId),
        getIntegration(integrationId),
      ]
      // we have to do this because the way we structured the integration query keys won't invalidate properly if I use [INTEGRATION]
      await Promise.all(queryKeys.map((queryKey) => queryClient.invalidateQueries({ queryKey })))
      showSuccessToast('Integration updated successfully')
    },
    onError: (err) => showErrorToast(err.message),
  })
}
