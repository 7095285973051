import { createRef } from 'react'
import { create } from 'zustand'

export type PortalStore = {
  bannerPortalRef: React.RefObject<HTMLDivElement>
  pageActionsPortalRef: React.RefObject<HTMLDivElement>
}

export const usePortalStore = create<PortalStore>(() => ({
  bannerPortalRef: createRef<HTMLDivElement>(),
  pageActionsPortalRef: createRef<HTMLDivElement>(),
}))
