import { isAuthorized } from '../features/users/services'
import { Permission } from '../features/users/types'
import { useWorkspaceMembers } from './users'

export function useIsAuthorized({ workspaceId, userId }: { workspaceId: string; userId?: string }) {
  const { data: collaborators } = useWorkspaceMembers({ workspaceId })

  const workspaceMember = collaborators?.find((collaborator) => collaborator.userId === userId)

  return (permission: Permission): boolean | undefined => {
    if (!workspaceMember) {
      return undefined
    }

    return isAuthorized({ permission, role: workspaceMember?.role })
  }
}
