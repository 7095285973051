import { useParams, useRouter } from '@tanstack/react-router'
import type { ClientReturn } from 'botpress-client'
import type { ReactNode } from 'react'
import { BotIcon } from '~/componentsV2/BotIcon'
import { DropdownMenu, type MenuItem } from '~/elementsv2'

type Props = { className?: string; bots: ClientReturn<'listBots'>['bots']; children: ReactNode }

export const BotSelectorMenu = ({ bots, ...props }: Props) => {
  const navigate = useRouter().navigate
  const { workspaceId, botId } = useParams({ strict: false })

  if (!workspaceId) {
    return null
  }

  const botMenuItems: MenuItem[] = [
    {
      type: 'radioGroup',
      value: botId,
      items:
        bots?.map((bot) => {
          return {
            content: bot.name,
            type: 'radioItem',
            value: bot.id,
            onSelect: () =>
              navigate({ to: '/workspaces/$workspaceId/bots/$botId', params: { workspaceId, botId: bot.id } }),
            leadingIcon: <BotIcon id={bot.id} size={'2'} />,
          }
        }) ?? [],
    },
  ]
  return <DropdownMenu {...props} disabled={!bots.length} variant="soft" color="gray" content={botMenuItems} />
}
