import { queryOptions } from '@tanstack/react-query'
import { listIntegrationApiKeys } from '../features/integrations/services'
import { useQueryKeys } from '../hooks'

type ListIntegrationApiKeysQueryProps = Parameters<typeof listIntegrationApiKeys>[0]

export function listIntegrationApiKeysQueryOptions(props: ListIntegrationApiKeysQueryProps) {
  const { listIntegrationApiKeys: listIntegrationApiKeysQueryKey } = useQueryKeys()
  return queryOptions({
    queryKey: listIntegrationApiKeysQueryKey(props.integrationId),
    queryFn: async () => listIntegrationApiKeys(props),
    meta: {
      suppressError: true,
    },
  })
}
