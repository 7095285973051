// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetConversationRequestHeaders {
  "x-user-key": string;
}

export interface GetConversationRequestQuery {}

export interface GetConversationRequestParams {
  id: string;
}

export interface GetConversationRequestBody {}

export type GetConversationInput = GetConversationRequestBody & GetConversationRequestHeaders & GetConversationRequestQuery & GetConversationRequestParams

export type GetConversationRequest = {
  headers: GetConversationRequestHeaders;
  query: GetConversationRequestQuery;
  params: GetConversationRequestParams;
  body: GetConversationRequestBody;
}

export const parseReq = (input: GetConversationInput): GetConversationRequest & { path: string } => {
  return {
    path: `/conversations/${encodeURIComponent(input['id'])}`,
    headers: { 'x-user-key': input['x-user-key'] },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetConversationResponse {
  conversation: {
    /**
     * Id of the [Conversation](#schema_conversation)
     */
    id: string;
    /**
     * Creation date of the [Conversation](#schema_conversation) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Conversation](#schema_conversation) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Title of the [Conversation](#schema_conversation)
     */
    title?: string;
    /**
     * Description of the [Conversation](#schema_conversation)
     */
    description?: string;
    /**
     * Status of the [Conversation](#schema_conversation)
     */
    status: "pending" | "assigned" | "timed_out" | "cancelled" | "solved" | "unknown";
    assignee?: {
      /**
       * Id of the [User](#schema_user)
       */
      id: string;
      /**
       * workspace member id of the [User](#schema_user)
       */
      workspaceMemberId?: string;
    };
    /**
     * User who initialy started the hitl [Conversation](#schema_conversation)
     */
    requester?: {
      id: string;
    };
    /**
     * Additional dynamic information about the [Conversation](#schema_conversation)
     */
    tags: {
      [k: string]: string;
    };
  };
}

