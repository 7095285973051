import { Flex } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { CodeSnippet } from '~/components'
import { Callout } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchatEmbedScript } from '~/hooks/webchat/useWebchatEmbedScript'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/share')({
  component: Component,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()

  const { embedScript, shareableLink } = useWebchatEmbedScript({ botId, workspaceId })

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection
        title="Shareable Link"
        description="Share the following link with people that would like to quickly test your chatbot."
        linkUrl={shareableLink ?? ''}
        linkLabel="View"
      >
        {shareableLink === null ? (
          <Callout color="blue">Your webchat is not yet configured. Please configure it first.</Callout>
        ) : (
          <CodeSnippet code={shareableLink ?? ''} />
        )}
      </ConfigSection>
      <ConfigSection
        title="Embed code"
        description="Copy and paste this code on your webpage."
        linkUrl="https://botpress.com/docs/developers/webchat-v2/embedded/"
        linkLabel="Documentation"
      >
        {embedScript === null ? (
          <Callout color="blue">Your webchat is not yet configured. Please configure it first.</Callout>
        ) : (
          <CodeSnippet code={embedScript ?? ''} />
        )}
      </ConfigSection>
    </Flex>
  )
}
