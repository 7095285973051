import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getClient } from '~/features/hitl/client'
import { useQueryKeys } from '~/hooks'
import { showErrorToast, showSuccessToast } from '~/services'

export const useSetAssignee = ({ workspaceId, botId }: { workspaceId: string; botId: string }) => {
  const queryClient = useQueryClient()
  const { listHitlConversations } = useQueryKeys()

  return useMutation({
    mutationFn: async ({
      workspaceMemberId,
      conversationId,
    }: {
      workspaceMemberId: string | null
      conversationId: string
    }) => {
      const client = await getClient({ workspaceId, botId })
      await client.setAssignee({
        conversationId,
        workspaceMemberId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: listHitlConversations({ botId, workspaceId }) })
      showSuccessToast('Assignee updated')
    },
    onError: () => {
      showErrorToast('Failed to update assignee')
    },
  })
}
