import { Button, IconButton } from '@botpress/ui-kit'
import { Flex, Link, Switch, Text } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { HiOutlineXMark } from 'react-icons/hi2'
import { CodeSnippet } from '~/components'
import { Callout, Input } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchat, useWebchatV2 } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey, getQueryOptions } from '~/services'
import { config as globalConfig } from '~/shared'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/advanced-settings')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { config, isPending, setConfig, updateWebchatIntegration, webchatIntegration } = useWebchat({
    botId,
    workspaceId,
  })
  const webhookId = webchatIntegration?.webhookId
  const { isWebchatV2Enabled } = useWebchatV2()

  const workspace = useQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  return (
    <Flex direction={'column'} gap={'6'}>
      {isWebchatV2Enabled ? (
        <Flex direction={'column'} gap={'6'}>
          {webhookId && (
            <ConfigSection
              title="Client Id"
              description="Client id is used to identify the client in the webchat. Only use this if you want to use the client without the webchat interface."
              linkUrl="https://botpress.com/docs/developers/webchat-v2/javascript-client/"
              linkLabel="Documentation"
            >
              <CodeSnippet code={webhookId} />
            </ConfigSection>
          )}
          <ConfigSection
            title="Allowed Origins"
            description="List of origins that are allowed to access the webchat. Leave an empty list to allow all origins."
          >
            {workspace?.plan === 'community' ? (
              <Callout className="text-center">
                <div className="mb-3">This feature is only available for Team and Enterprise plan.</div>
                <Link href={`/workspaces/${workspaceId}/settings/billing/plans`}>
                  <Button variant="soft" size={'1'}>
                    Upgrade Now
                  </Button>
                </Link>
              </Callout>
            ) : (
              <Callout className="mb-2" color="amber">
                You must include <b>{globalConfig.dashboardUrl}</b> to still be able to use the chat tab in the
                dashboard.
              </Callout>
            )}
            <div className="mb-4 flex flex-col gap-2">
              {config.allowedOrigins?.map((origin: string, index: number) => (
                <div key={index} className="flex items-center gap-2">
                  <Input
                    placeholder="http://example.com"
                    value={origin}
                    onChange={(e) => {
                      const newOrigins = [...(config.allowedOrigins ?? [])]
                      newOrigins[index] = e.target.value
                      setConfig((prevData) => ({ ...prevData, allowedOrigins: newOrigins }))
                    }}
                  />
                  <IconButton
                    icon={HiOutlineXMark}
                    variant="minimal"
                    color="red"
                    onClick={() => {
                      const newOrigins = [...(config.allowedOrigins ?? [])]
                      newOrigins.splice(index, 1)
                      setConfig((prevData) => ({ ...prevData, allowedOrigins: newOrigins }))
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="flex">
              {workspace && workspace.plan !== 'community' && (
                <Button
                  variant="ghost"
                  onClick={() =>
                    setConfig((prevData) => ({ ...prevData, allowedOrigins: [...(prevData.allowedOrigins ?? []), ''] }))
                  }
                >
                  Add Origin
                </Button>
              )}
            </div>
          </ConfigSection>
        </Flex>
      ) : (
        <ConfigSection title="Advanced Settings" description="Configure advanced settings for your webchat.">
          <Flex direction={'column'} gap={'4'}>
            <Flex gap={'2'}>
              <Switch
                checked={config.showBotInfoPage}
                onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, showBotInfoPage: checked }))}
              />
              <Text size={'2'} color="gray">
                Show Bot Information
              </Text>
            </Flex>
            <Flex gap={'2'}>
              <Switch
                checked={config.showPoweredBy}
                onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, showPoweredBy: checked }))}
              />
              <Text size={'2'} color="gray">
                Show "Created with Botpress"
              </Text>
            </Flex>
            <Flex gap={'2'}>
              <Switch
                checked={config.useSessionStorage}
                onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, useSessionStorage: checked }))}
              />
              <Text size={'2'} color="gray">
                Clear conversation on exit
              </Text>
            </Flex>
            <Flex gap={'2'}>
              <Switch
                checked={config.enableConversationDeletion}
                onCheckedChange={(checked) =>
                  setConfig((prevData) => ({ ...prevData, enableConversationDeletion: checked }))
                }
              />
              <Text size={'2'} color="gray">
                Allow user to delete conversation
              </Text>
            </Flex>
          </Flex>
        </ConfigSection>
      )}
      <Button
        loading={isPending}
        onClick={() => {
          updateWebchatIntegration(config)
          void queryClient.invalidateQueries({
            queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
              workspaceId,
              botId,
            }),
          })
        }}
        className="self-end"
      >
        <Text>Save</Text>
      </Button>
    </Flex>
  )
}
