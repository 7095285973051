import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getClient } from '~/features/hitl/client'
import { useQueryKeys } from '~/hooks'
import { showErrorToast, showSuccessToast } from '~/services'

type Params = {
  workspaceId: string
  botId: string
  onSuccess?: () => void
  onError?: () => void
}

export const useCloseConversation = ({ workspaceId, botId, onSuccess, onError }: Params) => {
  const queryClient = useQueryClient()
  const { listHitlConversations } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ conversationId }: { conversationId: string }) => {
      const client = await getClient({ workspaceId, botId })
      await client.solveTicket({
        conversationId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: listHitlConversations({ botId, workspaceId }) })
      showSuccessToast('Conversation closed')
      onSuccess?.()
    },
    onError: () => {
      showErrorToast('Failed to close Conversation')
      onError?.()
    },
  })
}
