import { withJsonFormsControlProps } from '@jsonforms/react'
import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'
import { InputErrorMessage } from '../../../components'
import { Input } from '../../../elements'

export const TextControl = withJsonFormsControlProps(
  ({ label, data, handleChange, path, description, enabled, errors }) => {
    const [modified, setModified] = useState(false)
    return (
      <div className="relative isolate">
        <Input
          className="z-10"
          label={label}
          disabled={!enabled}
          value={data ?? ''}
          placeholder={description}
          onChange={(event) => {
            setModified(true)
            handleChange(path, event.target.value || undefined)
          }}
        />
        <AnimatePresence>
          {modified && errors && errors.length > 0 && <InputErrorMessage>{errors}</InputErrorMessage>}
        </AnimatePresence>
      </div>
    )
  }
)
