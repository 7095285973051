import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Command, MenuListProps } from './types'
import { DropdownButton } from '~/componentsV2/RichTextEditor/components/ui/Dropdown'
import { Card } from '~/elementsv2'

export const MenuList = React.forwardRef((props: MenuListProps, ref) => {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const activeItem = useRef<HTMLButtonElement>(null)
  const [selectedCommandIndex, setSelectedCommandIndex] = useState(0)

  useEffect(() => {
    setSelectedCommandIndex(0)
  }, [props.items])

  const selectItem = useCallback(
    (commandIndex: number) => {
      const command = props.items[commandIndex]!
      props.command(command)
    },
    [props]
  )

  React.useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: React.KeyboardEvent }) => {
      if (!props.items.length || !['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        return false
      }

      if (event.key === 'ArrowDown') {
        const commands = props.items

        let newCommandIndex = selectedCommandIndex + 1

        if (commands.length - 1 < newCommandIndex) {
          newCommandIndex = 0
        }

        setSelectedCommandIndex(newCommandIndex)
      } else if (event.key === 'ArrowUp') {
        let newCommandIndex = selectedCommandIndex - 1

        if (newCommandIndex < 0) {
          newCommandIndex = props.items.length - 1
        }

        setSelectedCommandIndex(newCommandIndex)
      } else if (event.key === 'Enter') {
        if (selectedCommandIndex === -1) {
          return false
        }

        selectItem(selectedCommandIndex)
      }
      return true
    },
  }))

  useEffect(() => {
    if (activeItem.current && scrollContainer.current) {
      const offsetTop = activeItem.current.offsetTop
      const offsetHeight = activeItem.current.offsetHeight

      scrollContainer.current.scrollTop = offsetTop - offsetHeight
    }
  }, [selectedCommandIndex])

  const createCommandClickHandler = useCallback(
    (commandIndex: number) => {
      return () => {
        selectItem(commandIndex)
      }
    },
    [selectItem]
  )

  if (!props.items.length) {
    return null
  }

  return (
    <Card ref={scrollContainer} className="bg-gray-1 px-4 py-2 shadow-sm">
      <div className="grid grid-cols-1 gap-0.5">
        <div className="col-[1/-1] mx-2 mt-4 select-none text-[0.65rem] font-semibold uppercase tracking-wider text-gray-11 first:mt-0.5">
          Insert
        </div>
        {props.items.map((command: Command, commandIndex: number) => (
          <DropdownButton
            key={`${command.label}`}
            isActive={selectedCommandIndex === commandIndex}
            onClick={createCommandClickHandler(commandIndex)}
          >
            {command.icon}
            {command.label}
          </DropdownButton>
        ))}
      </div>
    </Card>
  )
})

export default MenuList
