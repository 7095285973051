import { BillingApiClient, PromoCode } from '~/features/billing/services'
import { useState } from 'react'
import { useDebounce } from 'react-use'
import _ from 'lodash'

export const useSearchPromoCode = ({ workspaceId, query }: { workspaceId: string; query: string }) => {
  const [promoCodeResult, setPromoCodeResult] = useState<PromoCode | null>(null)
  const [invalid, setInvalid] = useState<boolean>(false)

  const searchItem = async () => {
    if (!query) {
      return setPromoCodeResult(null)
    }
    const resp = await BillingApiClient.searchPromoCode({ workspaceId, query })
    if (_.isEmpty(resp)) {
      setPromoCodeResult(null)
      setInvalid(true)
    } else {
      setPromoCodeResult(resp as PromoCode)
      setInvalid(false)
    }
  }

  useDebounce(searchItem, 300, [query])

  return { promoCodeResult, invalid }
}
