import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'
import { Text } from '@radix-ui/themes'

export const OAuthNeutral = () => (
  <>
    <div className="mt-auto w-min rounded-full border-2 border-gray-8 bg-gray-2 p-6">
      <EllipsisHorizontalIcon className="h-16 w-16 text-gray-9" />
    </div>
    <Text color="gray" size={'4'} mt={'6'}>
      Hold on!
    </Text>
  </>
)
