import { Client, isApiError } from 'botpress-client'
import { isAxiosError } from 'axios'
import cookie from 'js-cookie'
import { PAT_KEY, config } from './shared'

const API_BASE_URL = config.apiBaseUrl

const HTTP_UNAUTHORIZED = 401

class ApiClient {
  private static _instances: { [key: string]: Client } = {}
  private static _botInstances: { [key: string]: Client } = {}
  private static _initialClient: Client

  private constructor() {
    ApiClient._initialClient = new Client({ apiUrl: API_BASE_URL })
  }

  public static getInstance(workspaceId: string): Client {
    if (ApiClient._instances[workspaceId]) {
      return ApiClient._instances[workspaceId]!
    } else {
      ApiClient._instances[workspaceId] = new Client({
        apiUrl: API_BASE_URL,
        workspaceId,
        token: config.usePat ? (config.pat ?? cookie.get(PAT_KEY)) : undefined,
      })
      return ApiClient._instances[workspaceId]!
    }
  }

  public static getInstanceForBot(props: { workspaceId: string; botId: string }): Client {
    const { workspaceId, botId } = props
    const key = `${workspaceId}-${botId}`

    const client = ApiClient._botInstances[key]

    if (client) {
      return client
    }

    ApiClient._botInstances[key] = new Client({
      apiUrl: API_BASE_URL,
      workspaceId,
      token: config.usePat ? (config.pat ?? cookie.get(PAT_KEY)) : undefined,
      botId,
    })

    return ApiClient._botInstances[key]!
  }

  public static getGenericClient(): Client {
    if (ApiClient._initialClient) {
      return ApiClient._initialClient
    } else {
      ApiClient._initialClient = new Client({
        apiUrl: API_BASE_URL,
        token: config.usePat ? (config.pat ?? cookie.get(PAT_KEY)) : undefined,
      })
      return ApiClient._initialClient
    }
  }
}

export const getApiClient = ApiClient.getInstance
export const getApiClientForBot = ApiClient.getInstanceForBot
export const getGenericClient = ApiClient.getGenericClient

export function isHttpError(error: Error | unknown | undefined, httpCode: number) {
  return isAxiosError(error) && error.response?.status === httpCode
}

export function isHttpUnauthorizedError(error: Error | unknown | undefined) {
  if (isApiError(error)) {
    return error.type === 'Unauthorized' || isHttpError(error.error, HTTP_UNAUTHORIZED)
  }
  return isHttpError(error, HTTP_UNAUTHORIZED)
}

export function isForbiddenError(error: Error | unknown | undefined) {
  if (isApiError(error)) {
    return error.type === 'Forbidden' || isHttpError(error.error, 403)
  }
  return isHttpError(error, 403)
}

export function isBreakingChangeError(error: Error | unknown | undefined) {
  return isApiError(error) && error.type === 'BreakingChanges'
}

export type TApiClient = ReturnType<typeof getApiClient>
