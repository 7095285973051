export function recursiveSum(obj1: any, obj2: any): any {
  const result: any = {}

  // Get all unique keys from both objects
  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)])

  // Iterate over each key in the set using a for...of loop
  for (const key of keys) {
    const value1 = obj1[key]
    const value2 = obj2[key]

    // If both values are objects, recurse
    if (typeof value1 === 'object' && typeof value2 === 'object' && value1 !== null && value2 !== null) {
      result[key] = recursiveSum(value1, value2)
    } else if (typeof value1 === 'number' && typeof value2 === 'number') {
      result[key] = value1 + value2
    } else if (value1 !== undefined) {
      result[key] = value1
    } else if (value2 !== undefined) {
      result[key] = value2
    }
  }

  return result
}
