import { Link as ThemeLink } from '@radix-ui/themes'
import { createLink } from '@tanstack/react-router'

import { ComponentProps, forwardRef, type ForwardedRef } from 'react'

type Props = ComponentProps<typeof ThemeLink>

export const Link = createLink(
  forwardRef((props: Props, ref: ForwardedRef<HTMLAnchorElement>) => {
    return <ThemeLink {...props} ref={ref} />
  })
)
