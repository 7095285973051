import { Flex } from '@radix-ui/themes'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { WorkspaceDetails } from '~/features/settings/components/WorkspaceDetails'
import { ProfileSettings } from '~/features/settings/components/ProfileSettings'
import { DangerZone } from '~/features/settings/components'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useIsAuthorized } from '../../../../hooks'
import { getQueryOptions } from '~/services'

export const Route = createFileRoute('/workspaces/$workspaceId/settings/details')({
  component: () => {
    const { user } = Route.useRouteContext()
    const { workspaceId } = Route.useParams()
    const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })
    const workspace = useSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
    const navigate = useNavigate()
    const navigateToHome = async () => await navigate({ to: '/' })

    return (
      <Flex direction={'column'} mx={'auto'} align={'center'} gap={'5'}>
        <WorkspaceDetails workspace={workspace} isAuthorized={isAuthorized} navigateToHome={navigateToHome} />
        <ProfileSettings workspace={workspace} isAuthorized={isAuthorized} />
        <DangerZone workspace={workspace} isAuthorized={isAuthorized} navigateToHome={navigateToHome} />
      </Flex>
    )
  },
})
