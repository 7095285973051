import { ChevronRightIcon } from '@heroicons/react/24/outline'
import * as AccordionPrimitive from '@radix-ui/react-accordion'

import cx from 'classnames'
import { ComponentProps, ComponentPropsWithoutRef, ElementRef, forwardRef, useEffect, useState } from 'react'

type AccordionRenderProps = {
  values: string[]
  setValue: (values: string[]) => void
}
type AccordionProps = {
  defaultValue?: string[]
  values?: string[]
  children: React.ReactNode | ((props: AccordionRenderProps) => React.ReactNode)
} & Omit<ComponentProps<typeof AccordionPrimitive.Root>, 'defaultValue' | 'type'>

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Accordion = ({ children, defaultValue, ...props }: AccordionProps) => {
  useEffect(() => {
    if (props.values) {
      setValue(props.values)
    }
  }, [props.values])

  const [values, setValue] = useState(defaultValue ?? [])
  return (
    <AccordionPrimitive.Root {...props} type="multiple" value={values} onValueChange={setValue}>
      {typeof children === 'function' ? children({ values, setValue }) : children}
    </AccordionPrimitive.Root>
  )
}

Accordion.Item = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => <AccordionPrimitive.Item ref={ref} className={cx(className)} {...props} />)
Accordion.Item.displayName = 'AccordionItem'

Accordion.Trigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex hover:underline">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cx('group flex flex-grow items-center py-2', className)}
      {...props}
    >
      <ChevronRightIcon className="mr-1 h-4 w-4 transition-transform  duration-200 group-data-[state=open]:rotate-90" />
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
Accordion.Trigger.displayName = AccordionPrimitive.Trigger.displayName

Accordion.Content = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    {...props}
    asChild
    className={cx(
      'relative my-4 overflow-hidden pl-6 text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
      className
    )}
  >
    <div className="before:absolute before:bottom-auto before:left-2 before:top-auto before:h-full before:w-px before:bg-gray-5 before:pb-4">
      {children}
    </div>
  </AccordionPrimitive.Content>
))
Accordion.Content.displayName = AccordionPrimitive.Content.displayName
