import { Button } from '@botpress/ui-kit'
import { Flex, Switch, Text } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchat } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey } from '~/services'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v1/advanced-settings')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { config, isPending, setConfig, updateWebchatIntegration } = useWebchat({
    botId,
    workspaceId,
  })

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection title="Advanced Settings" description="Configure advanced settings for your webchat.">
        <Flex direction={'column'} gap={'4'}>
          <Flex gap={'2'}>
            <Switch
              checked={config.showBotInfoPage}
              onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, showBotInfoPage: checked }))}
            />
            <Text size={'2'} color="gray">
              Show Bot Information
            </Text>
          </Flex>
          <Flex gap={'2'}>
            <Switch
              checked={config.showPoweredBy}
              onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, showPoweredBy: checked }))}
            />
            <Text size={'2'} color="gray">
              Show "Created with Botpress"
            </Text>
          </Flex>
          <Flex gap={'2'}>
            <Switch
              checked={config.useSessionStorage}
              onCheckedChange={(checked) => setConfig((prevData) => ({ ...prevData, useSessionStorage: checked }))}
            />
            <Text size={'2'} color="gray">
              Clear conversation on exit
            </Text>
          </Flex>
          <Flex gap={'2'}>
            <Switch
              checked={config.enableConversationDeletion}
              onCheckedChange={(checked) =>
                setConfig((prevData) => ({ ...prevData, enableConversationDeletion: checked }))
              }
            />
            <Text size={'2'} color="gray">
              Allow user to delete conversation
            </Text>
          </Flex>
        </Flex>
      </ConfigSection>
      <Button
        loading={isPending}
        onClick={() => {
          updateWebchatIntegration(config)
          void queryClient.invalidateQueries({
            queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
              workspaceId,
              botId,
            }),
          })
        }}
        className="self-end"
      >
        <Text>Save</Text>
      </Button>
    </Flex>
  )
}
