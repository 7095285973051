import cx from 'classnames'
import { motion } from 'framer-motion'

const duration = 0.2

const backgroundVariants = {
  initial: { height: 0, border: 0 },
  animate: { height: 'auto', border: '1px', transition: { duration, ease: 'easeInOut' } },
  exit: { height: 0, border: 0, transition: { duration, delay: duration / 2, ease: 'easeInOut' } },
}

const textVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration, delay: duration / 2, ease: 'easeInOut' } },
  exit: { opacity: 0, transition: { duration, ease: 'easeInOut' } },
}
export const InputErrorMessage = (props: React.ComponentProps<typeof motion.div>) => {
  return (
    <motion.div
      variants={backgroundVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      {...props}
      z-index={-1}
      className={cx(
        'relative -top-2 rounded-b-md border border-red-50 bg-red-50 text-xs text-red-700',
        props.className
      )}
      layout
    >
      <motion.div className="p-2 pt-4" variants={textVariants} layout>
        {props.children}
      </motion.div>
    </motion.div>
  )
}
