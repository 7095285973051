import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type DialogContextValue = {
  state: [boolean, Dispatch<SetStateAction<boolean>>]
  transitionDuration: number
}
export const DialogContext = createContext<DialogContextValue | null>(null)

export function useDialogContext() {
  const context = useContext(DialogContext)
  if (!context) {
    throw new Error(
      'useDialogContext must be used within a DialogContextProvider. \n Dialog.* components must be used within a Dialog component.'
    )
  }
  return context
}
