import { Dispatch, SetStateAction, createContext, useContext } from 'react'

type PopoverContextValue = {
  state: [boolean, Dispatch<SetStateAction<boolean>>]
}
export const PopoverContext = createContext<PopoverContextValue | null>(null)

export function usePopoverContext() {
  const context = useContext(PopoverContext)
  if (!context) {
    throw new Error(
      'usePopoverContext must be used within a PopoverContextProvider. \n Popover.* components must be used within a Popover component.'
    )
  }
  return context
}
