import { Popover as RadixPopover } from '@radix-ui/themes'
import { ComponentProps } from 'react'

type Props = {
  children?: React.ReactNode
  trigger: React.ReactNode
} & Pick<ComponentProps<typeof RadixPopover.Root>, 'defaultOpen' | 'modal' | 'onOpenChange' | 'open'> &
  ComponentProps<typeof RadixPopover.Content>

export const Popover = ({ children, trigger, defaultOpen, modal, onOpenChange, open, ...props }: Props) => {
  return (
    <RadixPopover.Root {...{ defaultOpen, modal, onOpenChange, open }}>
      <RadixPopover.Trigger>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Content {...props}>{children}</RadixPopover.Content>
    </RadixPopover.Root>
  )
}
