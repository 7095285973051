import { ColumnBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage, withBubble, WithBubble } from './Utils'
import { nanoid } from 'nanoid'

//TODO: Fix choice title that will not show up
type ChoiceBlockType = ColumnBlock
export const ChoiceSchema = BaseMessage.extend({
  type: z.literal('choice'),
  payload: z.object({
    text: z.string().min(1),
    options: z.array(
      z.object({
        label: z.string().min(1),
        value: z.string().min(1),
      })
    ),
  }),
})
  .transform(({ payload: { text, options }, ...props }) => {
    return {
      ...props,
      block: withBubble({
        type: 'column' as const,
        blocks: [
          {
            type: 'text' as const,
            text,
          },
          {
            type: 'row' as const,
            blocks: options.map(({ label, value }) => ({
              type: 'button' as const,
              variant: 'action' as const,
              text: label,
              buttonValue: value,
              groupId: nanoid(),
            })),
          },
        ],
      }),
    }
  })
  .transform<MessageObject<WithBubble<ChoiceBlockType>>>(ToMessage)
