import cn from 'classnames'

const DropdownButton = ({
  children,
  isActive,
  onClick,
  disabled,
  className,
}: {
  children: React.ReactNode
  isActive?: boolean
  onClick?: () => void
  disabled?: boolean
  className?: string
}) => {
  const buttonClass = cn(
    'flex items-center gap-2 p-1.5 text-sm font-medium text-gray-11 text-left bg-transparent w-full rounded',
    {
      'hover:bg-gray-4 hover:text-gray-11': !isActive && !disabled,
      'bg-gray-4 text-gray-12': isActive && !disabled,
      'text-gray-11 cursor-not-allowed': disabled,
      [className || '']: !!className,
    }
  )

  return (
    <button className={buttonClass} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}

export { DropdownButton }
