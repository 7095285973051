import { type ReactNode } from 'react'
import { Dialog as RadixDialog, Text } from '@radix-ui/themes'
import { content } from './dialog.module.css'

type DialogProps = {
  title?: string
  children?: ReactNode
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  size?: '1' | '2' | '3' | '4' | '5'
}

export const Dialog = ({ children, isOpen, size = '2', setIsOpen, title }: DialogProps) => {
  return (
    <RadixDialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <RadixDialog.Content data-size={size} className={content}>
        <RadixDialog.Title>{title}</RadixDialog.Title>
        <Text size={'2'}>{children}</Text>
      </RadixDialog.Content>
    </RadixDialog.Root>
  )
}
