import { LocationBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

//TODO: Decide what to do with title and address and fix ui when they are empty
export const LocationSchema = BaseMessage.extend({
  type: z.literal('location'),
  payload: z.object({
    latitude: z.number(),
    longitude: z.number(),
    address: z.string().optional(),
    title: z.string().optional(),
  }),
})
  .transform(({ type, payload: { latitude, longitude, title, address }, ...props }) => ({
    ...props,
    block: {
      type,
      latitude,
      longitude,
      title: title ?? address ?? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    },
  }))
  .transform<MessageObject<LocationBlock>>(ToMessage)
