import { Role, Permission } from '../types'

const viewerPermissions: Permission[] = ['bots.view', 'workspace.view', 'integrations.view', 'studio.view']
const billingPermissions: Permission[] = ['bots.view', 'workspace.view', 'integrations.view', 'workspace.manageBilling']
const developerPermissions: Permission[] = [
  ...viewerPermissions,
  'bots.create',
  'bots.update',
  'bots.delete',
  'bots.logs',
  'studio.view',
  'studio.edit',
]
const managerPermissions: Permission[] = [
  ...developerPermissions,
  'bots.transfer',
  'workspace.manageBilling',
  'workspace.audit',
  'studio.view',
  'studio.edit',
]

const administratorPermissions: Permission[] = [
  ...managerPermissions,
  'workspace.update',
  'workspace.manageMembers',
  'integrations.create',
  'integrations.update',
  'integrations.delete',
  'studio.view',
  'studio.edit',
]

const permissionsByRole: { [key in Role]: Permission[] } = {
  administrator: administratorPermissions,
  billing: billingPermissions,
  developer: developerPermissions,
  manager: managerPermissions,
  owner: [],
  viewer: viewerPermissions,
}

export function isAuthorized({ role, permission }: { role?: Role; permission: Permission }): boolean {
  if (role === 'owner') {
    return true
  }

  if (!role) {
    return false
  }

  return permissionsByRole[role].includes(permission)
}
