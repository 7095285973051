import { infiniteQueryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getApiClientForBot } from '../client'
import { Event } from 'botpress-client'

export type listEventsInfiniteQueryProps = {
  workspaceId: string
  botId: string
  conversationId?: string
  messageId?: string
  type?: string
  userId?: string
  status?: Event['status']
}

export const listEventsInfiniteQueryOptions = ({ workspaceId, botId, ...props }: listEventsInfiniteQueryProps) => {
  const queryKeys = useQueryKeys()
  return infiniteQueryOptions({
    staleTime: cacheDuration.medium,
    queryKey: queryKeys.listEvents({ workspaceId, botId, ...props }),
    queryFn: async ({ pageParam }) =>
      getApiClientForBot({ workspaceId, botId })
        .listEvents({ nextToken: pageParam, ...props })
        .catch((e) => {
          console.error('Failed to fetch events', e)
          return { events: [], meta: { nextToken: null } }
        }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
  })
}

export const getEventQueryOptions = ({
  workspaceId,
  botId,
  eventId,
}: {
  workspaceId: string
  botId: string
  eventId: string
}) => {
  const queryKeys = useQueryKeys()
  return {
    staleTime: cacheDuration.short,
    queryKey: queryKeys.getEvent({ workspaceId, botId, eventId }),
    queryFn: async () => getApiClientForBot({ workspaceId, botId }).getEvent({ id: eventId }),
  }
}
