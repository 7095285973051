import { PropsWithChildren, ReactNode } from 'react'

type DetailSectionProps = { title: string; description: ReactNode; icon?: ReactNode }

export const DetailSection = ({ title, description, icon, children }: PropsWithChildren<DetailSectionProps>) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <h3 className="text-sm text-gray-12">{title}</h3>
        {icon}
      </div>
      <p className="text-sm text-gray-11">{description}</p>
      {children}
    </div>
  )
}
