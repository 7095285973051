import { useQuery } from '@tanstack/react-query'
import { getQueryKey, queryFunctions } from '~/services'
import { cacheDuration } from '~/shared'

export function useBillingInfo(workspaceId: string) {
  const { data } = useQuery({
    queryKey: getQueryKey('workspaces_/$workspaceId_/billingInfo', { workspaceId }),
    queryFn: async () => queryFunctions['workspaces_/$workspaceId_/billingInfo']({ workspaceId }),
    staleTime: cacheDuration.medium,
  })

  return data ?? null
}
