import { useRef } from 'react'
import { Input } from '~/elements'
import { SocialIcon } from './SocialIcon'

export function SocialAccountInput(props: React.HTMLProps<HTMLInputElement>) {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Input
      className="mb-1"
      {...props}
      ref={inputRef}
      leadingIcon={<SocialIcon size={24} account={inputRef.current?.value ?? ''} />}
      placeholder="Link to Social Profile"
    />
  )
}
