import { Flex } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useMemo } from 'react'
import { getApiClient } from '~/client'
import { CodeSnippet } from '~/components'
import { Spinner } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { config } from '~/shared'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v1/share')({
  component: Component,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()

  const shareableLink = useMemo(() => `${config.cdnBaseUrl}/webchat/v2.1/shareable.html?botId=${botId}`, [botId])

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection
        title="Shareable Link"
        description="Share the following link with people that would like to quickly test your chatbot."
        linkUrl={shareableLink}
        linkLabel="View"
      >
        <WebChatCode {...{ workspaceId, botId }} codeType="sharableUrl" />
      </ConfigSection>
      <ConfigSection
        title="Embed code"
        description="Copy and paste this code on your webpage."
        linkUrl="https://botpress.com/docs/developers/webchat-v2/embedded/"
        linkLabel="Documentation"
      >
        <WebChatCode {...{ workspaceId, botId }} codeType="preconfigured" />
      </ConfigSection>
    </Flex>
  )
}

/** @deprecated this is legacy code from v1 */
const WebChatCode = ({
  codeType,
  workspaceId,
  botId,
}: {
  codeType: 'preconfigured' | 'configurable' | 'sharableUrl'
  workspaceId: string
  botId: string
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['webchat', codeType],
    queryFn: () => getApiClient(workspaceId).getBotWebchat({ id: botId, type: codeType }),
  })

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }
  //TODO: make this look nicer
  if (isError) {
    return <CodeSnippet code={'Unable to load webchat code'} />
  }
  return <CodeSnippet code={data?.code ?? ''} />
}
