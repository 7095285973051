import { useLayoutEffect, useRef } from 'react'
import { createFileRoute } from '@tanstack/react-router'
import Editor, { Monaco } from '@monaco-editor/react'
import { Popover, Select } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchat } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey } from '~/services'
import { Flex, Text, SegmentedControl, Slider } from '@radix-ui/themes'
import { HexColorPicker } from 'react-colorful'
import {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
} from '@radix-ui/colors'
import { useWebchatStore, useLocalStore } from '~/stores'
import { HexColorInput } from '~/componentsV2'
import { webchatClasses } from '@botpress/webchat'
import { Button } from '@botpress/ui-kit'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/v2/theme')({
  component: Component,
})

export const baseColors = {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
} as const

const pickerColors = Object.values(baseColors).map((palette) => Object.values(palette)[8] ?? '')

type StandaloneEditor = ReturnType<Monaco['editor']['create']>

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { config, isPending, updateWebchatIntegration } = useWebchat({ botId, workspaceId })
  const theme = useWebchatStore((state) => state.theme)
  const additionalStylesheet = useWebchatStore((state) => state.additionalStylesheet)
  const setColor = useWebchatStore((state) => state.setPrimaryColor)
  const setThemeMode = useWebchatStore((state) => state.setThemeMode)
  const setVariant = useWebchatStore((state) => state.setVariant)
  const setFont = useWebchatStore((state) => state.setFontFamily)
  const setRadius = useWebchatStore((state) => state.setBorderRadiusScale)
  const setExtraStyles = useWebchatStore((state) => state.setExtraStyles)
  const darkMode = useLocalStore((s) => s.theme)
  const editorRef = useRef<StandaloneEditor | null>(null)

  useLayoutEffect(() => {
    setExtraStyles(config.additionalStylesheetUrlContent ?? '')
  }, [config.additionalStylesheetUrlContent, setExtraStyles])

  const handleEditorDidMount = (editor: StandaloneEditor, monaco: Monaco) => {
    editorRef.current = editor

    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, () => {
      formatEditor()
    })

    monaco.languages.css.cssDefaults.setOptions({
      validate: true,
    })

    monaco.languages.registerCompletionItemProvider('css', {
      provideCompletionItems: () => {
        const suggestions: any[] = []

        function traverseClasses(obj: Record<string, any>) {
          for (const key in obj) {
            const value = obj[key]
            if (value && typeof value === 'object') {
              traverseClasses(value)
            } else if (key === 'className' && typeof value === 'string') {
              const classes = value.split(' ')
              for (const className of classes) {
                if (!suggestions.some((s) => s.label === `.${className}`)) {
                  suggestions.push({
                    label: `.${className}`,
                    kind: monaco.languages.CompletionItemKind.Class,
                    insertText: `.${className}`,
                  })
                }
              }
            }
          }
        }
        traverseClasses(webchatClasses)

        return { suggestions }
      },
    })
  }

  const formatEditor = () => {
    editorRef.current?.getAction('editor.action.formatDocument')?.run()
  }

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection title="Theme styler" description="Customize the look and feel of your bot.">
        <Flex direction={'column'} gap={'4'}>
          <Flex direction={'column'} gap={'1'}>
            <Text size={'2'} color="gray">
              Theme
            </Text>
            <Flex align={'center'} gap={'4'}>
              <Select
                variant="soft"
                value={theme.themeMode}
                onValueChange={(value) => {
                  setThemeMode(value as 'light' | 'dark')
                }}
                color="gray"
                items={[
                  { type: 'item', value: 'light', content: 'Light' },
                  { type: 'item', value: 'dark', content: 'Dark' },
                ]}
              />
              <Popover
                className="flex w-min flex-col gap-2"
                trigger={<div className="size-7 cursor-pointer rounded" style={{ background: theme.primaryColor }} />}
              >
                <HexColorPicker color={theme.primaryColor} onChange={setColor} />
                <Flex wrap={'wrap'} className="gap-1.5">
                  {pickerColors.map((presetColor) => (
                    <button
                      type="button"
                      key={presetColor}
                      className="size-7 rounded"
                      style={{ background: presetColor }}
                      onClick={() => setColor(presetColor)}
                    />
                  ))}
                </Flex>
                <HexColorInput value={theme.primaryColor} onChange={setColor} />
              </Popover>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-fit">
            <Text size={'2'} color="gray">
              Variant
            </Text>
            <SegmentedControl.Root
              value={theme.variant}
              onValueChange={(value) => {
                setVariant(value as 'soft' | 'solid')
              }}
            >
              <SegmentedControl.Item value="soft">Soft</SegmentedControl.Item>
              <SegmentedControl.Item value="solid">Solid</SegmentedControl.Item>
            </SegmentedControl.Root>
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-64">
            <Text size={'2'} color="gray">
              Radius
            </Text>
            <Slider
              value={[theme.borderRadiusScale]}
              onValueChange={(value) => {
                setRadius(value[0] ?? 1)
              }}
              min={0.5}
              max={4}
              step={0.5}
            />
          </Flex>
          <Flex direction={'column'} gap={'1'} className="w-32">
            <Text size={'2'} color="gray">
              Font
            </Text>
            <Select
              variant="soft"
              value={theme.fontFamily}
              onValueChange={(value) => {
                setFont(value as 'rubik' | 'inter' | 'ibm' | 'fira')
              }}
              color="gray"
              items={[
                { type: 'item', value: 'rubik', content: 'Rubik' },
                { type: 'item', value: 'inter', content: 'Inter' },
                { type: 'item', value: 'ibm', content: 'IBM Plex Sans' },
                { type: 'item', value: 'fira', content: 'Fira Code' },
              ]}
            />
          </Flex>
        </Flex>
      </ConfigSection>
      <ConfigSection title="Styles" description="Custom styles for your bot.">
        <Flex gap="4" direction={'column'}>
          <div className="rounded-sm border border-gray-6 py-1">
            <Editor
              height={400}
              language="css"
              theme={darkMode === 'dark' ? 'vs-dark' : 'light'}
              defaultValue="/* Your CSS styles here */"
              value={additionalStylesheet}
              onChange={(value) => setExtraStyles(value ?? '')}
              onMount={handleEditorDidMount}
            />
          </div>

          <Button
            loading={isPending}
            onClick={() => {
              formatEditor()
              updateWebchatIntegration({ ...config, ...theme, additionalStylesheet })
              void queryClient.invalidateQueries({
                queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
                  workspaceId,
                  botId,
                }),
              })
            }}
            className="self-end"
          >
            <Text>Save</Text>
          </Button>
        </Flex>
      </ConfigSection>
    </Flex>
  )
}
