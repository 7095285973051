import { Editor } from '@tiptap/react'

import { HorizontalRule, ImageBlock, ImageUpload, Link, CodeBlock } from '../extensions'
import Suggestion from '@tiptap/suggestion'

const isTableGripSelected = (node: HTMLElement) => {
  let container: HTMLElement | null = node

  while (container && !['TD', 'TH'].includes(container.tagName)) {
    container = container?.parentElement
  }

  const gripColumn = container?.querySelector?.('a.grip-column.selected')
  const gripRow = container?.querySelector?.('a.grip-row.selected')

  if (gripColumn || gripRow) {
    return true
  }

  return false
}

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    Link.name,
    Suggestion.name,
  ]

  return customNodes.some((type) => editor.isActive(type)) || isTableGripSelected(node)
}

export default isCustomNodeSelected
