import { z } from 'zod'
import { nanoid } from 'nanoid'
import { minH, minW } from '../constants'

export type DateRangeShortcut = { label: string; dateRange: { from: Date; to: Date } }

export const chartTypes = ['area', 'donut', 'bar', 'pie', 'metric', 'spark_bar', 'spark_area'] as const
export type ChartType = (typeof chartTypes)[number]

export const chartDateShortcuts = [
  'lastYear',
  'last6months',
  'last3months',
  'lastMonth',
  'lastWeek',
  'lastday',
] as const

export type ChartDateShortcut = (typeof chartDateShortcuts)[number]

export const dateShortcutDetails: Record<ChartDateShortcut, { label: string; days: number }> = {
  lastMonth: { label: 'Last 30 Days', days: 30 },
  lastWeek: { label: 'Last 7 Days', days: 7 },
  lastday: { label: 'Last Day', days: 1 },
  last3months: { label: 'Last 90 Days', days: 90 },
  last6months: { label: 'Last 6 Months', days: 180 },
  lastYear: { label: 'Last Year', days: 365 },
}

export type AnalyticsBoardConfig = z.infer<typeof analyticsBoardConfigSchema>
export type ChartConfig = AnalyticsBoardConfig['charts'][number]

export const analyticsBoardConfigSchema = z.object({
  version: z.number().catch(() => 1),
  layouts: z
    .object({
      lg: z.array(
        z.object({
          i: z.string().catch(() => nanoid()),
          x: z.number().catch(() => 0),
          y: z.number().catch(() => 0),
          w: z.number().catch(() => 0),
          h: z.number().catch(() => 0),
          minW: z
            .number()
            .optional()
            .catch(() => minW),
          maxW: z
            .number()
            .optional()
            .catch(() => 12),
          minH: z
            .number()
            .optional()
            .catch(() => minH),
          maxH: z
            .number()
            .optional()
            .catch(() => 12),
        })
      ),
      sm: z.array(
        z.object({
          i: z.string().catch(() => nanoid()),
          x: z.number().catch(() => 0),
          y: z.number().catch(() => 0),
          w: z.number().catch(() => 0),
          h: z.number().catch(() => 0),
          minW: z.number().optional(),
          maxW: z.number().optional(),
          minH: z
            .number()
            .optional()
            .catch(() => minH),
          maxH: z
            .number()
            .optional()
            .catch(() => 12),
        })
      ),
    })
    .catch(() => ({ lg: [], sm: [] })),
  charts: z
    .array(
      z.object({
        id: z.string().catch(() => nanoid()),
        title: z.string().catch(() => ''),
        type: z.enum(chartTypes).catch(() => 'area' as const),
        values: z.array(z.string()).catch(() => []),
        showLegend: z
          .boolean()
          .optional()
          .catch(() => true),
        dateRange: z
          .object({
            start: z
              .string()
              .optional()
              .catch(() => undefined),
            end: z
              .string()
              .optional()
              .catch(() => undefined),
            shortcut: z
              .enum(chartDateShortcuts)
              .optional()
              .catch(() => 'lastMonth' as const),
          })
          .catch(() => ({ shortcut: 'lastMonth' }) as const),
      })
    )
    .catch(() => []),
})
