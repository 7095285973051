import { FileBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'

export const FileSchema = BaseMessage.extend({
  type: z.literal('file'),
  payload: z.object({
    fileUrl: z.string().min(1),
    title: z.string().min(1).optional(),
  }),
})
  .transform(({ type, payload: { fileUrl }, ...props }) => ({ ...props, block: { type, url: fileUrl } }))
  .transform<MessageObject<FileBlock>>(ToMessage)
