import { createFileRoute } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Logs } from '~/components'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/logs')({
  component: Component,
})

function Component() {
  const { workspaceId, integrationId } = Route.useParams()
  const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toJSDate())
  const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toJSDate())

  return (
    <div className="relative h-[calc(100vh-14rem)]">
      <Logs
        id={integrationId}
        type="integration"
        workspaceId={workspaceId}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    </div>
  )
}
