import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Navigate } from '@tanstack/react-router'
import { messageQueryOptions } from '../../../../../../../queries'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/conversations/message/$messageId')({
  component: Component,
})

function Component() {
  const { messageId, botId, workspaceId } = Route.useParams()
  const { message } = useSuspenseQuery(messageQueryOptions({ messageId, botId, workspaceId })).data
  return (
    <Navigate
      to="/workspaces/$workspaceId/bots/$botId/conversations/$conversationId"
      replace
      params={{ botId, workspaceId, conversationId: message.conversationId }}
      search={{ messageId }}
    />
  )
}
