import { Badge } from '@radix-ui/themes'
import { conversationStatusesMap } from '~/features/hitl/constants'
import type { ConversationStatus } from '~/features/hitl/types'
import { cn } from '~/utils'

type Props = { className?: string; status: ConversationStatus }

export const StatusBadge = ({ className, status, ...props }: Props) => {
  const { color: statusColor, title: statusTitle } = conversationStatusesMap[status]

  return (
    <Badge variant={'soft'} color={statusColor} className={cn(className)} {...props}>
      {statusTitle}
    </Badge>
  )
}
