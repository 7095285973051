import { useTextmenuCommands } from './hooks/useTextmenuCommands'
import { useTextmenuStates } from './hooks/useTextmenuStates'
import { BubbleMenu, Editor } from '@tiptap/react'
import { memo } from 'react'
import { FontFamilyPicker } from './components/FontFamilyPicker'
import { FontSizePicker } from './components/FontSizePicker'
import { ContentTypePicker } from './components/ContentTypePicker'
import { EditLinkPopover } from './components/EditLinkPopover'
import { LuCode2, LuHighlighter, LuPalette, LuSquareCode } from 'react-icons/lu'
import { RxFontBold, RxFontItalic, RxStrikethrough, RxUnderline } from 'react-icons/rx'
import { ColorPickerPopover } from './components/ColorpickerPopover'
import { TextAlignmentMenu } from './components/TextAlignmentPopover'
import { Card } from '@radix-ui/themes'
import { ToolbarButton } from '../../ui/ToolbarButton'

// memo button to prevent re-rendering
const MemoButton = memo(ToolbarButton)
const MemoColorPicker = memo(ColorPickerPopover)
const MemoFontFamilyPicker = memo(FontFamilyPicker)
const MemoFontSizePicker = memo(FontSizePicker)
const MemoContentTypePicker = memo(ContentTypePicker)
const MemoTextAlignmentMenu = memo(TextAlignmentMenu)

export type TextMenuProps = {
  editor: Editor
}

export const TextMenu = ({ editor }: TextMenuProps) => {
  const commands = useTextmenuCommands(editor)
  const states = useTextmenuStates(editor)

  return (
    <BubbleMenu
      tippyOptions={{
        popperOptions: { placement: 'auto-start' },
        appendTo: () => document.querySelector('.rt-ContainerInner')!,
        maxWidth: 'none',
      }}
      editor={editor}
      pluginKey="textMenu"
      shouldShow={states.shouldShow}
      updateDelay={100}
    >
      <Card className="border-none bg-gray-1 shadow-sm [&_>div]:flex [&_>div]:p-2">
        <MemoContentTypePicker editor={editor} />
        <MemoFontFamilyPicker onChange={commands.onSetFont} value={states.currentFont || ''} />
        <MemoFontSizePicker onChange={commands.onSetFontSize} value={states.currentSize || ''} />
        <MemoTextAlignmentMenu commands={commands} states={states} />
        <MemoButton tooltip="Bold" tooltipShortcut={['Mod', 'B']} onClick={commands.onBold} active={states.isBold}>
          <RxFontBold />
        </MemoButton>
        <MemoButton
          tooltip="Italic"
          tooltipShortcut={['Mod', 'I']}
          onClick={commands.onItalic}
          active={states.isItalic}
        >
          <RxFontItalic />
        </MemoButton>
        <MemoButton
          tooltip="Underline"
          tooltipShortcut={['Mod', 'U']}
          onClick={commands.onUnderline}
          active={states.isUnderline}
        >
          <RxUnderline />
        </MemoButton>
        <MemoButton
          tooltip="Strikehrough"
          tooltipShortcut={['Mod', 'X']}
          onClick={commands.onStrike}
          active={states.isStrike}
        >
          <RxStrikethrough />
        </MemoButton>
        <MemoButton tooltip="Code" tooltipShortcut={['Mod', 'E']} onClick={commands.onCode} active={states.isCode}>
          <LuCode2 />
        </MemoButton>
        <MemoButton tooltip="Code block" onClick={commands.onCodeBlock}>
          <LuSquareCode />
        </MemoButton>
        <EditLinkPopover onSetLink={commands.onLink} />
        <MemoColorPicker
          currentColor={states.currentHighlight}
          onChange={commands.onChangeHighlight}
          icon={<LuHighlighter />}
        />
        <MemoColorPicker currentColor={states.currentColor} onChange={commands.onChangeColor} icon={<LuPalette />} />
      </Card>
    </BubbleMenu>
  )
}
