import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { Text } from '@radix-ui/themes'

type Props = {
  errorMessage?: string
}

export const OAuthError = ({ errorMessage }: Props) => (
  <>
    <div className="mt-auto w-min rounded-full border-2 border-red-8 bg-red-2 p-6">
      <HiOutlineExclamationTriangle className="h-16 w-16 text-red-9" />
    </div>

    <Text color="gray" size={'4'} mt={'6'}>
      Authentication failed! Please try again
    </Text>
    {errorMessage && (
      <Text size={'2'} color="gray">
        {errorMessage}
      </Text>
    )}
  </>
)
