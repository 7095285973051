import { Dispatch, SetStateAction, createContext, useContext } from 'react'

export type BotPageLayoutState = Record<string, { startDate: Date; endDate: Date }>

type BotPageLayoutValue = {
  state: [BotPageLayoutState, Dispatch<SetStateAction<BotPageLayoutState>>]
}
export const BotPageLayoutContext = createContext<BotPageLayoutValue | null>(null)

export function useBotPageLayoutContext() {
  const context = useContext(BotPageLayoutContext)
  if (!context) {
    throw new Error(
      'useBotPageLayoutContext must be used within a BotPageLayoutContextProvider. \n BotPageLayout.* components must be used within a BotPageLayout component.'
    )
  }
  return context
}
