import { infiniteQueryOptions } from '@tanstack/react-query'
import { getApiClient } from '~/client'
import { useQueryKeys } from '~/hooks'
import { cacheDuration } from '~/shared'

export const listAuditsInfiniteQueryOptions = ({ workspaceId }: { workspaceId: string }) => {
  const queryKeys = useQueryKeys()
  return infiniteQueryOptions({
    staleTime: cacheDuration.short,
    queryKey: queryKeys.listAudits(workspaceId),
    queryFn: async ({ pageParam }) =>
      getApiClient(workspaceId)
        .getAuditRecords({ nextToken: pageParam, id: workspaceId })
        .catch((e) => {
          console.error('Failed to fetch audits', e)
          return { records: [], meta: { nextToken: null } }
        }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
  })
}
