import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { Issue } from 'botpress-client'
import { getApiClientForBot } from '~/client'
import { useQueryKeys } from '~/hooks'

export const useResolveIssues = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const apiClient = getApiClientForBot({ botId, workspaceId })
  const queryClient = useQueryClient()
  const { listIssueEvents, listAllIssues } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ issueIds }: { issueIds: string[] }) => {
      await Promise.all(issueIds.map((i) => apiClient.deleteBotIssue({ id: botId, issueId: i })))
    },
    onSuccess: (_data, variables) => {
      queryClient.setQueryData<Issue[]>(listAllIssues({ botId, workspaceId }), (prevData) => {
        if (!prevData) {
          return
        }
        return prevData.filter((i) => !variables.issueIds.includes(i.id))
      })

      for (const issueId of variables.issueIds) {
        queryClient.removeQueries({ queryKey: listIssueEvents({ botId, issueId, workspaceId }) })
      }
    },
  })
}
