import { queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { cacheDuration } from '../shared'
import { getBot, listBots } from '../features/bots/services'
import { getQueryKey } from '~/services'

type BotQueryProps = {
  botId: string
  workspaceId: string
}
export const botQueryOptions = ({ botId, workspaceId }: BotQueryProps) => {
  const { getBot: getBotQueryKey } = useQueryKeys()
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getBotQueryKey(botId),
    queryFn: async () => getBot({ workspaceId, botId }),
    meta: {
      suppressError: true,
    },
  })
}

export const listBotsQueryOptions = (workspaceId: string) => {
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getQueryKey('workspaces_/$workspaceId_/bots_', { workspaceId }),
    queryFn: async () => listBots(workspaceId),
    meta: {
      suppressError: true,
    },
  })
}
