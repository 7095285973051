import { Button } from '~/elements'
import { EmptyState } from './EmptyState'

type Props = {
  navigateToHome: () => void
  navigateToPrevious?: () => void
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const NotFound = ({ navigateToHome, navigateToPrevious }: Props) => (
  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '550px', margin: '0 auto' }}>
      <EmptyState
        icon="error"
        title={'Not Found'}
        message={'Sorry, we could not find the page you were looking for.'}
      />
      <div className="flex justify-center">
        {navigateToPrevious && (
          <Button variant="tertiary" size="small" className="mr-2" onClick={() => navigateToPrevious()}>
            Back
          </Button>
        )}
        <Button size="small" onClick={() => navigateToHome()}>
          Home
        </Button>
      </div>
    </div>
  </div>
)
